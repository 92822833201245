import { observer } from 'mobx-react';
import React, { Component } from 'react';
import './apoienos.css';

import { lng } from '../ulanguages/language';
import { Helmet } from 'react-helmet-async';
import { getImgAddress, getSiteShortName } from '../util/text';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import LinkV2 from '../view_component/LinkV2';
import { getUserRoute } from '../store/AppRoutes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MyAppPool from '../AppPool';


  @observer
  class ApoienosView extends Component 
  {
    componentDidMount()
    {
        ApoienosView.getInitialValues();
        MyAppPool.cCampaign.getSupporters();
    }
    static async getInitialValues()
    {
        //let p1 = MyAppPool.cUser.fetchUserById(userId);
        //let p2 = MyAppPool.cUser.getUserRecentTasks();
        //let p2 = MyAppPool.cReview.fetchReviews(contentTypeToFastType('anime'),animeid);
        //let p3 = MyAppPool.cReactionPosts.fetchReactionPosts(contentTypeToFastType('anime'),animeid);
        //await Promise.all([]);
        return Promise.resolve();
        //console.log("User:::",userId);
        }

    componentWillReceiveProps()
    {
        ApoienosView.getInitialValues();
    }

    render()
    {
        let meta = {title:'Home | '+getSiteShortName()+'',desc:'',img:'/favicon.ico',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
        let supporters = MyAppPool.session.campaign.supporters;

        let desc = lng('Conheça maneiras de apoiar a plataforma!');
        meta =  {
        title:lng('Home')+' | '+getSiteShortName()+'',
        desc:desc,
        keywords:'',
        img:'/favicon.ico',
        imgwidth:'225',
        imgheight:'338',
        imgtype:'jpg'
        };
        return <div className="apoienosview">
        <Helmet>
          <title>{meta.title}</title>
          <meta name="title" content={meta.title} />
          <meta name="description" content={meta.desc} />
          <meta name="keywords" content={meta.keywords}/>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.desc} />
          <meta name="twitter:title" content={meta.title}/>
          <meta name="twitter:description" content={meta.desc}/>
          <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
          <meta property="og:image" content={getImgAddress(meta.img)} />
          <meta property="og:image:type" content={meta.imgtype}/>
          <meta property="og:image:width" content={meta.imgwidth}/>
          <meta property="og:image:height" content={meta.imgheight}/> 
        </Helmet>
        <div className="contentpage">
            <ContentBannerSetter />
            <div className='uk-flex uk-flex-row contentArea'> 
                <div className="leftContent">
                    
                </div>
                <div className="middleContent" >
                    <div className="uk-card uk-card-default mainthread">
                        <div>
                            <img src="https://i.imgur.com/Gxv0XzB.png" className="avatarimg"></img>
                            <LinkV2 to={getUserRoute("1","Nanimaru","#top")} >Nanimaru</LinkV2>
                        </div>
                        <h1>{lng("Apoie o NossoAnime!")}</h1>
                        <h2>{lng("Porque precisamos da sua ajuda?")}</h2>
                        <span>{lng("_whydonatetext")}</span>
                        <h2>{lng("Links Patrocinados")}</h2>
                        <span>{lng("_sponsoredlinkstext")}</span>
                        <h2>{lng("O que eu ganho doando, apoiando?")}</h2>
                        <span>{lng("_whatigettext")}</span>
                        <a target="_blank" href="https://apoia.se/nossoanime">
                        <div className="apoiasewidget">
                            <img src={"/logomarufinal.png"}></img>
                        </div>
                        </a>

                        <h3>{lng("Atenção, doações e apoios são processadas manualmente e podem levar até 48 horas para serem ativadas")}</h3>

                        
                        <span>{lng("Caso sua conta de apoiador não seja ativada, mande um e-mail para: ")+"fbgomesdev@gmail.com"}</span>
                        <h1>{lng("Abençoados Apoiadores")}</h1>
                        <div className="flexDrop">
                        {supporters.map((elem, index)=>{
                            let date = new Date(elem.supportDate);
                            return <div className="supporterBlock">
                                <LazyLoadImage className="supporterImg" src={elem.image} />
                                <div style={{paddingLeft:"5px"}}>
                                {elem.name}<br/>
                                {lng("Desde")}: {date.toLocaleDateString()}
                                </div>
                                <br/>
                            </div>
                        })}
                        </div>
                    </div>
                </div>

                <div className="rightContent">

                </div>
            </div>
        </div>
        </div>
        
    }
  }
  
  export default ApoienosView;