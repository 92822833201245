//import "./SwitcherHeading.css";
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getSearchRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { getCurrentPath } from '../util/windowUtil';
import MyAppPool from './../AppPool';

class SwitcherHeading extends Component {
  componentDidMount()
  {

  }

  render(){
    let {heading,links} = this.props;
    let currentPath = getCurrentPath();
    return (
        <h1 id="searchTitle" className='smallerheading clickable'
                  onClick={()=>{
                    MyAppPool.cPopMenu.set(
                      <div className="pop_search_selector">
                        <ul  onClick={()=>MyAppPool.cPopMenu.deactivate()} >
                            {links.map((item, index) => {
                                if (currentPath===item.to)
                                return <></>;
                                return <li key={index}><Link to={item.to} onClick={this.props.onClick}>{item.text}</Link></li>;
                            })}
                        </ul>
                      </div>
                    );
                  }}
        >{heading} <i className="fas fa-chevron-down btnChevron"></i></h1>
    );
}
}


SwitcherHeading.propTypes = {
    heading:PropTypes.string.isRequired,
    onClick:PropTypes.func,
    links:PropTypes.arrayOf(PropTypes.shape({
      to: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }))
};
SwitcherHeading.defaultProps = {
    heading:'',
    onClick:()=>{},
    links:[
      {to:getSearchRoute('anime'),text:lng('Animes')},
      {to:getSearchRoute('manga'),text:lng('Mangas')},
      {to:getSearchRoute('music'),text:lng('Músicas')},
      {to:getSearchRoute('pessoa'),text:lng('Pessoas')},
      {to:getSearchRoute('personagem'),text:lng('Personagens')},
      {to:getSearchRoute('empresa'),text:lng('Empresas')},
      {to:getSearchRoute('revista'),text:lng('Revistas')},
      {to:getSearchRoute('usuario'),text:lng('Usuários')},
  ]
};
  
export default SwitcherHeading;

