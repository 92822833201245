import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { convertDataToShowable, getImgAddress, getRouteParamAtIndex,getSiteShortName } from '../util/text';
import { contentTypeToFastType, isSet, mangaFastResourceType } from '../util/typeu';
import BannerReaction from '../view_component/BannerReaction';
import BreadCumbs from '../view_component/BreadCumbs';
import CapaSection from '../view_component/CapaSection';
import Erro404 from '../view_component/Erro404';
import SkeletonBlock from '../view_component/SkeletonBlock';
import SubNavigation from '../view_component/SubNavigation';
import TagList from '../view_component/TagList';
import TextContentBox from '../view_component/TextContentBox';
import { isMobile } from './../util/mobile';
import { getCurrentUrl, isServer } from './../util/windowUtil';
import CSSTransition2 from './../view_component/CSSTransition2';

import AnimePersonagensView from './animesub/AnimePersonagensView';
import AnimeRelatedView from './animesub/AnimeRelatedView';
import AnimeReviewView from './animesub/AnimeReviewView';
import AnimeStaffView from './animesub/AnimeStaffView';
import MangaGeralView from './mangasub/MangaGeralView';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import DropDown from '../view_component/DropDown';
import { serverRegisterFetch } from '../util/updateManager';
import ResourceMediaView from "./resourceMedia";
import Capa from './../view_component/Capa';
import MergeFormManga from '../view_component/MergeFormManga';

function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}

@observer
class Manga extends Component {
  constructor(props){
    super(props);
    serverRegisterFetch(Manga.getInitialValues);
  }
  componentDidMount()
  {
    Manga.getInitialValues();
  }
  static async getInitialValues()
  {
    let mangaid = getRouteParamAtIndex(getCurrentUrl(),2);
    if (isSet(mangaid))
    {
      let resourceType = contentTypeToFastType('manga');
      let p1 = MyAppPool.cManga.fetchMangaById(mangaid);
      let p2 = MyAppPool.cReview.fetchReviews(resourceType,mangaid);
      let p3 = MyAppPool.cReactionPosts.fetchReactionPosts(resourceType,mangaid);
      let p4 = MyAppPool.cResourceMedia.getResourceMedia(mangaFastResourceType,mangaid);
      await Promise.all([p1,p2,p3,p4]);
    }
    return Promise.resolve();
  }
  componentWillReceiveProps()
  {
    Manga.getInitialValues();
  }

  render(){

    let current = MyAppPool.cManga.currentManga;
    let mangaid = getRouteParamAtIndex(getCurrentUrl(),2);
    let ready = current.ready;
    if (current.id!==Number(mangaid))
        ready=false;

    let tabBaseUrl='';
    if (ready)
      tabBaseUrl = current.baseUrl;
    let tabs = [
      {name:lng('Geral'),url:tabBaseUrl},
      {name:'Media',url:tabBaseUrl+'/'+'media'},
      {name:lng('Reviews'),url:tabBaseUrl+'/'+lng('reviews')},
      {name:lng('Personagens'),url:tabBaseUrl+'/'+lng('personagens')},
      {name:lng('Staff'),url:tabBaseUrl+'/'+lng('autores')},
      {name:lng('Relacionados'),url:tabBaseUrl+'/'+lng('obras-relacionadas')}
    ];

    if (current.ready)
    {
        let resourceType = mangaFastResourceType;
        let reviews = MyAppPool.session.review[resourceType][current.id];
        let reviewsSize=0;
        if (typeof reviews !=='undefined')
          reviewsSize=reviews.length;
      if (reviewsSize<=0)
        tabs = tabs.filter(e=>e.name!==lng('Reviews'));
    }

    let lists = [];
    let listPresence = [];
    let reactionPosts = [];
    let reactionReady = 0;
    let resourceType = contentTypeToFastType('manga');
    if (ready)
    {
      reactionPosts = MyAppPool.session.reactionPost[resourceType][current.id];
      if (typeof reactionPosts ==='undefined')
        {
          reactionPosts=[];
          reactionReady = 0;
        }else
          reactionReady = 1;   
        lists = MyAppPool.session.user.lists[resourceType];
        listPresence = MyAppPool.cLists.getListPresence(resourceType,current.id);
        if (!Array.isArray(lists))
          lists = [];
    }
    
    
        
    /*
      {name:lng('Social'),url:tabBaseUrl+'/'+lng('social')},
      {name:lng('Arte'),url:tabBaseUrl+'/'+lng('personagens')},
    */
      const trailerOpts = {
        height:vhToPixels(75),
        width: '100%',
        playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        enablejsapi:1,
        controls:1
        }
    };
    let meta = {title:lng('Manga')+' | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
   
    if (ready)
    {
      let desc = lng('Lançamento') + ': ' + convertDataToShowable(current.airfrom) +' '+lng('Sinopse')+': '+ current.description;
      meta =  {
        title:current.mainTitle +' - ' + lng(current.type) + ' | '+getSiteShortName()+'',
        desc:desc,
        keywords:current.mainTitle +  ', ' + current.otherTitles +', ' +current.remainingTitles,
        img:current.mainimg,
        imgwidth:'225',
        imgheight:'338',
        imgtype:'jpg'
      };
    }

    let bannerImages = ['random:sky',getImgAddress(current.mainimg)];
    let bannerModes = ['bg','capa'];
    let useCapa=false;
    if (isSet(current.bannerimg) && current.bannerimg.length>0)
    {
      bannerImages=[getImgAddress(current.bannerimg)];
      bannerModes=['bg'];
      useCapa=true;
    }

    if (current.empty)
      return <Erro404/>;
  
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
        <div className="contentpage">
        {ready ?
        <ContentBannerSetter images={bannerImages} modes={bannerModes}/>
        :<></>}
          <div>
          {!isMobile.any?
          <div style={{position:'relative'}}>
            <div className="reactions">
              <BannerReaction
                items ={reactionPosts}
                ready={reactionReady}
              />
            </div>
          </div>
          : <></>}
          </div>
          <div className='uk-flex uk-flex-row topsection'>
            {!isMobile.any?
            <div className="capa_left_column">
            <Capa ready={ready} img={current.mainimg}/>
            <CapaSection ready={ready} img={getImgAddress(current.mainimg,'/imgs/defaults/manga.webp')}
            resourceType={resourceType}
            resourceId={current.id}
            lists={lists}
            listsPresence ={listPresence}
            gradeable={true}
            />
            </div>
            :<></>
            }
            <div className="contentInfo">
            {isMobile.any?
            <div style={{"width":"100%"}}>
            {useCapa?<Capa ready={current.ready} img={current.mainimg}/>:<></>}
            <CapaSection ready={ready} img={current.mainimg} 
            resourceType={resourceType}
            resourceId={current.id}
            lists={lists}
            listsPresence ={listPresence}
            gradeable={true} /> </div>
            :<></>
            }
              <BreadCumbs/>
              {ready ?
               <>
                <h1 className="smallerheading"> <span id='title'>{current.mainTitle} &nbsp;</span>
                {MyAppPool.cAuth.isDataMod()? <>
                <DropDown
                btnContent={<button className="uk-button uk-button-default">{lng("Moderador")}</button>}>
                  <button onClick={()=>{MyAppPool.cTasks.createMalMangaSyncTask(current.id,false)}} style={{width:'200px'}} className='uk-button uk-button-default' >mal simple update</button>
                  <button onClick={()=>{MyAppPool.cTasks.createMalMangaSyncTask(current.id,true)}} style={{width:'200px'}} className='uk-button uk-button-default' >mal full update</button>
                  {current.ready && (current.characters.length>0 || current.people.length>0)?
                  <button onClick={()=>
                    MyAppPool.cModal.set(lng("Merge Manga"),
                      <MergeFormManga mangaid={current.id} coverImage={getImgAddress(current.mainimg)} date={current.airfrom} title={current.mainTitle} otherTitles={current.otherTitles}/>
                      ,null,'mergepop',()=>{})
                    } style={{width:'200px'}} className='uk-button uk-button-default' >{lng("Merge Manga")}</button>
                    :<></>
                  }
                  </DropDown></>:<></>
                }
                </h1>
                <h2 className="smallerheading2"> {current.otherTitles}</h2>
                <TextContentBox content={current.description}/>
                <TextContentBox content={current.background}/>
                <TagList tags={current.genres}/>
                </>
                :<>
                <SkeletonBlock style={{'width':'250px',height:'50px', 'marginTop':'8px'}}/>
                <SkeletonBlock style={{'width':'300px',height:'25px', 'marginTop':'8px'}}/>
                <SkeletonBlock style={{'width':'95%',height:'150px', 'marginTop':'15px'}}/>
                <SkeletonBlock style={{'width':'250px',height:'30px', 'marginTop':'15px'}}/>
                <SkeletonBlock style={{'width':'350px',height:'30px', 'marginTop':'15px'}}/>
                </>
                }
                </div>
          </div>
          
          <div className="naveg" data-uk-sticky="offset: 0">
            <div className = "uk-flex uk-flex-row"> 
            <SubNavigation items={tabs}/> 
            {!isMobile.any?
            <button aria-label={lng('MAB por MaxBrasi')} className="link-button tinylink">{lng('MAB por MaxBrasi')}</button>
            :<></>}
            </div>
            {isMobile.any?
            <button aria-label={lng('MAB por MaxBrasi')} className="link-button tinylink">{lng('MAB por MaxBrasi')}</button>
            :<></>}
          </div>

          <div className="authortag">
                    </div>
          {//!isMobile.any?
          //:<></>
        }
          
          
          <Route render={({ location }) => {
            const { pathname, key } = location;
            return (
              <div style={{position:'relative'}}>
                <CSSTransition2
                  
                  key={key}
                  classNames="switch-node"
                  timeout={{enter: 750, exit: 0}}
                >
                  <div>
                  <Switch>
                    <Route path={'/manga/:aid/:aname/'+lng('personagens')}>
                        <AnimePersonagensView current={current}/>
                    </Route>
                    <Route path={'/manga/:aid/:aname/'+lng('autores')}>
                      <AnimeStaffView current={current}/>
                    </Route>
                    <Route path={'/manga/:aid/:aname/'+lng('obras-relacionadas')}>
                      <AnimeRelatedView current={current}/>
                    </Route>
                    <Route path={'/manga/:aid/:aname/'+lng('reviews')}>
                      <AnimeReviewView current={current}/> 
                    </Route>
                    <Route path={'/manga/:aid/:aname/'+'media'}>
                      <ResourceMediaView resourceType={mangaFastResourceType} resourceId={current.id}/> 
                    </Route>
                    <Route path={'/manga/:aid/:aname/'+lng('social')}>
                      <SubNavigation/> 
                    </Route>
                    <Route path='/manga/:aid/:aname' exact={true}>
                      <MangaGeralView current={current}/>
                    </Route>
                    <Route path="*" render={() => <Erro404 />} />
                  </Switch>
                  </div>
                </CSSTransition2>
              </div>
          )
        }}/>

        </div>
        </div>
      );

}
}

export default Manga;
