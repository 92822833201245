import React, { Component } from 'react';
import { getCurrentLocale, lng } from '../ulanguages/language';
import { capitalizeFirstLetterAllWords, getImgAddress, getSiteShortName } from '../util/text';
import MyAppPool from '../AppPool';
import { serverRegisterFetch } from '../util/updateManager';
import { observer } from 'mobx-react';
import { isMobile } from '../util/mobile';
import RelatedWorks from '../view_component/RelatedWorks';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import './broadcastcalendar.css'
import { Helmet } from 'react-helmet-async';

@observer
class BroadcastCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      daysOfWeek: this.getWeekDays()
    };
    serverRegisterFetch (BroadcastCalendar.getInitialValues);
  }

  componentDidMount()
  {
    BroadcastCalendar.getInitialValues();
  }
  static async getInitialValues()
  {
    let p1 = MyAppPool.cAnime.getAnimBroadCastingThisWeek();

    await Promise.all([p1]);
    
    return Promise.resolve();
  }


  // Método para obter os dias da semana
  getWeekDays() {
    const today = new Date();
    const sunday = today.getDate() - today.getDay();
    const daysOfWeek = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(today.setDate(sunday + i));
      daysOfWeek.push({
        date: currentDate.toLocaleDateString(getCurrentLocale(), {
          day: '2-digit',
          month: '2-digit',
          //year: 'numeric',
        }),
        dayName: capitalizeFirstLetterAllWords(currentDate.toLocaleDateString(getCurrentLocale(), { weekday: 'long' }))
      });
    }
    let reOrdered = daysOfWeek.slice(1);
    reOrdered.push(daysOfWeek[0]);

    return reOrdered;
  }

  render() {


    let meta = {title:lng('Programação')+' | '+getSiteShortName()+'',desc:'',img:'/favicon.ico',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
   
    let desc = lng('O planejamento, data de lançamento e horário dos próximos episódios desta semana!');
    meta =  {
      title:lng('Programação')+' | '+getSiteShortName()+'',
      desc:desc,
      keywords:'',
      img:'/favicon.ico',
      imgwidth:'225',
      imgheight:'338',
      imgtype:'jpg'
    };

    let tvShowsByDay = MyAppPool.session.anime.tvShowsByDay;

    let sliderHeight=352;
    let sliderShowItens=7;
    let sliderImgSize='';
    if (isMobile.any)
    {
      sliderHeight=225;
      sliderShowItens=4;
      sliderImgSize='x50';
    }
    let hoje = new Date();
    let dia = hoje.getDay();
    let diaDate= hoje.toLocaleDateString(getCurrentLocale(), {
        day: '2-digit',
        month: '2-digit'
      });

    return (
      <div className="comp_viewbroadcast">
        <Helmet>
          <title>{meta.title}</title>
          <meta name="title" content={meta.title} />
          <meta name="description" content={meta.desc} />
          <meta name="keywords" content={meta.keywords}/>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.desc} />
          <meta name="twitter:title" content={meta.title}/>
          <meta name="twitter:description" content={meta.desc}/>
          <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
          <meta property="og:image" content={getImgAddress(meta.img)} />
          <meta property="og:image:type" content={meta.imgtype}/>
          <meta property="og:image:width" content={meta.imgwidth}/>
          <meta property="og:image:height" content={meta.imgheight}/> 
        </Helmet>
        <ContentBannerSetter />
        <h1>{lng('Programação')}</h1>
        <h2>{lng('Hoje')+` `}</h2>
        <RelatedWorks 
            title=''
            notFoundText={lng('Carregando')+'...'}
            notFoundButtonText={''}
            showItemNumber={sliderShowItens}
            hideIfEmpty={1}
            imgSize={sliderImgSize}
            ready={1}
            height={sliderHeight}
            items={tvShowsByDay[dia]}/>

        {this.state.daysOfWeek.map((day, index) => (
          <div key={index} className="day-category">
            <h2>{`${day.dayName} `}</h2>
            <RelatedWorks 
            title=''
            notFoundText={lng('Carregando')+'...'}
            notFoundButtonText={''}
            showItemNumber={sliderShowItens}
            hideIfEmpty={1}
            imgSize={sliderImgSize}
            ready={1}
            height={sliderHeight}
            items={tvShowsByDay[(index+1)%7]}/>
          </div>
        ))}
        <br/>
      </div>
    );
  }
}

export default BroadcastCalendar;