import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import "./TabbedContent.css"
class TabbedContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: 0 // Set the initial active tab to the first tab
    };
  }

  setActiveTab = (index) => {
    this.setState({ activeTabIndex: index });
  }

  render() {
    const { tabs } = this.props;
    const { activeTabIndex } = this.state;
    
    return (
      <div>
        <div className="tabs">
          {tabs.map((tab, index) => {
            if (!tab.visible)
                return <></>;
            return <button
              key={index}
              onClick={() => this.setActiveTab(index)}
              className={`tab-button ${activeTabIndex === index ? 'active' : ''}`}
            >
              {tab.tabName}
            </button>
          })}
        </div>
        <div className="tab-content">
          {tabs[activeTabIndex].component}
        </div>
      </div>
    );
  }
}

TabbedContent.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        tabName: PropTypes.string,
        component: PropTypes.object,
        visible: PropTypes.bool
        }))
  };
  TabbedContent.defaultProps = {
    tabs: [{tabName:"example",component:<span>test</span>}]
  };

export default TabbedContent;