import React, { Component } from 'react';
//import "./UrlParamHorizontalListButtons.css";
import MyAppPool from './../AppPool';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { isSet } from './../util/typeu';

@observer
class UrlParamHorizontalListButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickVal = this.onClickVal.bind(this);
}
  onClickVal(val,key)
  {
    MyAppPool.cLocation.setSingularParamVal(val,key);
    this.props.onChangeFunction(val,key);
  }

  
  componentDidMount()
  {

  }

  render(){
    let {textSubField,valueSubField,filterKey,items} = this.props;
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    return <ul>
    {items.map((item, index) => {
        let text=item;
        let value=item;
        console.log("LOOK2:",text);
        if (textSubField!==null)
          text=item[textSubField];
        if (valueSubField!==null)
          value=item[valueSubField];
        
        let activeClass='';
        if (isSet(parameters[filterKey]))
          if (parameters[filterKey].indexOf(value)!==-1)
            activeClass='searchBtnActive';

        if (item==="null")
          text="????";
        return (<li key={index} ><button aria-label={text} className={'searchBtn '+activeClass} onClick={()=>this.onClickVal(value,filterKey)} value={value}>{text}</button></li>)
      })
    }
    </ul>
  }
}

UrlParamHorizontalListButtons.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      valueSubField: PropTypes.string,
      textSubField: PropTypes.string
    })
  ),
  textSubField:PropTypes.string,
  valueSubField:PropTypes.string,
  filterKey:PropTypes.string.isRequired,
  onChangeFunction:PropTypes.func.isRequired
};

UrlParamHorizontalListButtons.defaultProps = {
  items: [],
  textSubField:null,
  valueSubField:null,
  filterKey:'',
  onChangeFunction:()=>{}
};


export default UrlParamHorizontalListButtons;
