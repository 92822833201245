
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { getRelatedTypeIcon } from '../util/icon';
import { lng } from '../ulanguages/language';
import ScrollToTopButtom from '../view_component/ScrollToTopButton';
import SkeletonBlock from '../view_component/SkeletonBlock';
import MyAppPool from './../AppPool';
import { getObraRoute } from './../store/AppRoutes';
import { isMobile } from './../util/mobile';
import { getAnchorName, getImgAddress, getSiteShortName, localeDateTime } from './../util/text';
import { isSet } from './../util/typeu';
import { isServer } from './../util/windowUtil';
import NoRecordsFoundCard from './../view_component/NoRecordsFoundCard';
import RelatedRowCard from './../view_component/RelatedRowCard';
import './resourceMedia.css';
import { serverRegisterFetch } from '../util/updateManager';
import { PropTypes } from 'prop-types';
import TrailerYoutubePlayBig from '../view_component/TrailerYoutubePlayBig';
import { prettyDateTimeStringNoSecond } from '../util/timeUtil';
import DropDown from '../view_component/DropDown';
import DataVideoUploadForm from '../view_component/DataVideoUploadForm';
import FileSelect from '../view_component/FileSelect';
import DataImageUploadForm from '../view_component/DataImageUploadForm';

let currentResourceType=0;
let currentResourceId=128642;

@observer
class ResourceMediaView extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        typeFilter:'',
    };        
    this.stringFilter = this.stringFilter.bind(this);
    this.passFilter = this.passFilter.bind(this);
    currentResourceType=this.props.resourceType;
    currentResourceId=this.props.resourceId;
    }

    componentWillMount()
    {

    }
    stringFilter(filter,name)
    {
      if (filter==='')
        return true;
      if (filter==='null' && !isSet(name))
        return true;
      if (!isSet(name))
        return false;
      if (name.toLowerCase().indexOf(filter.toLowerCase())!==-1)
        return true;
      return false;
    }
    passFilter(item)
    {
      let {typeFilter} = this.state;
      return ( this.stringFilter(typeFilter,item.purpose)
      )
    }

    render()
    {
        //let baseUrl = ct.server.BASE_URL + ct.server.BASE_PORT;
        let {resourceType,resourceId} = this.props;
        let current = MyAppPool.session.media[resourceType][resourceId];
        if (!Array.isArray(current))
        {return <></>};

        let purposes = new Set();
        current.forEach(item => purposes.add(item.purpose));
        purposes = [...purposes];
        /*
        let meta = {title:'Media | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:''};
   
        if (current.ready)
        {
          let desc = lng('Media de') + ' '  + current.mainTitle;
          meta =  {
            title:'Media - '+ this.props.title + ' | '+getSiteShortName()+'',
            desc:desc,
            keywords:desc
          };
        }
        */
        let mainWidth="60%";
        let mainMarginLeft="15px";
        if (isMobile.any)
        {
          mainWidth="95%";
          mainMarginLeft="2.5%";
        }

        return (<div className="resource_media_view contentArea uk-flex uk-flex-row">
        

        
        {!isMobile.any?
        <div style={{width:"21%",marginLeft:"15px"}}>
          {//left collum
          }
        </div>
        :<></>}

        <div style={{width:mainWidth,marginLeft:mainMarginLeft}}>
          <div className="uk-flex uk-flex-row  filterBox">
              <select onChange={(e) => {this.setState({typeFilter: e.target.value});}}>
                <option value="">{lng('Tipo')}</option>
                {
                  purposes.map((item, index) => {
                    return (<option value={item} key={index}>{lng(item)}</option>);
                  })
                }
              </select>
              {
              MyAppPool.cAuth.isDataMod()? <>
                <DropDown className="dropModerador"
                btnExtraClass="dropModerador"
                btnContent={<button className="uk-button uk-button-default dropModerador">{lng("Moderador")}</button>}>
                  <button onClick={()=>{
                      MyAppPool.cModal.set("Upload Image",
                      <DataImageUploadForm resourceType={resourceType} resourceId={resourceId}/>
                    ,null,'',()=>{})
                    }}
                     className='uk-button uk-button-default' >Upload Image</button>
                     <button onClick={()=>{
                      MyAppPool.cModal.set("Add Video",
                      <DataVideoUploadForm resourceType={resourceType} resourceId={resourceId}/>
                    ,null,'',()=>{})
                    }}
                     className='uk-button uk-button-default' >Add Video</button>
                  </DropDown></>:<></>
                }
          </div>
          <div>
          
          </div>
          <div className="flexDrop">
            {current.map((elem,index)=>{
              if (!this.passFilter(elem))
                return <></>;

              let detailsBottom = <div>
              <span>{lng(elem.purpose)} <br/>{localeDateTime(elem.datecreated)}</span> <br/>
              </div>; 

              let detailsTop = MyAppPool.cAuth.isDataMod()? <>
                <DropDown
                btnContent={<span>...</span>}>
                  {elem.type==='image'?
                  [
                  <button onClick={()=>{MyAppPool.cResourceMedia.saveResourceMediaDefault(resourceType,resourceId,'cover image',elem.link)}} style={{width:'200px'}} className='uk-button uk-button-default' >Set as Default Cover</button>,
                  <button onClick={()=>{MyAppPool.cResourceMedia.saveResourceMediaDefault(resourceType,resourceId,'banner',elem.link)}} style={{width:'200px'}} className='uk-button uk-button-default' >Set as Default Banner</button>
                  ]:
                  [
                    <button onClick={()=>{MyAppPool.cResourceMedia.saveResourceMediaDefault(resourceType,resourceId,'banner',elem.link)}} style={{width:'200px'}} className='uk-button uk-button-default' >Set as Default Trailer</button>
                  ]}
                  <button onClick={()=>MyAppPool.cModal.set("Delete Media",lng('Tem certeza?'),()=>MyAppPool.cResourceMedia.deleteResourceMedia(resourceType,resourceId, elem.link))} style={{width:'200px'}} className='uk-button uk-button-default' >Delete</button>
                </DropDown></>:<></>;
                

              if (elem.type==='image')
              return <div className="item_ext">
                {detailsTop}
                <div className="item">
               <LazyLoadImage className="internal_item" visibleByDefault={isServer()} key={index} src={getImgAddress(elem.link)} alt={elem.purpose} />
               </div>
               {detailsBottom}
              </div>;
              if (elem.type==='video' && elem.subtype==='youtube')
              return <div className="item_ext">
                {detailsTop}
                <div className="item">
               <TrailerYoutubePlayBig className="internal_video_item" height={"200px"} key={index} videourl={elem.link} title={''}/>
               </div>
              {detailsBottom}
              </div>;
              return <></>;
            })}
          </div>

        </div>

        {!isMobile.any?
        <div  style={{width:"15%",marginLeft:"15px"}}>
          <ScrollToTopButtom />
        </div>
        :<></>}

      </div>);


    }

}

ResourceMediaView.propTypes = {
    title: PropTypes.string.isRequired,
    resourceType:PropTypes.number,
    resourceId: PropTypes.number,
};
ResourceMediaView.defaultProps = {
    title:'',
    resourceType:0,
    resourceId:0
};

export default ResourceMediaView;