
import { getCurrentLanguage, getCurrentLocale, lng } from '../ulanguages/language';
import { serviceHosts } from './../store/ServiceHosts';
import { isSet } from './typeu';

String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.split(search).join(replacement);
};
String.prototype.reverse = function() {
  let str = this;
  return str.split('').reduce((reversed, character) => character + reversed, '')
};

let siteNames = {
  'pt':'NossoAnime Network',
  'es':'NuestroAnime Network',
  'en':'OurAnime Network',
  'de':'UnserAnime Network'
};
let siteShortNames = {
  'pt':'NANI!',
  'es':'NANI!',
  'en':'ONI!',
  'de':'uNANI!'
};

export function getSiteName()
{
  let lan = getCurrentLanguage();
  if (isSet(siteNames[lan]))
    return siteNames[lan];
  return siteNames['pt'];
}


export function getSiteShortName()
{
  let lan = getCurrentLanguage();
  if (isSet(siteShortNames[lan]))
    return siteShortNames[lan];
  return siteShortNames['pt'];
}

export function extractYoutubeId(link)
{
    let url = link;
    if (url.length===0)
        return '';
    url = url.replace(/^\s+/,"").replace(/\s+$/,"");
    let a;
    if (url.indexOf("youtu.be")!==-1) {
        a = url.match(/^http[s]*:\/\/youtu\.be\/([a-z0-9_-]+)/i);
    }else{
        a = url.match(/^http[s]*:\/\/www\.youtube\.com\/watch\?.*?v=([a-z0-9_-]+)/i);
    }
    console.log("Extract:",a);
    if (a && a.length===2) 
    {
        var code = a[1];
        return code;
    }
    return '';
}

export function textAreaAdjust(event) {
    let o = event.target;
    o.style.height = "auto";
    let maxHeight=70;
    if (o.scrollHeight>maxHeight)
    {
      o.style.height = maxHeight+'px';
      o.style.overflow="auto";
    }else{
      if (o.scrollHeight<=40)
        o.style.height = "auto";
      else
        o.style.height = (o.scrollHeight)+"px";
        o.style.overflow="hidden";
    }
  }

  export function getRouteParamAtIndex(str, index){
    //console.log("url: ",str, 'pos ', index);
    return str.split("/")[index+2];
}

export function getImgAddress(strarg,defaultImg='/imgs/defaults/manga.webp',size='',bucket='mab')//'x25', 'x50', ''
{
  let str=strarg;
  if (typeof str !=='string')
    str=defaultImg;
  if (str==="/favicon.ico")
    return str;
  if ( !isSet(str) || str==='')
    str = defaultImg;
  if (str.indexOf("http")!==-1)
    return str;
  let addBar = '';
  if (str[0]!=='/')
    addBar = '/';
  if (size!=='')
  {
    if (size!=='x25' && size!=='x50')
      size='';
  }
  if (size!=='')
    size = '/'+size;

  let buckets =  '/buckets/'+bucket;
  let bucketsPos = str.indexOf('buckets/');
  if (str.indexOf('buckets/')!==-1)
  {//new way of storing urls counts with bucket, so we must split the string and add the size parameter to the middle of it.
    buckets = '';
    bucketsPos+='buckets/'.length;
    let tempStr = str.substring(bucketsPos);
    let barPos = tempStr.indexOf('/');
    let addSizePos = bucketsPos+barPos;
    const part1 = str.substring(0, addSizePos);
    const part2 = str.substring(addSizePos);
    return  encodeURI(serviceHosts.mabfiles + '/' +part1 + size  + part2);
  }else{
    return  encodeURI(serviceHosts.mabfiles +buckets+ size + addBar+ str);
  }
}
export function getSEOUriPath(str)
{
  if (!isSet(str))
    return '';
  let firstCharacter = str.charAt(0);
  let ret = str.toLowerCase();
  //ret = ret.replaceAll(' ','-');
  ret = ret.trim();
  ret = ret.replace(/[\W_]+/g,"-");
  if (firstCharacter===':') //in case we are dealing with a parameter, we keep the : when it is the first character
    ret = firstCharacter + ret.substr(1);
  //ret = encodeURIComponent(ret);
  return ret;
}
export function getAnchorName(str)
{
  if (typeof str !== 'string')
        return '';
  let ret = str.toLowerCase();
  ret = ret.trim();
  ret = ret.replace(/[\W_]+/g,"_");
  ret = '_'+ret;
  return ret;
}

export function getPrettyUrlName(url)
{
  return url.replace('https://','').replace('http://','').replace('http:','');
}

export function prettyNumber(numb)
{
  let newNumb = parseFloat(numb);
  if (parseInt(newNumb)===newNumb && newNumb < 10)
    return newNumb + '.0';
  return newNumb.toString();
}
export function prettyGradeNumber(numb)
{
  let newNumb = parseFloat(numb);
  if (newNumb<=5)
    return '<=5';
  if (parseInt(newNumb)===newNumb && newNumb < 10)
    return newNumb + '.0';
  
  return newNumb.toString();
}

export function getYearFromDate(data)
{
  if (!isSet(data))
    return '';
  if (data==='')
    return '';
  let df = new Date(data);
  return df.getFullYear();
}

export function convertDataToShowable(data)
{
  if (!isSet(data))
    return '';
  if (data==='')
    return '';
  let df = new Date(data).toLocaleDateString(getCurrentLocale(),{
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Africa/Accra'//GMT 00 //'Asia/Tokyo' GMT +9
  });
  return df;
}
export function localeDateTime(data)
{
  if (!isSet(data))
    return '';
  if (data==='')
    return '';
  let df = new Date(data).toLocaleDateString(getCurrentLocale(),{
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour:'numeric',
    minute:'numeric',
    timeZone: 'Africa/Accra'
  });
  return df;
}

var weekday = new Array(7);
weekday[0] = lng("Domingo");
weekday[1] = lng("Segunda-Feira");
weekday[2] = lng("Terça-Feira");
weekday[3] = lng("Quarta-Feira");
weekday[4] = lng("Quinta-Feira");
weekday[5] = lng("Sexta-Feira");
weekday[6] = lng("Sábado");

function getFormattedTime(d){
  d = ('0' + d.getHours()).slice(-2) + ":" + ('0' + d.getMinutes()).slice(-2);

  return d;
}

function convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

export function convertBroadcastDayToShowable(data)
{
  if (!isSet(data))
    return '';

  let df = new Date(data);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let timeZoneDate = convertTZ(df,timezone);
  let day = timeZoneDate.getDay();
  
  let time = new Date(data);
  return weekday[day] +lng('(s)')+ ' '+ lng('às')+' '+getFormattedTime(timeZoneDate);
}

var season = new Array(4);
season[0] = lng("Inverno");
season[1] = lng("Primavera");
season[2] = lng("Verão");
season[3] = lng("Outono");

export function convertSeasonNumberToName(seasonnumber, year='')
{
  if (!isSet(seasonnumber))
    return '';
  return season[seasonnumber] + ' (JP) ' + year;
}


var animeStatus = new Array(3);
animeStatus[0] = lng("Anunciada");
animeStatus[1] = lng("Em exibição");
animeStatus[2] = lng("Finalizada");


export function convertAnimeStatusNumberToName(animeStatusNumber)
{
  if (!isSet(animeStatusNumber))
    return '';
  return animeStatus[animeStatusNumber];
}

var mangaStatus = new Array(3);
mangaStatus[0] = lng("Anunciada");
mangaStatus[1] = lng("Lançando");
mangaStatus[2] = lng("Finalizada");
export function convertMangaStatusNumberToName(mangaStatusNumber)
{
  if (!isSet(mangaStatusNumber))
    return '';
  return mangaStatus[mangaStatusNumber];
}

export function  capitalizeFirstLetterString(str)
{
  let res = str;
  if ((typeof res === 'string') && res.length>=1)
    res = res[0].toUpperCase() + res.substring(1);
  return res;
}
export function  capitalizeFirstLetterAllWords(str)
{
  let splitted = str.split(" ");
  for(let i=0;i<splitted.length;i++)
  {
    splitted[i] = capitalizeFirstLetterString(splitted[i]);
  }
  let res = splitted.join(" ");
  return res;
}


export function onlyLettersAndNumbers(inputString) {
  // Use a regular expression to remove spaces and keep only letters and numbers
  return inputString.replace(/[^a-zA-Z0-9]/g, '');
}