import React, { Component } from 'react';
import { isServer } from '../util/windowUtil';
import { isSet } from './../util/typeu';


class CSSTransition2 extends Component {

    constructor(props) {
        super(props);
        let letshowAnything=true;
        if (isServer() && props.in==0)
          letshowAnything=false;
        
        this.state = {currentClassName: 'potato',previousStatus:-1,showAnything:letshowAnything};
        this.activate = this.activate.bind(this);
  }
  manageMount(status)
  {
      let {unmountOnExit} = this.props;
      if (unmountOnExit)
      {
          if (status==0)
            this.setState({showAnything:false});
          else
            this.setState({showAnything:true});
      }
  }
  activate(status)
  {
    let baseClass = this.props.classNames;
    let {previousStatus} = this.state;
    let {timeout} = this.props;
    let timeoutStart=0;
    let timeoutEnd=0;
    if (typeof timeout === 'object')
    {
        timeoutStart=timeout.enter;
        timeoutEnd=timeout.exit;
        if (!isSet(timeoutStart))
            timeoutStart = 0;
        if (!isSet(timeoutEnd))
            timeoutEnd=0;
    }else
    {
        timeoutStart=timeout;
        timeoutEnd=timeout;
    }
    timeoutStart+=5;
    timeoutEnd+=5;
    if (status==previousStatus)
        return;
    if (previousStatus==-1 && status==0)
    {
        this.manageMount(status);
        this.setState({previousStatus: status, currentClassName: baseClass + '-exit-done'});
        return;
    }
    this.setState({previousStatus: status});
    if (status==1)
    {
      this.manageMount(status);
      this.setState({currentClassName: baseClass + '-enter'});
      setTimeout(()=>this.setState({currentClassName: baseClass + '-enter ' + baseClass + '-enter-active'}),5);
      setTimeout(()=>this.setState({currentClassName: baseClass + '-enter-done'}),timeoutStart);
    }else
    {
      this.setState({currentClassName: baseClass + '-exit'});
      setTimeout(()=>this.setState({currentClassName: baseClass + '-exit ' +baseClass + '-exit-active'}),5);
      setTimeout(()=>{
          this.setState({currentClassName: baseClass + '-exit-done'});
          this.manageMount(status);},
      timeoutEnd);
    }
  }
  componentDidMount()
  {
      this.activate(this.props.in);
  }
  componentWillReceiveProps(newProps)
  {
    this.activate(newProps.in);
  }

  render(){
    let {children} = this.props;
    let {showAnything} = this.state;
    if (showAnything==false)
        return <></>;
    let mainChild = {};
    let myClassNames = '';
    if (Array.isArray(children))
    {
        myClassNames = this.state.currentClassName;
        mainChild = <div>{children}</div>;
    }
    else
    {
        mainChild=children;
        myClassNames = mainChild.props.className +' '+ this.state.currentClassName;
    }
    if (!isSet(mainChild))
        return <></>;
    let childElement = React.cloneElement(mainChild, { className:myClassNames });

    return childElement;
}
}

CSSTransition2.propTypes = {
  
};
CSSTransition2.defaultProps = {
  in:1,
  classNames:'',
  timeout:1000,
  unmountOnExit:false
};
/*note to self:
timeout can also be an object with enter and exit timeout values: ex:
timeout:{
  enter:300, 
  exit:500
}
*/

export default CSSTransition2;
