import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from './../AppPool';
import { isSet } from './../util/typeu';
import "./UrlParamPageSelector.css";

@observer
class UrlParamPageSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickVal = this.onClickVal.bind(this);
    this.updatePageSelectionToZero = this.updatePageSelectionToZero.bind(this);
}
  onClickVal(val,key)
  {
    MyAppPool.cLocation.setSingularParamVal(val,key);
    this.props.onChangeFunction(val,key);
  }

  
  componentDidMount()
  {

  }
  updatePageSelectionToZero()
  {
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let {filterKey,items} = this.props;
    let currentPage = parseInt(parameters[filterKey]);
    let lastItem = items.length-1;
    if (currentPage>lastItem && items.length>0)
    {
      currentPage=0;
      MyAppPool.cLocation.setSingularParamVal(currentPage,filterKey);
    }
  }
  componentDidUpdate()
  {
    let timeToCheckIfSearchResultsContainsDesiredPage=800;//ms
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let {filterKey,items} = this.props;
    let currentPage = parseInt(parameters[filterKey]);
    let lastItem = items.length-1;
    if (currentPage>lastItem && items.length>0)
    {
      setTimeout(this.updatePageSelectionToZero,timeToCheckIfSearchResultsContainsDesiredPage);
    }
  }

  render(){
    let {filterKey,items} = this.props;
    let useItems = JSON.parse(JSON.stringify(items));
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let currentPage = parseInt(parameters[filterKey]);
    if (!isSet(currentPage) || currentPage==='' || isNaN(currentPage))
        currentPage=0;
    if (useItems.length===0)
        useItems[0]=0;
    let itensSize=4;
    let lowerCorrection=0;
    let upperCorrection=0;
    let halfItens = Math.floor(itensSize/2);
    let lastItem = useItems.length-1;
    
    if (currentPage-halfItens<0)
        lowerCorrection = Math.abs(currentPage-halfItens);
    if (currentPage+halfItens>=useItems.length)
      upperCorrection = Math.abs(lastItem-(currentPage+halfItens));
    let lowerBound = Math.max(currentPage-halfItens-upperCorrection,0);
    let upperBound = Math.min(currentPage+halfItens+lowerCorrection+1,useItems.length);
    let showNumbers = useItems.slice(lowerBound,upperBound);
    return <ul className="comp_urlParamPageSelector">
    {
        (currentPage>halfItens) ?
        <li ><button aria-label={'0...'} className={'pageSelectBtn '} onClick={()=>this.onClickVal(0,filterKey)} value={0}>{'0...'}</button></li>
        :<></>
    }
    {showNumbers.map((item, index) => {
        let text=item;

        let activeClass='';
        if (parseInt(currentPage)===parseInt(item))
            activeClass='pageSelectBtnActive';
        if (activeClass!=='')
            return <li key={index} ><button aria-label={text} className={'pageSelectBtn '+activeClass} value={text}>{text}</button></li>

        return (<li key={index} ><button aria-label={text} className={'pageSelectBtn '} onClick={()=>this.onClickVal(text,filterKey)} value={text}>{text}</button></li>)
      })
    }
    {
        (lastItem>itensSize && currentPage+halfItens<lastItem) ?
        <li ><button aria-label={'..'+lastItem} className={'pageSelectBtn '} onClick={()=>this.onClickVal(lastItem,filterKey)} value={lastItem}>{'..'+lastItem}</button></li>
        :<></>
    }
    </ul>
  }
}

UrlParamPageSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.number
  ),
  filterKey:PropTypes.string.isRequired,
  onChangeFunction:PropTypes.func.isRequired
};

UrlParamPageSelector.defaultProps = {
  items: [],
  filterKey:'',
  onChangeFunction:()=>{}
};


export default UrlParamPageSelector;
