import loadable from "@loadable/component";
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
//import YouTube from 'react-youtube';
import { lng } from '../ulanguages/language';
import { isSet, mobToObj } from '../util/typeu';
import MyAppPool from './../AppPool';
import { isMobile } from './../util/mobile';
import { getClientHeight, getElementRectAbsolute } from './../util/windowUtil';
import CSSTransition2 from './CSSTransition2';
import "./GlobalMusicPlayer.css";
import MusicFloatingMenu from './MusicFloatingMenu';
import MusicItem from './MusicItem';
import ProgressBar from './ProgressBar';
import GlobalMusicQueueMusicItem from './GlobalMusicQueueMusicItem';
import GlobalMusicCurrentMusicItem from './GlobalMusicCurrentMusicItem';
import ListWidget from './ListWidget';
import { contentTypeToFastType } from './../util/typeu';
import GlobalMusicListAddWidget from './GlobalMusicListAddWidget';
import MusicPlaylistCreator from './MusicPlaylistCreator';

const YouTube = loadable(() => import(/* webpackChunkName: "react-youtube" */ 'react-youtube'));


function secondsToTime(secs)
{
    let minutes = secs/60;
    let hours = minutes/60;
    minutes = Number.parseInt(minutes%60);
    hours = Number.parseInt(hours);
    let newSecs = secs - hours*60*60 - minutes * 60;
    minutes = minutes.toFixed(0);
    newSecs = newSecs.toFixed(0);
    hours = hours.toFixed(0)
    if (minutes<10)
        minutes = '0'+minutes;
    if (hours<10)
        hours = '0'+hours;
    if (newSecs<10)
        newSecs = '0'+newSecs;
    if (hours>0)
        return hours+':'+minutes+':'+newSecs;
    return minutes+':'+newSecs;
}
function getRelativeMousePos(e){
    var m_posx = 0, m_posy = 0, e_posx = 0, e_posy = 0,
           obj = e.currentTarget;
    //get mouse position on document crossbrowser
    if (!e){e = window.event;}
    if (e.pageX || e.pageY){
        m_posx = e.pageX;
        m_posy = e.pageY;
    } else if (e.clientX || e.clientY){
        m_posx = e.clientX + document.body.scrollLeft
                 + document.documentElement.scrollLeft;
        m_posy = e.clientY + document.body.scrollTop
                 + document.documentElement.scrollTop;
    }
    //get parent element position in document
    if (obj.offsetParent){
        //obj = obj.offsetParent;
        do { 
            e_posx += obj.offsetLeft;
            e_posy += obj.offsetTop;
            obj = obj.offsetParent;
        } while (obj);
    }
    // mouse position minus elm position is mouseposition relative to element:
    return {x:m_posx-e_posx,y:m_posy-e_posy}
}

@observer
class GlobalMusicPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            player:{
                playVideo(){},
                setVolume(){},
                pauseVideo(){},
                seekTo(){}
            }, 
            currentTime:0, 
            maxTime:1,
            playing:false,
            maxVol:100,
            queueOpen:false,
            changePossible:false,
            active:false,
            menuRight:0,
            menuBottom:0, 
            menuScroll: false, 
            menuMusic: {}, 
            menuIndex: -1, 
            scrollPosition: 0,
            openMenuIndex: 0
        };        
        this.onVideoEnd = this.onVideoEnd.bind(this);
        this.onYoutubeReady = this.onYoutubeReady.bind(this);
        this.updateVideoStatus = this.updateVideoStatus.bind(this);
        this.setProgressBar = this.setProgressBar.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.playNextMusic = this.playNextMusic.bind(this);
        this.playPreviousMusic = this.playPreviousMusic.bind(this);
        this.togglePlay = this.togglePlay.bind(this);
        this.mute = this.mute.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.toggleQueueOpen = this.toggleQueueOpen.bind(this);

        this.removeFromMusicQueue = this.removeFromMusicQueue.bind(this);
        this.addToPlaylist = this.addToPlaylist.bind(this);

        this.openMenu = this.openMenu.bind(this);
        this.musicBoxRef = React.createRef();
        this.setMusicBoxRef = this.setMusicBoxRef.bind(this);
        this.closeMenuOnScroll = this.closeMenuOnScroll.bind(this);

        this.musicMenuOptions = [
            {
                icon : "fa-times",
                text : lng("Remover da fila de reprodução"),
                function : this.removeFromMusicQueue
            },
            {
                icon : "fa-list-ol",
                text : lng("Adicionar à playlist"),
                function : this.addToPlaylist
            }
        ]
            
        
    }
    componentDidMount()
    {
        setTimeout(this.updateVideoStatus,1000);
    }

    addToPlaylist(){
        //?????

    }

    setMusicBoxRef(node) {
        this.musicBoxRef = node;
    }
    
    closeMenuOnScroll(e){
        //console.log(this.state.scrollPosition," on scroll")
        this.setState({scrollPosition: e.target.scrollTop});
        //console.log(this.state.scrollPosition, " close on scroll")
    }

    openMenu(e, music, index){

        if(!isSet(this.musicBoxRef.current)){
          return ;
        }

        let openMenu = this.state.openMenuIndex;
        this.setState({openMenuIndex : openMenu + 1});

    
        let menuButtom = e.target.getBoundingClientRect(); 
        this.setState({menuMusic: music, menuIndex: index});
      
        // e = Mouse click event.
        var musicBoxElement = this.musicBoxRef.current.getBoundingClientRect();
        var menuH = this.musicBoxRef.current.offsetHeight;

        //console.log("menuH ", menuH)
        //console.log("menuButtom.bottom ", menuButtom.bottom, " musicBoxElement.bottom ", musicBoxElement.bottom)
        //console.log("menuButtom.top ", menuButtom.top, " musicBoxElement.top " , musicBoxElement.top)
        //console.log("e.target.clientHeight ", e.target.clientHeight)
    
        if((menuButtom.top - (e.target.clientHeight * 2.5)) < 0){
           // console.log("menor")
            var bottom = musicBoxElement.bottom - (menuButtom.bottom + menuButtom.top + e.target.clientHeight);
        }
        else{
            //console.log("else")
            var bottom = menuH - (menuButtom.top - musicBoxElement.top);
        }
    
        var right =  musicBoxElement.right - menuButtom.right;
        
    
        this.setState({menuRight: right, menuBottom:bottom});
       // console.log("Right ", right, " bottom ", bottom)
      
    }

    removeFromMusicQueue(){
        MyAppPool.cMusic.removeFromPlayQueue(this.state.menuMusic);
    }

    setProgressBar(event)
    {
        if (!this.state.active)
            return;
        let mouseCoord = getRelativeMousePos(event);
        //console.log(mouseCoord);
        let total = event.currentTarget.offsetWidth;
        let percent = mouseCoord.x / total;
        //console.log('percent: '+ percent);
        let goTo = this.state.maxTime * percent;
        if(!isSet(this.state.player.seekTo))
            return;
        this.state.player.seekTo(goTo);
        this.setState({currentTime:goTo});
    }
    setMusicBar(event)
    {
        let mouseCoord = getRelativeMousePos(event);
        let total = event.currentTarget.offsetWidth;
        let percent = mouseCoord.x / total;
        let goTo = this.state.maxVol * percent;
        this.state.player.setVolume(goTo);
        MyAppPool.cMusic.setVolume(goTo);
    }
    updateVideoStatus()
    {
        //in case of inactive, activate when asked to
        if (!this.state.active || typeof this.state.player.getCurrentTime =='undefined')
        {
            if (MyAppPool.cMusic.shouldPlay) // start music when started externally
            {
                this.forcePlay();
                MyAppPool.cMusic.setPlayPlease(0);
            }
            setTimeout(this.updateVideoStatus,1000);
            return;
        }
        //update video status
        this.setState({currentTime:this.state.player.getCurrentTime()});
        setTimeout(this.updateVideoStatus,1000);
        if (MyAppPool.cMusic.shouldPlay) // start music when started externally
        {
            this.forcePlay();
            MyAppPool.cMusic.setPlayPlease(0);
        }
        if (MyAppPool.cMusic.shouldStop) // start music when started externally
        {
            if (this.state.playing)
            {
                this.stopPlay();
                MyAppPool.cMusic.setStopPlease(0);
            }
        }
    }

    onYoutubeReady(event)
    {
        let player = event.target;
        let controller = MyAppPool.cMusic;
        
        this.setState({player:player,currentTime:player.getCurrentTime(),maxTime:player.getDuration() });
        player.setVolume(controller.volume);
        //console.log('duration:',secondsToTime(player.getDuration()));
        
       // console.log('video duration '+player.getDuration()+ ' ');
        

        this.setState({playing:true});
        player.playVideo();
        if (player.getDuration()===0)
            MyAppPool.cMusic.goNext();

    }
    onPlay(event){
        let player = event.target;
        this.setState({playing:true, maxTime:player.getDuration()});
        MyAppPool.cMusic.setIsPlaying(true);
    }
    onPause(){this.setState({playing:false}); MyAppPool.cMusic.setIsPlaying(false);}

    onVideoEnd(event)
    {
        MyAppPool.cMusic.goNext();
    }
    onError(event)
    {
        
        let errorCode = event.data;
        switch(errorCode)
        {
            
            case 5:
                console.log("HTML5 player error");
                break;
            case 2:
            case 100:
            case 101:
            case 150:
                //video is broken, needs to clean and request a new one
            break;
            default:
                console.error('YT Error '+errorCode);
        }
        
        console.log("YT Error!",event);
        MyAppPool.cMusic.goNext();

        //this.setState({playing:false});
        //MyAppPool.cMusic.setIsPlaying(false);
    }
    toggleQueueOpen()
    {
        let {queueOpen} = this.state;
        this.setState({queueOpen:!queueOpen,changePossible:false});
    }
    togglePlay()
    {
        if (typeof MyAppPool.cMusic.currentMusic.title ==='undefined')
            return;

        if (! this.state.active) return this.setState({active:true});
        let player = this.state.player;
        if (this.state.playing)
        {
            this.setState({playing:false});
            player.pauseVideo();
        }else
        {
            this.setState({playing:true});
            player.playVideo();
            
        }
    }
    stopPlay()
    {
        if (!this.state.active) 
        return;
        let player = this.state.player;
        this.setState({playing:false});
        player.pauseVideo();
    }
    forcePlay()
    {
        if (! this.state.active) return this.setState({active:true});
        let player = this.state.player;
        this.setState({playing:true});
        player.playVideo();
    }
    toggleOpen()
    {
        
        this.setState({scrollPosition: Math.random(),queueOpen:false,changePossible:false, menuVisibility:"none"});
        //console.log("scroll ",this.state.scrollPosition)
        MyAppPool.cMusic.toggleOpen();
    }
    mute()
    {
        let player = this.state.player;
        player.setVolume(0);
        MyAppPool.cMusic.setVolume(0);
    }
    playNextMusic()
    {
        //let player = this.state.player
        this.setState({active:false});
        MyAppPool.cMusic.goNext();
    }
    playPreviousMusic()
    {
        //let player = this.state.player
        let {currentPlayIndex} = MyAppPool.session.musicPlayer;
        if (currentPlayIndex>0)
        {
            this.setState({active:false});
            MyAppPool.cMusic.goBack();
        }
    }
    toggleReplay()
    {
        MyAppPool.cMusic.toggleReplay();
    }
    toggleShuffle()
    {
        MyAppPool.cMusic.toggleShuffle();
    }

    render(){
        const opts = {
            height: '75',
            width: '100%',
            playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            enablejsapi:1,
            controls:0
            },
        };
        let {currentPlayQueue,currentPlayIndex,currentMusic,open,volume,shuffle,replay,shouldPlay} = MyAppPool.cMusic;
        let {active} = this.state;
        let musicLists = [];
        musicLists = MyAppPool.session.user.lists[contentTypeToFastType('music')];

        MyAppPool.cMusic.forcePlayMusic = this.forcePlay;
        let shuffleActiveClass = '';
        let replayActiveClass = '';
        let queueMenuHeight = getClientHeight() - 65;
        let musicProgressBarHeight = 3;
        if (isMobile.any)
        {
            musicProgressBarHeight=6;
        }

        if (shuffle)
         shuffleActiveClass = 'iconActive';
        if (replay)
         replayActiveClass = 'iconActive';

        return (
        <div className="comp_musicplayer">
            
            <CSSTransition2
                in={(!open)}
                timeout={{
                    appear: 500,
                    enter: 300,
                    exit: 0,
                }}
                classNames="my-node"
                unmountOnExit
            >
            <div className="upButton" onClick={this.toggleOpen}>
                <span className="iconButton clickable">
                    <i className="fas fa-music supportingIcon"/>
                    {//<i className="fas fa-angle-double-up supportingIcon" />
                    }
                </span>
            </div>
            </CSSTransition2>
            <CSSTransition2
                in={open}
                timeout={300}
                classNames="my-node"
            >
            <div className="uk-flex uk-flex-row playerbar">
            {currentMusic.ytid!==''?
            <div className="playerContainer">
                <CSSTransition2
                in={(active)}
                timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                }}
                classNames="bare"
                unmountOnExit
            >
                <YouTube
                videoId={currentMusic.ytid}
                id='musicplayer'
                containerClassName='playerYoutubeVideo'
                onReady={this.onYoutubeReady}
                onPause={this.onPause}
                onPlay={this.onPlay}
                onEnd={this.onVideoEnd}
                onError = {this.onError}
                autoplay= {shouldPlay}
                opts={opts}
                />
            </CSSTransition2>
            </div>
            :<div className="playerContainer"></div>
            }
            <div className="playerControl">
                <div className="playerControlBar">
                    <div className="uk-flex uk-flex-row itemsFlow">
                        <div className="musicTitle">{active?currentMusic.title : lng("Esperando Ativação")}</div>
                        <div className="centerControl">
                            {!isMobile.any?
                            <i onClick={this.toggleShuffle} className={"fas fa-random supportingIcon iconButton clickable "+shuffleActiveClass} />
                            :<></>}
                            <i onClick={this.playPreviousMusic} className="fas fa-step-backward supportingIcon iconButton clickable " />
                            { this.state.playing? 
                            <i onClick={this.togglePlay} className="far fa-pause-circle playIcon iconButton clickable" />
                            :
                            <i onClick={this.togglePlay} className="far fa-play-circle playIcon iconButton clickable" />
                            }
                            <i onClick={this.playNextMusic} className="fas fa-step-forward supportingIcon iconButton clickable" />
                            {!isMobile.any?
                            <i onClick={this.toggleReplay} className={"fas fa-retweet supportingIcon iconButton clickable "+replayActiveClass} />
                            :<></>}
                            <div className="uk-flex uk-flex-row progressArea">
                                {secondsToTime(this.state.currentTime)}
                                <div className="progressContainer" onClick={(e)=>this.setProgressBar(e)}>
                                <ProgressBar
                                    currentValue={this.state.currentTime}
                                    maxValue={this.state.maxTime}
                                    height={musicProgressBarHeight}
                                />
                                </div>
                                {secondsToTime(this.state.maxTime)}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
                <div className="rightControl">
                    <div className="uk-flex uk-flex-row">
                    <div onClick={this.toggleQueueOpen} className="supportingIcon iconButton clickable">
                        <i className="fas fa-bars" />
                        <i className="fas fa-cog " />
                    </div>
                    {!isMobile.any?
                    <div onClick={this.mute} className="supportingIcon">
                        {volume===0?
                        <i className="fas fa-volume-off  iconButton clickable" />
                        :<i className="fas fa-volume-up  iconButton clickable" />
                        }
                    </div>
                    :<></>}
                    {!isMobile.any?
                    <div onClick={(e)=>this.setMusicBar(e)} style={{width:'30%',marginTop:'8px', marginRight:'8px'}}>
                        <ProgressBar
                            currentValue={volume}
                            maxValue={this.state.maxVol}
                            height={3}
                        />
                    </div>
                    :<></>}
                    <div onClick={this.toggleOpen} className="closeIcon">
                        <i className="fas fa-angle-double-down  iconButton clickable" />
                    </div>
                    </div>
                </div>
            </div>
            </CSSTransition2>
            <CSSTransition2
                in={this.state.queueOpen}
                timeout={300}
                classNames="queue-node"
                unmountOnExit
            >
            <div className="closerDiv" onClick={this.toggleQueueOpen}>
                <div ref={this.musicBoxRef} onClick={(e)=>e.stopPropagation()} className="queueCollumn" id="musicQueueSandwich" style={{height:queueMenuHeight}}>
                    <div className="queueInnerWrapper">
                        <div className="queueTitleWrapper">
                            <i onClick={this.toggleQueueOpen} className="fas fa-times iconButton clickable xcloseIcon"/>
                            <h3 className="musicQueueTitle">{lng('Tocando')}</h3>
                        </div>
                        
                        <GlobalMusicCurrentMusicItem key={currentPlayIndex} music={currentMusic} showBars={false} openMenuFunction={(event) => this.openMenu(event,currentMusic,currentPlayIndex)}/>
                        <h3 className="musicQueueTitle">{lng('Fila de Reprodução')+' '} 
                        <i className="fas fa-bars clickable queueIcons" onClick={(e)=>{
                            var rect = getElementRectAbsolute(e.target);
                            MyAppPool.cPopMenu.set(
                                <GlobalMusicListAddWidget />
                            ,'',()=>{},rect.left-180,rect.bottom+15)}}/>
                        <i className="fas fa-save clickable queueIcons" onClick={()=>
                        MyAppPool.cModal.set(
                          lng("Adicionar Lista de Músicas"),
                          <MusicPlaylistCreator items={currentPlayQueue}/>,
                          (ok)=>{},'',(cleanup)=>{})}/>    
                        <i data-uk-tooltip={lng("Anterior")} className="fas fa-step-backward clickable queueIcons" onClick={()=>MyAppPool.cMusic.goBack()} />
                        <i data-uk-tooltip={lng("Próxima")} className="fas fa-step-forward clickable queueIcons" onClick={()=>MyAppPool.cMusic.goNext()} />
                        <i data-uk-tooltip={lng("Apagar tudo")} className="fas fa-eraser clickable queueIcons" onClick={()=>MyAppPool.cMusic.clearPlayQueue()} />
                         </h3>
                        <div className="queueHolder">
                            {
                            currentPlayQueue.map((element, indexarg) => {
                                return <GlobalMusicQueueMusicItem music={element} key={indexarg} showBars={false} openMenuFunction={(event) => this.openMenu(event,element,indexarg)}/>
                                })
                            }
                        </div>
                        {isMobile.any?
                                <i onClick={this.toggleShuffle} className={"fas fa-random supportingIcon iconButton clickable "+shuffleActiveClass} />
                                :<></>}
                        {isMobile.any?
                                <i onClick={this.toggleReplay} className={"fas fa-retweet supportingIcon iconButton clickable "+replayActiveClass} />
                                :<></>}
                        {isMobile.any?
                            <div className="uk-flex uk-flex-row">
                                <div onClick={this.mute} className="supportingIcon">
                                    {volume===0?
                                    <i className="fas fa-volume-off  iconButton clickable" />
                                    :<i className="fas fa-volume-up  iconButton clickable" />
                                    }
                                </div>
                
                                <div onClick={(e)=>this.setMusicBar(e)} style={{width:'80%',marginTop:'8px', marginRight:'8px'}}>
                                    <ProgressBar
                                        currentValue={volume}
                                        maxValue={this.state.maxVol}
                                        height={12}
                                    />
                                </div>
                            </div>
                        :<></>}
                    </div>

                </div>
            </div>
            </CSSTransition2>
        </div>);
    }
}

GlobalMusicPlayer.propTypes = {
    currentMusic:PropTypes.shape(
    {
        title: PropTypes.string.isRequired,
        band: PropTypes.string.isRequired,
        where: PropTypes.string.isRequired,
        ytid: PropTypes.string.isRequired
    }),
  currentPlaylistMusicIndex: PropTypes.number,
  playList: PropTypes.arrayOf(PropTypes.shape(
      {
        title: PropTypes.string.isRequired,
        band: PropTypes.string.isRequired,
        where: PropTypes.string.isRequired,
        ytid: PropTypes.string.isRequired
      }
      ))
};
GlobalMusicPlayer.defaultProps = {
    currentMusic:
    {
        title: 'Tank!!',
        band: 'Bilboard',
        where: '(ep 20 25)',
        ytid: 'vWUHoAGRTHU'
    },
    currentPlaylistMusicIndex:0,
    playList:[]
};

export default GlobalMusicPlayer;
