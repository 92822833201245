import React, { Component } from 'react';
//import "./UrlParamTextInput.css";
import MyAppPool from './../AppPool';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { isSet } from './../util/typeu';

@observer
class UrlParamTextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {nameFilter:''};
    this.onClickVal = this.onClickVal.bind(this);
    this.delayedSetNameFilterVal = this.delayedSetNameFilterVal.bind(this);
}

  componentDidMount()
  {
    let {filterKey} = this.props;
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    this.setState({
      nameFilter:parameters[filterKey]
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    
    let {parameters,filterKey} = this.props;
    let newVal='';

    if (isSet(parameters[filterKey]))
      newVal=parameters[filterKey];

    if (this.props.parameters !== prevProps.parameters) {
        this.setState({
          nameFilter:newVal});
    }
    
  }

  delayedSetNameFilterVal(val,key)
  {
    let {parameters,filterKey} = this.props;
    //console.log('Set Text');
    let delayTime=500;
    let updateFunc = ()=>{
      let {nameFilter} = this.state;
      
      if (nameFilter===val && parameters[filterKey]!==val)
      {
      //  console.log("filter:",val,'urlparm:',parameters[filterKey]);
        this.onClickVal(val,key);
      }
      else
        setTimeout(updateFunc,delayTime);
    }
    updateFunc();
    this.setState({nameFilter:val});
  }

  onClickVal(val,key)
  {
    MyAppPool.cLocation.setDefinitiveParamVal(val,key);
    this.props.onChangeFunction(val,key);
  }
  
  render(){
    let {filterKey,placeHolderText} = this.props;
    return <input type="text" value={this.state.nameFilter} onChange={(e) => {this.delayedSetNameFilterVal(e.target.value,filterKey);}} className="uk-textarea textInput" maxLength={140} rows={1} placeholder={placeHolderText}  />
  }
}

UrlParamTextInput.propTypes = {
  filterKey:PropTypes.string.isRequired,
  placeHolderText:PropTypes.string.isRequired,
  onChangeFunction:PropTypes.func.isRequired,
  parameters:PropTypes.object.isRequired
};

UrlParamTextInput.defaultProps = {
  filterKey:'',
  onChangeFunction:()=>{}
};


export default UrlParamTextInput;
