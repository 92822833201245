import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { serverRegisterFetch } from '../util/updateManager';
import { isServer } from './../util/windowUtil';
import Spinner from './Spinner';
import { getCurrentLanguage, lng, matchUserLanguagesWithOptions } from '../ulanguages/language';
import MyAppPool from '../AppPool';
import "./LanguageRedirect.css";
import renderHTML from 'react-render-html';
import { getFlag } from '../util/icon';
import LinkV2 from './LinkV2';

class LanguageRedirect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matchedLanguages: matchUserLanguagesWithOptions(),
            userLanguage: navigator.language || navigator.userLanguage
        };
    }

    render() {
        const { matchedLanguages, userLanguage } = this.state;
        let currentWebsiteLang = getCurrentLanguage();
        
        let userLang = userLanguage.split('-')[0] || 'en';
        let currentLanguageWord = lng("_"+currentWebsiteLang,false,userLang);

        // Message translations
        let messages = {
            en: "You have reached the <b>"+currentLanguageWord+"</b> <i class=\" flag "+getFlag(currentWebsiteLang)+"\"></i> version of this website. Would you rather be directed to the version that matches your preferred language?",
            pt: "Você acessou a versão em <b>"+currentLanguageWord+"</b> <i class=\" flag "+getFlag(currentWebsiteLang)+"\"></i> deste site. Gostaria de ser direcionado para a versão que corresponde ao seu idioma preferido?",
            de: "Sie haben die <b>"+currentLanguageWord+"</b> <i class=\" flag "+getFlag(currentWebsiteLang)+"\"></i> Version dieser Website erreicht. Möchten Sie lieber zur Version wechseln, die Ihrer bevorzugten Sprache entspricht?",
            es: "Has llegado a la versión en <b>"+currentLanguageWord+"</b> <i class=\" flag "+getFlag(currentWebsiteLang)+"\"></i> de este sitio web. ¿Prefieres ser dirigido a la versión que coincide con tu idioma preferido?"
        };
        // Fallback message in English if no match is found
        let message = messages[userLang] || messages[userLang];

        // Websites and their respective languages
        const websites = {
            'nueanime.net': 'es',
            'unseranime.net': 'de',
            'ouranime.net': 'en',
            'nossoanime.net': 'pt'
        };
        let showedLanguages = {en:false,pt:false,de:false,es:false};

        return (
            <div className="comp_LanguageRedirect">
                <p>{renderHTML(message)}</p>
                <div className="languageRedirectButtons">
                    {matchedLanguages.map((lang, index) => {
                        return Object.keys(websites).map((site) => {
                            if (showedLanguages[lang.language]===true) return <></>;
                            if (showedLanguages[lang.language] === currentWebsiteLang)  return <></>;
                            if (websites[site] === lang.language) {
                                showedLanguages[lang.language] = true;
                                return (
                                    <a className="uk-button uk-button-primary" key={site + index} href={MyAppPool.getAlternativeUrlsForThiPage[lang.language]}>
                                        {lng("Go to",false,userLang)} {site} ({lng("_"+lang.language,false,userLang)}) <i className={" flag "+getFlag(lang.language)}></i>
                                    </a>
                                );
                            }
                            return null;
                        });
                    })}
                </div>
                <div className="languageRedirectButtons">
                    {Object.keys(websites).map((site) => {
                        if (showedLanguages[websites[site]]===true) return <></>;
                        if (websites[site] === currentWebsiteLang)  return <></>;
                        return <a className="uk-button uk-button-primary" key={site} href={MyAppPool.getAlternativeUrlsForThiPage[websites[site]]}>
                            {lng("Go to",false,userLang)} {site} ({lng("_"+websites[site],false,userLang)}) <i className={" flag "+getFlag(websites[site])}></i>
                        </a>
                        }
                    )}
                    <br/>
                <button className="uk-button uk-button-primary" onClick={() => {
                    MyAppPool.cModal.deactivate();
                    MyAppPool.saveItemToLocalStorage("languageRedirect",true);
                }}>{lng("Do Not Show Again",false,userLang)}</button>
                </div>
                
            </div>
        );
    }
}

export default LanguageRedirect;