import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import MyAppPool from './../AppPool';
import CSSTransition2 from './CSSTransition2';
import "./ReviewWriter.css";
import SpinnerBox from './SpinnerBox';
import WYSIWYGWriter from './WYSIWYGWriter';
import { genRandId } from './WYSIWYGWriterUtils';
import StarsRating from './StarsRating';
import { prettyGradeNumber } from '../util/text';

function textAreaAdjust(event) {
    let o = event.target;
    o.style.height = "auto";
    let maxHeight=70;
    if (o.scrollHeight>maxHeight)
    {
      o.style.height = maxHeight+'px';
      o.style.overflow="auto";
    }else{
      if (o.scrollHeight<=40)
        o.style.height = "auto";
      else
        o.style.height = (o.scrollHeight)+"px";
        o.style.overflow="hidden";
    }
  }

class ReviewWriter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            sending:false,
            animGrade:"8.0",
            musicGrade:"8.0",
            storyGrade:"8.0",
            characterGrade:"8.0",
            content:"",
            editorEditId:genRandId('revwriter-')
        };
        this.toogleWriter = this.toogleWriter.bind(this);
        this.sendReview = this.sendReview.bind(this);
        this.onUpdateText = this.onUpdateText.bind(this);  
    }

  componentDidMount()
  {
      this.setState(
          {
            animGrade:this.props.animGrade,
            musicGrade:this.props.musicGrade,
            storyGrade:this.props.storyGrade,
            characterGrade:this.props.characterGrade,
            content:this.props.startContent,
            open:this.props.alwaysOpen
          }
      );
  }

  sendReview()
  {
    let {resourceType,resourceId,reviewId} = this.props;
    let {content,animGrade,musicGrade,storyGrade,characterGrade} = this.state;
    this.setState({sending: true});

    MyAppPool.cReview.createOrUpdateReview(
    resourceType,resourceId,content,animGrade,musicGrade,storyGrade,characterGrade,reviewId
    ).then(()=>{
        this.setState({sending: false,open: false});
        this.props.onClose();
    });
  }

  toogleWriter()
  {
    if (MyAppPool.cAuth.isGuest())
    {
        MyAppPool.cAuth.activateGlobalLoginBox();
        return;
    }
      if (this.state.open===true)
      {
        this.setState({
            open: false
          });
      this.props.onClose();
      }else
      {
        this.setState({
            open: true
          });
      }
  }
  onUpdateText(contentarg)
  {
    this.setState({content: contentarg});
  }

  render(){
      let {contentName,alwaysOpen,startContent,cats} = this.props;
      let {sending} = this.state;
      let minVal = 6;
      let maxVal=10;
      let finalGrade = (parseFloat(this.state.animGrade) + parseFloat(this.state.characterGrade) + parseFloat(this.state.musicGrade) + parseFloat(this.state.storyGrade)) /4.0;
    return (
            <div className="comp_reviewWriter">
            {
            !(this.state.open || alwaysOpen) ?
            <button aria-label={lng('Clique e Escreva uma Análise!')} onClick={this.toogleWriter} className="uk-button uk-button-primary escrevabtn">{lng('Clique e Escreva uma Análise!')}</button>
            : <> </>
            }
            <CSSTransition2
            in={this.state.open || alwaysOpen}
            timeout={300}
            classNames="write-node"
            unmountOnExit
            >
                <div className="reviewItem">
                        <div className="reviewBox">
                            <table width="100%">
                                <tr className="tableTitles">
                                    <td><span data-uk-tooltip={cats[0].name}>{cats[0].icons.map((element, index) => {return (<span className={element} key={index}/>)})}</span></td>
                                    <td><span data-uk-tooltip={cats[1].name}>{cats[1].icons.map((element, index) => {return (<span className={element} key={index}/>)})}</span></td>
                                    <td><span data-uk-tooltip={cats[2].name}>{cats[2].icons.map((element, index) => {return (<span className={element} key={index}/>)})}</span></td>
                                    <td><span data-uk-tooltip={cats[3].name}>{cats[3].icons.map((element, index) => {return (<span className={element} key={index}/>)})}</span></td>
                                    <td>{lng("Final")}</td>
                                    
                                </tr>
                                <tr>
                                    <td>
                                    <select onChange={(evt)=>{this.setState({animGrade: evt.target.value})}} value={this.state.animGrade}>
                                        <option value="5.0">{'<=5'}</option>
                                        <option value="5.5">5.5</option>
                                        <option value="6.0">6.0</option>
                                        <option value="6.5">6.5</option>
                                        <option value="7">7.0</option>
                                        <option value="7.5">7.5</option>
                                        <option value="8.0" selected>8.0</option>
                                        <option value="8.5">8.5</option>
                                        <option value="9.0">9.0</option>
                                        <option value="9.5">9.5</option>
                                        <option value="10">10</option>
                                    </select>
                                    </td>
                                    <td>
                                    <select onChange={(evt)=>{this.setState({musicGrade: evt.target.value})}} value={this.state.musicGrade}>
                                        <option value="5.0">{'<=5'}</option>
                                        <option value="5.5">5.5</option>
                                        <option value="6.5">6.5</option>
                                        <option value="7.0">7.0</option>
                                        <option value="7.5">7.5</option>
                                        <option value="8.0" selected>8.0</option>
                                        <option value="8.5">8.5</option>
                                        <option value="9.0">9.0</option>
                                        <option value="9.5">9.5</option>
                                        <option value="10">10</option>
                                    </select>
                                    </td>
                                    <td>
                                    <select onChange={(evt)=>{this.setState({characterGrade: evt.target.value})}} value={this.state.characterGrade}>
                                        <option value="5.0">{'<=5'}</option>
                                        <option value="5.5">5.5</option>
                                        <option value="6.0">6.0</option>
                                        <option value="6.5">6.5</option>
                                        <option value="7.0">7.0</option>
                                        <option value="7.5">7.5</option>
                                        <option value="8.0" selected>8.0</option>
                                        <option value="8.5">8.5</option>
                                        <option value="9.0">9.0</option>
                                        <option value="9.5">9.5</option>
                                        <option value="10">10</option>
                                    </select>
                                    </td>
                                    <td><select onChange={(evt)=>{this.setState({storyGrade: evt.target.value})}} value={this.state.storyGrade}>
                                        <option value="5.0">{'<=5'}</option>
                                        <option value="5.5">5.5</option>
                                        <option value="6.0">6.0</option>
                                        <option value="6.5">6.5</option>
                                        <option value="7.0">7.0</option>
                                        <option value="7.5">7.5</option>
                                        <option value="8.0" selected>8.0</option>
                                        <option value="8.5">8.5</option>
                                        <option value="9.0">9.0</option>
                                        <option value="9.5">9.5</option>
                                        <option value="10">10</option>
                                    </select>
                                    </td>
                                    <td>
                                    <StarsRating
                                        minVal={minVal}
                                        maxVal={maxVal}
                                        actualVal={finalGrade}
                                        data-uk-tooltip={ lng("Final") + " "+prettyGradeNumber(finalGrade)}
                                        style={{paddingLeft:'25%'}}
                                    />
                                        </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} className="textBox">
                                    <WYSIWYGWriter id={this.state.editorEditId} startContent={startContent} onUpdateText={this.onUpdateText}
                                        actions={
                                            [
                                            'superscript',
                                            'emoji',
                                            'spoiler',
                                            'insertOrderedList',
                                            'insertUnorderedList'
                                            ]} 
                                            actionClasses={{
                                                insertOrderedList:'list-ol',
                                                insertUnorderedList:'list-ul',
                                                table:'border-all',
                                                spoiler: 'eye-slash',
                                                emoji:'grin-squint-tears'
                                                }}
                                            actionTags={{
                                                spoiler:'<span class="spoiler">'
                                            }}
                                            placeHolder={lng("Faça uma análise sobre sua experiência com ") + contentName+"!"}
                                    ></WYSIWYGWriter>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>
                                        <div className="uk-flex uk-flex-row">
                                        <button aria-label={lng('Fechar')} onClick={this.toogleWriter} className="uk-button uk-button-secondary closeButton">{lng('Fechar')}</button>
                                        <button aria-label={lng('Enviar')} onClick={this.sendReview} className="uk-button uk-button-primary sendButton">{sending ? <SpinnerBox /> : lng('Enviar')+'!' }</button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

            </CSSTransition2>
            </div>
        
    );
}
}

ReviewWriter.propTypes = {
    contentName:PropTypes.string.isRequired,
    resourceType:PropTypes.string.isRequired,
    resourceId:PropTypes.number.isRequired,
    cats:PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        icons:PropTypes.arrayOf(PropTypes.string.isRequired),
        })),
  };
  ReviewWriter.defaultProps = {
    contentName:'content name',
    resourceType:'notype',
    resourceId:0,
    alwaysOpen:false,
    reviewId:-1,
    startContent:'',
    animGrade:"8.0",
    musicGrade:"8.0",
    storyGrade:"8.0",
    characterGrade:"8.0",
    cats:[
        {name:lng("Arte"),icons:["fas fa-paint-brush","fas fa-palette"]},
        {name:lng('Música/Sons'),icons:["fas fa-music"]},
        {name:lng('Personagens'),icons:['fas fa-running','fas fa-hiking','fas fa-skating']},
        {name:lng('História'),icons:['fas fa-pen']}
    ],
    onClose: function(){}
  }

export default ReviewWriter;
