import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getObraRoute } from '../store/AppRoutes';
import "./BannerReaction.css";


class ObraTopTextList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
}

  render(){
    let {items,type,max} = this.props;

    let renderItems = items.slice(0,max);
    return renderItems.map((element, index) => {
        return <li className="">
                <Link to={getObraRoute(element.id,element.title,type,'#top')}><span>{element.title+" "}
                </span> 
                </Link>
            </li>
        })
    ;
  }
}


ObraTopTextList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title:PropTypes.string.isRequired
    })),
    type:PropTypes.string.isRequired,
    max:PropTypes.number.isRequired,
};
ObraTopTextList.defaultProps = {
  items:[],
  type:'anime',
  max:5
};

export default ObraTopTextList;
