import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentPath } from '../util/windowUtil';
import "./SubNavigation.css";

class SubNavigation extends Component {
  constructor(props)
  {
    super(props);
    this.state = {clicks:0};
  }

  render(){
    let {items} = this.props;
    let currentPath=getCurrentPath();
    return (
        <ul id="naveg" className="uk-subnav uk-subnav-divider uk-subnav-pill comp_naveg" data-uk-margin>
            {
                items.map((element, index) => {
                return (//"Quer"
                <li key = {index} ><Link to={element.url} className={currentPath==element.url?'item-active':''} onClick={()=>this.setState({clicks:this.state.clicks+1})}>{element.name}</Link></li>
                    )
                })
            } 
        </ul>
    );
}
}


SubNavigation.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired
        })
      ) 
};
SubNavigation.defaultProps = {
    items:[]
};
  
export default SubNavigation;

