import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { contentTypeToFastType } from '../util/typeu';
import MyAppPool from './../AppPool';
import "./MusicPlaylistCreator.css";
import ListWidget from './ListWidget';
import { observer } from 'mobx-react';

function themeGetType(type)
{
  if (type===0)
    return 'op';
  if (type===1)
    return 'ed';
  return '';
}

@observer
class MusicPlaylistCreator extends Component {

  constructor(props) {
      super(props);
      let lists = [];

      this.state = {checkBoxes:[],allTrue:true,ops:true,eds:true, newList:false,listName:"",selectedListPos:-1};
      this.toggleCheckBox = this.toggleCheckBox.bind(this);
      this.toggleAllCheckBox = this.toggleAllCheckBox.bind(this);
      this.toggleOpsCheckBox = this.toggleOpsCheckBox.bind(this);
      this.toggleEdsCheckBox = this.toggleEdsCheckBox.bind(this);
      this.selectList = this.selectList.bind(this);
      this.confirmListAdd = this.confirmListAdd.bind(this);
  }
  toggleCheckBox(index)
  {
    let {items} = this.props;
    let {checkBoxes,allTrue,ops,eds} = this.state;
    checkBoxes[index] = !checkBoxes[index];
    if (checkBoxes[index]===false)
      allTrue=false;
    if (checkBoxes[index]===false && items[index].type===0)
      ops=false;
    if (checkBoxes[index]===false && items[index].type===1)
      eds=false;
    if (checkBoxes[index])
    {
      allTrue=true;
      ops=true;
      eds=true;
      for(let i=0;i<checkBoxes.length;i++)
      {
        if (checkBoxes[i]===false && items[i].type===0)
        {
          ops=false;
          break;
        }
      }
      for(let i=0;i<checkBoxes.length;i++)
      {
        if (checkBoxes[i]===false && items[i].type===1)
        {
          eds=false;
          break;
        }
      }
      allTrue = eds && ops;
    }
    this.setState({checkBoxes:checkBoxes,allTrue:allTrue,ops:ops,eds:eds});
  }
  toggleAllCheckBox()
  {
    let {checkBoxes,allTrue,ops,eds} = this.state;
    for(let i=0;i<checkBoxes.length;i++)
    {
      if (checkBoxes[i]===false)
      {
        allTrue=false;
        break;
      }
    }
    if (allTrue)
    {
      for(let i=0;i<checkBoxes.length;i++)
        checkBoxes[i]=false;
      allTrue=false;
      ops=false;
      eds=false;
    }else
    {
      for(let i=0;i<checkBoxes.length;i++)
        checkBoxes[i]=true;
      allTrue=true;
      ops=true;
      eds=true;
    }
    this.setState({checkBoxes:checkBoxes,allTrue,ops,eds});
  }

  toggleOpsCheckBox()
  {
    let {items} = this.props;
    let {checkBoxes,ops,eds} = this.state;
    let allTrue=ops;
    for(let i=0;i<checkBoxes.length;i++)
    {
      if (checkBoxes[i]===false && items[i].type===0)
      {
        allTrue=false;
        break;
      }
    }
    if (allTrue)
    {
      for(let i=0;i<checkBoxes.length;i++)
      {
        if(items[i].type===0)
          checkBoxes[i]=false;
      }
    }else
    {
      for(let i=0;i<checkBoxes.length;i++)
      {
        if(items[i].type===0)
          checkBoxes[i]=true;
      }
    }
    let newOp=!ops;
    
    allTrue=newOp && eds;
    this.setState({checkBoxes:checkBoxes,ops:newOp,allTrue:allTrue});
  }
  toggleEdsCheckBox()
  {
    let {items} = this.props;
    let {checkBoxes,eds,ops} = this.state;
    let allTrue=true;
    for(let i=0;i<checkBoxes.length;i++)
    {
      if (checkBoxes[i]===false && items[i].type===1)
      {
        allTrue=false;
        break;
      }
    }
    if (allTrue)
    {
      for(let i=0;i<checkBoxes.length;i++)
      {
        if(items[i].type===1)
          checkBoxes[i]=false;
      }
    }else
    {
      for(let i=0;i<checkBoxes.length;i++)
      {
        if(items[i].type===1)
          checkBoxes[i]=true;
      }
    }
    let newEd=!eds;
    allTrue=newEd && ops;
    this.setState({checkBoxes:checkBoxes,eds:!eds,allTrue:allTrue});
  }

  selectList(index)
  {
   // console.log("LIST::",index);
    this.setState({selectedListPos:index});
  }
  setNewList(newStateArg)
  {
    let resourceType = contentTypeToFastType('music');
    let newState = newStateArg;
    let lists = [];
    
    lists = MyAppPool.session.user.lists[resourceType];
    if (!Array.isArray(lists))
      lists=[];
    if (lists.length===0)
      newState=true;


    this.setState({newList:newState});
  }
  confirmListAdd()
  {
    let {items} = this.props;
    let {checkBoxes,newList,listName,selectedListPos} = this.state;
    
    let newListItens = [];
    for(let i=0;i<items.length;i++)
    {
      if (checkBoxes[i])
      {
        newListItens[newListItens.length]=items[i];
      }
    }

    let lists = [];
    let resourceType = contentTypeToFastType('music');
    lists = MyAppPool.session.user.lists[resourceType];
    if (!Array.isArray(lists))
      lists=[];
    if (lists.length===0)
      newList=true;
    if (listName==='')
       console.log("CHOOSE A LIST NAME");

    //console.log("ITEMS:",newListItens);
    if (newList)
    {
      if (listName==='')
      {
        MyAppPool.cAlert.notifyError(lng("Você precisa escolher um nome para sua lista!"),'','music playlist creator create');
        return;
      }
      MyAppPool.cLists.createListWithItems(listName,newListItens);
    }
    else
    {
      if (selectedListPos===-1)
      {
        MyAppPool.cAlert.notifyError(lng("Você precisa escolher uma lista!"),'','music playlist creator select a list');
        return;
      }
      MyAppPool.cLists.addManyItemsToList(newListItens,resourceType,selectedListPos);
    }
  }

  componentDidMount()
  {
    this.setState({checkBoxes: new Array(this.props.items.length).fill(true)});
    MyAppPool.cModal.setOkFunction(this.confirmListAdd);
  }

  render(){
    let {items} = this.props;
    let {checkBoxes,allTrue,ops,eds,newList} = this.state;
    let resourceType = contentTypeToFastType('music');
    let newListDisabled=false;
    let lists = [];
    lists = MyAppPool.session.user.lists[resourceType];
    if (!Array.isArray(lists))
      lists=[];
    if (lists.length===0)
    {
      newList=true;
      newListDisabled=true;
    }

    return (<div className="comp_musicplaylistcreator">
        <div className="spacerDiv">
          <span onClick={()=>this.setNewList(false)} ><input className="radiobox" type="radio" checked={!newList} disabled={newListDisabled}/><label>{lng("Lista já Existente")} </label></span>
          <span onClick={()=>this.setNewList(true)} ><input className="radiobox" type="radio" checked={newList}/><label>{lng("Nova Lista")} </label> </span>
        </div>
        <div className="spacerDiv">
        {!newList?
          <select className="semiUkInput inputWidth"  onChange={(e) => {this.selectList(e.target.value);}}>
            <option value={-1} >{lng('Selecione uma Lista')}</option>
          {lists.map((element, index) => {
              return (<option key={index} value={index} >{element.name}</option>);
          })}
          </select>
          :
          <input className="semiUkInput inputs input_left inputWidth" type="text" placeholder={lng('Nome da Lista')}
              onChange={(e) => {this.setState({listName:e.target.value})}} value={this.state.listName}></input>
        }
        </div>
            <input className="uk-checkbox" type="checkbox" onClick={()=>this.toggleAllCheckBox()} checked={allTrue}/><label>{lng("Todos")} </label>
            <input className="uk-checkbox" type="checkbox" onClick={()=>this.toggleOpsCheckBox()} checked={ops}/><label>OPs </label>
            <input className="uk-checkbox" type="checkbox" onClick={()=>this.toggleEdsCheckBox()} checked={eds}/><label>EDs </label>
        <ul className="uk-list uk-list-striped listItens">
            {items.map((music, index) => {
              let extraInfo = themeGetType(music.type) + ' ' +music.where;
              if (extraInfo!==' ')
                extraInfo = '('+extraInfo+')'
              let check= checkBoxes[index];
            return (
              <li className={"comp_musicitem"}>
              <div className="uk-flex uk-flex-row">
              <input className="uk-checkbox checkbox_list" type="checkbox"  checked={check} onClick={()=>this.toggleCheckBox(index)}/>
                <div className="musicdata">
                  <p className={"ytmusicname"}>{music.title + ' ' + extraInfo}</p>
                  <p className={"ytsinger"}>{music.band}</p>
                </div>
                <span onClick={
                  ()=>{
                    MyAppPool.cMusic.playMusicObject(music);
                  }
                } data-uk-tooltip={lng('Tocar')} className="fas fa-play-circle ytplayicon"></span>
              </div>
             </li>
             )})
            }
        </ul>

    </div>);

  }
}

MusicPlaylistCreator.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          band: PropTypes.string.isRequired,
          where: PropTypes.string.isRequired,
          type: PropTypes.number.isRequired,
          id: PropTypes.number.isRequired,
          ytid: PropTypes.string.isRequired
        })
      )
};
MusicPlaylistCreator.defaultProps = {
  items:[],
  openMenuFunction: () => {}

};

export default MusicPlaylistCreator;
