
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { getRelatedTypeIcon } from '../../util/icon';
import { lng } from '../../ulanguages/language';
import ScrollToTopButtom from '../../view_component/ScrollToTopButton';
import SkeletonBlock from '../../view_component/SkeletonBlock';
import MyAppPool from './../../AppPool';
import { getObraRoute } from './../../store/AppRoutes';
import { isMobile } from './../../util/mobile';
import { getAnchorName, getImgAddress, getSiteShortName } from './../../util/text';
import { isSet } from './../../util/typeu';
import { isServer } from './../../util/windowUtil';
import NoRecordsFoundCard from './../../view_component/NoRecordsFoundCard';
import RelatedRowCard from './../../view_component/RelatedRowCard';
import './AnimeRelatedView.css';


@observer
class AnimeRelatedView extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        nameFilter:'',
        relationFilter:'',
        typeFilter:'',
    };        
    this.stringFilter = this.stringFilter.bind(this);
    this.passFilter = this.passFilter.bind(this);
    }
    componentDidMount()
    {

    }
    stringFilter(filter,name)
    {
      if (filter==='')
        return true;
      if (filter==='null' && !isSet(name))
        return true;
      if (!isSet(name))
        return false;
      if (name.toLowerCase().indexOf(filter.toLowerCase())!==-1)
        return true;
      return false;
    }
    passFilter(item)
    {
      let {nameFilter,relationFilter,typeFilter} = this.state;
      return (this.stringFilter(nameFilter,item.title)
      && this.stringFilter(relationFilter,item.relation)
      && this.stringFilter(typeFilter,item.type)
      )
    }

    render()
    {
        //let baseUrl = ct.server.BASE_URL + ct.server.BASE_PORT;
        let {current} = this.props;

        let meta = {title:'Anime | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:''};
   
        if (current.ready)
        {
          let items = current.related;
          let desc = lng('Filmes , Mangás , Novels , Temporadas de') + ' '  + current.mainTitle + ' ' + lng('seguem') + ': ';

          for(let i=0; i< items.length; i++)
          {
            desc += items[i].relation +' / '+ lng(items[i].type) +' - ' +  items[i].title;
            if (i!==(items.length-1))
              desc+=', ';
          }
          
          meta =  {
            title:current.mainTitle +' - ' + lng('Obras Relacionadas') + ' | '+getSiteShortName()+'',
            desc:desc,
            keywords:desc
          };
        }
        let mainWidth="60%";
        let mainMarginLeft="15px";
        if (isMobile.any)
        {
          mainWidth="95%";
          mainMarginLeft="2.5%";
        }

        return (<div className="animerelated_view contentArea uk-flex uk-flex-row">
        
        <Helmet>
        <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
        </Helmet>
        
        {!isMobile.any?
        <div style={{width:"21%",marginLeft:"15px"}}>
          {//left collum
          }
        </div>
        :<></>}

        <div style={{width:mainWidth,marginLeft:mainMarginLeft}}>
          <div className="uk-flex uk-flex-row  filterBox">
              <input type="text" value={this.state.nameFilter} onChange={(e) => {this.setState({nameFilter: e.target.value});}} className="uk-textarea textInput" maxLength={140} rows={1} placeholder={lng("Filtrar por Nome") }  />
              <select onChange={(e) => {this.setState({typeFilter: e.target.value});}}>
                <option value="">{lng('Tipo')}</option>
                {
                  current.ready?
                  current.relatedAnimeMangaTypes.map((item, index) => {
                    return (<option value={item} key={index}>{lng(item)}</option>);
                  })
                  :<></>
                }
              </select>
              <select onChange={(e) => {this.setState({relationFilter: e.target.value});}}>
                <option value="">{lng('Relação')}</option>
                {
                  current.ready?
                  current.relatedAnimeMangaRelations.map((item, index) => {
                    return (<option value={item} key={index}>{item}</option>);
                  })
                  :<></>
                }
              </select>
          </div>
          {
            current.ready?
            (current.related.length===0?
            <NoRecordsFoundCard text={lng('Nenhuma obra relacionada encontrada, conhece uma ? Adicione!')} /> :<></>)
            :<></>
          }
          {current.ready? 
          current.related.map((item, index) => {
            return (
              this.passFilter(item)?
              <div id={getAnchorName(item.title)} key={index} className="rowCardContainer">
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'225px', 'marginTop':'8px'}}/>}
              >
                <RelatedRowCard
                id ={item.id}
                name ={item.title}
                relation = {item.relation}
                type = {lng(item.type)}
                data = {item.airfrom}
                img ={getImgAddress(item.mainimg)}
                description = {item.description}
                icon = {getRelatedTypeIcon(item.type)}
                url ={getObraRoute(item.id,item.title,item.pagetype,'#top')}
                ready={current.ready}
                />
              </LazyLoadComponent>
            </div> : <></>
            )})
          :
          <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
          </div>}
        </div>

        {!isMobile.any?
        <div  style={{width:"15%",marginLeft:"15px"}}>
          <ScrollToTopButtom />
        </div>
        :<></>}

      </div>);


    }
}

export default AnimeRelatedView;