import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import ReactionItem from './ReactionItem';
import "./ReactionsCard.css";
import ReactionWriter from './ReactionWriter';


@observer
class ReactionsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {max:4};
    this.expand = this.expand.bind(this);
  }

  expand()
  {
    let {max,expand,items} = this.props;
    let actualMax = this.state.max;

    if (actualMax<items.length)
    {
      this.setState({max: actualMax + expand});
    }
  }

  componentDidMount()
  {
    this.setState({max:this.props.max});
  }

  render(){
    let {items,currentUserId,currentUserRoles,ready,goToUrl} = this.props;
    let {max} = this.state;
    let useItems = items;
    if (!ready)
      return <div className="uk-card uk-card-default comp_reactions skeleton">
        <div style={{height:'200px','width':'100%'}}></div>
      </div>
  
    let showEmptyBox = false;
    if (!Array.isArray(useItems))
      useItems=[];
    if (useItems.length===0)
      showEmptyBox = true;

    let filteredItems = useItems.slice(0,max);

    return (
    <div className="uk-card uk-card-default comp_reactions">
      {lng('Reações')} 
      <div className="reactionsItem">
        <ReactionWriter
        resourceName= {this.props.resourceName}
        resourceType= {this.props.resourceType}
        resourceId= {this.props.resourceId}
        />
      </div>
      {filteredItems.map((element, index) => {
      return (
        <div key={index} className="reactionsItem">
        <ReactionItem
        reactionItem={MyAppPool.session.reactionData[element]}
        currentUserRoles={currentUserRoles}
        currentUserId={currentUserId}/>
      </div>
      )})
      }
      {showEmptyBox? 
      <div className="emptyReactionContent">
         {lng('Ninguém escreveu uma reação ainda! Seja o primeiro(a)!!')}
      </div>
      :<></>}
      <p className="moreReactions">
      {false? //useItems.length>0?
        <span><Link to={goToUrl} className="smalllink"> <i className="fas fa-external-link-alt"></i> {lng('Todas')}</Link><span> </span> </span>
        :  <span></span>  
      }
        {max<useItems.length?
        <button aria-label={lng('Mais')} className="link-button smalllink" onClick={()=>this.expand()}>{lng('Mais')}</button>
        : <span></span>  
       }
      </p>
    </div>
    );
}
}

ReactionsCard.propTypes = {
  ready: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  resourceName: PropTypes.string.isRequired,
  resourceType: PropTypes.number.isRequired,
  resourceId: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  expand: PropTypes.number.isRequired,
  goToUrl:PropTypes.string.isRequired
};
ReactionsCard.defaultProps = {
  items: [],
  currentUserId: 0,
  currentUserRoles: [],
  ready:0,
  resourceName: '',
  resourceType: 0,
  resourceId: 0,
  max:4,
  expand:4,
  goToUrl:''
}

export default ReactionsCard;
