import React, { Component } from 'react';
//import "./UrlParamSelect.css";
import MyAppPool from './../AppPool';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { isSet } from './../util/typeu';

@observer
class UrlParamSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickVal = this.onClickVal.bind(this);
}
  onClickVal(val,key)
  {
    MyAppPool.cLocation.setSingularParamVal(val,key);
    this.props.onChangeFunction(val,key);
  }

  
  componentDidMount()
  {

  }

  render(){
    let {textSubField,valueSubField,filterKey,items,selectDefaultName} = this.props;
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    return <select onChange={(e) => {this.onClickVal(e.target.value,filterKey);}}>
    <option value="">{selectDefaultName}</option>
    {
        items.map((item, index) => {
        let text=item;
        let value=item;
        if (textSubField!==null)
          text=item[textSubField];
        if (valueSubField!==null)
          value=item[valueSubField];

        if (text==='null' || !isSet(text))
          return;
        //text = '????';

        let active=undefined;
        if (isSet(parameters[filterKey]))
          if (parameters[filterKey]===''+value)
            active=true;
        return (<option key={index} value={value} selected={active}>{text}</option>);
      })
    }
  </select>;
  }
}

UrlParamSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      valueSubField: PropTypes.string,
      textSubField: PropTypes.string
    })
  ),
  textSubField:PropTypes.string,
  valueSubField:PropTypes.string,
  filterKey:PropTypes.string.isRequired,
  onChangeFunction:PropTypes.func.isRequired,
  selectDefaultName:PropTypes.string.isRequired,
};

UrlParamSelect.defaultProps = {
  items: [],
  textSubField:null,
  valueSubField:null,
  filterKey:'',
  onChangeFunction:()=>{},
  selectDefaultName:''
};


export default UrlParamSelect;
