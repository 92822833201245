
import levenshtein from 'js-levenshtein';
import { toJS } from 'mobx';
import { routes } from '../store/ApiRoutes';
import { lng } from '../ulanguages/language';
import { capitalizeFirstLetterAllWords, convertAnimeStatusNumberToName, convertMangaStatusNumberToName, convertSeasonNumberToName } from '../util/text';
import MyAppPool from '../AppPool';
import { getCurrentLanguage } from '../ulanguages/language';
import { fetchAndProcess } from '../util/net';
import { contentTypeToFastType, getIfIsSetElems, isSet, mobToObj, setIfNotSetObjElems, setObjectAttribute } from '../util/typeu';


function getMergeAll(searchContent,currentPage){
    let entries = Object.entries(searchContent);
    let res = [];
    for (let i=0;i<entries.length;i++)
    {
        if (isSet(entries[i]) && typeof entries[i] ==='object')
        {
            if (Array.isArray(entries[i][1]))
            {
                if (Number(entries[i][0])<=currentPage)
                    res.push(...entries[i][1]);
            }
        }
    }
    //res = res.sort((a,b)=>Number(a.position)-Number(b.position));
    return res;
}

function tryCastStringToNumber(val)//should return a number or undefine the variable
{
    let result = parseInt(val);
    if (isNaN(result))
        return undefined;
    return result;
}
let sortSearchingForText=false;//code smell, yeah
let searchText = '';
/*
function nameSortFunction(a,b)
{
    let aScore=99999999;
    let bScore=99999999;
    let tempScore=99999999;
    for(let i=0; i<a.titleList.length;i++)
    {
        tempScore = levenshtein(a.titleList[i],searchText);
        if (tempScore<aScore)
            aScore=tempScore;
    }
    for(let i=0; i<b.titleList.length;i++)
    {
        tempScore = levenshtein(b.titleList[i],searchText);
        if (tempScore<bScore)
            bScore=tempScore;
    }
    if (aScore < bScore)
        return -1;
    if (aScore > bScore)
        return 1;
    return 0; 
}
*/
function setGeneralSearchData(data0,contentType,pageArg,pageSize)
{
    if (!data0.ok)
        return Promise.reject(data0.msg);
    let resData = data0.data;
    let searchItens = resData['itens'];
    let contentLength = resData['totalItens'];
    let pagesNumber=resData['pages'];
    let sortType='name_desc';
    setIfNotSetObjElems(MyAppPool.session.search.content,
        [contentType,''+pageSize,pageArg]);
    MyAppPool.session.search.content[contentType][''+pageSize][''+pageArg] = searchItens;
    let pages = Array.from(Array(pagesNumber).keys());
    if (pages.length==0)
        pages[0]=0;
    MyAppPool.session.search.contentInfo[contentType]={pages,pageSize,contentLength,sortType};
    return Promise.resolve();
}
export default class SearchOthersController
{
    getPersonSearch(name,pageArg, pageSize=40)
    {
        let page = tryCastStringToNumber(pageArg);
        return fetchAndProcess ((data0,ready)=>{
            return setGeneralSearchData(data0,contentTypeToFastType('person'),pageArg,pageSize);
        },
        routes.searchPerson,true,
        {name,page,pageSize})
        .catch(e => console.log(("Error Getting Person Search "),e));
    }

    getUserSearch(name,pageArg, pageSize=40)
    {
        let page = tryCastStringToNumber(pageArg);
        return fetchAndProcess ((data0,ready)=>{
            return setGeneralSearchData(data0,contentTypeToFastType('user'),pageArg,pageSize);
        },
        routes.searchUser,true,
        {name,page,pageSize})
        .catch(e => console.log(("Error Getting User Search "),e));
    }
    getMusicSearch(name,pageArg, pageSize=40)
    {
        let page = tryCastStringToNumber(pageArg);
        return fetchAndProcess ((data0,ready)=>{
            return setGeneralSearchData(data0,contentTypeToFastType('music'),pageArg,pageSize);
        },
        routes.searchMusic,true,
        {name,page,pageSize})
        .catch(e => console.log(("Error Getting Music Search "),e));
    }

    getCharacterSearch(name,pageArg, pageSize=40)
    {
        let page = tryCastStringToNumber(pageArg);
        let lang = getCurrentLanguage();
        return fetchAndProcess ((data0,ready)=>{
            return setGeneralSearchData(data0,contentTypeToFastType('character'),pageArg,pageSize);
        },
        routes.searchCharacter,true,
        {lng:lang,name,page,pageSize})
        .catch(e => console.log(("Error Getting Character Search "),e));
    }
    getGeneralSearchAllPage(searchContent,contentType,pageSize,currentPage){
        let elem = getIfIsSetElems(searchContent,[contentType,''+pageSize]);
        if (isSet(elem))
            return getMergeAll(elem,currentPage);
        return null;
    };


    getCompanySearch(name,pageArg, pageSize=40)
    {
        
        let page = tryCastStringToNumber(pageArg);
        let lang = getCurrentLanguage();
        return fetchAndProcess ((data0,ready)=>{
            return setGeneralSearchData(data0,contentTypeToFastType('producer'),pageArg,pageSize);
        },
        routes.searchCompany,true,
        {lng:lang,name,page,pageSize})
        .catch(e => console.log(("Error Getting Producer Search "),e));
    }

    getPublisherSearch(name,pageArg, pageSize=40)
    {
        let contentType = contentTypeToFastType('publisher');
        let page = tryCastStringToNumber(pageArg);
        let lang = getCurrentLanguage();
        return fetchAndProcess ((data0,ready)=>{
            return setGeneralSearchData(data0,contentType,pageArg,pageSize);
        },
        routes.searchPublisher,true,
        {lng:lang,name,page,pageSize})
        .catch(e => console.log(("Error Getting Publisher Search "),e));
    }
    

}