import React, { Component } from 'react';
import "./SpinnerBox.css";
import { isSet } from '../util/typeu';

class SpinnerBox extends Component {
  componentDidMount()
  {

  }

  render(){
    let receivingClassName = this.props.className;
    let myClassName = " sk-cube-grid";
    if (isSet(receivingClassName))
      myClassName = receivingClassName+ myClassName;
    return (
    <div {...this.props} className={myClassName} >
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div>);
}
}


export default SpinnerBox;
