
import { merge } from 'lodash';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { fetchAndProcess } from '../util/net';
import { getDefaultStarter } from '../util/typeu';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { isSet, mobToObj, contentTypeToFastType } from '../util/typeu';

export default class ListsController
{
    statsChangeVal(resourceType,resourceId,essentialid,change)
    {
        if (isSet(MyAppPool.session.grades[resourceType][resourceId]))
        {
            let gradecount = MyAppPool.session.grades[resourceType][resourceId][0];
            if (essentialid===1)
            gradecount.countwant = gradecount.countwant + change;
            if (essentialid===2)
            gradecount.countcurrent = gradecount.countcurrent + change;
            if (essentialid===3)
            gradecount.countfinished = gradecount.countfinished + change;
            if (essentialid===4)
            gradecount.countdropped = gradecount.countdropped + change;
        }
    }
    createList(name='',type=-1)
    {
        let currentType = mobToObj(MyAppPool.session.listWidget.type);
        let currentName = mobToObj(MyAppPool.session.listWidget.name);
        if (type!==-1)
        {
            currentType = type;
            currentName = name;
        }
        //console.log("CREATING TYPE::",currentType);
        if (currentType===-1 || currentName==='')
        {
            console.warn("List Creation not suitable, invalid type or name");
            return Promise.reject();
        }

        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
           let newlist = data0.data[0]; 
           let lists = MyAppPool.session.user.lists[currentType];
           if (!Array.isArray(lists))
                MyAppPool.session.user.lists[currentType]=[];
           lists = MyAppPool.session.user.lists[currentType];
           let newPos = lists.length;
           lists[newPos] = newlist;
           MyAppPool.cUser.createListPage(newlist);
           //console.log("NEWLIST:",mobToObj(MyAppPool.session.user.lists[currentType]));
           return Promise.resolve(newPos);
        }
        ,
        routes.sendCreateList,false,
        {resourceType:currentType,name:currentName,lang:getCurrentLanguage()})
        .then ( (data)=>
        {
            this.cleanListWidgetVals();
            return Promise.resolve(data);
        })
        .catch(e => {
            this.cleanListWidgetVals();
            MyAppPool.cAlert.notifyGenericError(e,"lists, add item to list");
            console.error("Error Sending Work Add Item to List",e)}
            );
    }

    deleteList(listId,currentType)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
           let newlist = data0.data[0]; 
           let lists = MyAppPool.session.user.lists[currentType];
           if (!Array.isArray(lists))
                MyAppPool.session.user.lists[currentType]=[];

           lists = MyAppPool.session.user.lists[currentType]
                    .filter((item)=>item.listid!==listId);
           MyAppPool.session.user.lists[currentType] = lists;
           MyAppPool.cUser.deleteListPage(listId);
           return Promise.resolve();
        }
        ,
        routes.sendDeleteList,false,
        {listId:listId})
        .catch(e => {
            MyAppPool.cAlert.notifyGenericError(e,"list, delete list");
            console.error("Error deleting List",e)}
            );
    }

    updateList(listId,currentType,name=null,ordering=null,orderChange=0)
    {
        let lang = getCurrentLanguage();
        //console.log("ORDER CHANGE::",orderChange);
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
           let updatedListData = data0.data[0]; 
           let lists = MyAppPool.session.user.lists[currentType];
           if (!Array.isArray(lists))
                MyAppPool.session.user.lists[currentType]=[];

           lists = MyAppPool.session.user.lists[currentType];
           for(let i=0;i<lists.length;i++)
           {
                let myList = lists[i];
                if (myList.listid===listId)
                {
                    myList.name = updatedListData.name;
                    myList.ordering = updatedListData.ordering;
                    lists[i]=myList;
                }
           }

           return MyAppPool.cUser.updateListPage(listId,updatedListData.name,ordering,orderChange);
        }
        ,
        routes.sendUpdateList,false,
        {lang:lang,listId:listId,name:name,ordering:(ordering+orderChange)})
        .catch(e => {
            MyAppPool.cAlert.notifyGenericError(e,"list, update list");
            console.error("Error updating List",e)}
            );
    }

    cleanListWidgetVals()
    {
        MyAppPool.session.listWidget.type=-1;
        MyAppPool.session.listWidget.name='';
    }
    removeListItem(resourceType,resourceId,listId,listPos)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
           let list = MyAppPool.session.user.lists[resourceType][listPos];
           let listItens = list.itens;

           let newListItens = [];
           let k=0;
           for(let i=0;i<listItens.length;i++)
           {
               if (listItens[i].resourceid!==resourceId)
               {
                newListItens[k] = listItens[i];
                k++;
               }
           }
           this.statsChangeVal(resourceType,resourceId,list.essentialid,-1);
           MyAppPool.session.user.lists[resourceType][listPos].itens = newListItens;
           MyAppPool.cUser.removeListEntryPage(listId,resourceId);
           
           return Promise.resolve();
        }
        ,
        routes.sendRemoveListItem,false,
        {resourceType,resourceId,listId})
        .catch(e => {
            MyAppPool.cAlert.notifyGenericError(e,"list, remove item to list");
            console.error("Error Sending remove Item to List",e);
        });
    }

    addListItem(resourceType,resourceId,listId,listPos)
    {
        if (resourceId<0)
            return;
            
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
           let list = MyAppPool.session.user.lists[resourceType][listPos];
           let newListItem = data0.data[0];
           this.statsChangeVal(resourceType,resourceId,list.essentialid,+1);
           let listItens = MyAppPool.session.user.lists[resourceType][listPos].itens;
           listItens[listItens.length] = newListItem;
           MyAppPool.cUser.addListEntryPage(listId,resourceId);
           return Promise.resolve(newListItem.resourceid);
        }
        ,
        routes.sendAddListItem,false,
        {resourceType,resourceId,listId})
        .catch(e => {
            MyAppPool.cAlert.notifyGenericError(e,"list, add item to list");
            console.error("Error Sending Work Add Item to List",e);
        });
    }

    sendCheckList
    (resourceType,resourceId, listPos)
    {
        let lists = MyAppPool.session.user.lists[resourceType];
        let list = MyAppPool.session.user.lists[resourceType][listPos];
        let listPresence = this.getListPresence(resourceType,resourceId); //array with user lists that have this resource
        //console.log(mobToObj(list));
        if (list.essentialid!==null)
        {//it is essential
            //console.log("IS ESSENTIAL:::");
            for(let i=0;i<lists.length;i++)
            {
                let item = lists[i];
                if (listPresence[''+i] && listPos!==i)
                {
                    if (item.essentialid!==null)
                    { //this item is currently in another essential list
                        this.removeListItem(resourceType,resourceId,item.listid,i);
                    }
                }
            }
        }
        if (listPresence[listPos])
        {
            let item = lists[listPos];
            return this.removeListItem(resourceType,resourceId,item.listid,listPos);
        }else
        {
            let item = lists[listPos];
            return this.addListItem(resourceType,resourceId,item.listid,listPos);
        }
    }
    

    fetchUserLists(useCache=true, attempt=0)
    {
        //console.log("FETCH::",useCache,attempt);
        if (MyAppPool.cAuth.isGuest())
            return Promise.resolve("guest has no lists");
        return fetchAndProcess ((datas,ready)=>{
            if (!datas.ok)
                return Promise.reject(datas.msg);
            //console.log("LITEMS:",datas);
            let data = datas.data;
            
            if (data.length>0)
            {
                let result = getDefaultStarter();
                for (let i=0; i < data.length; i++)
                {
                    let item = data[i];
                    if (!Array.isArray(result[''+item.resourcetype]))
                        result[''+item.resourcetype] = [];
                    let itemArr = result[''+item.resourcetype];
                    itemArr[itemArr.length]=item;
                }
                //web client should be more updated, therefore we merge server cached fetch with current 
                //web client user votes.
                let oldLists = MyAppPool.session.user.lists;
                let newRes = merge(result,oldLists);
                
                MyAppPool.session.user.lists = newRes;
                return Promise.resolve('ok');
            }else
            {
                if (attempt===0)
                {
                    return fetchAndProcess ((datas,ready)=>{
                        
                        if (!datas.ok)
                            return Promise.reject(datas.msg);
                        //console.log(datas);
                        return this.fetchUserLists(false,1);
                        
                    },routes.initUserLists,false,
                    {});
                }
            }
            return Promise.resolve('no items');
        },
        routes.getUserLists,
        useCache,
        {uid:MyAppPool.currentUser.userid,lang: getCurrentLanguage()}
        )
        .catch(e => console.error("Error Getting User Lists",e));
    }

    getListPresence(resourceType,resourceId)
    {
        if (resourceType < 0 || resourceId < 0)
            return false;
        let lists = MyAppPool.session.user.lists[resourceType];
        let listsSize = lists.length;
        let isInLists = {};
        for(let i=0; i<listsSize;i++)
        {
            let list = lists[i];
            let listSize = list.itens.length;
            for(let k=0;k<listSize;k++)
            {
                let item = list.itens[k];
                if (item.resourceid===resourceId)
                {
                    isInLists[''+i] = true;
                    break;
                }
            }
        }
        return isInLists;
    }

    createListWithItems(listName='',listOfItems)
    {
        if (!Array.isArray(listOfItems))
            return;
        //console.log("CREATING LIST:::");
        let resourceType=contentTypeToFastType('music');
        MyAppPool.session.listWidget.name=listName;
        return this.createList(listName,resourceType).then((listPos)=>{
            return this.addManyItemsToList(listOfItems,resourceType,listPos);
        })
    }

    addManyItemsToList(listOfItems,resourceType,listPos)
    {
        if (resourceType < 0)
            return Promise.resolve();

        let list = MyAppPool.session.user.lists[resourceType][listPos];
        let listId = list.listid;
        let promises = [];
        for(let i=0;i<listOfItems.length;i++)
        {
            let item = listOfItems[i];
            let p = this.addListItem(resourceType,item.id,listId,listPos);
            promises[promises.length] = p;
        }
        return Promise.all(promises).then(data =>{
            //console.log(data);
        });
    }

}