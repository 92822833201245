
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { getCharacterRoute } from '../../store/AppRoutes';
import ScrollToTopButtom from '../../view_component/ScrollToTopButton';
import SkeletonBlock from '../../view_component/SkeletonBlock';
import MyAppPool from './../../AppPool';
import { lng } from '../../ulanguages/language';
import { isMobile } from './../../util/mobile';
import { getAnchorName, getImgAddress, getSiteShortName } from './../../util/text';
import { isServer } from './../../util/windowUtil';
import CharacterRowCard from './../../view_component/CharacterRowCard';
import './PersonPersonagemView.css';

@observer
class PersonPersonagensView extends Component {

    constructor(props) {
      super(props);
      this.state = { 
          nameFilter:'',
      };        
      this.nameFilter = this.nameFilter.bind(this);
      this.passFilter = this.passFilter.bind(this);
  }
    componentDidMount()
    {

    }
    nameFilter(name)
    {
      let {nameFilter} = this.state;
      if (nameFilter==='')
        return true;
      if (name.toLowerCase().indexOf(nameFilter.toLowerCase())!==-1)
        return true;
      return false;
    }
    passFilter(personagemItem)
    {
      if (this.nameFilter(personagemItem.name + ' '+personagemItem.familyname))
        return true;
      return false;
    }

    render()
    {
        //let baseUrl = ct.server.BASE_URL + ct.server.BASE_PORT;
        let {current} = this.props;

        let meta = {title:'Person | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:''};
   
        if (current.ready)
        {
          let items = current.acted;
          let desc = lng('Personagens atuados por') + ' '  + current.fullName + ': ';

          for(let i=0; i< items.length; i++)
          {
            desc += items[i].name + ' '+ items[i].familyname;
            if (i!==(items.length-1))
              desc+=', ';
          }
          
          meta =  {
            title:current.fullName +' - ' + lng('Personagens') + ' | '+getSiteShortName()+'',
            desc:desc,
            keywords:desc
          };
        }

        let mainWidth="60%";
        let mainMarginLeft="15px";
        if (isMobile.any)
        {
          mainWidth="95%";
          mainMarginLeft="2.5%";
        }

        return (<div className="person_personagem_view contentArea uk-flex uk-flex-row">
          <Helmet>
            <title>{meta.title}</title>
                <meta name="title" content={meta.title} />
                <meta name="description" content={meta.desc} />
                <meta name="keywords" content={meta.keywords}/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta.title} />
                <meta property="og:description" content={meta.desc} />
                <meta name="twitter:title" content={meta.title}/>
                <meta name="twitter:description" content={meta.desc}/>
          </Helmet>
        {!isMobile.any?
        <div style={{width:"21%",marginLeft:"15px"}}>
          {//left collum
          }
        </div>
        :<></>}

        <div style={{width:mainWidth,marginLeft:mainMarginLeft}}>

          <div className="uk-flex uk-flex-row  filterBox">
              <input type="text" value={this.state.nameFilter} onChange={(e) => {this.setState({nameFilter: e.target.value});}} className="uk-textarea textInput" maxLength={140} rows={1} placeholder={lng("Filtrar por Nome") }  />
          </div>
          {current.ready? 
          current.acted.map((item, index) => {
            return (
              this.passFilter(item) ? 
              <div id={getAnchorName(item.name + ' '+ item.familyname)} key={index} className="rowCardContainer">
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'225px', 'marginTop':'8px'}}/>}
              >
                <CharacterRowCard 
                charid ={item.characterid}
                name ={item.name + ' '+ item.familyname} 
                protag  ={0}
                img ={getImgAddress(item.mainimg)}
                description = {item.description}
                url ={getCharacterRoute(item.characterid,item.name + ' '+ item.familyname)}
                ready={current.ready}
                />
              </LazyLoadComponent>
            </div>
            : <> </>
            )})
          
          :
          <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
          </div>}
        </div>

        {!isMobile.any?
        <div  style={{width:"15%",marginLeft:"15px"}}>
             <ScrollToTopButtom />
        </div>
        :<></>}

      </div>);


    }
}

export default PersonPersonagensView;