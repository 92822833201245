import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import "./TagList.css";

class TagList extends Component {
  componentDidMount()
  {

  }

  render(){
    let {tags} = this.props;
    if (!tags)
        tags = [];
    return (
        <div>
            {
            tags.map((element, index) => {
                return (
                    <span  key={index} style={{'backgroundColor':element.color}} className="uk-badge genretag" data-uk-tooltip={element.description}>{element.name}</span> 
                    )
            })
            }
        </div>
        );
}
}


TagList.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        color:PropTypes.string.isRequired
        }))
  };
  TagList.defaultProps = {
    tags: [
        {name:'action',description:'lots of action', color:'red'}
    ]
  };


export default TagList;
