

import MyAppPool from './../AppPool';
import React, { Component } from 'react';
import ReactDom from 'react-dom';
import { PropTypes } from 'prop-types';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { Route } from 'react-router-dom';
import { scrollToAnchor } from '../util/windowUtil';
import { mobToObj } from './../util/typeu';

export function isIntersectionObserverAvailable() {
	let intersectionAvailable = (
		typeof window !== 'undefined' &&
		'IntersectionObserver' in window &&
		'isIntersecting' in window.IntersectionObserverEntry.prototype
	);
	//console.log("intersectionAvailable::",intersectionAvailable)
	return intersectionAvailable;
}
const style = (element, prop) =>
	typeof getComputedStyle === 'undefined'
		? element.style[prop]
        : getComputedStyle(element, null).getPropertyValue(prop);
        
const overflow = element =>
	style(element, 'overflow') +
	style(element, 'overflow-y') +
	style(element, 'overflow-x');

const getScrollElement = element => {
	if (!(element instanceof HTMLElement)) {
		return window;
	}

	let parent = element;

	while (parent) {
		if (
			parent === document.body ||
			parent === document.documentElement ||
			!parent.parentNode
		) {
			break;
		}

		if (/(scroll|auto)/.test(overflow(parent))) {
			return parent;
		}

		parent = parent.parentNode;
	}

	return window;
};

const getScrollX = () =>
	typeof window === 'undefined' ? 0 : window.scrollX || window.pageXOffset;
const getScrollY = () =>
	typeof window === 'undefined' ? 0 : window.scrollY || window.pageYOffset;

	

class ScrollTracker extends Component {
		constructor(props) {
			super(props);

			this.useIntersectionObserver =
				props.useIntersectionObserver &&
				isIntersectionObserverAvailable();
			
			if (this.useIntersectionObserver) {
				MyAppPool.setScrollPosition(undefined);
				return;
			}

			const onChangeScroll = this.onChangeScroll.bind(this);

			if (props.delayMethod === 'debounce') {
				this.delayedScroll = debounce(onChangeScroll, props.delayTime);
			} else if (props.delayMethod === 'throttle') {
				this.delayedScroll = throttle(onChangeScroll, props.delayTime);
			}

			this.state = {
				scrollPosition: {
					x: getScrollX(),
					y: getScrollY(),
				},
            };
			MyAppPool.setScrollPosition({x: getScrollX(), y: getScrollY()});
			
			this.baseComponentRef = React.createRef();
		}

		componentDidMount() {
			this.addListeners();
		}

		componentWillUnmount() {
			this.removeListeners();
		}

		componentDidUpdate() {
			if (typeof window === 'undefined' || this.useIntersectionObserver) {
				return;
			}

			const scrollElement = getScrollElement(
				ReactDom.findDOMNode(this.baseComponentRef.current)
			);

			if (scrollElement !== this.scrollElement) {
				this.removeListeners();
				this.addListeners();
			}
		}

		addListeners() {
			if (typeof window === 'undefined' || this.useIntersectionObserver) {
				return;
			}

			this.scrollElement = getScrollElement(
				ReactDom.findDOMNode(this.baseComponentRef.current)
			);

			this.scrollElement.addEventListener('scroll', this.delayedScroll, {
				passive: true,
			});
			window.addEventListener('resize', this.delayedScroll, {
				passive: true,
			});

			if (this.scrollElement !== window) {
				window.addEventListener('scroll', this.delayedScroll, {
					passive: true,
				});
			}
		}

		removeListeners() {
			if (typeof window == 'undefined' || this.useIntersectionObserver) {
				return;
			}

			this.scrollElement.removeEventListener(
				'scroll',
				this.delayedScroll
			);
			window.removeEventListener('resize', this.delayedScroll);

			if (this.scrollElement !== window) {
				window.removeEventListener('scroll', this.delayedScroll);
			}
		}

		onChangeScroll() {
			if (this.useIntersectionObserver) {
				MyAppPool.setScrollPosition(undefined);
				return;
			}

			this.setState({
				scrollPosition: {
					x: getScrollX(),
					y: getScrollY(),
				},
            });
            MyAppPool.setScrollPosition({x: getScrollX(), y: getScrollY()});
		}

		render() {
			const { delayMethod, delayTime, ...props } = this.props;
			//const scrollPosition = this.useIntersectionObserver
			//	? null
			//	: this.state.scrollPosition;
				
			return (
				<div>
					<Route render={({ location }) => {
						const { pathname, key } = location;
						if (MyAppPool.session.location.hash==='' && MyAppPool.session.location.pathname==='' &&
						MyAppPool.session.location.key==='' && MyAppPool.session.location.search==='')
							MyAppPool.setCurrentLocation(location);//if location is not set, set it
						return (<div style={{display:'none'}}>{location.pathname} {scrollToAnchor(null,600)}</div>)}} />
                 </div>
			);
		}
	}

ScrollTracker.propTypes = {
    delayMethod: PropTypes.oneOf(['debounce', 'throttle']),
    delayTime: PropTypes.number,
    useIntersectionObserver: PropTypes.bool,
};

ScrollTracker.defaultProps = {
    delayMethod: 'throttle',
    delayTime: 300,
    useIntersectionObserver: true,
};


export default ScrollTracker;
