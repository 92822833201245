import HtmlToReact from 'html-to-react';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { getResourceRoute, getUserRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon } from '../util/icon';
import { lng } from '../ulanguages/language';
import { getImgAddress, prettyGradeNumber, prettyNumber } from '../util/text';
import { prettyDateTimeStringNoSecond } from '../util/timeUtil';
import { contentFastTypeToType, contentTypeToFastType, isSet } from '../util/typeu';
import { isServer } from '../util/windowUtil';
import MyAppPool from './../AppPool';
import "./ReviewItem.css";
import ReviewWriter from './ReviewWriter';
import { genRandId } from './WYSIWYGWriterUtils';
import StarsRating from './StarsRating';




@observer
class ReviewItemHome extends Component {

  constructor(props) {
    super(props);
    this.state = {editing:false,editorEditId:genRandId('revthe-'), needMore:'needMore'};
    this.deletePost = this.deleteReview.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.deleteReview = this.deleteReview.bind(this);
}
  deleteReview()
  {
    let {reviewItem} = this.props;
    MyAppPool.cReview.deleteReview(reviewItem);
  }
  toggleEditing()
  {
    this.setState({editing:!this.state.editing});
  }
  componentDidMount()
  {

  }

  render(){

    let {reviewItem,currentUserRoles,currentUserId,cats} = this.props;
    let capaImg='';
    let title = 'Loading...';
    let icon = null;
    let iconText = '';

    let contentTypeName =  contentFastTypeToType(reviewItem.resourcetype);

    let item = MyAppPool.session.samples[''+reviewItem.resourcetype][''+reviewItem.resourceid];
    if (isSet(item))
    {
      capaImg=item.mainimg;
      title = item.title;
      icon = getRelatedTypeIcon(item.type);
      iconText = lng(item.type);
    }
    let resourceUrl = getResourceRoute(reviewItem.resourcetype,reviewItem.resourceid,title,'#top');
    
    let authorId = reviewItem.author;
    let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+contentTypeToFastType('user')][''+authorId]);
    
    let voteResourceType = contentTypeToFastType('review');
    let userVote = MyAppPool.session.user.votes[''+voteResourceType][''+reviewItem.reviewid];
    if (typeof userVote ==='undefined')
      userVote=0;
    let {editing,needMore} = this.state;
    let showEditIcons = false;
    let currentUserRolesUse = []
    if (Array.isArray(currentUserRoles))
      currentUserRolesUse = currentUserRoles;
    
    if (currentUserId===reviewItem.author || currentUserRolesUse.indexOf('mabmod')!==-1)
      showEditIcons=true;
    let minLengthToShowMoreBtn=190;
    let minVal= 6;
    let maxVal= 10;
    
    const displayContent = HtmlToReact.Parser().parse(reviewItem.displayhtml);
    return (
        <div className="comp_reviewItem reviewHome uk-card uk-card-default">
        {editing?
        <ReviewWriter alwaysOpen={true}  startContent={reviewItem.displayhtml}
        animGrade={prettyNumber(reviewItem.animationgrade)}
        musicGrade={prettyNumber(reviewItem.musicgrade)}
        storyGrade={prettyNumber(reviewItem.storygrade)}
        characterGrade={prettyNumber(reviewItem.charactergrade)}
        reviewId={reviewItem.reviewid}
        onClose={this.toggleEditing}
        resourceType={reviewItem.resourcetype}
        resourceId={reviewItem.resourceid}
        >
        </ReviewWriter>
        :
        <div className="reviewBox">
          <div> 
            <div> 
            <Link   to={resourceUrl}>
            {title}
            </Link> {(icon)? <i className={icon + ' typeIcon'} data-uk-tooltip={iconText}></i> : <></>}
            </div>
            
              <div className="uk-flex uk-flex-row"> 
                <div>
                  <div className="uk-flex uk-flex-row" data-uk-tooltip={cats[0].name}> 
                    <div className="catName" >{cats[0].icons.map((element, index) => {return (<span className={element} key={index}/>)})} </div> 
                    <div className="catVal">{prettyGradeNumber(reviewItem.animationgrade)}</div> 
                  </div>
                  <div className="uk-flex uk-flex-row" data-uk-tooltip={cats[1].name}>
                    <div className="catName" >{cats[1].icons.map((element, index) => {return (<span className={element} key={index}/>)})} </div>
                    <div className="catVal"> {prettyGradeNumber(reviewItem.musicgrade)} </div> 
                  </div>
                  <div className="uk-flex uk-flex-row" data-uk-tooltip={cats[2].name}>
                    <div className="catName" >{cats[2].icons.map((element, index) => {return (<span className={element} key={index}/>)})} </div>
                    <div className="catVal">{prettyGradeNumber(reviewItem.charactergrade)} </div> 
                  </div>
                  <div className="uk-flex uk-flex-row" data-uk-tooltip={cats[3].name}>
                    <div className="catName" >{cats[3].icons.map((element, index) => {return (<span className={element} key={index}/>)})} </div>
                    <div className="catVal"> {prettyGradeNumber(reviewItem.storygrade)}</div> 
                  </div>
                  <StarsRating 
                  minVal={minVal}
                  maxVal={maxVal}
                  actualVal={reviewItem.finalgrade}
                  data-uk-tooltip={ lng("Final") + " "+prettyGradeNumber(reviewItem.finalgrade)}
                  />
              </div>
              <div style={{paddingLeft:'5%', width:'80%'}}>  
                <Link aria-label={title}  style={{float:'right', marginRight:'5%'}} to={resourceUrl}>
                <LazyLoadImage alt={lng("Capa de")+" "+title} className='listImage' src={getImgAddress(capaImg,'imgs/defaults/anime.webp','x25')}/>
                </Link> 
                <p className={"contentText "+needMore}> {displayContent}</p>
                {reviewItem.displayhtml.length>minLengthToShowMoreBtn? needMore!=='' ?
                <button aria-label={lng('Mais')} className="link-button smalllink" style={{position:'absolute',right:'80px'}} onClick={()=>this.setState({needMore:''})}>{lng('Mais')}</button>
                :<button aria-label={lng('Menos')} className="link-button smalllink" style={{position:'absolute',right:'80px'}} onClick={()=>this.setState({needMore:'needMore'})}>{lng('Menos')}</button>

                : <></>
                }
              </div>
            </div>
            <div className="reviewBottom">
              {userVote===0?
              <button aria-label={lng('Eu achei essa review útil!')} className="uk-button uk-button-default uk-align-left usefulReviewButton" onClick={()=>MyAppPool.cVotes.sendReviewVote(reviewItem.reviewid,reviewItem.resourcetype,reviewItem.resourceid)}> <div className="enclosureThumbs"><i className="far fa-thumbs-up reactionsImg"></i></div> <span className="textInBtn">{lng('Eu achei essa review útil!')}&nbsp; ( {reviewItem.likes} ) </span> </button>
              :
              <button aria-label={lng('Eu achei essa review útil!')} className="uk-button uk-button-default uk-align-left usefulReviewButton reviewButtonActivated" onClick={()=>{}}> <div className="enclosureThumbs"><i className="far fa-thumbs-up thumbsbtn"></i></div> <span className="textInBtn">{lng('Eu achei essa review útil!')}&nbsp; ( {reviewItem.likes} ) </span> </button>
              } 
              <p className="authorText">{lng('Por')} <Link to={getUserRoute (reviewItem.author,sampleAuthor.name,"#top")}>{sampleAuthor.name}</Link>, {prettyDateTimeStringNoSecond(reviewItem.publishdate)} 
                {showEditIcons?
                <span>
                  <span onClick={()=>this.toggleEditing()} className="fas fa-pen iconButton clickable"/> <span onClick={()=>MyAppPool.cModal.set('Deletar Análise',lng('Tem certeza que quer deletar essa análise?'),this.deleteReview)} className="fas fa-trash-alt iconButton clickable"/>
                </span> :
                <span></span>
                }
              </p>
            </div>
          </div>
    </div>
          
  }
    </div>);
}
}

ReviewItemHome.propTypes = {
  reviewItem: PropTypes.shape({
  author: PropTypes.number.isRequired,
  nickname: PropTypes.string.isRequired,
  mainimg: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  reviewid: PropTypes.number.isRequired,
  resourcetype: PropTypes.number.isRequired,
  resourceid: PropTypes.number.isRequired,
  displayhtml: PropTypes.string.isRequired,
  animationgrade: PropTypes.number.isRequired,
  musicgrade: PropTypes.number.isRequired,
  charactergrade: PropTypes.number.isRequired,
  storygrade: PropTypes.number.isRequired,
  finalgrade: PropTypes.number.isRequired,
  publishdate:PropTypes.string.isRequired,
  }).isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  cats:PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icons:PropTypes.arrayOf(PropTypes.string.isRequired),
    })),
};

ReviewItemHome.defaultProps = {
  reviewItem: {
    author: 0,
    nickname: 'Botus',
    mainimg: '',
    likes: 0,
    reviewid: 0,
    resourcetype: 0,
    resourceid: 0,
    displayhtml: 'this is a review',
    animationgrade: 8,
    musicgrade: 8,
    charactergrade: 8,
    storygrade: 8,
    finalgrade: 8,
    publishdate:'06-03-1980'
    },
    currentUserId: 0,
    currentUserRoles: ['mabmod'],
    cats:[
      {name:lng("Arte"),icons:["fas fa-paint-brush","fas fa-palette"]},
      {name:lng('Música/Sons'),icons:["fas fa-music"]},
      {name:lng('Personagens'),icons:['fas fa-running','fas fa-hiking','fas fa-skating']},
      {name:lng('História'),icons:['fas fa-pen']}
  ]
};

export default ReviewItemHome;
