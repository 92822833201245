import PropTypes from 'prop-types';
import React, { Component } from 'react';
import "./AvatarPicker.css";


import { observer } from 'mobx-react';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { getUserAvatar } from '../util/icon';
import FileSelect from '../view_component/FileSelect';
import Spinner from '../view_component/Spinner';
import LinkV2 from './LinkV2';
import { getSupportPage } from '../store/AppRoutes';

@observer
class AvatarPicker extends Component {
    constructor(props) {
        super(props);      
        this.state = {
          sendingAvatar:false,
          avatarUrl:""
        };
        this.resetAvatar = this.resetAvatar.bind(this);
        this.handleAvatarupload = this.handleAvatarupload.bind(this);
      }
      componentDidMount()
      {
        MyAppPool.cImage.fetchUserImages('avatar');
        MyAppPool.cImage.fetchUserImages('banner');
      }
      handleAvatarupload(link){
        this.setState({avatarUrl:link});
      }
      resetAvatar(){
        this.setState({avatarUrl:""});
      }

  render(){
    let userAvatars = MyAppPool.session.user.images.avatar;
    let {onSave} = this.props;
    return <div className="comp_avatar_picker">
    <div id ={lng('Avatar')} className="sectionCard">
    <h1 className="sectionName"><i className="fa fa-user"></i> {lng('Avatar')}</h1>
        <div className="">
            <div className="uk-flex uk-flex-row">
                <img className="avatarBig" src={MyAppPool.cUser.getCurrentUserImg} alt={lng("User")+" "+MyAppPool.currentUser.name+ " "+lng("avatar")}/>
                <div className="fas fa-chevron-right avatarArrow"></div>
                <img className="avatarBig" src={getUserAvatar(this.state.avatarUrl,MyAppPool.currentUser.mailHash)} alt={lng("User")+" "+MyAppPool.currentUser.name+ " "+lng("avatar")}/>
            </div>
            <div className="uk-flex uk-flex-row avatarSmallBox">
                <img className="avatarSmall" src={MyAppPool.cUser.getCurrentUserImg} alt={lng("User")+" "+MyAppPool.currentUser.name+ " "+lng("avatar")}/>
                <div className="fas fa-chevron-right avatarArrowSmall"></div>
                <img className="avatarSmall" src={getUserAvatar(this.state.avatarUrl,MyAppPool.currentUser.mailHash)} alt={lng("User")+" "+MyAppPool.currentUser.name+ " "+lng("avatar")}/>
            </div>
            <div>
                <br/>
                <FileSelect purpose='avatar' text={lng("Carregar do Dispositivo")} onUpload={this.handleAvatarupload}/>
                <LinkV2 to={getSupportPage()}><br/>{"*"+lng('_errgif')}</LinkV2>
                <div className="overflowrapper ">
                <div className="overflowinner appScroll">
                  {userAvatars.map((item,index)=>{
                    let active = item===this.state.avatarUrl?'active':'';
                    let inUse = (item===MyAppPool.currentUser.img||active);
                    return  <div key={index} className="imgDiv">
                              <img onClick={()=>this.setState({avatarUrl:item})} className={"imgForSelection clickable "+active} src={item} alt={lng("Avatar available")}/>
                              {!inUse?<i className="fas fa-trash-alt destroyIcon clickable"
                              onClick={()=>MyAppPool.cModal.set(lng('Deletar Imagem'),lng('Tem certeza?'),()=>MyAppPool.cImage.deleteUserImage(item))}></i>:<></>}
                            </div>    
                  })}
                </div>
                </div>
                <input className="uk-button uk-button-primary" type="button" onClick={()=>{this.resetAvatar()}} value={lng("Usar Gravatar")}/> <a target="_blank"  href={lng("https://gravatar.com/")}>Gravatar</a> {lng("é uma solução global de avatares adotada por muitos sites")} <br/><br/>
                {this.state.sendingAvatar?
                  <div className="uk-button uk-button-primary">
                    <Spinner/>
                  </div>
                :
                <input onClick={()=>MyAppPool.cUser.updateAuthedUserProfile({avatar:this.state.avatarUrl}).then(onSave)} className="uk-button uk-button-green" type="button" value={lng('Salvar')}/>
                }
                <div id="message"></div> 
               
            </div>
        </div>
  </div>
  </div>
  }
}


AvatarPicker.propTypes = {
    ready:PropTypes.number.isRequired,
    changeTime:PropTypes.number.isRequired,
    onSave: PropTypes.func
};
AvatarPicker.defaultProps = {
  ready:0,
  changeTime:8000,
  onSave: ()=>{return Promise.resolve()}
};

export default AvatarPicker;
