import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import MyAppPool from '../AppPool';
import { detectUndefinedInArray } from '../util/typeu';

class ContentBannerSetter extends Component {
  componentDidMount()
  {
    
  }

  render(){
    if (detectUndefinedInArray(this.props.images))
      return <></>;
    MyAppPool.cContentBanner.setContentBannerImage(this.props.images,this.props.modes, this.props.component);

    return <div></div>;
}

}
ContentBannerSetter.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  modes: PropTypes.arrayOf(PropTypes.string).isRequired,
  component: PropTypes.object
};
ContentBannerSetter.defaultProps = {
    images:[''],
    modes:['bg'],
    component:<></>
};

export default ContentBannerSetter;
