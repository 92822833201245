import HtmlToReact from 'html-to-react';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getUserRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { prettyGradeNumber } from '../util/text';
import { prettyDateTimeStringNoSecond } from '../util/timeUtil';
import { contentTypeToFastType } from '../util/typeu';
import MyAppPool from './../AppPool';
import "./ReviewItem.css";
import ReviewWriter from './ReviewWriter';
import { genRandId } from './WYSIWYGWriterUtils';
import StarsRating from './StarsRating';

function prettyNumber(numb)
{
  let newNumb = parseFloat(numb);
  if (parseInt(newNumb)===newNumb && newNumb < 10)
    return newNumb + '.0';
  return newNumb.toString();
}

@observer
class ReviewItem extends Component {

  constructor(props) {
    super(props);
    this.state = {editing:false,editorEditId:genRandId('revthe-'),needMore:'needMore'};
    this.deletePost = this.deleteReview.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.deleteReview = this.deleteReview.bind(this);
}
  deleteReview()
  {
    let {reviewItem} = this.props;
    MyAppPool.cReview.deleteReview(reviewItem);
  }
  toggleEditing()
  {
    this.setState({editing:!this.state.editing});
  }
  componentDidMount()
  {

  }

  render(){

    let {reviewItem,currentUserRoles,currentUserId,cats} = this.props;
    let voteResourceType = contentTypeToFastType('review');
    let userVote = MyAppPool.session.user.votes[''+voteResourceType][''+reviewItem.reviewid];
    if (typeof userVote ==='undefined')
      userVote=0;
    let minLengthToShowMoreBtn=200;
    let {editing,needMore} = this.state;
    let showEditIcons = false;
    let currentUserRolesUse = []
    if (Array.isArray(currentUserRoles))
      currentUserRolesUse = currentUserRoles;
    
    if (currentUserId===reviewItem.author || currentUserRolesUse.indexOf('mabmod')!==-1)
      showEditIcons=true;

    let authorId = reviewItem.author;
    let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+contentTypeToFastType('user')][''+authorId]);

    let minVal= 6;
    let maxVal= 10;

    const displayContent = HtmlToReact.Parser().parse(reviewItem.displayhtml);
    return (
        <div className="comp_reviewItem">
        {editing?
        <ReviewWriter alwaysOpen={true}  startContent={reviewItem.displayhtml}
        animGrade={prettyNumber(reviewItem.animationgrade)}
        musicGrade={prettyNumber(reviewItem.musicgrade)}
        storyGrade={prettyNumber(reviewItem.storygrade)}
        characterGrade={prettyNumber(reviewItem.charactergrade)}
        reviewId={prettyNumber(reviewItem.reviewid)}
        onClose={this.toggleEditing}
        resourceType={reviewItem.resourcetype}
        resourceId={reviewItem.resourceid}
        >
        </ReviewWriter>
        :
        <div className="reviewBox">
        <table width="100%">
        <tbody>
        <tr className="tableTitles">
          <td><span data-uk-tooltip={cats[0].name}>{cats[0].icons.map((element, index) => {return (<span className={element} key={index}/>)})}</span></td>
          <td><span data-uk-tooltip={cats[1].name}>{cats[1].icons.map((element, index) => {return (<span className={element} key={index}/>)})}</span></td>
          <td><span data-uk-tooltip={cats[2].name}>{cats[2].icons.map((element, index) => {return (<span className={element} key={index}/>)})}</span></td>
          <td><span data-uk-tooltip={cats[3].name}>{cats[3].icons.map((element, index) => {return (<span className={element} key={index}/>)})}</span></td>
          <td>{lng("Final")}</td>
        </tr>
          <tr>
            <td style={{width:'20%'}}>{prettyGradeNumber(reviewItem.animationgrade)}</td>
            <td style={{width:'20%'}}>{prettyGradeNumber(reviewItem.musicgrade)}</td>
            <td style={{width:'20%'}}>{prettyGradeNumber(reviewItem.charactergrade)}</td>
            <td style={{width:'20%'}}>{prettyGradeNumber(reviewItem.storygrade)}</td>
            <td style={{width:'20%'}}>
            <StarsRating
                  minVal={minVal}
                  maxVal={maxVal}
                  actualVal={reviewItem.finalgrade}
                  data-uk-tooltip={ lng("Final") + " "+prettyGradeNumber(reviewItem.finalgrade)}
                  style={{paddingLeft:'10%'}}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={5} style={{position:'relative'}}>
              <p className={"contentText "+needMore}> {displayContent}</p>
              {reviewItem.displayhtml.length>minLengthToShowMoreBtn? 
                needMore!==''?
                <button aria-label={lng('Mais')} className="link-button smalllink" style={{position:'absolute',right:'30px', bottom:'-5px'}} onClick={()=>this.setState({needMore:''})}>{lng('Mais')}</button>
                :<button aria-label={lng('Menos')} className="link-button smalllink" style={{position:'absolute',right:'30px', bottom:'-5px'}} onClick={()=>this.setState({needMore:'needMore'})}>{lng('Menos')}</button>

              : <></>
              }
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
            {userVote===0?
            <button aria-label={lng('Eu achei essa review útil!')} className="uk-button uk-button-default uk-align-left usefulReviewButton" onClick={()=>MyAppPool.cVotes.sendReviewVote(reviewItem.reviewid,reviewItem.resourcetype,reviewItem.resourceid)}> <div className="enclosureThumbs"><i className="far fa-thumbs-up reactionsImg"></i></div> <span className="textInBtn">{lng('Eu achei essa review útil!')}&nbsp; ( {reviewItem.likes} ) </span> </button>
            :
            <button aria-label={lng('Eu achei essa review útil!')} className="uk-button uk-button-default uk-align-left usefulReviewButton reviewButtonActivated" onClick={()=>{}}> <div className="enclosureThumbs"><i className="far fa-thumbs-up thumbsbtn"></i></div> <span className="textInBtn">{lng('Eu achei essa review útil!')}&nbsp; ( {reviewItem.likes} ) </span> </button>
            } 
            <p className="authorText">{lng('Por')} <Link to={getUserRoute (reviewItem.author,sampleAuthor.name,"#top")}>{sampleAuthor.name}</Link>, {prettyDateTimeStringNoSecond(reviewItem.publishdate)} 
            {showEditIcons?
            <span>
              <span onClick={()=>this.toggleEditing()} className="fas fa-pen iconButton clickable"/> <span onClick={()=>MyAppPool.cModal.set('Deletar Análise',lng('Tem certeza que quer deletar essa análise?'),this.deleteReview)} className="fas fa-trash-alt iconButton clickable"/>
            </span> :
            <span></span>
            }
            </p>
            </td>
          </tr>
          </tbody>
        </table>
    </div>
  }
    </div>);
}
}

ReviewItem.propTypes = {
  reviewItem: PropTypes.shape({
  author: PropTypes.number.isRequired,
  nickname: PropTypes.string.isRequired,
  mainimg: PropTypes.string.isRequired,
  hashimg: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  reviewid: PropTypes.number.isRequired,
  displayhtml: PropTypes.string.isRequired,
  animationgrade: PropTypes.number.isRequired,
  musicgrade: PropTypes.number.isRequired,
  charactergrade: PropTypes.number.isRequired,
  storygrade: PropTypes.number.isRequired,
  finalgrade: PropTypes.number.isRequired,
  publishdate:PropTypes.string.isRequired,
  }).isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  cats:PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icons:PropTypes.arrayOf(PropTypes.string.isRequired),
    })),
};

ReviewItem.defaultProps = {
  reviewItem: {
    author: 0,
    nickname: 'Botus',
    mainimg: '',
    hashimg: '',
    likes: 0,
    reviewid: 0,
    displayhtml: 'this is a review',
    animationgrade: 8,
    musicgrade: 8,
    charactergrade: 8,
    storygrade: 8,
    finalgrade: 8,
    publishdate:'06-03-1980'
    },
    currentUserId: 0,
    currentUserRoles: ['mabmod'],
    cats:[
      {name:lng("Arte"),icons:["fas fa-paint-brush","fas fa-palette"]},
      {name:lng('Música/Sons'),icons:["fas fa-music"]},
      {name:lng('Personagens'),icons:['fas fa-running','fas fa-hiking','fas fa-skating']},
      {name:lng('História'),icons:['fas fa-pen']}
  ]
};

export default ReviewItem;
