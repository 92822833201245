import HtmlToReact from 'html-to-react';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { getResourceRoute, getUserRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon } from '../util/icon';
import { lng } from '../ulanguages/language';
import { getImgAddress, prettyGradeNumber, prettyNumber } from '../util/text';
import { prettyDateTimeStringNoSecond } from '../util/timeUtil';
import { contentFastTypeToType, contentTypeToFastType, isSet } from '../util/typeu';
import { isServer } from '../util/windowUtil';
import MyAppPool from './../AppPool';
import "./StarsRating.css";
import ReviewWriter from './ReviewWriter';
import { genRandId } from './WYSIWYGWriterUtils';




@observer
class StarsRating extends Component {

  constructor(props) {
    super(props);
    this.state = {};
}
  componentDidMount()
  {

  }

  render(){
    let {minVal,maxVal,actualVal,vertical} = this.props;

    let stars = [];
    for(let k=0;k<=(maxVal-minVal);k+=1)
    {
      let currentPoint=k+minVal;
      if (currentPoint > actualVal)
        stars[k] = <i className="far fa-star star"></i>
      if (currentPoint <= actualVal)
        stars[k] = <i className="fas fa-star star" style={{color:'#F8B704'}}></i>
      if (currentPoint > actualVal && currentPoint-0.5 <= actualVal)
        stars[k] = <i className="fas fa-star-half-alt star" style={{color:'#F8B704'}}></i>
    }
    let receivingClassName = this.props.className;

    let myClassName = " uk-flex uk-flex-row "
    if (vertical)
    {
    myClassName = " flexDrop verticalStarsRating ";
    //stars = stars.reverse();
    }

    if (isSet(receivingClassName))
      myClassName = myClassName+receivingClassName;

    
    return (<div {...this.props} className={myClassName} >
                    {stars}
                  </div>);
}
}

StarsRating.propTypes = {
    minVal:PropTypes.number.isRequired,
    maxVal:PropTypes.number.isRequired,
    actualVal:PropTypes.number.isRequired,
    vertical:PropTypes.bool
};

StarsRating.defaultProps = {
    minVal:6,
    maxVal:10,
    actualVal:5,
    vertical:false
};

export default StarsRating;
