import React, { Component } from 'react';
import "./BannerReaction.css";
import PropTypes from 'prop-types';
import { lng } from '../ulanguages/language';
import CSSTransition2 from './CSSTransition2';
import MyAppPool from '../AppPool';
import { getUserAvatar } from '../util/icon';
import { contentTypeToFastType, isSet } from '../util/typeu';
import { genRandId } from './WYSIWYGWriterUtils';
import LinkV2 from './LinkV2';
import { getUserRoute } from '../store/AppRoutes';

let bannerReactionControl = {};

class BannerReaction extends Component {
  constructor(props) {
    super(props);
    this.changeReaction = this.changeReaction.bind(this);
    this.state = {fade:'',img:'',content:'',userId:0,userName:'',reactionId:0, lastPick:-1};
    this.myId= genRandId();
    bannerReactionControl[this.myId]=true;
  }

  componentDidMount()
  {
    bannerReactionControl[this.myId]=true;
    this.changeReaction();
  }
  componentWillUnmount(){
    bannerReactionControl[this.myId]=false;
  }
  changeReaction()
  {
    let {items,changeTime} = this.props;
    let {lastPick,open} = this.state;
    if(!bannerReactionControl[this.myId])
      return;
    if (items.length>0)
    {
      let pick = Math.floor( Math.random() * items.length ); 
      while (lastPick==pick && items.length>1)
      {
        pick = Math.floor( Math.random() * items.length ); 
      }
      let item = MyAppPool.session.reactionData[items[pick]];
      if (!isSet(item))
      {//if something is wrong, we avoid and try again later
        setTimeout(this.changeReaction,changeTime);
        return;
      }

      let authorId = item.author;
      let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+contentTypeToFastType('user')][''+authorId]);
      this.setState({
        img:getUserAvatar(sampleAuthor.img,sampleAuthor.hash,'x25'),
        content:item.displaytext,
        userId:item.author,
        userName:sampleAuthor.name,
        reactionId:item.reactid,
        lastPick:pick,
        fade:'fade-in-text'
      });
        setTimeout(()=> this.setState({fade:''}),1000);
    }
    if (lastPick==-1)
      setTimeout(this.changeReaction,100);
    else
      setTimeout(this.changeReaction,changeTime);
  }

  render(){
    let {img,content,userId,userName,reactionId,fade} = this.state;
    let {items,ready} = this.props;
    const displayContent = content;
    return (
      <div className="comp_bannerReaction">
      {items.length>0 && reactionId!=0?
      <CSSTransition2 
      in={ready}
      timeout={300}
      classNames="reaction-node"
      unmountOnExit>
        <div className={"uk-flex uk-flex-row comp_reactionContent "+ fade}>
          <LinkV2 to={getUserRoute(userId,userName,"#top")}>
            <img  className="posterimg" src={img} alt={lng("User")+" "+userName+ " "+lng("avatar")}/>
          </LinkV2>
          <span id={reactionId} dangerouslySetInnerHTML={{__html: displayContent}}></span>
        </div>
      </CSSTransition2>
      : <span></span>
      
    }
    </div>);
  }
}


BannerReaction.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    displaytext: PropTypes.string.isRequired,
    publishdate:PropTypes.string.isRequired,
    votes:PropTypes.number.isRequired,
    authorname:PropTypes.string.isRequired,
    authorimg:PropTypes.string.isRequired,
    author:PropTypes.number.isRequired
    })),
    ready:PropTypes.number.isRequired,
    changeTime:PropTypes.number.isRequired
};
BannerReaction.defaultProps = {
  items:[],
  ready:0,
  changeTime:8000
};

export default BannerReaction;
