import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from '../AppPool';
import { isSet } from './../util/typeu';
import { isServer } from './../util/windowUtil';
import CSSTransition2 from './CSSTransition2';
import "./PopMenuReceiver.css";

@observer
class PopMenuReceiver extends Component {

  constructor(props) {
      super(props);
      this.state = {
        children:[],
        grace:0,
        lastX:0,
        lastY:0,
        show:1
      };
      this.setChildren = this.setChildren.bind(this);
      this.handleMenuClick = this.handleMenuClick.bind(this);
      this.listenToScroll = this.listenToScroll.bind(this);
      this.wrapperRef = React.createRef();
      if (!isServer())
       window.addEventListener("scroll", this.listenToScroll);
  }
  componentWillUnmount()
  {
    if (!isServer())
      window.removeEventListener("scroll", this.listenToScroll); 
  }
  listenToScroll()
  {
    MyAppPool.cPopMenu.deactivate();
  }
  
  setChildren = autorun(()=>{
   // let {lastX,lastY,show} = this.state;
    this.setState({children:MyAppPool.jsObjectStore.popMenu.content});
    this.setState({grace:Date.now()+50, lastX:MyAppPool.jsObjectStore.popMenu.x});
    //console.log(toJS(MyAppPool.session.scrollPosition));
  });

  handleMenuClick(e) {
    if(isSet(this.wrapperRef)){
    if(isSet(this.wrapperRef.current)){
      if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
        if (this.state.grace<Date.now())//check if we didn't just open the menu
        {
          MyAppPool.cPopMenu.deactivate();
          //console.log("Handle Click3 ");
        }
      }
    }
  }
}
  
  componentDidMount()
  {
    document.addEventListener('click', this.handleMenuClick, false);
  }

  componentDidUpdate(prevProps, prevState)
  {
    if (prevState.lastX!==MyAppPool.jsObjectStore.popMenu.x || prevState.lastY!==MyAppPool.jsObjectStore.popMenu.y)
    {
      this.setState({show:0,lastX:MyAppPool.jsObjectStore.popMenu.x,lastY:MyAppPool.jsObjectStore.popMenu.y});
      setTimeout(()=>{this.setState({show:1})},50);
      //console.log("X CHANGED:",prevState.lastX,MyAppPool.jsObjectStore.popMenu.x);
    }
  }



  render(){
    let {x,y,className,active} = MyAppPool.jsObjectStore.popMenu;
    let {children,show,lastX,lastY} = this.state;
    //console.log(this.state);
    let myStyle = {'left':lastX+'px','top':lastY+'px'};
    
    return <div className={"comp_pop_menu"} >
        {show?
        <CSSTransition2
        in={active}
        timeout={100}
        classNames="pop-node"
        unmountOnExit
        id={x*y}
        >
          <div key={0} ref={this.wrapperRef} className={"window "+className} style={myStyle}>
            {children}
          </div>
        </CSSTransition2>
        :<></>}
    </div>;
}
}

PopMenuReceiver.propTypes = {
index: PropTypes.number.isRequired,
};
PopMenuReceiver.defaultProps = {
  index:0
};

export default PopMenuReceiver;


//theres a problem when closing music player but floating menu is still open