import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { isMobile } from './../util/mobile';
import { getImgAddress } from './../util/text';
import { isSet } from './../util/typeu';
import { getCurrentDomain, getCurrentPageDescription, getCurrentPageTitle, getCurrentUrl, getElementRectAbsolute, isServer, getCurrentUrlUrlEncoded, getCurrentPageTitleUrlEncoded } from './../util/windowUtil';
import ButtonsPanel from './ButtonsPanel';
import "./CapaSection.css";
import CapaWorkLikeButton from './CapaWorkLikeButton';
import Grader from './Grader';
import ListWidget from './ListWidget';




class Capa extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {

  }

  render(){
    let {img,ready} = this.props;

    let ratioDiv='ratioDiv'; // class name for divs adjusted by WIDTH
    if (isMobile.any)
    {
      ratioDiv='';//not needed in mobile mode, since capa has fixed height in mobile
    }
    if (!ready)
    {
        return <div className="capacontainer capaUserPage ">
        <div className={"capa skeleton capaskeleton "+ratioDiv} /> 
        </div>;
    }
    return (
        <div className="capacontainer capaUserPage">
        <img  className="capa" src={getImgAddress(img)}  alt={lng('Capa')}/> <br/>
    </div>);
}
}

Capa.propTypes = {
  img: PropTypes.string.isRequired,
  resourceType:PropTypes.number.isRequired,
  resourceId:PropTypes.number.isRequired,
  ready: PropTypes.number.isRequired,
  gradeable:PropTypes.bool.isRequired,
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      created: PropTypes.string.isRequired,
      essentialid: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      ordering: PropTypes.number.isRequired,
      resourcetype: PropTypes.number.isRequired,
      listid: PropTypes.number.isRequired,
      itens: PropTypes.arrayOf(
        PropTypes.shape({
          setdate: PropTypes.string.isRequired,
          resourceid: PropTypes.number.isRequired
        }))
    })),
    listsPresence: PropTypes.object.isRequired
};
Capa.defaultProps = {
  img: '',
  ready:0,
  resourceType:0,
  gradeable:false,
  resourceId:-1,
  lists:[],
  listsPresence:{},
  gradeOptions:{
    minVal: 5,
    maxVal: 10,
    increment: 0.5,
    rgbStart : [153,0,0],
    rgbMiddle : [255, 255, 153],
    rgbEnd: [0, 255, 85]
  }
};

export default Capa;
