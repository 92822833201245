import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyAppPool from './../AppPool';
import { isMobile } from './../util/mobile';
import "./LinkV2.css";
import { isSet } from '../util/typeu';

@observer
class LinkV2 extends Component {
    constructor(props)
    {
    super(props);
    }
    componentDidMount()
    {
    }

    render()
    {
    let {to} = this.props;
    if (typeof to ==='string' && to.indexOf("http")!==-1)
        return <a {...this.props} href={to} target="_blank"> {this.props.children} </a>;
    if (!isSet(to) || to==='')
        return <span {...this.props} > {this.props.children} </span>
    return <Link {...this.props} > {this.props.children} </Link>
    }
}

LinkV2.propTypes = {
};
LinkV2.defaultProps = {
};
export default LinkV2;
