import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import {  convertDataToShowable, getImgAddress, getRouteParamAtIndex } from '../util/text';
import { contentTypeToFastType, isSet } from '../util/typeu';
import BannerReaction from '../view_component/BannerReaction';
import BreadCumbs from '../view_component/BreadCumbs';
import CapaSection from '../view_component/CapaSection';
import Erro404 from '../view_component/Erro404';
import SkeletonBlock from '../view_component/SkeletonBlock';
import SubNavigation from '../view_component/SubNavigation';
import TagList from '../view_component/TagList';
import TextContentBox from '../view_component/TextContentBox';
import { isMobile } from './../util/mobile';
import { getCurrentUrl, isServer } from './../util/windowUtil';
import './TopAnimeCollumn.css';
import CSSTransition2 from './CSSTransition2';
import { PropTypes } from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getObraRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon} from '../util/icon';
import Spinner from './Spinner';
import { mobToObj } from './../util/typeu';


function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}
const nextTime=5000;
@observer
class TopAnimeCollumn extends Component {
  constructor(props)
  {
    super(props);
    this.state = {};
  }

  componentDidMount()
  {
  }
  componentWillReceiveProps()
  {
  }

  render(){

    let {items,topsName,moreUrl,max,showType} = this.props;

    let {ready} = this.props;
    if (!ready)
      return <SkeletonBlock  style={{'width':'90%',height:'916px'}}/>
    
    if (items.length==0)
        return <></>;
     // console.log(mobToObj(items));
    if (!Array.isArray(items))
      items=[];
    let renderItems = items.slice(0,max);
//{icon? <i className={icon + ' searchIcon'} data-uk-tooltip={iconText}></i> : <></>}
    return (
        <div className="comp_topAnimeCollumn uk-card uk-card-default">
            <div className="titlebar">
                <h3>{topsName} {moreUrl!==''?<Link className="maisLink uk-align-right" to={moreUrl}>{lng('Mais')}</Link>:<></>}</h3>
            </div>
            <div className="colunmList">
              <ul className="listItens">
                {renderItems.map((element, index) => {
                  let icon = getRelatedTypeIcon(element.type);
                  let iconText = lng(element.type);
                return (
                <li key={index}>
                  <div className={'uk-flex uk-flex-row uk-card listItem '}>
                    <div>{index+1}</div>
                    <Link aria-label={element.title} className='listImageLink' to={getObraRoute(element.id,element.title,'anime','#top')}>
                      <LazyLoadImage alt={lng("Capa de")+" "+element.title} className='listImage'  src={getImgAddress(element.mainimg,'imgs/default/anime.webp','x25')}/>
                    </Link>
                    <div className="textContent">
                      <Link to={getObraRoute(element.id,element.title,'anime','#top')}><h5>{element.title+" "}
                      {(icon && showType)? <i className={icon + ' searchIcon'} data-uk-tooltip={iconText}></i> : <></>}
                      </h5> 
                      </Link>
                      <span>
                        
                        {isSet(element.views)? lng('Usuários')+': '+element.views :<span></span>}
                        {isSet(element.averagegrade)? lng('Nota')+': '+element.averagegrade :<span></span>} <br />
                        {isSet(element.episodecount)? lng('Episódios')+': '+(element.episodecount>0?element.episodecount:'?') :<span></span>}
                      </span>
                      {(isSet(element.airfrom) && element.airfrom!='')?
                        <div className="itemUnderText" onClick={()=>{}}>
                          
                          {convertDataToShowable(element.airfrom)}
                        </div>
                      :<></>}
                    </div>
                    
                  </div>
                    <div className="uk-nav-divider"></div> 
                </li>)})
                }
                
            </ul>
          </div>
        
      </div>
    );
  }
}


TopAnimeCollumn.propTypes = {
  ready:PropTypes.number,
  topsName:PropTypes.string.isRequired,
  moreUrl:PropTypes.string.isRequired,
  max:PropTypes.number,
  showType:PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(
      {
        title: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        mainimg: PropTypes.string,

        airfrom:PropTypes.string,
        type:PropTypes.string,
        totalvotes: PropTypes.number,
        episodecount: PropTypes.number,
        views: PropTypes.number
      }
      ))
};
TopAnimeCollumn.defaultProps = {
    items:[
    ],
    topsName:'',
    moreUrl:'',
    max:7,
    showType:false,
    ready:0
};



export default TopAnimeCollumn;
