
import { computed, toJS } from 'mobx';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { getCharacterRoute, getMagazineRoute, getMangaPeopleRoute, getMangaPersonagensRoute, getMangaRelatedRoute, getObraRoute, getPersonRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon } from '../util/icon';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { convertDataToShowable, getAnchorName, getSEOUriPath } from '../util/text';
import { contentTypeToFastType, isValidIndex, isSet, mobToObj } from '../util/typeu';
import { isServer } from '../util/windowUtil';


export default class MangaController
{
    fetchMangaById(index)
    {
        let loadId;
        if (!isSet(index) || !isValidIndex(index))
            return Promise.resolve(true);
        if (Number(index)===Number(MyAppPool.session.manga.currentManga))
        {   
            loadId = MyAppPool.cGlobalLoadingBar.start();
            MyAppPool.cGlobalLoadingBar.end(loadId,true);
            return Promise.resolve(true);
        }
        MyAppPool.session.manga.mangas[index] = {ready:0};
        MyAppPool.session.manga.currentManga= index;
        loadId = MyAppPool.cGlobalLoadingBar.start(1500);

        if (!isServer())
            brasRequest(routes.resourcePopularCount,false,{resourceType:contentTypeToFastType('manga'),resourceId:index});
      
        return fetchAndProcess(this.setSessionManga,
            routes.getMangaByIdLng,true,{aid:index,lng: getCurrentLanguage()})
            .then(async (data) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,true);
                let manga = MyAppPool.session.manga.mangas[MyAppPool.session.manga.currentManga];
                await MyAppPool.cAffiliate.getMangaAmazonAffiliate(manga);
                return Promise.resolve(data);
            })
            .catch((e) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,false);
                console.log("Error Fetching manga",e)
            });
    }

    setSessionManga(data0,ready)
    {
        if (!data0.ok)
            return Promise.reject();
        
        let data = data0.data;
        if (!data.empty)
        {    
            data.mainTitle = data.titles[0].title;
            let maxLength = 8;
            let engTitle = '';
            data.remainingTitles = '';
            if (data.titles.length<maxLength)
                maxLength = data.titles.length;
            else
            {
                for (let i=maxLength; i < data.titles.length;i++)
                {
                    data.remainingTitles += data.titles[i].title;
                    if (data.titles[i].type!=="syn" && data.titles[i].langs.indexOf("en")!==-1 && data.titles[i].title.length>engTitle.length)
                        engTitle=data.titles[i].title;
                    if (i<(data.titles.length-1))
                        data.remainingTitles+=", ";
                }
            }
            data.otherTitles = '';
            for (let i=1; i < maxLength;i++)
            {
                data.otherTitles += data.titles[i].title;
                if (data.titles[i].type!=="syn" && data.titles[i].langs.indexOf("en")!==-1 && data.titles[i].title.length>engTitle.length)
                    engTitle=data.titles[i].title;
                if (i<(maxLength-1))
                    data.otherTitles+=", ";
            }
            if (engTitle==='')
                engTitle=data.mainTitle;
            data.engTitle = engTitle;
            
            data.columItems = [];
            if (data.type!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Tipo'),info:[{type:'misc',value:'',display:lng(data.type)}]};
            
            if (data.publishers!==null)
            {   
                if (data.publishers.length>0)
                {
                    let items = data.publishers;
                    let dcolumItems = [];
                    for (let i=0; i< items.length; i++)
                    {
                        dcolumItems[i] = {type:'url',value:getMagazineRoute(items[i].id,items[i].name),display:items[i].name};
                    }
                    data.columItems[data.columItems.length] ={name:lng('Revista(s)'),info:dcolumItems};
                }
            }
            
            
            if (data.airfrom!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Lançamento'),info:[{type:'date',value:'',display:convertDataToShowable(data.airfrom)}]};            
            if (data.airto!==null && data.airto!==data.airfrom)
                data.columItems[data.columItems.length] = 
                {name:lng('Finale'),info:[{type:'date',value:'',display:convertDataToShowable(data.airto)}]};
            if (data.chapters!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Capítulos'),info:[{type:'misc',value:'',display:data.chapters}]};
            if (data.volumes!==null)
                data.columItems[data.columItems.length] =
                {name:lng('Volumes'),info:[{type:'misc',value:'',display:data.volumes}]};
            if (data.remainingTitles!=='')
                data.columItems[data.columItems.length] =
                {name:lng('Outros Titulos'),info:[{type:'misc',value:'',display:data.remainingTitles}]};
            
            let relPeople = [];
            let relRelatedWorks = [];
            let relCharacters = [];
            let relatedAnimeMangaTypes = {};
            let relatedAnimeMangaRelations = {};
            for(let i =0; i<data.related.length; i++)
            {
                let element = data.related[i];
                let anchor = '#'+getAnchorName(element.title);
                relRelatedWorks[i] = {url:  getMangaRelatedRoute(data.id,getSEOUriPath(data.mainTitle),anchor),
                directUrl:getObraRoute(element.id,getSEOUriPath(element.title),element.pagetype,'#top'),
                imgurl:element.mainimg, 
                title:element.title,
                icon: getRelatedTypeIcon(element.type),
                iconText: lng(element.type),
                resourceType:contentTypeToFastType(element.pagetype),
                resourceId:element.id
                }
                relatedAnimeMangaTypes[element.type]=1;
                relatedAnimeMangaRelations[element.relation]=1;
            }
            relatedAnimeMangaTypes = Object.keys(relatedAnimeMangaTypes);
            relatedAnimeMangaRelations = Object.keys(relatedAnimeMangaRelations);
            

            for(let i =0; i<data.characters.length; i++)
            {
                let element = data.characters[i];
                let anchor = '#'+getAnchorName(element.name+ ' ' + element.familyname);
                relCharacters[i] = {url:  getMangaPersonagensRoute(data.id,getSEOUriPath(data.mainTitle),anchor),
                    directUrl:getCharacterRoute (element.characterid,getSEOUriPath(element.name + ' ' + element.familyname),'#top'),
                    imgurl:element.mainimg, 
                    title:element.name + ' ' + element.familyname,
                    resourceType:contentTypeToFastType("character"),
                    resourceId:element.characterid
                }
                element.relVoiceActors = [];
            }

            let peopleById = {};
            let personRow = [];
            let kCounter=0;
            let peoplePositions = {};
            for(let i =0; i<data.people.length; i++)
            {
                let element = data.people[i];
                let arrayId = peopleById[element.personid];
                if (typeof arrayId!=='undefined')
                {
                    personRow[arrayId].roles[personRow[arrayId].roles.length] = {role:element.role,salary:element.salary,description:element.roledescription};
                    personRow[arrayId].rolesTogether += ', '+ element.role;
                    continue;
                }
                
                personRow[kCounter] = element;
                personRow[kCounter].roles = [{role:element.role,salary:element.salary,description:element.roledescription}];
                personRow[kCounter].rolesTogether = element.role;
                peoplePositions[element.role]=1;
                

                peopleById[data.people[i].personid] = kCounter; 
                let anchor = '#'+getAnchorName(element.name+ ' ' + element.familyname);
                relPeople[kCounter] = {
                    url:  getMangaPeopleRoute(data.id,getSEOUriPath(data.mainTitle),anchor),
                    directUrl:getPersonRoute(element.personid,getSEOUriPath(element.name + ' ' + element.familyname),'#top'),
                    imgurl:element.mainimg, title:element.name+ ' ' + element.familyname,
                    resourceType:contentTypeToFastType("person"),
                    resourceId:element.personid
                } 
                kCounter++;
            }
            peoplePositions = Object.keys(peoplePositions);
            
            data.peoplePositions = peoplePositions;
            data.relatedAnimeMangaRelations = relatedAnimeMangaRelations;
            data.relatedAnimeMangaTypes = relatedAnimeMangaTypes;
            data.personRows = personRow;
            data.relPeople = relPeople;
            data.relRelatedWorks = relRelatedWorks;
            data.relCharacters = relCharacters;
            data.baseUrl = getObraRoute(data.id,data.mainTitle,'manga');
            data.resourceType = contentTypeToFastType('manga');
        }
        //console.log(data);
        MyAppPool.session.grades[contentTypeToFastType('manga')][data.id] = data.grades;
        MyAppPool.session.manga.mangas[data.id] = {...MyAppPool.session.manga.mangas[data.id],...data} ;
        MyAppPool.session.manga.mangas[data.id].ready = ready?1:0;
        //console.log(data.people);
        
        MyAppPool.session.manga.currentManga= data.id;


        MyAppPool.cPageJsonSchema.makeMangaJsonSchema();

        return Promise.resolve(true);
    }

    @computed get currentManga()
    {
        let data = toJS(MyAppPool.session.manga.mangas[MyAppPool.session.manga.currentManga]);
        if (!isSet(data))
            return {ready:0};
        data.grades = MyAppPool.session.grades[contentTypeToFastType('manga')][MyAppPool.session.manga.currentManga];
        return data;//MyAppPool.session.manga.mangas[MyAppPool.session.manga.currentManga];
    }

    getMangaSamplesByCommaSeparatedIdsList(idsInCommaSeparatedList)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            for(let i=0;i<resData.length;i++)
            {
                let item = resData[i];
                MyAppPool.session.samples.manga[''+item.id] = item;
            }
            //console.log("MANgas:::",mobToObj(MyAppPool.session.samples.manga));
            return Promise.resolve();
        },
        routes.getMangasSamplesByIdsLng,true,
        {ids:idsInCommaSeparatedList,lng:getCurrentLanguage()})
        .catch(e => console.log(("Error Getting Manga Samples"),e));
    }


    mergeManga(receiverId,giverId)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            MyAppPool.cAlert.notifyOk("Manga Merged Succesfully");
            return Promise.resolve();
        },
        routes.mergeManga,false,
        {receiverId,giverId})
        .catch(e => {
            MyAppPool.cAlert.notifyGenericError(JSON.stringify(e),"merge manga");
        });
    }
    
}