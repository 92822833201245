
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { lng } from '../../ulanguages/language';
import ScrollToTopButtom from '../../view_component/ScrollToTopButton';
import SkeletonBlock from '../../view_component/SkeletonBlock';
import MyAppPool from './../../AppPool';
import { getRgbRanges } from './../../util/gradient';
import { isMobile } from './../../util/mobile';
import { getAnchorName, getSiteShortName } from './../../util/text';
import { contentTypeToFastType, mobToObj } from './../../util/typeu';
import { isServer } from './../../util/windowUtil';
import NoRecordsFoundCard from './../../view_component/NoRecordsFoundCard';
import ReviewItem from './../../view_component/ReviewItem';
import ReviewWriter from './../../view_component/ReviewWriter';
import './CompanyReviewView.css';


@observer
class CompanyReviewView extends Component {

  constructor(props) {
    super(props);
    let colors = getRgbRanges([[153,0,0],[255, 255, 153],[0, 255, 85]],4);
    let gradients = [];
    for(let i=0;i<colors.length;i++)
    {
      gradients[i] =`linear-gradient(to right, rgb(${colors[i].start[0]}, ${colors[i].start[1]}, ${colors[i].start[2]}),
      rgb(${colors[i].end[0]}, ${colors[i].end[1]}, ${colors[i].end[2]}) )`;
    }

    this.state = { 
        maxValueFilter:-1,
        minValueFilter:-1,
        filterIndex:-1,
        positionFilter:'',
        gradients: gradients
    };        
    this.biggerOrEqualThanFilter = this.biggerOrEqualThanFilter.bind(this);
    this.smallerThanFilter = this.smallerThanFilter.bind(this);
    this.passFilter = this.passFilter.bind(this);
    }
    componentDidMount()
    {
    }
    biggerOrEqualThanFilter(filter,value)
    {
      if (filter===-1)
        return true;
      if (value>=filter)
        return true;
      return false;
    }
    smallerThanFilter(filter,value)
    {
      if (filter===-1)
        return true;
      if (value<filter)
        return true;
      return false;
    }
    passFilter(item)
    {
      let {minValueFilter,maxValueFilter} = this.state;
      return (this.smallerThanFilter(maxValueFilter,item.finalgrade)
      && this.biggerOrEqualThanFilter(minValueFilter,item.finalgrade)
      )
    }

    filterBtn(btnIndex,btnMinVal,btnMaxVal)
    {
      let {filterIndex} = this.state;
      let newVal = filterIndex===btnIndex?-1:btnIndex;
      let newMin = filterIndex===btnIndex?-1:btnMinVal
      let newMax = filterIndex===btnIndex?-1:btnMaxVal;
      this.setState({filterIndex: newVal, minValueFilter:newMin,maxValueFilter:newMax});
    }

    render()
    {
      //let baseUrl = ct.server.BASE_URL + ct.server.BASE_PORT;
      let {filterIndex,gradients} = this.state;
      let {current} = this.props;
      let reviews = [];
      let reviewsReady=0;
      let resourceType = contentTypeToFastType('producer');
      let meta = {title:'Company | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:''};
   

      if (current.ready)
      {
        resourceType = current.resourceType;
        reviews = MyAppPool.session.review[resourceType][current.id];
        if (typeof reviews ==='undefined')
        {
          reviews = [];
          reviewsReady=0;
        }else
          reviewsReady=1;

       
          let items = reviews;
          let desc = lng('Reviews de') + ' '  + current.name + ' ' + lng('seguem') + ': ';

          for(let i=0;(i < items.length && i<1); i++)
          {
            let item = MyAppPool.session.reviewData[items[i]];
            //console.log("ITEM:::",item);
            desc += item.authorname + ' - ' + lng('Nota') +': '+ item.finalgrade + ' - '+ item.displayhtml.substring(0,255);
          }
          
          meta =  {
            title:current.name +' - ' + lng('Reviews') + ' | '+getSiteShortName()+'',
            desc:desc,
            keywords:desc
          };
        
      }
      let mainWidth="60%";
      let mainMarginLeft="15px";
      if (isMobile.any)
      {
        mainWidth="95%";
        mainMarginLeft="2.5%";
      }

        return (<div className="companyreview_view contentArea uk-flex uk-flex-row">
        
        <Helmet>
            <title>{meta.title}</title>
                <meta name="title" content={meta.title} />
                <meta name="description" content={meta.desc} />
                <meta name="keywords" content={meta.keywords}/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta.title} />
                <meta property="og:description" content={meta.desc} />
                <meta name="twitter:title" content={meta.title}/>
                <meta name="twitter:description" content={meta.desc}/>
          </Helmet>
        
        {!isMobile.any?
        <div style={{width:"21%",marginLeft:"15px"}}>
          {//left collum
          }
        </div>
        :<></>}

        <div style={{width:mainWidth,marginLeft:mainMarginLeft}}>
       
        {
            current.ready?
        <div className="uk-card uk-card-default rowCardContainer">
        <ReviewWriter 
        contentName={current.name} 
        resourceType={resourceType} 
        resourceId={current.id}
        cats={[
            {name:lng("Arte"),icons:["fas fa-paint-brush","fas fa-palette"]},
            {name:lng('Música/Sons'),icons:["fas fa-music"]},
            {name:lng('Animação'),icons:['fas fa-skiing','fas fa-running','fas fa-skating']},
            {name:lng('Adaptação/Roteiro'),icons:['fas fa-pen']}
        ]}/>
        </div>
        :<SkeletonBlock  style={{'width':'100%',height:'100px', 'marginTop':'8px'}}/>
      }
        <div className="uk-flex uk-flex-row filterBox">
          <span className='filterTitle'>{lng("Filtrar Por Avaliação")} <i class="fas fa-filter"></i></span>
          <button aria-label={lng('Ruim')} style={{backgroundImage:gradients[0]}}  className={'clickable filterBtns '+(filterIndex===0?'fbtnactive':'fbtinactive')} onClick={(e) => this.filterBtn(0,0,5.1)}> {lng('Ruim')}
          <br/><i className="far fa-star"></i><i className="far fa-star"></i><i className="far fa-star"></i><i className="far fa-star"></i><i className="far fa-star"></i>
          </button>
          <button aria-label={lng('Mediano')} style={{backgroundImage:gradients[1]}} className={'clickable filterBtns '+(filterIndex===1?'fbtnactive':'fbtinactive')} onClick={(e) => this.filterBtn(1,5.1,7)}> {lng('Mediano')}
          <br/><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i><i className="far fa-star"></i><i className="far fa-star"></i></button>
          <button aria-label={lng('Boa')} style={{backgroundImage:gradients[2]}} className={'clickable filterBtns '+(filterIndex===2?'fbtnactive':'fbtinactive')} onClick={(e) => this.filterBtn(2,7,9)}> {lng('Boa')}
          <br/><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i><i className="far fa-star"></i></button>
          <button aria-label={lng('Excelente')} style={{backgroundImage:gradients[3]}} className={'clickable filterBtns '+(filterIndex===3?'fbtnactive':'fbtinactive')} onClick={(e) => this.filterBtn(3,9,10.1)}> {lng('Excelente')}
          <br/><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></button>
        </div>
         {
            current.ready?
            (reviews.length===0?
            <NoRecordsFoundCard text={lng('Nenhuma análise encontrada, escreva uma!')} /> :<></>)
            :<></>
          }
          {reviewsReady? 
          reviews.map((element, index) => {
            let item = MyAppPool.session.reviewData[element];
            return (
              this.passFilter(item)?
              <div id={getAnchorName(item.reviewid + item.authorname)} key={index} className="rowCardContainer">
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock  style={{'width':'100%',height:'225px', 'marginTop':'8px'}}/>}
              >
                <div className="uk-card uk-card-default reviewItemEnclosure">
                <ReviewItem 
                    currentUserId={MyAppPool.currentUser.userid}
                    currentUserRoles={MyAppPool.currentUser.roles}
                    reviewItem={item}
                    cats={[
                        {name:lng("Arte"),icons:["fas fa-paint-brush","fas fa-palette"]},
                        {name:lng('Música/Sons'),icons:["fas fa-music"]},
                        {name:lng('Animação'),icons:['fas fa-skiing','fas fa-running','fas fa-skating']},
                        {name:lng('Adaptação/Roteiro'),icons:['fas fa-pen']}
                    ]}/>
                </div>
              </LazyLoadComponent>
            </div> : <></>
            
            )})
          
          :
          <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
          </div>}
        </div>

        {!isMobile.any?
        <div  style={{width:"15%",marginLeft:"15px"}}>
          <ScrollToTopButtom />
        </div>
        :<></>}

      </div>);


    }
}

export default CompanyReviewView;