import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Redirect } from 'react-router-dom';
import MyAppPool from '../AppPool';
import '../css/flags.css';
import { lng } from '../ulanguages/language';
import { getAnchorName, getImgAddress } from './../util/text';
import { animeFastResourceType, companyFastResourceType, isSet, magazineFastResourceType, mangaFastResourceType, mobToObj } from './../util/typeu';
import CSSTransition2 from './CSSTransition2';
import CapaWorkLikeButton from './CapaWorkLikeButton';
import ChangeableImg from './ChangeableImg';
import Grader from './Grader';
import LinkV2 from './LinkV2';
import ListWidget from './ListWidget';
import "./RelatedWorks.css";
import StarsRating from './StarsRating';

function getMouseOrTouchX(e)
{
  let x = 0;
  if (isSet(e.touches) && isSet(e.touches[0]))
    x = e.touches[0].clientX;
  else
    x = e.clientX;
  return x;
}
function getMovenessRatio(e)
{
  let r = 0;
  if (isSet(e.touches) && isSet(e.touches[0]))
    r = 52;
  else
    r = 80;
  return r;
}

@observer
class RelatedWorks extends Component {

  constructor(props) {
    super(props);
    this.state = {hover:{}, itemIndex:-1,dragging:false,dragStartTime:0,dragX:0, startDragX:0,itemUrl:'',goToRedirect:false};
    this.moveRight = this.moveRight.bind(this);
    this.moveLeft = this.moveLeft.bind(this);
    this.startDrag = this.startDrag.bind(this);
    this.duringDrag = this.duringDrag.bind(this);
    this.endDrag = this.endDrag.bind(this);
    this.play = this.play.bind(this);
  }

  play(playItem)
  {
    if (isSet(playItem))
    {
      MyAppPool.cMusic.addToPlayQueue(playItem);
      MyAppPool.cMusic.playMusicObject(playItem);
    }
  }

  startDrag(e,element
    ) {
    let x = getMouseOrTouchX(e);
    this.setState({dragging:true,dragStartTime:Date.now(),dragX: x,startDragX:x,itemUrl:element.url});
    if (!isSet(e.touches))
    {
      e.target.onpointermove = this.duringDrag;
      e.target.setPointerCapture(e.pointerId);
    }
  }
  
  duringDrag(e)
  {
    let {dragging,dragX,itemIndex} = this.state;
    if (!dragging)
      return; 
    let x = getMouseOrTouchX(e);

    let moveDiff = dragX - x;
    moveDiff = moveDiff/getMovenessRatio(e);
    this.setState({dragX:x, itemIndex:itemIndex+moveDiff});
  }
  
  endDrag(e) {
    let {dragStartTime,startDragX} = this.state;
    let timeDiff = Date.now()- dragStartTime;
    let x = getMouseOrTouchX(e);

    let movementDiff = startDragX-x;
    if (Math.abs(movementDiff) < 3)
    {
      //MyAppPool.cLocation.updateUrl(this.state.itemUrl);
      //this.setState({goToRedirect:true});
    }
    if (Math.abs(movementDiff) > 10)
      setTimeout(()=>this.setState({dragging:false}),100);
    else
      this.setState({dragging:false});
    if (!isSet(e.touches))
      e.target.releasePointerCapture(e.pointerId);
  }

  moveRight()
  {
    let {showItemNumber} = this.props;
    let {itemIndex} = this.state;
    itemIndex = Math.round(itemIndex);
    let nextState = itemIndex +showItemNumber;
    let remaining =  nextState - itemIndex;
    let iterations = 40;
    let step = remaining/iterations;
    for(let i=0;i<=iterations;i++)
      setTimeout(()=>this.setState({itemIndex:itemIndex+(step*i)}),i*5)
  }
  moveLeft()
  {
    let {showItemNumber} = this.props;
    let {itemIndex} = this.state;
    itemIndex = Math.round(itemIndex);
    let nextState = itemIndex -showItemNumber;
    let remaining =  nextState - itemIndex;
    let iterations = 40;
    let step = remaining/iterations;
    for(let i=0;i<=iterations;i++)
      setTimeout(()=>this.setState({itemIndex:itemIndex+(step*i)}),i*5)
  }

  componentDidMount()
  {

  }

  getCurrentItens(itens, simpleIndex, shownItensNumber)
  {
    let index =simpleIndex;
    index = index % itens.length;
    if (index<0)
      index = itens.length + index;
    
    let maxItemIndex = itens.length;
    let endOfRange = index + shownItensNumber;
    let currentItens = [];

    
    //console.log(index,shownItensNumber,endOfRange,maxItemIndex);
    if (endOfRange>maxItemIndex)
    {
      let newRange = endOfRange -maxItemIndex;
      let endItens = itens.slice(index, maxItemIndex);
      let newItens = itens.slice(0,newRange);
      currentItens = [...currentItens,...endItens,...newItens];
      if (currentItens.length>itens.length)
        currentItens = currentItens.slice(0,itens.length);
      return currentItens;
    }
    return itens.slice(index, index+shownItensNumber);
  }

  render(){
    //console.log(this.props);
    let {gradeOptions,showItemNumber,title,height,items,ready,notFoundText,notFoundButtonText,hideIfEmpty,imgSize} = this.props;
    let {itemIndex,dragging,goToRedirect,itemUrl} = this.state;

    let myheight = height;
    if (!ready)
      return <div style={{height:myheight}} className="uk-card uk-card-default comp_relatedworks skeleton"></div>
    if (items.length<=0)
      if (hideIfEmpty)
        return <></>
      else
      return (<div className="uk-card uk-card-default comp_relatedworks">
        {title}
        <div className="emptydiv">
          <p> {notFoundText} </p>
          <button aria-label={notFoundText} class="uk-button uk-button-default"> { notFoundButtonText }</button>
        </div>
      </div>);

    let reducedItens = this.getCurrentItens(items, itemIndex, showItemNumber+2);
    let localItemNumber = showItemNumber;
    if (reducedItens.length === showItemNumber+1)
      localItemNumber-=1;
    
    let itemWidth = 100/localItemNumber;
    let titleHeight=24;
    if (title==='0')
      titleHeight=0;
    //console.log('DRAG::',dragging);

    let textSizePercent = 1 *(myheight/352);//352 is a big card height
    let lineSizePercent = 1.4 *(myheight/352);

    let movement = itemIndex -Math.floor(itemIndex);
    let interactable = reducedItens.length>showItemNumber;
    let showArrows = interactable;
    let startingIndex = interactable?1:0;

    

    //everything ok
    return (<div ref={this.myRef} className="uk-card uk-card-default comp_relatedworks">
      {title}
      {goToRedirect? <Redirect to={itemUrl} />: <></>}
      {reducedItens.length>0? 
      <div style={{height:myheight-titleHeight}} className="carouselfather">
          {reducedItens.map((element, index) => {

            let showPlayButtons = false;
            let {playItem,grade,streamItem,redditDiscussionItem}=element;
            if (isSet(playItem))
            {
              if (playItem.ytid!=='')
                  showPlayButtons=true;
            }
            let addBtnClasses = '';
            let mySelectedGrade = -1;
            let mySelectedGradeToPrint='';
            let useResourceId = element.resourceId;
            let useResourceType = element.resourceType;
            //console.log("Related::",mobToObj(element));
            let gradeable=false;
            if (useResourceType===animeFastResourceType || useResourceType===mangaFastResourceType
                || useResourceType===companyFastResourceType || useResourceType===magazineFastResourceType)
                gradeable=true;

          if (isSet(MyAppPool.session.user.grades[element.resourceType]))
          {
            addBtnClasses = 'far fa-plus-square';
            mySelectedGrade = MyAppPool.session.user.grades[element.resourceType][element.resourceId];
            mySelectedGradeToPrint='';
            if (isSet(mySelectedGrade) && mySelectedGrade!==-1)
            {
              mySelectedGradeToPrint = '('+mySelectedGrade+')';
              addBtnClasses = 'fas fa-check-square comp_searchcard_graded';
            }
          }else
          {
            useResourceId=-1;
            useResourceType=-1;
          }

            return (
            <div key={index} 
            style={{'width':itemWidth+'%','left':(itemWidth*(index-startingIndex) - movement*itemWidth) +'%'}}
            className="carouselItem"
            onMouseEnter={()=>{let n = this.state.hover; n[index]=true; this.setState({hover:n}) } } onMouseLeave={()=>{let n = this.state.hover; n[index]=false; this.setState({hover:n}) } }
            draggable={false}
            onPointerDown={(e)=> {if (interactable) this.startDrag(e,element)}}
            onPointerUp={(e)=> {if (interactable) this.endDrag(e)}}
            onTouchStart={(e)=> {if (interactable) this.startDrag(e,element)}}
            onTouchMove={(e)=> {if (interactable) this.duringDrag(e)}}
            onTouchEnd={(e)=> {if (interactable) this.endDrag(e)}}
            >
              <LinkV2 aria-label={title} onClick={(e)=> {
                //await delay(5);
                if (dragging)
                {
                 /// console.log("IM DRAGGGGIIIIN...")
                  e.preventDefault();
                  e.stopPropagation();
                }
              }} 
              to={element.url} draggable={false} >
              <ChangeableImg
              alt={lng("Capa de")+" "+ title}
              id={getAnchorName(title)+'i'+(index+itemIndex)}
              draggable={false} 
              style={{'height':myheight-titleHeight}}
              effect="blur" className="relatedImg" src={getImgAddress(element.imgurl,'imgs/defaults/manga.webp',imgSize)}/>
              </LinkV2>
              
              <CSSTransition2
              in={this.state.hover[index]?1:0}
              timeout={50}
              classNames="related-node "
              unmountOnExit>
                <div className="uk-overlay uk-overlay-primary uk-position-top relatedNameBox" onClick={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();}}>
                  <div className="uk-flex uk-flex-row uk-align-right ">
                  {isSet(grade)?<StarsRating className="iconButton" data-uk-tooltip={grade.actualVal} actualVal={grade.actualVal} vertical={false}/>: <></>}
                  {(isSet(playItem) && showPlayButtons)?
                    <div className="fas fa-play-circle  clickable iconButton ytplayicon" onClick={()=>this.play(playItem)} data-uk-tooltip={lng('Tocar')} ></div>
                    :<></>
                  }
                  <CapaWorkLikeButton 
                  resourceType={useResourceType}
                  resourceId={useResourceId}
                  />
                  <div className="clickable iconButton" onClick={(e)=>{
                    MyAppPool.cPopMenu.set(
                        <div>
                          <button aria-label={lng('Fechar')} className="uk-button uk-button-default searchCloseBtn" onClick={()=>MyAppPool.cPopMenu.deactivate()}>{lng('Fechar')}</button>
                          {gradeable?
                          <Grader resourceId={useResourceId} resourceType={useResourceType} gradeOptions={gradeOptions}>
                            <button aria-label={lng('Avaliar')} className="uk-button uk-button-default searchCloseBtn">{lng('Avaliar')} <i className={addBtnClasses}></i> {mySelectedGradeToPrint}</button>
                          </Grader>
                          :<></>}
                          {(isSet(redditDiscussionItem))?
                            <a className="uk-button uk-button-default searchCloseBtn" href={redditDiscussionItem.searchTerm} target="_blank" data-uk-tooltip={lng('Reddit Discussion')} >{lng('Reddit Discussion')}</a>
                            :<></>
                          }
                          {isSet(playItem)?
                          <div myid={playItem.id}>
                          <ul className="pop_ytmenulist">
                            <li className="pop_ytmenuitem" onClick={this.addToCurrentPlayList}>
                              <span className={"fas pop_ytmenuitemicon fa-music"} ></span><div className="ytmenuoptiontext">{lng("Adicionar à fila de reprodução")}</div>
                            </li>
                        </ul>
                        </div>:
                        <></>}
                          <ListWidget resourceType={useResourceType} resourceId={useResourceId} />
                        </div>
                      );
                    }}>
                    <i className="fas fa-ellipsis-v multiBackgroundText"></i>
                  </div>
                  </div>
                </div>
              </CSSTransition2>             
             
              <div className="uk-overlay uk-overlay-primary uk-position-bottom relatedNameBox" style={{lineHeight:lineSizePercent + 'em'}}>
                  
              
                  {(isSet(redditDiscussionItem))?
                    <a data-uk-icon={"icon:  reddit"} className=" clickable iconButton relnameicon_discussion" href={redditDiscussionItem.searchTerm} target="_blank" data-uk-tooltip={lng('Reddit Discussion')} ></a>
                    :<></>
                  }
                  {(isSet(streamItem))?
                    <a className="fas fa-play-circle  clickable iconButton ytplayicon relnameicon_stream" href={streamItem.url} target="_blank" data-uk-tooltip={lng('Tocar')} >{streamItem.episodenum}</a>
                    :<></>
                  }
                  {element.directUrl?
                  <LinkV2 to={element.directUrl} aria-label={lng("Ir Diretamente")+":"+element.directUrl}>
                  <i className={'fas fa-external-link-alt relnameicon_ext'} ></i>
                  <i className={'fas fa-external-link-alt relnameicon_ext2'} data-uk-tooltip={lng("Ir Diretamente")}></i>
                  </LinkV2>:<></>}
                  
                  {element.icon? <i className={element.icon+' relnameicon'} ></i> : <></>}
                  {element.icon? <i className={element.icon+' relnameicon2'} data-uk-tooltip={element.iconText}></i> : <></>}
                  <span style={{lineHeight:lineSizePercent + 'em',fontSize: textSizePercent + 'em'}} className='borderedText'>{element.title}</span>
              </div> 
            </div>
          )
          })}
          {showArrows?
          <button aria-label={lng('Mover para Esquerda')} onClick={this.moveLeft} className="link-button moveBtn moveLeftBtn"><i className="fas fa-chevron-left"></i></button>:<></>
          }
          {showArrows?
          <button aria-label={lng('Mover para Direita')} onClick={this.moveRight} className="link-button moveBtn moveRightBtn"><i className="fas fa-chevron-right"></i></button>:<></>
          }
            </div>
      : <></>}
    </div>);
}
}

RelatedWorks.propTypes = {
  ready: PropTypes.number.isRequired,
  hideIfEmpty: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  notFoundText:PropTypes.string.isRequired,
  notFoundButtonText:PropTypes.string.isRequired,
  icon:PropTypes.string.isRequired,
  showItemNumber:PropTypes.number.isRequired,
  imgSize:PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      directUrl: PropTypes.string,
      imgurl:PropTypes.string.isRequired,
      icon:PropTypes.string.isRequired,
      iconText:PropTypes.string.isRequired, 
      resourceType:PropTypes.number.isRequired,
      resourceId:PropTypes.number.isRequired,
      isHentai: PropTypes.bool,
      playItem: PropTypes.shape({
        title: PropTypes.string.isRequired,
        band: PropTypes.string.isRequired,
        where: PropTypes.string.isRequired,
        ytid: PropTypes.string.isRequired,
        id:PropTypes.number.isRequired}),
      streamItem: PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          episodenum: PropTypes.string.isRequired}),
      redditDiscussionItem:PropTypes.shape({
        searchTerm: PropTypes.string.isRequired
      }),
      grade: PropTypes.shape({
        minVal:PropTypes.number,
        maxVal:PropTypes.number,
        actualVal:PropTypes.number.isRequired
      }),
    }))
};
RelatedWorks.defaultProps = {
  items: [],
  hideIfEmpty:0,
  title:'',
  height: 272,
  imgSize:'',
  ready:0,
  ico: '',
  showItemNumber:4,
  notFoundText:lng('Nenhum encontrado'),
  notFoundButtonText:lng('Adicionar'),
  gradeOptions:{
    minVal: 5,
    maxVal: 10,
    increment: 0.5,
    rgbStart : [153,0,0],
    rgbMiddle : [255, 255, 153],
    rgbEnd: [0, 255, 85]
  }
}

export default RelatedWorks;


//onMouseEnter={()=>{let n = this.state.hover; n[index]=true; this.setState({hover:n}) } } onMouseLeave={()=>{let n = this.state.hover; n[index]=false; this.setState({hover:n}) } }