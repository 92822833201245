import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { onlyLettersAndNumbers } from '../util/text';
import { scrollToAnchor } from '../util/windowUtil';
import "./ExternalLinksBox.css";
import { isSet } from '../util/typeu';
import { getFlag } from '../util/icon';

function getLinkIcons(link,lang)
{
    let out = [];
    let langLower = '';
    if (isSet(lang))
    {
        let langLower = lang.toLowerCase();
    }
            
    if (link.indexOf("wikipedia")!==-1)
        out.push(<i className="fab fa-wikipedia-w" style={{color:"#3399ff"}}></i>);
    if (link.indexOf("prime.video")!==-1)
        out.push(<i className="fab fa-amazon" style={{color:"#F47521"}}></i>);
    if (link.indexOf("amazon.")!==-1)
        out.push(<i className="fab fa-amazon" style={{color:"#F47521"}}></i>);
    if (link.indexOf("hulu.")!==-1)
        out.push(...[<i data-uk-tooltip="English" alt="English" className={getFlag("us")+" flag"}></i>,<i data-uk-tooltip="Japanese" alt="Japanese" className={getFlag("jp")+" flag"}></i>]);
    if (link.indexOf("animenewsnetwork")!==-1)
        out.push(...[<i data-uk-tooltip="English" alt="English" className={getFlag("us")+" flag"}></i>]);
    if (link.indexOf("anidb")!==-1)
        out.push(...[<i data-uk-tooltip="English" alt="English" className={getFlag("us")+" flag"}></i>]);
    if (link.indexOf("aniplustv")!==-1)
        out.push(...[<i data-uk-tooltip="Korean" alt="Korean" className={getFlag("kr")+" flag"}></i>]);
    if (link.indexOf("danet.vn")!==-1)
        out.push(...[<i data-uk-tooltip="Vietnamese" alt="Vietnamese" className={getFlag("vn")+" flag"}></i>]);
    if (link.indexOf("flixerapp")!==-1)
        out.push(...[<i data-uk-tooltip="Thailandese" alt="Thailandese" className={getFlag("th")+" flag"}></i>]);
    if (link.indexOf("iq.com")!==-1)
        out.push(...[<i data-uk-tooltip="Global" alt="Global" className="fas fa-globe-americas" style={{color:"cyan"}}></i>]);
    if (link.indexOf("bilibili.tv")!==-1)
        out.push(...[<i data-uk-tooltip="Global" alt="Global" className="fas fa-globe-americas" style={{color:"cyan"}}></i>]);
    if (link.indexOf("bilibili.com")!==-1)
        out.push(...[<i data-uk-tooltip="Japanese" alt="Japanese" className={getFlag("jp")+" flag"}></i>]);
    if (link.indexOf("coolmic")!==-1)
        out.push(...[<i data-uk-tooltip="Global" alt="Global" className="fas fa-globe-americas" style={{color:"cyan"}}></i>]);
    if (link.indexOf("akibapass")!==-1)
        out.push(...[<i data-uk-tooltip="Deutsch" alt="Deutsch" className={getFlag("de")+" flag"}></i>]);
    if (link.indexOf("netflix")!==-1)
        out.push(...[<div data-uk-tooltip="Netflix" alt="Netflix" className="svgIcon" style={{mask:"url(/brands/netflix.svg) no-repeat center",background:"#E50914"}} ></div>,<i data-uk-tooltip="Global" alt="Global" className="fas fa-globe-americas" style={{color:"cyan"}}></i>]);
    if (link.indexOf("crunchyroll")!==-1)
        out.push(...[<div className="svgIcon" style={{mask:"url(/brands/crunchyroll.svg) no-repeat center",background:"#F47521"}} ></div>,<i data-uk-tooltip="Global" alt="Global" className="fas fa-globe-americas" style={{color:"cyan"}}></i>]);
    if (link.indexOf("funimation")!==-1)
        out.push(...[<div className="svgIcon" style={{mask:"url(/brands/crunchyroll.svg) no-repeat center",background:"#F47521"}} ></div>,<i data-uk-tooltip="Global" alt="Global" className="fas fa-globe-americas" style={{color:"cyan"}}></i>]);
    if (link.indexOf("disneyplus")!==-1)
        out.push(...[<img style={{width:"40px"}} src="/brands/disneyplus.svg"></img>,<i data-uk-tooltip="Global" alt="Global" className="fas fa-globe-americas" style={{color:"cyan"}}></i>]);
    if (link.indexOf("twitter")!==-1)
        out.push(...[<i data-uk-tooltip="Twitter(X)" alt="Twitter(X)" className="fab fa-twitter"> X</i>]);
    if (link.indexOf("catchplay")!==-1)
        out.push(<i data-uk-tooltip="Taiwanese" alt="Taiwanese" className={getFlag("tw")+" flag"}></i>);
    if (link.indexOf("mewatch")!==-1)
        out.push(<i data-uk-tooltip="Singapore" alt="Singapore" className={getFlag("sg")+" flag"}></i>);
    
    if (langLower.indexOf("korea")!==-1 || langLower=="kr")
        out.push( <i data-uk-tooltip="Korean" alt="Korean" className={getFlag("kr")+" flag"}></i>)
    if (link.indexOf("es.")!==-1 || langLower.indexOf("spanish")!==-1 || langLower=="es")
        out.push( <i data-uk-tooltip="Spanish" alt="Spanish" className={getFlag("es")+" flag"}></i>)
    if (link.indexOf("pt.")!==-1 || langLower.indexOf("portuguese")!==-1 || langLower=="pt")
        out.push(<i data-uk-tooltip="Portuguese" alt="Portuguese" className={getFlag("br")+" flag"}></i>);
    if (link.indexOf("de.")!==-1 || langLower.indexOf("german")!==-1 || langLower=="de")
        out.push(<i data-uk-tooltip="Deutsch" alt="Deutsch" className={getFlag("de")+" flag"}></i>);
    if (link.indexOf(".en")!==-1 || link.indexOf("en.")!==-1 || langLower.indexOf("english")!==-1 || langLower=="us")
        out.push(<i data-uk-tooltip="English" alt="English" className={getFlag("us")+" flag"}></i>);
    if (link.indexOf(".uk/")!==-1 || langLower=="uk")
        out.push(<i data-uk-tooltip="English" alt="English" className={getFlag("uk")+" flag"}></i>);
    if (link.indexOf(".ja")!==-1 || link.indexOf("ja.")!==-1 || link.indexOf(".jp")!==-1 || link.indexOf(".jp")!==-1 || langLower.indexOf("japan")!==-1 || langLower=="ja"|| langLower=="jp") 
        out.push(<i data-uk-tooltip="Japanese" alt="Japanese" className={getFlag("jp")+" flag"}></i>);
    if (link.indexOf(".tw")!==-1)
        out.push(<i data-uk-tooltip="Taiwanese" alt="Taiwanese" className={getFlag("tw")+" flag"}></i>);
    if (link.indexOf(".cn")!==-1)
        out.push(<i data-uk-tooltip="Chinese" alt="Chinese" className={getFlag("cn")+" flag"}></i>);
    
    
    return out;
}
function getLinkText(text){
    if (!isSet(text))
        return '';
    let lowText = text.toLowerCase();
    if (lowText.indexOf("official")!==-1)
        return lng("Oficial Site");
    return text;
}
@observer
class ExternalLinksBox extends Component {

  componentDidMount()
  {
  }

  render()
  {
    let {items,title} = this.props;
    let oficial = [];
    let info = [];
    let stream = [];
    let readOnline  = [];
    if (!isSet(items) || items.length==0)
        return <></>;

    for(let i=0;i<items.length;i++)
    {
        let item = items[i];
        let catLower = item.category.toLowerCase();
        if (catLower.indexOf("available at")!==-1 || catLower.indexOf("social")!==-1)
            oficial.push(item);
        if (catLower.indexOf("resource")!==-1 || catLower.indexOf("info")!==-1)
            info.push(item);
        if (catLower.indexOf("stream")!==-1)
            stream.push(item);
        if (catLower.indexOf("read")!==-1)
            readOnline.push(item);
    }
    return (
        <div className="comp_extlinksBox"> 
            <div className="uk-card uk-card-default mainCard">
            <span>{title} <br/></span>
              <div className="anchorBoxList">
            {oficial.length>0?<>
              <span>{lng("Oficiais")} <br/></span>
                  {oficial.map((item,index)=>{ 
                      return <span>
                        <a className="link-button" target='_blank' href={item.linkurl} >
                            {getLinkText(item.linktext)} {getLinkIcons(item.linkurl,item.lang)}
                        </a> 
                            <br />
                        </span>
                  })}
                  </> :<></>}
                  {info.length>0?<>
                <br/><span>{lng("Informações")} <br/></span>
                 {info.map((item,index)=>{ 
                      return <span>
                        <a className="link-button" target='_blank' href={item.linkurl} >
                            {getLinkText(item.linktext)} {getLinkIcons(item.linkurl,item.lang)}
                        </a> 
                            <br />
                        </span>
                  })}
                  </> :<></>}
                  {stream.length>0?<>
                <br/><span>{lng("Streaming")} <br/></span>
                  {stream.map((item,index)=>{ 
                      return <span>
                        <a className="link-button" target='_blank' href={item.linkurl} >
                            {getLinkText(item.linktext)} {getLinkIcons(item.linkurl,item.lang)}
                        </a> 
                            <br />
                        </span>
                  })}
                  </> :<></>}
                  {readOnline.length>0?<>
                <br/><span>{lng("Read Online")} <br/></span>
                    {readOnline.map((item,index)=>{ 
                      return <span>
                        <a className="link-button" target='_blank' href={item.linkurl} >
                            {getLinkText(item.linktext)} {getLinkIcons(item.linkurl,item.lang)}
                        </a> 
                            <br />
                        </span>
                  })}
                  </> :<></>}
               </div>
            </div>
        </div>);
  }
}

ExternalLinksBox.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
          linktext: PropTypes.string.isRequired,
          category: PropTypes.string.isRequired,
          linkurl: PropTypes.string.isRequired,
          lang: PropTypes.string
        })
      ),
      title:PropTypes.string
};
ExternalLinksBox.defaultProps = {
    items:[],
    title:lng("Listas")
};

export default ExternalLinksBox;
