import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { lng } from '../ulanguages/language';
import { isMobile } from './../util/mobile';
import "./CharacterRowCard.css";
import RelatedWorks from './RelatedWorks';
import { isSet } from './../util/typeu';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class CharacterRowCard extends Component {
  componentDidMount()
  {

  }

  render(){
    let {description,voiceActorRelated,charid,name,protag,img,url,ready} = this.props;
    if (!ready)
    return <div className="uk-card uk-card-default comp_character_row skeleton">
      <div style={{height:'160px','width':'100%'}}></div>
    </div>;
    if (isSet(description))
    {
      description = description.substring(0,200);
      description += '...';
    }
    if (!isMobile.any)
    return (
          <div className="uk-card uk-card-default comp_character_row">
            <div className="uk-flex uk-flex-row"> 
              <div style={{width:'20%'}}>
              <Link aria-label={lng('Personagem')+" "+name} to={url}> <LazyLoadImage src={img} style={{height:'225px'}} alt={lng('Personagem')+" "+name+" "+ lng("Capa")}></LazyLoadImage> </Link>
              </div>
              <div style={{width:'40%'}}>
                <h2>
                  <Link to={url}> {name} </Link>
                </h2>
                {protag? 
                <span>{lng('Protagonista')}</span>
                :
                <span></span>
                }
                <p> {description} </p>
              </div>
              <div style={{width:'40%'}}>
                <RelatedWorks
                imgSize='x50'
                title={lng("Atores e Atrizes")} 
                notFoundText={lng('Nenhuma ator/atriz encotrado, conhece um ? Adicione!')}
                notFoundButtonText={lng('Adicionar ator / atriz')}
                items={voiceActorRelated} 
                hideIfEmpty={1}
                ready={ready}  height={222}/>
                
              </div>
            </div>
        </div>);

if (isMobile.any)
return (
      <div className="uk-card uk-card-default comp_character_row">
        <h2>
              <Link to={url}> {name} </Link>
            </h2>
            
        <div className="uk-flex uk-flex-row"> 
          <div style={{width:'30%'}}>
          <Link aria-label={lng('Personagem')+" "+name} to={url}> <img src={img} style={{height:'225px'}} alt={lng('Personagem')+" "+name+" "+ lng("Capa")}></img> </Link>
          </div>
          <div style={{width:'70%'}}>
            <RelatedWorks
            imgSize='x50'
            title={lng("Atores e Atrizes")} 
            notFoundText={lng('Nenhuma ator/atriz encotrado, conhece um ? Adicione!')}
            notFoundButtonText={lng('Adicionar ator / atriz')}
            items={voiceActorRelated} 
            hideIfEmpty={1}
            ready={ready}  height={222}/>
          </div>
        </div>
        <div>
          {protag? 
            <span>{lng('Protagonista')}</span>
            :
            <span></span>
            }
            <p> {description} </p>
          </div>
    </div>);
}
}

CharacterRowCard.propTypes = {
  ready: PropTypes.number.isRequired,
  charid: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  protag: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description:PropTypes.string.isRequired,
  voiceActorRelated: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      imgurl:PropTypes.string.isRequired
    })
  ) 
  
};
CharacterRowCard.defaultProps = {
  voiceActorRelated: [],
  charid: 0,
  name: '',
  protag:0,
  img:'',
  url:'',
  ready:0,
  description:''
}

export default CharacterRowCard;
