
import MyAppPool from '../AppPool';

export default class Modal
{
    set(title,content,okFunction,className='',cleanupFunction=()=>console.log('closing modal'))
    {
        MyAppPool.jsObjectStore.modal = {
            title,
            content,
            okFunction,
            active:1,
            className,
            cleanupFunction
        }
    }
    setOkFunction(funct)
    {
        //MyAppPool.jsObjectStore.modal.okFunction = funct;
        MyAppPool.jsObjectStore.modal.okFunction = () => {
            funct();
            MyAppPool.jsObjectStore.modal.okFunction = ()=>{};
        };
    }
    setCleanupFunction(funct)
    {
        MyAppPool.jsObjectStore.modal.cleanupFunction = funct;
    }
    rerender()
    {
        MyAppPool.jsObjectStore.modal.render+=1;
    };
    deactivate()
    {
        MyAppPool.jsObjectStore.modal.active=0;
        MyAppPool.jsObjectStore.modal.okFunction = ()=>{};
        MyAppPool.jsObjectStore.modal.cleanupFunction = ()=>{};
    }
}