import { getAnimeTopsDefaultStarter, getDefaultStarter, getMangaTopsDefaultStarter, isSet } from "../util/typeu";
import { isMobile } from './../util/mobile';
import { lng } from '../ulanguages/language';
import { mobToObj } from './../util/typeu';
import MyAppPool from "../AppPool";
import { isServer } from "../util/windowUtil";

export const ENTRY_STATES = {
	AUTH:0,
	NOAUTH:1,
	WAIT:2
}

var defaultUserInfo={    
        userid:0,
        roles:[],
        name:lng('Visitante'),
        img:'',
        token:'123',
        refreshToken:'123',
        tokenExpiry:0,
        mailHash:'00000000000000000000000000000000',
        refreshTime:0
};

var defaultSession = {
    media:getDefaultStarter(),
    httpStatus:200,
    jsonld:{},
    alerts:{data:[],time:new Date()},
    contentBanner:{
        mode:['bg'],
        lastMode:['bg'],
        lastImage:['https://files.nuecloud.cyou/buckets/mab/imgs/defaults/bg1.webp'],
        image:['https://files.nuecloud.cyou/buckets/mab/imgs/defaults/bg1.webp'],
        itens:[]
    },
    campaign:{
        goals:[],
        supporters:[],
        users:0,
        money:0,
        currency:"$",
        loaded:false,
        url:""
    },
    user:{    
        votes:getDefaultStarter(),
        grades:getDefaultStarter(),
        lists:getDefaultStarter(),
        images:{
            avatar:[],
            banner:[],
            thread:[]
        },
    },
    isMobile: isMobile.anyByAgent(),
    listWidget:{ name:'',type:-1},
    theme:'darkTheme',
    gMapsApiKey:'AIzaSyCohCcubmJF00HUopk0i-yTAPcFEVAGYqE',
    scrollPosition:undefined,
    mousePosition:{x:0,y:0},
    loginBoxActive:false,
    disableLogout:false,
    country:"u",
    affiliate:{
        amazon:{}    
    },
    terms:{
        ofUse:{
            en:'',
            pt:'',
            de:'',
            es:'',
        },
        ofPrivacy:{
            en:'',
            pt:'',
            de:'',
            es:'',
        },
        ofCookies:{
            en:'',
            pt:'',
            de:'',
            es:'',
        }
    },
    tops:{
        anime:{
            filters:getAnimeTopsDefaultStarter(),
            airing:getAnimeTopsDefaultStarter(),
            upcoming:getAnimeTopsDefaultStarter(),
            popular:{
                allTime:getAnimeTopsDefaultStarter(),
                week:getAnimeTopsDefaultStarter(),
                month:getAnimeTopsDefaultStarter(),
                today:getAnimeTopsDefaultStarter(),
            },
            grade:getAnimeTopsDefaultStarter(),
            gradeSeason:getAnimeTopsDefaultStarter(),
            gradeYear:getAnimeTopsDefaultStarter()
        },
        manga:{
            filters:getMangaTopsDefaultStarter(),
            airing:getMangaTopsDefaultStarter(),
            upcoming:getMangaTopsDefaultStarter(),
            popular:{
                allTime:getMangaTopsDefaultStarter(),
                week:getMangaTopsDefaultStarter(),
                month:getMangaTopsDefaultStarter(),
                today:getMangaTopsDefaultStarter(),
            },
            grade:getMangaTopsDefaultStarter(),
            gradeSeason:getMangaTopsDefaultStarter(),
            gradeYear:getMangaTopsDefaultStarter()
        }
    },
    latestContent:{
        trailers:{
            loadedPages:0,
            lastLoadedItensAmount:4,
            pageSize:10,
            items:[]
        },
        musics:{
            loadedPages:0,
            lastLoadedItensAmount:4,
            pageSize:10,
            items:[]
        },
        reviews:{
            loadedPages:0,
            lastLoadedItensAmount:4,
            pageSize:4,
            items:[]
        },
        reactions:{
            loadedPages:0,
            lastLoadedItensAmount:4,
            pageSize:4,
            items:[]
        }
    },
    currentSeason:{
        anime:[],
        otherAnimation:[],
        manga:[],
        novel:[],
        otherReadable:[]
    },
    location:{
        hash: "",
        key: "",
        pathname: "",
        search: ""
    },
    locationExtra:{
        locales:[],
        alternateUrls:[],
        dePath:"/",
        enPath:"/",
        ptPath:"/",
        esPath:"/"
    },
    sessionVersion:'',
    anime:{
        tvShowsByDay:[[],[],[],[],[],[],[],[]],
        tvShowsSimulcastByDay:{"Audio Original":[[],[],[],[],[],[],[],[]]},
        currentAnime:0,
        animes:{
            '0':{ready:0}
        },
    },
    userPage:{
        currentUserPage:0,
        profiles:{
            '0':{ready:0}
        }
    },
    users:{},
    company:{
        currentCompany:0,
        companies:{
            '0':{ready:0}
        },
    },
    person:{
        currentPerson:0,
        persons:{
            '0':{ready:0}
        },
    },
    character:{
        currentCharacter:0,
        characters:{
            '0':{ready:0}
        },
    },
    publisher:{
        currentPublisher:0,
        publishers:{
            '0':{ready:0}
        },
    },
    manga:{
        currentManga:0,
        mangas:{
            '0':{ready:0}
        },
    },
    music:{
        currentMusic:0,
        musics:{
            '0':{ready:0}
        },
    },
    breadcrumbs:{
        currentPath:'',
        history:[]
    },
    search:{
        filters:getDefaultStarter(),
        content:getDefaultStarter(),
        contentInfo:getDefaultStarter(),
        contentEmpty:getDefaultStarter(),
    },
    samples:getDefaultStarter(),
    grades:getDefaultStarter(),
    review:getDefaultStarter(),
    reviewData:{},
    reactionPost:getDefaultStarter(),
    reactionData:{},
    musicPlayer:{
        shouldResume:0,
        stopPlease:0,
        currentPlayItem:{/*
            title: 'Beebop Mixtape!!',
            band: 'Bilboard',
            where: '(ep 20 25)',
            ytid: 'vWUHoAGRTHU'*/
        },
        currentPlayIndex:0,
        currentPlayQueue:[/*
            {
                title: 'Beebop Mixtape!!',
                band: 'Bilboard',
                where: '(ep 20 25)',
                ytid: 'vWUHoAGRTHU'
            },*/
        ],
        ordering:'title',
        shuffle:false,
        replay:true,
        open:true,
        currentVol:50,
        isPlaying:false
    },
    globalPageLoadingBar:{
        currentValue:0,
        expectedTimeToFinish:0,
        loadingId:0,
        success: true
    },
    isMobile:false
};

export function getDefaultSession()
{
    //@bugpatch: there is a weird infinite loop bug in server-side only in this function, could not fix it, so we just set a different behavior for the server
    if (isServer())
        return mobToObj(defaultSession);
    let retVal = mobToObj(defaultSession);
    if (isSet(MyAppPool))
    {
        if (isSet(MyAppPool.session))
        {
            let currentTheme = mobToObj(MyAppPool.session.theme);
            retVal.theme = currentTheme;
        }
    }
    return retVal;
}
export function getDefaultUser()
{
    return mobToObj(defaultUserInfo);
}