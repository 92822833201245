
export default {
    'entrar':'-',
    'recuperar':'-',
    'confirmar':'-',
    'personagem':'-',
    'pessoa':'-',
    'manga':'-',
    'anime':'-',
    'MAB por MaxBrasi':'Nani! por MaxBrasi',
    "Imagem acima do limite (> 5 MB)":'-',
    "Gif Acima do Limite (> 15 MB)":'-',
    "Erro":'-',
    'ou':'-',
    'Adicionar':'-',
    'Arte':'-',
    'Música/Sons':'-',
    'Personagens':'-',
    'História':'-',
    'Final':'-',
    'bold':'Negrito',
    'italic':'Itálico',
    'underline':'Sublinhar',
    'strikethrough':'Riscar',
    'superscript':'Sobrescrito',
    'link':'Link',
    'image':'Imagem',
    'emoji':'Emoji',
    'spoiler':'Spoiler',
    'insertOrderedList':'Inserir Lista Ordernada',
    'insertUnorderedList':'Inserir Lista Sem Ordem',
    'Compartilhar':'-',
    'Resp':'-', //Responder encurtado,
    'em':'-', //em, in, bei
    'Análises':'-', //reviews
    'Reações':'-',
    'Distribuição das Notas':'-',
    'Trilha Sonora':'-',
    'youtube':'Youtube Video',
    'removeformat': 'Remover Formatação',
    'O que esta pensando ?': '-',
    'Enviar': '-',
    'Fechar': '-',
    'Confirmar':'-',
    'Cancelar':'-',
    'Clique e Escreva uma Análise!': '-',
    'Compartilhar no Facebook': '-',
    'Compartilhar no Twitter': '-',
    'Compartilhar no Reddit': '-',
    'Compartilhar no Tumblr': '-',
    'Copiar para à Area de Colagem':'-',
    'Nota':'-',
    'usuários':'-',
    'Por':'-', //by
    'Ninguém escreveu uma reação ainda! Seja o primeiro(a)!!':'-',
    'Ninguém escreveu uma análise ainda! Seja o primeiro(a)!!':'-',
    'respondeu':'-',
    'Adicionar a lista de música':'-',
    'Adicionar ao fim da fila de reprodução':'-',
    'Tocar':'-',
    'Tocando':'-',
    'Fila de Reprodução':'-',
    'meusanimesbrasili':'-',
    'Lançamento':'Data de Lançamento',
    'Finale':'-',//data de fim de exibição
    'Segunda-Feira':'-',
    'Terça-Feira':'-',
    'Quarta-Feira':'-',
    'Quinta-Feira':'-',
    'Sexta-Feira':'-',
    'Sábado':'-',
    'Domingo':'-',
    'às':'-',
    'Exibição':'-',
    '(s)':'-', //plural
    'Episódios':'-',
    'Duração':'-',
    'minutos':'-',
    'Tipo':'-',
    'TV':'-',
    'Movie':'Filme',
    'OVA':'Animação Original de Vídeo (OVA)',
    'ONA':'Animação Original de Internet (ONA)',
    'Special':'Especial',
    'Indicação':'-', //rating age
    'Origem':'-', //source
    'Temporada':'-',//season
    'Outono':'-',
    'Inverno':'-',
    'Primavera':'-',
    'Verão':'-',
    'Outros Titulos':'-',
    'Querem':'-',
    'Assistem':'-',
    'Concluiram':'-',
    'Largaram':'-',
    'Usuários':'-',
    'Nenhuma obra relacionada encontrada, conhece uma ? Adicione!':'-',
    'Nenhuma análise encontrada, escreva uma!':'-',
    'Adicionar Obras Relacionadas':'-',
    'Nenhum encontrado':'-',
    'Obras Relacionadas':'-',
    'Nenhum personagem encontrado, conhece um ? Adicione!':'-',
    'Adicionar Personagem':'-',
    'Personagem':'-',
    'Autor':'-',
    'Autores':'-',
    'Nenhuma pessoa, conhece uma ? Adicione!':'-',
    'Adicionar pessoas':'-',
    'Nenhuma música encontrada':'-',
    'Geral':'-',
    'Reviews':'Análises',
    'reviews':'-', //no accents on this translation
    'personagens':'-',
    'Staff':'-',
    'autores':'-',
    'Relacionados':'-',
    'obras-relacionadas':'-',
    'Social':'-',
    'social':'-',
    'arte':'-',
    'Conheça!':'-',//more...
    'Protagonista':'-',
    'Atores e Atrizes':'-',
    'Nenhuma ator/atriz encotrado, conhece um ? Adicione!':'-',
    'Adicionar ator / atriz':'-',
    'Língua ':'-', //language
    'Manga':'-',
    'Mangás':'-',
    'Anime':'-',
    'Animes':'-',
    'Editora':'-',
    'Produtora':'-',
    'Doujinshi':'Doujinshi ( Obra de Fã )',
    'Manhwa':'Manhwa ( Quadrinho Koreano )',
    'Manhua':'Manhua ( Quadrinho Chinês )',
    'One-Shot':'Volume Único',
    'One-shot':'Volume Único',
    'Novel':'Romance',
    'autor':'-',
    'Error Creating Review':'Erro Criando a Review',
    'Faça uma análise sobre sua experiência com ':'-',
    'Tem certeza que quer deletar essa análise?':'-',
    'Deletar Análise':'-',
    'Eu achei essa review útil!':'Me ajudou!',
    'Assistido':'-',
    'Assistindo':'-',
    'Quero Assistir':'-',
    'Capa':'-',
    'banner':'-',
    'Esperando Ativação':'-',
    'Em poucas palavras, o que você diria de ':'-',
    'Deletar Reação':'-',
    'Tem certeza?':'-',
    'Todas':'-',
    'Mais':'-',
    'Menos':'-',
    'usuario':'-',
    'Todos os Animes':'-',
    'empresa':'-',
    'Estúdio(s)':'-',
    'Licenciado por':'-',
    'Produtor(as)':'-',
    'Meus Animes Brasili':'-',
    'Meus Anime Brasili , tudo sobre animes e mangas em um lugar só! Comunidade localizada de animes e mangá ! Siga as têndencias de animes, conheça a trilha sonora de animes, veja as datas de lançamento de animes , os trailers de animes , crie suas listas de animes, mangás , empresas , musicas , e muito mais!':'-',
    'Sinopse':'-',
    'Capa de':'-',
    'seguem':'-',
    'são':'-',
    'Personagens de':'-',
    'Staff de':'-',
    'Reviews de':'Análises de',
    'Filmes , Mangás , Novels , Temporadas de':'-',
    'Filtrar por Nome':'Buscar por Nome',
    'Relação':'-',
    'Posição':'-',
    'Filtrar Por Avaliação':'-',
    'Ruim':'-',
    'Mediano':'-',
    'Boa':'-',
    'Excelente':'-',
    'Batata':'-',
    'Adicionar Nota':'-',
    'Você votou ':'-',
    'Criar Lista':'-',
    'Nome da Lista':'-',
    'revista':'-',
    'Revista(s)':'-',
    'Capítulos':'-',
    'Volumes':'-',
    'Todos os Mangas':'-',
    'Leem':'-',
    'Empresa':'-',
    'Todas as Empresas':'-',
    'Sem informações a mostrar':'-',
    'Música':'-',
    'Produced':'Produziu',
    'Created':'Criou',
    'Licensed':'Licenciou',
    'null':'-',
    'Animação':'-',
    'Adaptação/Roteiro':'-',
    'Nasceu em':'-',
    'Website':'-',
    'Nacionalidade':'-',
    'Todas as Pessoas':'-',
    'Pessoa':'-',
    'Trabalhou em':'-',
    'Atuou':'-',
    'Personagens atuados por':'-',
    'Nenhuma obra encontrada, conhece uma ? Adicione!':'-',
    'Nenhuma papel encontrado, conhece um ? Adicione!':'-',
    'Atores / Atrizes':'-',
    'Recuperar minha senha':'-',
    'Entrar':'-',
    'E-mail':'-',
    'Senha':'-',
    'Entrar no M.A.B!':'-',
    'ou':'-',
    'Visitante':'-',
    'Sair':'-',
    'Nenhum encontrado, conhece um ? Adicione!':'-',
    'buscar':'-',
    'Buscar':'-',
    'Adicionar tudo à fila de reprodução':'-',
    'Adicionar à fila de reprodução':'-',
    'Adicionar à playlist':'-',
    'Remover da fila de reprodução': '-',
    'Remover da reprodução' : '-',
    'Voltar':'-',
    'Há um problema em nosso servidor, tente novamente mais tarde.':'-',
    'E-mail não encontrado':'-',
    'Pedido enviado incorretamente':'-',
    'Um e-mail com instruções para trocar sua senha foi enviado para o seu endereço.':'-',
    'Usuário ou Senha incorretos':'-',
    'Nova Senha':'-',
    'Repita a Senha':'-',
    'Há um problema com essa sessão, comece o processo novamente.':'-',
    'Sua nova senha foi configurada com sucesso. Redirecionando ao login...':'-',
    'As senhas estão diferentes, tente escrever novamente.':'-',
    'A senha deve ter pelo menos 7 caracteres.':'-',
    'Crie uma senha de backup':'-',
    'Sua senha foi configurada com sucesso.':'-',
    'Criar minha conta':'-',
    'Primeiro Nome (Nome de Pessoa)':'-',
    'Nickname (Nome de Usuário)':'-',
    'E-mail (a conta será validada)':'-',
    'Criar Conta':'-',
    'Campos de nome e apelido devem ter pelo menos 2 caracteres':'-',
    'Use um e-mail válido':'-',
    'Esse nickname já está em uso, tente outro':'-',
    'Esse endereço de e-mail já esta em uso':'-',
    'Sua conta foi criado com sucesso. Você receberá dentro de alguns minutos um e-mail para confirmar a conta, lembre de verificar também a caixa de spam e \'outros\'. Contas não confirmadas serão deletadas em três dias.':'-',
    'Há um problema com seu pedido, usuário ou token não encontrados, tente acessar pelo seu e-mail.':'-',
    'Validando sua conta...':'-',
    'Sua conta foi habilitada com sucesso!':'-',
    'Anunciada':'Anunciado',
    'Em exibição':'-',
    'Finalizada':'Finalizado',
    'Descubra Animes':'-',
    'Descubra Mangas':'-',
    'Descubra Pessoas':'-',
    'ano':'-',
    'Ano':'-',
    'estacao':'-',
    'tipo':'-',
    'exibicao':'-',
    'genero':'-',
    'Genero':'-',
    'tipo_filtro':'-',
    'Filtro: Exclusivo':'-',
    'Filtro: Inclusivo':'-',
    'nome':'-',
    'pagina':'-',
    'Seleciona se a filtragem vai incluir novos items a cada genero escolhido (Inclusivo), ou excluir itens que não contenham todos os genêros escolhidos (Exclusivo)':'-',
    'Remove todos os filtros da pesquisa':'-',
    'Limpar Filtros':'-',
    'Avaliar':'-',
    'Popular de todos os tempos':'-',
    'Próximos a lançar':'-',
    'Popular desta temporada':'-',
    'Pessoas':'-',
    'Empresas':'-',
    'Mangas':'-',
    'Revistas':'-',
    'de':'-',
    'até':'-',
    'Busca Avançada':'-',
    'Busca Simples':'-',
    'data-inicio':'-',
    'data-fim':'-',
    'Buscar Manga':'-',
    'Buscar Anime':'-',
    'Buscar Pessoa':'-',
    'Buscar Revistas':'-',
    'Buscar Empresas':'-',
    'Buscar Personagem':'-',
    'serializacao':'-',
    'Serialização':'-',
    'Lançando':'-',
    'Mostrar Conteúdo Adulto':'-',
    'Personagens':'-',
    'Descubra Personagens':'-',
    'Descubra Empresas':'-',
    'Descubra Revistas':'-',
    'Descubra Empresas':'-',
    'Adicionar tudo à fila de reprodução':'-',
    'Remover':'-',
    'Próxima':'-',
    'Anterior':'-',
    'Descobrir':'-',
    'Olá Visitante,':'-',
    'Adicionar OPs à fila de reprodução':'-',
    'Adicionar EDs à fila de reprodução':'-',
    'Apagar tudo':'-',
    'Nada':'-',
    'Tocar Lista de Musica':'-',
    'Criar Lista com essas músicas':'-',
    'Adicionar Lista de Músicas':'-',
    'Todos':'-',
    'Lista já Existente':'-',
    'Nova Lista':'-',
    'Selecione uma Lista':'-',
    'Top Animes em Exibição':'-',
    'Animes mais Esperados':'-',
    'Animes mais Populares esse Mês':'-',
    'Animes da Temporada':'-',
    'Filmes e Especiais da Temporada':'-',
    'Light Novel':'Romance Leve',
    'Music':'Música',
    'Unknown':'Não Declarado',
    'Home':'Início',
    'Consistencia':'-',
    'Top Anime':'-',
    'Top Filmes':'-',
    'Top Especiais':'-',
    'Top OVAs':'-',
    'Top Mangas':'-',
    'Top Romances':'-',
    'Personagens Mais Amados':'-',
    'Últimas Trilhas Sonoras':'-',
    'Termos de Uso':'-',
    'Politica de Privacidade':'-',
    'Politica de Cookies':'-',
    'Declaro que Li e Concordo com:':'-',
    'Você deve concordar com nossos termos para usar o site':'-',
    'Mangas Populares Essa Semana':'-',
    'Romances Populares Essa Semana':'-',
    'Romances Leves Populares Essa Semana':'-',
    'Carregando':'-',
    'Todos Direitos Reservados':'-',
    'Design e Engenharia por':'-'
}