import HtmlToReact from 'html-to-react';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { getUserRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import MyAppPool from './../AppPool';
import { contentTypeToFastType } from './../util/typeu';
import "./ReactionItem.css";
import { getUserAvatar } from '../util/icon';

@observer
class ReactionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {sending:false,content:''};
    this.deletePost = this.deletePost.bind(this);
}
  deletePost()
  {
    let {reactionItem} = this.props;
    MyAppPool.cReactionPosts.deleteReactionPost(reactionItem);
  }

  


  componentDidMount()
  {

  }

  render(){
    let {reactionItem,currentUserId,currentUserRoles} = this.props;
    let showEditIcons = false;
    let voteResourceType = contentTypeToFastType('reactionPost');
    let userVote = MyAppPool.session.user.votes[''+voteResourceType][''+reactionItem.reactid];
    if (typeof userVote ==='undefined')
      userVote=0;

      let currentUserRolesUse = []
      if (Array.isArray(currentUserRoles))
        currentUserRolesUse = currentUserRoles;
      if (currentUserId===reactionItem.author || currentUserRolesUse.indexOf('mabmod')!==-1)
        showEditIcons=true;

    let authorId = reactionItem.author;
    let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+contentTypeToFastType('user')][''+authorId]);


    const displayContent = HtmlToReact.Parser().parse(reactionItem.displaytext);
    return (
    <div className="uk-flex uk-flex-row comp_reactionItem">
        <div className="mbtngroup">
          {userVote==1? 
            <button aria-label={"+1"} className="uk-button uk-button-default mbtn upvote btnActivatedGood" onClick={()=>MyAppPool.cVotes.sendReactionPostVote(reactionItem.reactid,reactionItem.resourcetype,reactionItem.resourceid,1)}> <span className="fas fa-chevron-up textActivatedGood"/></button> 
          : <button aria-label={"+1"} className="uk-button uk-button-default mbtn upvote" onClick={()=>MyAppPool.cVotes.sendReactionPostVote(reactionItem.reactid,reactionItem.resourcetype,reactionItem.resourceid,1)}> <span className="fas fa-chevron-up"/></button>
          }
          {userVote==0? <p className="votesText">{reactionItem.votes}</p> 
          : userVote==1? <p className="votesText textActivatedGood">{reactionItem.votes}</p> 
          : <p className="votesText textActivatedBad">{reactionItem.votes}</p> 
         }
          
          {userVote==-1? 
            <button aria-label={"-1"} className="uk-button uk-button-default mbtn downvote btnActivatedBad" onClick={()=>MyAppPool.cVotes.sendReactionPostVote(reactionItem.reactid,reactionItem.resourcetype,reactionItem.resourceid,-1)}> <span className="fas fa-chevron-down textActivatedBad" /></button> 
          : <button aria-label={"-1"} className="uk-button uk-button-default mbtn downvote" onClick={()=>MyAppPool.cVotes.sendReactionPostVote(reactionItem.reactid,reactionItem.resourcetype,reactionItem.resourceid,-1)}> <span className="fas fa-chevron-down"/></button>
          }
        </div>
        <div className="uk-flex uk-flex-row reactionContent"> 
        <Link aria-label={sampleAuthor.name} className="userimghref" to={getUserRoute(reactionItem.author,sampleAuthor.name,"#top")}><LazyLoadImage alt={lng("User")+" "+sampleAuthor.name+ " "+lng("avatar")} className="userimg" src={getUserAvatar(sampleAuthor.img,sampleAuthor.hash,'x25') }/></Link>
          <div className="textContent">
            <Link className="reactionTitle" to={getUserRoute(reactionItem.author,sampleAuthor.name,"#top")}><span className="author">{sampleAuthor.name}</span> </Link>
            <p className="contentText">{displayContent} 
            {showEditIcons?
            <span onClick={()=>MyAppPool.cModal.set(lng('Deletar Reação'),lng('Tem certeza?'),this.deletePost)} className="fas fa-trash-alt iconButton clickable uk-align-right" />
            : <span></span>}
            </p>
          </div>
        </div>
    </div>);
}
}

ReactionItem.propTypes = {
  reactionItem: PropTypes.shape({
  displaytext: PropTypes.string.isRequired,
  publishdate:PropTypes.string.isRequired,
  votes:PropTypes.number.isRequired,
  authorname:PropTypes.string.isRequired,
  authorimg:PropTypes.string.isRequired,
  author:PropTypes.number.isRequired
  }),
  currentUserId: PropTypes.number.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string)
  
};

ReactionItem.defaultProps = {
  reactionItem: {
    displaytext: '',
    publishdate:'',
    votes:0,
    authorname:'Defaulter',
    authorimg:'',
    author:0
    },
    currentUserId: 0,
    currentUserRoles: ['guest']
};


export default ReactionItem;
