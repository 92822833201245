
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { contentTypeToFastType, mobToObj } from '../util/typeu';
import MyAppPool from '../AppPool';
import "./MusicCardHome.css";
import ListWidget from './ListWidget';
import MusicPlaylistCreator from './MusicPlaylistCreator';
import MusicItemHome from './MusicItemHome';
import { getObraRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon } from '../util/icon';
import { Link } from 'react-router-dom';
import { getImgAddress } from '../util/text';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function themeGetType(type)
{
  if (type===0)
    return 'op';
  if (type===1)
    return 'ed';
  return '';
}
class MusicCardHome extends Component {

    constructor(props) {
        super(props);
        this.addAllToQueue = this.addAllToQueue.bind(this);
        this.state = {};
      }
    
      componentDidMount()
      {
    
      }
    
      addAllToQueue(type=-1)
      {
        let {animeMusics} = this.props;
        let items = animeMusics.data;
        for(let i=0; i < items.length; i++)
        {
          let music = items[i];
          if (type!==-1 && items[i].type!==type)
            continue;
          MyAppPool.cMusic.addToPlayQueue(music);
        }
        MyAppPool.cPopMenu.deactivate();
      }

      render(){
        let {animeMusics} = this.props;
       // console.log(mobToObj(animeMusics));
        if (Array.isArray(animeMusics))
        return <></>;
        let items = animeMusics.data;

        let icon = getRelatedTypeIcon(animeMusics.animetype);
        let iconText = lng(animeMusics.animetype);
      //  console.log("ANIME TYPE:::",animeMusics.animetype);

        return (
            <div className="comp_musiccardhome">
            <div className="cardTitle">

            <div className="fas fa-ellipsis-v alignBtnRight clickable" 
                      onClick={(e)=>{
                        MyAppPool.cPopMenu.set(
                          <div>
                            <ul className="pop_ytmenulist">
                              <li className="pop_ytmenuitem" onClick={()=>this.addAllToQueue(-1)}>
                                <span className={"fas pop_ytmenuitemicon fa-music"} ></span><div className="ytmenuoptiontext">{lng("Adicionar tudo à fila de reprodução")}</div>
                              </li>
                              <li className="pop_ytmenuitem" onClick={()=>MyAppPool.cModal.set(
                                lng("Adicionar Lista de Músicas"),
                                <MusicPlaylistCreator items={items}/>,
                                (ok)=>{},'',(cleanup)=>{})}
                              >
                                <span className={"fas pop_ytmenuitemicon fa-plus"} ></span><div className="ytmenuoptiontext">{lng("Criar Lista com essas músicas")}</div>
                              </li>
                          </ul>
                          
                          </div>
                        ,'')}}
                      >
                </div>
                
              <Link   to={getObraRoute(animeMusics.animeid,animeMusics.animetitle,'anime','#top')}>
              <span className="animetitle">{animeMusics.animetitle}</span>
              </Link> {(icon)? <i className={icon + ' typeIcon animetitle'} data-uk-tooltip={iconText}></i> : <></>}
              
                </div>
              <div className="uk-flex uk-flex-row">
                <div className="imgWrapper">
                  <Link aria-label={animeMusics.animetitle}  style={{width:'100%',float:'right', marginRight:'5%'}} to={getObraRoute(animeMusics.animeid,animeMusics.animetitle,'anime','#top')}>
                  <LazyLoadImage alt={lng("Capa de")+" "+animeMusics.animetitle} className='listImage' src={getImgAddress(animeMusics.mainimg,'imgs/default/anime.webp','x25')}/>
                  </Link> 
                </div>
                <div className="musiclisthider">
                  <div className="musiclistwrapper">
                  <ul className="uk-list uk-list-striped">
                  {items.map((element, index) => {
                      return <MusicItemHome key={index} music={element} showBars={false} />
                    })
                  }
                  </ul>
                  </div>
                </div>
              </div>
            </div>
          );

      }


}
MusicCardHome.propTypes = {
    animeMusics: PropTypes.shape({
        animeid:PropTypes.number.isRequired,
        animetitle:PropTypes.string.isRequired,
        mainimg:PropTypes.string.isRequired,
        animetype:PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
            PropTypes.shape({
            title: PropTypes.string.isRequired,
            band: PropTypes.string.isRequired,
            where: PropTypes.string.isRequired,
            ytid: PropTypes.string.isRequired,
            id:PropTypes.number.isRequired}))
    }),
    showBars: PropTypes.bool.isRequired
  };
  MusicCardHome.defaultProps = {
    animeMusics:{
            animeid:0,
            animetitle:'loading',
            mainimg:'',
            animetype:'OVA',
            data:[
                {
                    title: 'loading',
                    band: '',
                    where: '',
                    ytid: '',
                    id:0
                  }
            ]
        }
    ,
    showBars: false
  };
  
  export default MusicCardHome;