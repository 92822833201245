
import { computed, toJS } from 'mobx';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { getCharacterRoute, getObraRoute, getPersonAnimeRoute, getPersonMangaRoute, getPersonPersonagensRoute, getPersonRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon } from '../util/icon';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { convertDataToShowable, getAnchorName, getSEOUriPath } from '../util/text';
import { contentTypeToFastType, isSet } from '../util/typeu';
import { isServer } from '../util/windowUtil';




export default class PersonController
{
    fetchPersonById(index)
    {
        let loadId;
        if (Number(index)===Number(MyAppPool.session.person.currentPerson))
        {   
            loadId = MyAppPool.cGlobalLoadingBar.start();
            MyAppPool.cGlobalLoadingBar.end(loadId,true);
            return Promise.resolve(true);
        }
        MyAppPool.session.person.persons[index] = {ready:0};
        MyAppPool.session.person.currentPerson= index;
        loadId = MyAppPool.cGlobalLoadingBar.start(1500);

        if (!isServer())
            brasRequest(routes.resourcePopularCount,false,{resourceType:contentTypeToFastType('person'),resourceId:index});
      
        return fetchAndProcess(this.setSessionPerson,
            routes.getPersonByIdLng,true,{pid:index,lng: getCurrentLanguage()})
            .then((data) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,true);
                return Promise.resolve(data);
            })
            .catch((e) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,false);
                console.log("Error Fetching person",e)
            });
    }

    setSessionPerson(data0,ready)
    {
        if (!data0.ok)
            return Promise.reject();
        
        let data = data0.data;
        if (!data.empty)
        {     
            data.fullName = data.name + ' ' + data.familyname;
            data.otherTitles = '';
            for (let i=0; i < data.altnames.length;i++)
            {
                data.otherTitles += data.altnames[i].name
                if (i<(data.altnames.length-1))
                    data.otherTitles+=", ";
            }

            data.columItems = [];
            if (data.website!=='' && isSet(data.website))
            {
                let displayVal = data.website;
                console.log(displayVal);
                displayVal = displayVal.replaceAll('/',' ');
                data.columItems[data.columItems.length] =
                {name:lng('Website'),info:[{type:'url',value:data.website,display:displayVal}]};
            }
            if (isSet(data.birthday) && data.birthday!=='')
            {
                data.columItems[data.columItems.length] =
                {name:lng('Nasceu em'),info:[{type:'date',value:data.birthday,display:convertDataToShowable(data.birthday)}]};
            }
            if (isSet(data.nationality) && data.nationality!=='' && data.natid!==1)//natid 1 is unknown, we prefer to hide if we don't know
            {
                data.columItems[data.columItems.length] =
                {name:lng('Nacionalidade'),info:[{type:'misc',value:data.nationality,display:data.nationality}]};
            }
            for(let i=0; i< data.extra.length; i++)
            {
                let item = data.extra[i];
                let content = {};
                if (item.type==='url')
                {
                    let displayVal = item.mvalue;
                    displayVal = displayVal.replace('/',' ');
                    
                    content =  {name:item.mkey,info:[{type:item.type,value:item.url,display:displayVal}]};
                }
                else
                    content =  {name:item.mkey,info:[{type:item.type,value:item.mvalue,display:item.mvalue}]};

                data.columItems[data.columItems.length] =content; 
            }

            let seoUriName = getSEOUriPath(data.name+ ' ' + data.familyname);
            
            let relStaffedWorks = [];
            let animeById = {};
            let animeRow = [];
            let kCounter=0;
            let personAnimePositions = {};
            let relatedPersonAnimeStaffedTypes = {};

            for(let i =0; i<data.staffed.length; i++)
            {
                let element = data.staffed[i];
                let arrayId = animeById[element.id];
                if (typeof arrayId!=='undefined')
                {
                    animeRow[arrayId].roles[animeRow[arrayId].roles.length] = {role:element.role,salary:element.salary,description:element.roledescription};
                    animeRow[arrayId].rolesTogether += ', '+ element.role;
                    continue;
                }
                
                animeRow[kCounter] = element;
                animeRow[kCounter].roles = [{role:element.role,salary:element.salary,description:element.roledescription}];
                animeRow[kCounter].rolesTogether = element.role;
                personAnimePositions[element.role]=1;
                relatedPersonAnimeStaffedTypes[element.type]=1;

                animeById[element.id] = kCounter; 
                let anchor = '#'+getAnchorName(element.title);
                relStaffedWorks[kCounter] = {
                    url: getPersonAnimeRoute(data.id,seoUriName,anchor),
                    directUrl:getObraRoute(element.id,getSEOUriPath(element.title),element.pagetype,'#top'),
                    imgurl:element.mainimg, 
                    title:element.title,
                    icon:getRelatedTypeIcon(element.type),
                    iconText:lng(element.type),
                    resourceType:contentTypeToFastType(element.pagetype),
                    resourceId:element.id
                } 
                kCounter++;
            }
            personAnimePositions = Object.keys(personAnimePositions);
            relatedPersonAnimeStaffedTypes = Object.keys(relatedPersonAnimeStaffedTypes);

            let mangaById = {};
            let mangaRow = [];
            kCounter=0;
            let personMangaPositions = {};
            let relCreatedWorks = [];
            let relatedPersonMangaStaffedTypes = {};
            for(let i =0; i<data.created.length; i++)
            {
                let element = data.created[i];
                let arrayId = mangaById[element.id];
                if (typeof arrayId!=='undefined')
                {
                    mangaRow[arrayId].roles[mangaRow[arrayId].roles.length] = {role:element.role,salary:element.salary,description:element.roledescription};
                    mangaRow[arrayId].rolesTogether += ', '+ element.role;
                    continue;
                }
                
                mangaRow[kCounter] = element;
                mangaRow[kCounter].roles = [{role:element.role,salary:element.salary,description:element.roledescription}];
                mangaRow[kCounter].rolesTogether = element.role;
                personMangaPositions[element.role]=1;
                relatedPersonMangaStaffedTypes[element.type]=1;

                mangaById[element.id] = kCounter; 
                let anchor = '#'+getAnchorName(element.title);
                relCreatedWorks[kCounter] = {
                    url: getPersonMangaRoute(data.id,seoUriName,anchor),
                    directUrl:getObraRoute(element.id,getSEOUriPath(element.title),element.pagetype,'#top'),
                    imgurl:element.mainimg, 
                    title:element.title,
                    icon:getRelatedTypeIcon(element.type),
                    iconText: lng(element.type),
                    resourceType:contentTypeToFastType(element.pagetype),
                    resourceId:element.id
                };
                kCounter++;
            }
            personMangaPositions = Object.keys(personMangaPositions);
            relatedPersonMangaStaffedTypes = Object.keys(relatedPersonMangaStaffedTypes);

            
            
            let relCharacters = [];
            for(let i =0; i<data.acted.length; i++)
            {
                let element = data.acted[i];
                let anchor = '#'+getAnchorName(element.name+ ' ' + element.familyname);
                relCharacters[i] = {url:  getPersonPersonagensRoute(data.id,seoUriName,anchor),
                    directUrl:getCharacterRoute(element.characterid,getSEOUriPath(element.name + ' ' + element.familyname),'#top'),
                    imgurl:element.mainimg, 
                    title:element.name + ' ' + element.familyname,
                    resourceType:contentTypeToFastType("character"),
                    resourceId:element.characterid
                }
                    element.relVoiceActors = [];
            }
            
            data.personAnimePositions = personAnimePositions;
            data.personMangaPositions = personMangaPositions;
            data.relatedPersonAnimeStaffedTypes = relatedPersonAnimeStaffedTypes;
            data.relatedPersonMangaStaffedTypes = relatedPersonMangaStaffedTypes;
            data.animeRow = animeRow;
            data.mangaRow = mangaRow;
            data.relStaffedWorks = relStaffedWorks;
            data.relCreatedWorks = relCreatedWorks;
            data.relCharacters = relCharacters;
            data.baseUrl = getPersonRoute(data.id,seoUriName);
            data.resourceType = contentTypeToFastType('person');
        }
        //console.log(data);
        MyAppPool.session.person.persons[data.id] = {...MyAppPool.session.person.persons[data.id],...data} ;
        MyAppPool.session.person.persons[data.id].ready = ready?1:0;
        //console.log(data);
        
        MyAppPool.session.person.currentPerson= data.id;


        MyAppPool.cPageJsonSchema.makePersonJsonSchema();

        return Promise.resolve(true);
    }

    @computed get currentPerson()
    {
        let data = toJS(MyAppPool.session.person.persons[MyAppPool.session.person.currentPerson]);
        if (!isSet(data))
            return {ready:0};
        return data;//MyAppPool.session.person.persons[MyAppPool.session.person.currentPerson];
    }
    
}