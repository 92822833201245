
import { routes } from '../store/ApiRoutes';
import MyAppPool from '../AppPool';
import { brasRequest, fetchAndProcess } from '../util/net';
import { contentFastTypeToType, contentTypeToFastType, isSet } from '../util/typeu';
import { deleteIdFromArray } from '../util/array';
import { lng } from '../ulanguages/language';

export default class ReactionPostController
{
    fetchAndProcessCreateReactionPost(resourceType,resourceId,content,reactionPostId=-1)
    {
        if (!isSet(MyAppPool.session.reactionPost[resourceType]))
            MyAppPool.session.reactionPost[resourceType] = {};

        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let data = data0.data;
            if (data.length>0)
            {
                let myReactionPost = data[0];
                
                let oldReactionPosts = MyAppPool.session.reactionPost[''+resourceType][''+resourceId];
                if (!isSet(oldReactionPosts))
                    oldReactionPosts = [];
                if (reactionPostId===-1)//create
                {
                    MyAppPool.session.reactionData[''+myReactionPost.reactid] = myReactionPost;
                    MyAppPool.session.reactionPost[''+resourceType][''+resourceId]=[myReactionPost.reactid, ...oldReactionPosts];
                }
                else
                {//update
                    MyAppPool.session.reactionData[''+myReactionPost.reactid] = myReactionPost;
                }
                MyAppPool.cAlert.notifyOk(lng("Impressão Enviada com Sucesso!"));
                return Promise.resolve();
            }
        },
        routes.createOrUpdateReactionPost,false,
        {resourceType,resourceId,content,reactionPostId})
        .catch(e => {
            MyAppPool.cAlert.notifyGenericError(e,"create reaction post");
            console.log(("Error Creating ReactionPost"),e)
        });
    }

    createOrUpdateReactionPost
    (resourceType,resourceId,content,animGrade,musicGrade,storyGrade,characterGrade,reactionPostId=-1)
    {
        if (content==='')
            return Promise.resolve();
        let sendResourceType;
        sendResourceType = resourceType;
        if (isNaN(resourceType))
            sendResourceType = contentTypeToFastType(resourceType);

        return this.fetchAndProcessCreateReactionPost
        (sendResourceType,resourceId,content,animGrade,musicGrade,storyGrade,characterGrade,reactionPostId);
    }

    deleteReactionPost(reactionPost)
    {
        return brasRequest(routes.deleteReactionPost,false,{reactionPostId:reactionPost.reactid},{})
        .then((response)=>{
            let temp = MyAppPool.session.reactionPost[''+reactionPost.resourcetype][''+reactionPost.resourceid];
            if (temp)
                MyAppPool.session.reactionPost[''+reactionPost.resourcetype][''+reactionPost.resourceid]=deleteIdFromArray(reactionPost.reactid,temp);
            temp = MyAppPool.session.latestContent.reactions.items;
            if (temp)
                MyAppPool.session.latestContent.reactions.items=deleteIdFromArray(reactionPost.reactid,temp);

            MyAppPool.session.reactionData[''+reactionPost.reactid] = undefined;
            
        }).catch((e)=>{
            MyAppPool.cAlert.notifyGenericError(e,"delete reaction post");
            console.error(e);
        });
    }

    fetchReactionPosts(resourceType,resourceId,useCache=true)
    {
        //console.log('resType:',resourceType,'resId:',resourceId);
        if (!isSet(MyAppPool.session.reactionPost[resourceType]))
            MyAppPool.session.reactionPost[resourceType] = {};
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let data = data0.data;
            let dataIds = [];
            let authorIds = [];
            for(let i=0;i<data.length;i++)
            {
                let item = data[i];
                MyAppPool.session.reactionData[''+item.reactid] = item;
                authorIds.push(item.author);
                dataIds[i] = item.reactid;
            }

            if (data.length>0)
            {
                MyAppPool.session.reactionPost[''+resourceType][''+resourceId] = dataIds;
                MyAppPool.cSamples.getSamplesByArrayIdsList(contentTypeToFastType('user'),authorIds);
                return Promise.resolve(data);
            }
            MyAppPool.session.reactionPost[''+resourceType][''+resourceId] = [];
            return Promise.resolve([]);
        },
        routes.getReactionPostByResourceId,useCache,
        {resourceType,resourceId})
        .catch(e => console.log("Error Getting "+contentFastTypeToType(resourceType)+" ReactionPosts",e));
    }
}