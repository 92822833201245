import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import MyAppPool from './../AppPool';
import CSSTransition2 from './CSSTransition2';
import ErrorJSXCatcher from './ErrorJSXCatcher';
import "./GlobalAlert.css";

@observer
class GlobalAlert extends Component {
  constructor(props)
  {
    super(props);
    this.close = this.close.bind(this);
  }
  close()
  {
    MyAppPool.cAlert.deactivate(); 
  }
  componentDidMount()
  {
  }
  componentDidUnmount()
  {
  }
  render(){
    let alerts = MyAppPool.session.alerts.data;
    let timeToStayOpen=3;//in seconds
    return (<div className='comp_app_alert'>
       <ErrorJSXCatcher>
        <div className="wrapper uk-sticky uk-sticky-fixed uk-active">
        {
          alerts.map((alert,index)=>{
            var startDate = alert.time;
            var endDate   = MyAppPool.session.alerts.time;
            console.log(startDate,endDate);
            var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
            let display="block";
            let color="#F1506E";//red
            if (alert.status===400)
              color="#ff9933";//orange
            if (alert.status===200)
              color="#00cc00";//green
            if (seconds>=timeToStayOpen)
             display="none";
            return <div style={{display:display,backgroundColor:color}} className="uk-card uk-card-default card">
              
              <i className="fas fa-times uk-align-right clickable" onClick={()=>MyAppPool.cAlert.deactivate(index)}></i>
              <span className="alertText">{alert.text}</span>
            </div>
          })
        }
        </div>
       </ErrorJSXCatcher>
       
       </div>);
  }
}

export default GlobalAlert;
