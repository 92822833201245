import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { onlyLettersAndNumbers } from '../util/text';
import { scrollToAnchor } from '../util/windowUtil';
import "./AnchorBox.css";
 
@observer
class AnchorBox extends Component {

  componentDidMount()
  {
  }

  render()
  {
    let {items,title} = this.props;
    return (
        <div className="comp_anchorBox"> 
            <div className="uk-card uk-card-default mainCard" data-uk-sticky="offset: 80">
            <span>{title} <br/></span>
              <div className="anchorBoxList appScroll">
                  {items.map((item,index)=>{ 
                      return <span><button className="link-button" onClick={()=>scrollToAnchor("#"+onlyLettersAndNumbers(item.name),0)} >{item.name}{item.length!==-1?"("+item.length+")":""}</button> <br /></span>
                  })}
               </div>
            </div>
        </div>);
  }
}

AnchorBox.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          length: PropTypes.number.isRequired
        })
      ),
      title:PropTypes.string
};
AnchorBox.defaultProps = {
    items:[],
    title:lng("Listas")
};

export default AnchorBox;
