
import { computed, toJS } from 'mobx';
import { routes } from '../store/ApiRoutes';
import { getDefaultSession, getDefaultUser } from '../store/defaultSessionVals';
import { MD5 } from '../util/secUtil';
import { isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { getCurrentLanguage } from '../ulanguages/language';
import { fetchAndProcess } from '../util/net';
import { contentTypeToFastType, getDefaultStarter, getIfIsSetElems, isSet, mobToObj, setIfNotSetObjElems } from '../util/typeu';

function getMergeAll(userWallPages,currentPage){
    let entries = Object.entries(userWallPages);
    let res = [];
    
    for (let i=0;i<entries.length;i++)
    {
        if (Number(entries[i][0])<=currentPage)
            if (Array.isArray(entries[i][1]))
                res.push(...entries[i][1]);
    }
    return res;
}

export default class LatestContentController
{
    getMoreRecentTrailers()
    {
        let askForPage = MyAppPool.session.latestContent.trailers.loadedPages;
        MyAppPool.session.latestContent.trailers.loadedPages = MyAppPool.session.latestContent.trailers.loadedPages+1;
        let pageSize=MyAppPool.session.latestContent.trailers.pageSize;
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;

            let currentTrailers = MyAppPool.session.latestContent.trailers.items;
            let newTrailers = [...currentTrailers,...resData];
            newTrailers = newTrailers.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);//filter repeated data, happens sometimes

            MyAppPool.session.latestContent.trailers.items=newTrailers;
            MyAppPool.session.latestContent.trailers.lastLoadedItensAmount = resData.length;
      
            return Promise.resolve();
        },
        routes.getRecentTrailersPage,true,
        {type:'',page:askForPage,pageSize})
        .catch(e => console.log(("Error Getting Recent Trailers"),e));
    }

    getMoreRecentMusic()
    {
        let askForPage = MyAppPool.session.latestContent.musics.loadedPages;
        MyAppPool.session.latestContent.musics.loadedPages = MyAppPool.session.latestContent.musics.loadedPages+1;
        let pageSize=MyAppPool.session.latestContent.musics.pageSize;
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            
            let animeMusicUnited = {};
            for(let i=0;i<resData.length;i++)
            {
                let item = resData[i];
                let myid = ''+item.animeid;
                if (typeof animeMusicUnited[myid] ==='undefined')
                    animeMusicUnited[myid]={pos:i,animetitle:item.animetitle,animeid:item.animeid,mainimg:item.mainimg, animetype:item.animetype, data:[]};
                animeMusicUnited[myid].data.push(item);
            }
            let animeMusicArray = [];
            let entries = Object.values(animeMusicUnited);
            for(let i=0;i<entries.length;i++)
                animeMusicArray.push(entries[i]);
            
            animeMusicArray.sort(function(a,b){
                if(a.pos > b.pos){
                    return 1;
                }
                return -1;
                });


            let currentMusics = MyAppPool.session.latestContent.musics.items;
            MyAppPool.session.latestContent.musics.items=[...currentMusics,...animeMusicArray];
            MyAppPool.session.latestContent.musics.lastLoadedItensAmount = animeMusicArray.length;

            return Promise.resolve();
        },
        routes.getRecentMusicsPage,true,
        {page:askForPage,pageSize})
        .catch(e => console.log(("Error Getting Recent Musics"),e));
    }

    getMoreRecentReviews()
    {
        let askForPage = MyAppPool.session.latestContent.reviews.loadedPages;
        MyAppPool.session.latestContent.reviews.loadedPages = MyAppPool.session.latestContent.reviews.loadedPages+1;
        let pageSize=MyAppPool.session.latestContent.reviews.pageSize;
        
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let dataIds = [];
            let resourceIds = {};
            let userIds = [];
            for(let i=0;i<resData.length;i++)
            {
                let item = resData[i];
                MyAppPool.session.reviewData[''+item.reviewid] = item;
                dataIds[i] = item.reviewid;
                userIds.push(item.author);
                if (!Array.isArray(resourceIds[''+item.resourcetype]))
                    resourceIds[''+item.resourcetype] = [];
                resourceIds[''+item.resourcetype].push(item.resourceid);
            }

            let currentReviews = MyAppPool.session.latestContent.reviews.items;
            MyAppPool.session.latestContent.reviews.items=[...currentReviews,...dataIds];
            MyAppPool.session.latestContent.reviews.lastLoadedItensAmount = dataIds.length;
            
            let resourcesArray = Object.entries(resourceIds);
            for(let i=0;i<resourcesArray.length;i++)
            {
                let item = resourcesArray[i];
                MyAppPool.cSamples.getSamplesByArrayIdsList(item[0],item[1]);
            }
            MyAppPool.cSamples.getSamplesByArrayIdsList(contentTypeToFastType('user'),userIds);

            return Promise.resolve();
        },
        routes.getLatestReviews,true,
        {page:askForPage,pageSize})
        .catch(e => console.log(("Error Getting Recent Reviews"),e));
    }

    getMoreRecentReactions()
    {
        let askForPage = MyAppPool.session.latestContent.reactions.loadedPages;
        MyAppPool.session.latestContent.reactions.loadedPages = MyAppPool.session.latestContent.reactions.loadedPages+1;
        let pageSize=MyAppPool.session.latestContent.reactions.pageSize;
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let dataIds = [];
            let resourceIds = {};
            let userIds = [];
            for(let i=0;i<resData.length;i++)
            {
                let item = resData[i];
                MyAppPool.session.reactionData[''+item.reactid] = item;
                dataIds[i] = item.reactid;
                userIds.push(item.author);
                if (!Array.isArray(resourceIds[''+item.resourcetype]))
                    resourceIds[''+item.resourcetype] = [];
                resourceIds[''+item.resourcetype].push(item.resourceid);
            }
            
            let currentReactions = MyAppPool.session.latestContent.reactions.items;
            MyAppPool.session.latestContent.reactions.items=[...currentReactions,...dataIds];
            MyAppPool.session.latestContent.reactions.lastLoadedItensAmount = dataIds.length;

            let resourcesArray = Object.entries(resourceIds);
            for(let i=0;i<resourcesArray.length;i++)
            {
                let item = resourcesArray[i];
                MyAppPool.cSamples.getSamplesByArrayIdsList(item[0],item[1]);
            }
            MyAppPool.cSamples.getSamplesByArrayIdsList(contentTypeToFastType('user'),userIds);
           
            return Promise.resolve();
        },
        routes.getLatestReactions,true,
        {page:askForPage,pageSize})
        .catch(e => console.log(("Error Getting Recent Reactions"),e));
    }


    getUserWall(userId,page,pageSize)
    {
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            let dataIds = [];
            let resourceIds = {};
            let userIds = [];
            
            for(let i=0;i<resData.length;i++)
            {
                let item = resData[i];
                if (item.contenttype==='review')
                {
                    MyAppPool.session.reviewData[''+item.reviewid] = item;
                    dataIds.push({id:item.reviewid,type:'review'});
                    userIds.push(item.author);
                    if (!Array.isArray(resourceIds[''+item.resourcetype]))
                        resourceIds[''+item.resourcetype] = [];
                    resourceIds[''+item.resourcetype].push(item.resourceid);
                }
                if (item.contenttype==='impression')
                {
                    MyAppPool.session.reactionData[''+item.reactid] = item;
                    dataIds.push({id:item.reactid,type:'impression'});
                    userIds.push(item.author);
                    if (!Array.isArray(resourceIds[''+item.resourcetype]))
                        resourceIds[''+item.resourcetype] = [];
                    resourceIds[''+item.resourcetype].push(item.resourceid);
                }
            }
            setIfNotSetObjElems(MyAppPool.session.users,[''+userId,'wallItems',''+pageSize,page]);
            MyAppPool.session.users[''+userId].wallItems[''+pageSize][page]=dataIds;
            
            let resourcesArray = Object.entries(resourceIds);
            for(let i=0;i<resourcesArray.length;i++)
            {
                let item = resourcesArray[i];
                MyAppPool.cSamples.getSamplesByArrayIdsList(item[0],item[1]);
            }
            MyAppPool.cSamples.getSamplesByArrayIdsList(contentTypeToFastType('user'),userIds);

            return Promise.resolve(resData.length);
        },
        routes.getUserWall,true,
        {uid:userId,page:page,pageSize})
        .catch(e => console.log(("Error Getting User Wall"),e));
    }

    getUserWallAllPage(baseField,userId,pageSize,currentPage){
        let elem = getIfIsSetElems(baseField,[''+userId,'wallItems',''+pageSize]);
        if (isSet(elem))
        {
            return getMergeAll(elem,currentPage);
        }
        return null;
    };
}