
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import SkeletonBlock from '../../view_component/SkeletonBlock';
import MyAppPool from './../../AppPool';
import { getPersonRoute } from './../../store/AppRoutes';
import { lng } from '../../ulanguages/language';
import { isMobile } from './../../util/mobile';
import { getAnchorName, getImgAddress, getSiteShortName } from './../../util/text';
import { isServer } from './../../util/windowUtil';
import PersonRowCard from './../../view_component/PersonRowCard';
import ScrollToTopButtom from './../../view_component/ScrollToTopButton';
import './AnimeStreamView.css';
import { isSet } from './../../util/typeu';
@observer
class AnimeStreamView extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        nameFilter:'',
        positionFilter:'Audio Original'
    };        
    this.stringFilter = this.stringFilter.bind(this);
    this.passFilter = this.passFilter.bind(this);
    }
    componentDidMount()
    {

    }
    stringFilter(filter,name)
    {
      if (filter==='')
        return true;
      if (name.toLowerCase().indexOf(filter.toLowerCase())!==-1)
        return true;
      return false;
    }
    passFilter(item)
    {
    
      let {nameFilter,positionFilter} = this.state;
      return (
        this.stringFilter(nameFilter,item.name + ' ' + item.episodenum)
      && ((positionFilter==='Audio Original' && item.isdub===0) || (positionFilter===item.dublang)) )
      
    }

    render()
    {
        let {current} = this.props;

        let meta = {title:lng('Watch')+' Anime | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:''};

        let dubs = [];
        if (current.ready)
        {
          let desc = lng('Watch') + ' '  + current.mainTitle;
          
          meta =  {
            title:lng('Watch') + ' '  + current.mainTitle + ' | '+getSiteShortName()+'',
            desc:desc,
            keywords:desc
          };
        
            for(let i=0;i<current.stream.length;i++)
            {
                let item = current.stream[i];
                if (item.isdub)
                    dubs.push(item.dublang);
            }
            dubs = [...new Set(dubs)];
        }

        let mainWidth="60%";
        let mainMarginLeft="15px";
        if (isMobile.any)
        {
          mainWidth="95%";
          mainMarginLeft="2.5%";
        }

        

        return (<div className="stream_view contentArea uk-flex uk-flex-row">
           <Helmet>
            <title>{meta.title}</title>
                <meta name="title" content={meta.title} />
                <meta name="description" content={meta.desc} />
                <meta name="keywords" content={meta.keywords}/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta.title} />
                <meta property="og:description" content={meta.desc} />
                <meta name="twitter:title" content={meta.title}/>
                <meta name="twitter:description" content={meta.desc}/>
          </Helmet>
        {!isMobile.any?
        <div style={{width:"21%",marginLeft:"15px"}}>
          {//left collum
          }
        </div>
        :<></>}

        <div style={{width:mainWidth,marginLeft:mainMarginLeft}}>
          <div className="uk-flex uk-flex-row filterBox">
              <input type="text" value={this.state.nameFilter} onChange={(e) => {this.setState({nameFilter: e.target.value});}} className="uk-textarea textInput" maxLength={140} rows={1} placeholder={lng("Filtrar por Nome") }  />
              <select onChange={(e) => {this.setState({positionFilter: e.target.value});}}>
                <option value="Audio Original">{lng('Audio Original')}</option>
                {
                  current.ready?
                  dubs.map((item, index) => {
                    return (<option value={item} key={index}>{item}</option>);
                  })
                  :<></>
                }
              </select>
          </div>
          <div className="flexDrop" style={{width:"100%"}}>
          {current.ready? 
          current.stream.map((item, index) => {
            let redditDiscussionUrl='https://www.google.com/search?q='+ encodeURIComponent('reddit '+current.mainTitle+' - Episode '+item.episodenum+' Discussion '+item.episodenum+' Anime')+'&btnI=';
          
            let itemImg = item.imgurl?item.imgurl:current.mainimg;
            return (
              this.passFilter(item)?
              <div key={index} className="stream_item">
              <LazyLoadComponent 
              scrollPosition={MyAppPool.scrollPosition }
              visibleByDefault={isServer()}
              placeholder={<SkeletonBlock />}
              >
                <a target="_blank" href={item.episodeurl} >
                <div className="stream_item_bg" style={{backgroundImage:"url("+getImgAddress(itemImg)+")"}}>
                <a className="fas fa-play-circle  clickable iconButton ytplayicon relnameicon_stream" href={item.episodeurl} target="_blank" data-uk-tooltip={lng('Tocar')} >{item.episodenum} </a><i className={item.platform.toLowerCase()}></i><br/>
                <a data-uk-icon="icon: reddit" className="clickable iconButton relnameicon_stream" href={redditDiscussionUrl} target="_blank" data-uk-tooltip={lng('Reddit Discussion')} ></a>
                
                <div className="stream_item_name"><span>{item.name}</span></div>
                </div>
                </a>
              </LazyLoadComponent>
            </div> : <></>
            )})
          
          :
          <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
          </div>}
          </div>
        </div>

        {!isMobile.any?
        <div  style={{width:"15%",marginLeft:"15px"}}>
             <ScrollToTopButtom />
        </div>
        :<></>}

      </div>);


    }
}

export default AnimeStreamView;