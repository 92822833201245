
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../../AppPool';
import { lng } from '../../ulanguages/language';
import { isMobile } from '../../util/mobile';
import { getSiteShortName, localeDateTime, onlyLettersAndNumbers } from '../../util/text';
import { isSet } from '../../util/typeu';
import { isServer } from '../../util/windowUtil';
import AnchorBox from '../../view_component/AnchorBox';
import AvatarPicker from '../../view_component/AvatarPicker';
import BannerPicker from '../../view_component/BannerPicker';
import ScrollToTopButtom from '../../view_component/ScrollToTopButton';
import SkeletonBlock from '../../view_component/SkeletonBlock';
import WYSIWYGWriter from '../../view_component/WYSIWYGWriter';
import { genRandId } from '../../view_component/WYSIWYGWriterUtils';
import './UserConfigView.css';


@observer
class UserConfigView extends Component {

  constructor(props) {
      super(props);      
      this.state = {
        editorId:genRandId(),
        malNameValue:""
      };
    }

  componentDidMount()
  {
    
  }
  static async getInitialValues()
  {
  }
    
    render()
    {
        //let baseUrl = ct.server.BASE_URL + ct.server.BASE_PORT;
        let {current} = this.props;
        let {mode} = this.state;

        let userAvatars = MyAppPool.session.user.images.avatar;

        let meta = {title:lng('Usuário')+' | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:''};
        let displayFavorites = [];
        if (current.ready)
        {
          let desc = '';
          meta =  {
            title: ' | '+getSiteShortName()+'',
            desc:desc,
            keywords:desc
          };
          
        }
        let listAnchors = [];
        listAnchors.push({name:lng('Avatar'),length:-1});
        listAnchors.push({name:lng('Banner'),length:-1});
        listAnchors.push({name:lng('Biografia'),length:-1});
        listAnchors.push({name:lng('Sincronizar Conta'),length:-1});
        listAnchors.push({name:lng('Tarefas Assíncronas'),length:-1});

        let mainWidth="60%";
        let mainMarginLeft="15px";
        if (isMobile.any)
        {
          mainWidth="95%";
          mainMarginLeft="2.5%";
        }

        return (<div className="userconfig_view contentArea uk-flex uk-flex-row">
        
        <Helmet>
        <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
        </Helmet>
        
        {!isMobile.any?
        <div style={{width:"17%",marginLeft:"4%"}}>
          {//left collum
          
          }
          <AnchorBox items={listAnchors} title={lng("Configurações/Preferências")}/>
        </div>
        :<></>}

        <div style={{width:mainWidth,marginLeft:mainMarginLeft}}>
          <div className="uk-card uk-card-default filterBox">
          </div>
          {current.ready? 
          <div className="uk-card uk-card-default ">
          <AvatarPicker />
          <BannerPicker />
          <div id={lng('Biografia')} className="uk-card uk-card-default sectionCard">
            <h1 className="sectionName"><i className="fas fa-user"> </i> {lng('Biografia')}</h1>
            <WYSIWYGWriter id={this.state.editorId} hasSendButton={1} 
              placeHolder={lng("Escreva sua folha de rosto!")}
              actions={
                  [
                  'superscript',
                  'link',
                  'image',
                  'youtube',
                  'emoji',
                  'spoiler',
                  'insertOrderedList',
                  'insertUnorderedList'
                  ]} 
                  actionClasses={{
                      insertOrderedList:'list-ol',
                      insertUnorderedList:'list-ul',
                      table:'border-all',
                      spoiler: 'eye-slash',
                      emoji:'grin-squint-tears',
                      youtube:'fab fa-youtube'
                      }}
                  actionTags={{
                      link:'<a ',
                      spoiler:'<span class="spoiler">'
                  }}
                  onCloseClick = {()=>{}}
                  onSendClick = {(content)=>MyAppPool.cUser.updateAuthedUserProfile({bio:content})}
                  startContent = {current.description}
                  />
          </div>

          <div id={onlyLettersAndNumbers(lng('Sincronizar Conta'))} className="uk-card uk-card-default sectionCard">
          <h1 className="sectionName"><i className="fas fa-sync"> </i> {lng('Sincronizar Conta')}</h1>
                <button className="uk-button uk-button-primary" onClick={()=>{
                  MyAppPool.cModal.set(lng('Requisitar Syncronização com')+' MyAnimeList',
                <div>
                  <span>{lng("Insira seu nome de usuário do MyAnimeList Abaixo, ao clicar em Confirmar uma tarefa assincrona será criada, pode levar algumas horas para sincronização completar.")}</span><br/><br/>
                  
                  <input id="configViewMalName" type="text" className="config_malNameInput" placeholder={lng('Nome de Usuário no MyAnimeList')} />
                  <span>{lng("Você pode encontrar seu nome de usuário na URL da página da sua lista de anime, exemplo:")}</span><br/>
                  <span>https://myanimelist.net/animelist/<b>Zezinho</b></span>
                  <br/><br/>
                </div>,()=>{if (!isServer()){//ok function
                  let valDom = document.getElementById("configViewMalName");
                  if (isSet(valDom)){
                    valDom = valDom.value;
                    if (valDom!=='')
                      MyAppPool.cUser.createUserTask("mal sync",{name:valDom});
                    else
                    {
                      MyAppPool.cAlert.notifyError(lng("Você precisa escrever seu nome de usuário para requisitar a sincronização"),'',"mal sync pop up");
                    }
                  }
                }},'',()=>{})
                }}
                >{lng('Requisitar Syncronização com')+' MyAnimeList'}</button>  
          </div>

          <div  id={onlyLettersAndNumbers(lng('Tarefas Assíncronas'))} className="uk-card uk-card-default sectionCard">
          <h1 className="sectionName"><i className="fas fa-tasks"> </i> {lng('Tarefas Assíncronas')}</h1>
          <table style={{width:"100%",textAlign:"left"}}>
            <tr><th>{lng("Tarefa")}</th><th>{lng("Data de Requisição")}</th><th>{lng("Status")}</th></tr>
          
          {current.tasks.map((elem,index)=>{
            let name = lng("_code_"+elem.taskname);
            if (name==='-.-')
              name = elem.taskname;
            let status = lng("_code_"+elem.taskstatus);
            if (status==='-.-')
              status = elem.taskstatus;
            
            return <tr><td>{name}</td><td>{localeDateTime(elem.createtime)}</td><td>{status}</td></tr>
          })} 
          </table>
          </div>

          </div>
          :
          <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
          </div>}
        </div>

        {!isMobile.any?
        <div  style={{width:"15%",marginLeft:"15px"}}>
          <ScrollToTopButtom />
        </div>
        :<></>}

      </div>);


    }
}

export default UserConfigView;