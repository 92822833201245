import React, { Component } from 'react';

class SkeletonBlock extends Component {


  render(){
    return (
    <div {...this.props} className={this.props.className+" skeleton"}></div>);
}
}

export default SkeletonBlock;
