import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { isMobile } from './../util/mobile';
import { getImgAddress } from './../util/text';
import { isSet } from './../util/typeu';
import { getCurrentDomain, getCurrentPageDescription, getCurrentPageTitle, getCurrentUrl, getElementRectAbsolute, isServer, getCurrentUrlUrlEncoded, getCurrentPageTitleUrlEncoded } from './../util/windowUtil';
import ButtonsPanel from './ButtonsPanel';
import "./CapaSection.css";
import CapaWorkLikeButton from './CapaWorkLikeButton';
import Grader from './Grader';
import ListWidget from './ListWidget';
import AvatarPicker from './AvatarPicker';
import BannerPicker from './BannerPicker';



class CapaSectionUserPage extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount()
  {

  }

  render(){

    let {img,ready,resourceType,resourceId,canEdit, lists, listsPresence,gradeable,gradeOptions} = this.props;

    let stickyText = "offset: 30";
    let buttons=[
        {tooltip:lng('Compartilhar no Facebook'),ukicon:'facebook', url:"https://www.facebook.com/share.php?u="+ MyAppPool.currentUrlEncoded }
       ,{tooltip:lng('Compartilhar no Twitter'),ukicon:'twitter', url:"http://twitter.com/share?related="+getCurrentDomain()+"&via=meusanimesbrasili&url="+getCurrentUrlUrlEncoded()+"&text="+getCurrentPageTitleUrlEncoded()+"&hashtags=anime,nani,"+lng('meusanimesbrasili')}
       ,{tooltip:lng('Compartilhar no Reddit'),ukicon:'reddit', url:"http://reddit.com/submit?url="+getCurrentUrlUrlEncoded()+"&title="+getCurrentPageTitleUrlEncoded()}
       ,{tooltip:lng('Compartilhar no Tumblr'),ukicon:'tumblr', url:"http://www.tumblr.com/share/link?url="+getCurrentUrlUrlEncoded()+"&name="+getCurrentPageTitleUrlEncoded()}
       ,{tooltip:lng('Compartilhar'),faicon:'fas fa-share', 
        onClick: ()=>{
          let shareData = {
            url:getCurrentUrl() ,
            text: getCurrentPageTitle()+' - '+getCurrentPageDescription().substr(0,128) + '...',
            title:getCurrentPageTitle()
          };
          if (!isServer())
            if (typeof navigator.share !== 'undefined')
              navigator.share(shareData);
            else
              MyAppPool.cAlert.notifyError(lng("Compartilhar não disponível no seu browser"),shareData,"share button user page");//prompt "share not supported by browser"
        }}
      ];

    let addBtnClasses = 'far fa-plus-square';
    let mySelectedGrade = MyAppPool.session.user.grades[resourceType][resourceId];
    let mySelectedGradeToPrint='';
    if (isSet(mySelectedGrade) && mySelectedGrade!==-1)
    {
      mySelectedGradeToPrint = '('+mySelectedGrade+')';
      addBtnClasses = 'fas fa-check-square comp_searchcard_graded';
    }

    let ratioDiv='ratioDiv'; // class name for divs adjusted by WIDTH
    if (isMobile.any)
    {
      stickyText=undefined;
      ratioDiv='';//not needed in mobile mode, since capa has fixed height in mobile
    }
    //ready=false;
    if (!ready)
    {
        return <div className="capacontainer capaUserPage">
        
            <div className="menuSection" data-uk-sticky={stickyText}>
                <div className="buttons">
                <button aria-label={lng('Adicionar')} className="uk-button uk-button-primary addbutton skeleton"> {lng('Adicionar')} <span data-uk-icon="icon:  triangle-down"></span></button>
                <button aria-label={lng('Favoritar')} className="uk-button uk-button-default lovebutton skeleton"> <span data-uk-icon="icon:  heart"></span></button>
                </div>
            </div>
        </div>;
    }
    return (
        <div className="capacontainer capaUserPage">
        <div className="editBanner" onClick={
        ()=>{
          if (canEdit)
            return MyAppPool.cModal.set(
            '',
            <BannerPicker onSave={()=>MyAppPool.cModal.deactivate()}/>,
            undefined,'',undefined)
        }
        }></div>
        <img className="capa" src={getImgAddress(img)}  alt={lng('Capa')}
        onClick={
        ()=>{
          if (canEdit)
            return MyAppPool.cModal.set(
            '',
            <AvatarPicker onSave={()=>MyAppPool.cModal.deactivate()}/>,
            undefined,'',undefined)
        }}
        /> <br/>
        <div className="menuSection" data-uk-sticky={stickyText}>
        <div className="buttons">
        </div>
        <div style={{marginTop:'5px'}}>
        <ButtonsPanel
        buttons={buttons}/>
        </div>

      </div>
      
    </div>);
}
}

CapaSectionUserPage.propTypes = {
  img: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  resourceType:PropTypes.number.isRequired,
  resourceId:PropTypes.number.isRequired,
  ready: PropTypes.number.isRequired,
  gradeable:PropTypes.bool.isRequired,
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      created: PropTypes.string.isRequired,
      essentialid: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      ordering: PropTypes.number.isRequired,
      resourcetype: PropTypes.number.isRequired,
      listid: PropTypes.number.isRequired,
      itens: PropTypes.arrayOf(
        PropTypes.shape({
          setdate: PropTypes.string.isRequired,
          resourceid: PropTypes.number.isRequired
        }))
    })),
    listsPresence: PropTypes.object.isRequired
};
CapaSectionUserPage.defaultProps = {
  canEdit:false,
  img: '',
  ready:0,
  resourceType:0,
  gradeable:false,
  resourceId:-1,
  lists:[],
  listsPresence:{},
  gradeOptions:{
    minVal: 5,
    maxVal: 10,
    increment: 0.5,
    rgbStart : [153,0,0],
    rgbMiddle : [255, 255, 153],
    rgbEnd: [0, 255, 85]
  }
};

export default CapaSectionUserPage;

/*
 <button className="uk-button uk-button-primary addbutton" onClick={(e)=>{
            var rect = getElementRectAbsolute(e.target);
            MyAppPool.cPopMenu.set(
              <div>
                {gradeable?
                <Grader resourceId={resourceId} resourceType={resourceType} gradeOptions={gradeOptions}>
                  <button className="uk-button uk-button-default searchCloseBtn">{lng('Avaliar')} <i className={addBtnClasses}></i> {mySelectedGradeToPrint}</button>
                </Grader>
            :<></>}
              <ListWidget resourceType={resourceType} resourceId={resourceId} />
              </div>
            ,'',()=>{},rect.left-10,rect.bottom)}
          }> {lng('Adicionar')} <span data-uk-icon="icon:  triangle-down"></span></button>
          
          <div style={{"width":"20%", "display":"inline-block"}}>
          <CapaWorkLikeButton resourceType={resourceType} resourceId={resourceId}/>
          </div>
          */