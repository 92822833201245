import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyAppPool from './../AppPool';
import { isMobile } from './../util/mobile';
import "./BreadCumbs.css";

@observer
class BreadCumbs extends Component {
  componentDidMount()
  {
    //console.log(JSON.stringify(window.history.state));
  }

  render()
  {
    let elemens = MyAppPool.cLocation.getCurrentCrumbs;
    if (isMobile.any)
    {
      return <></>;
    }
    return (
    <ul className="comp_breadcrumb uk-breadcrumb marginbottom0">
      {elemens.map((element, index) => {
        let selectedClass='';
        if (element.url===MyAppPool.session.breadcrumbs.currentPath) 
          selectedClass='selectedBold';
        return <li className="overflow" key={index}><Link className={selectedClass + " overflow"} to={element.url+element.params}>{element.name}</Link></li>
        })
      }
    </ul>);
  }
}

BreadCumbs.propTypes = {
  
};
BreadCumbs.defaultProps = {
};
export default BreadCumbs;
