import React, { Component } from 'react';
import "./SpinnerPulse.css";

class SpinnerPulse extends Component {
  componentDidMount()
  {

  }

  render(){
    return (
    <div className="spinnerPulse">
      </div>);
}
}

export default SpinnerPulse;
