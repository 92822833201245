import { observer } from 'mobx-react';
import React, { Component } from 'react';
import MyAppPool from './../AppPool';
import "./GlobalPageLoadingBar.css";

@observer
class GlobalPageLoadingBar extends Component {

    constructor(props) {
        super(props);
        //this.play = this.play.bind(this);
        this.state = {};
    }
  render(){
      let color = ((MyAppPool.session.globalPageLoadingBar.success)? 'var(--global-loading-bar-success)': 'var(--global-loading-bar-fail)');
    return (
        <div>
            <div className='comp_global_loading_bar'>
                <div className = 'filling' style={{'width':MyAppPool.session.globalPageLoadingBar.currentValue*100 +'%', 'background-color':color}}>

                </div>
                
            </div>
            {/* <button onClick={()=>MyAppPool.cGlobalLoadingBar.start(800)}>butão</button>
            <button onClick={()=>MyAppPool.cGlobalLoadingBar.end(10,true)}>BUTÃO DO FIM DO MUNDO</button>
            <button onClick={()=>MyAppPool.cGlobalLoadingBar.end(10,false)}>BUTÃO DO FIM DO MUNDO ERRADO</button> */}
        </div>
        
    );
  }
}

GlobalPageLoadingBar.propTypes = {

};
GlobalPageLoadingBar.defaultProps = {

};

export default GlobalPageLoadingBar;
