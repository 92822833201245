import { isSet } from "./typeu";
import { isServer } from "./windowUtil";

//===================================================================================
///THIS EVENTS API WILL NOT WORK SERVERSIDE (forceUpdate does not work there)
let events={};
export function subscribeEvent(me,eventName)
{
    if (!isSet(events[eventName]))
        events[eventName]=[];
    
    events[eventName].push(me);
}
export function unsubscribeEvent(me,eventName)
{
    if (!isSet(events[eventName]))
        events[eventName]=[];
    
    events[eventName]=events[eventName].filter((elem) => elem !== me);
}
export function notifyEvent(eventName){
    if (!isSet(events[eventName]))
        events[eventName]=[];
    
    events[eventName].forEach((elem) => {
        console.log(elem, "should update"); 
        elem.forceUpdate();
    
    });
}
//================================================================================

//Being used for SSR
let fetches = [];
export function clearFetches(){
    fetches = [];
};
export function serverRegisterFetch(fetchFunc){
    if (isServer)
        fetches.push(fetchFunc);
};
export function getFetches(){
    return fetches;
}