import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { contentTypeToFastType } from './../util/typeu';
import "./ListCreateWidget.css";

class ListCreateWidget extends Component {

  constructor(props)
  {
    super(props);
    this.state = {resourceTypeHint:{}};
  }
  componentDidMount()
  {
    this.componentWillReceiveProps();
  }
  componentWillReceiveProps()
  {
    let {resourceTypeHint} = this.props;
    this.setState({resourceTypeHint:resourceTypeHint});
    MyAppPool.session.listWidget.type=resourceTypeHint;
  }

  render(){
    let {resourceTypeHint} = this.state;
    return (
        <div className="comp_createlistwidget">
            <input className="inputs input_left" type="text" placeholder={lng('Nome da Lista')}
            onChange={(e) => {MyAppPool.session.listWidget.name=e.target.value;}}></input>
            <select className="inputs input_right" value={''+resourceTypeHint} onChange={(e) => {
                MyAppPool.session.listWidget.type=e.target.value;
                this.setState({resourceTypeHint:e.target.value});
            }}>
                <option value={-1}>{lng('Tipo')}</option>
                <option value={contentTypeToFastType('anime')}>{lng('Anime')}</option>
                <option value={contentTypeToFastType('manga')}>{lng('Manga')}</option>
                <option value={contentTypeToFastType('music')}>{lng('Música')}</option>
                <option value={contentTypeToFastType('character')}>{lng('Personagem')}</option>
                <option value={contentTypeToFastType('producer')}>{lng('Produtora')}</option>
                <option value={contentTypeToFastType('publisher')}>{lng('Editora')}</option>
                <option value={contentTypeToFastType('person')}>{lng('Pessoa')}</option>
            </select>
        </div>
    );
}
}

ListCreateWidget.propTypes = {
  resourceTypeHint:PropTypes.number.isRequired,
};
ListCreateWidget.defaultProps = {
    resourceTypeHint:-1
};

export default ListCreateWidget;
