import React, { Component } from 'react';
import { isSet } from '../util/typeu';
import "./Spinner.css";

class Spinner extends Component {
  componentDidMount()
  {

  }

  render(){
    let style = undefined;
    if (isSet(this.props.color))
    {
      style = {backgroundColor:this.props.color};
     // console.log("COLORRR:::",this.props.color);
    }
    return (
    <div className="spinner">
        <div className="rect1" style={style}></div>
        <div className="rect2" style={style}></div>
        <div className="rect3" style={style}></div>
        <div className="rect4" style={style}></div>
        <div className="rect5" style={style}></div>
      </div>);
}
}

export default Spinner;
