import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import "./ChartVerticalBar.css";

class ChartVerticalBar extends Component {

  constructor(props) {
    super(props);
    this.state = {show: false};
    this.showStats = this.showStats.bind(this);
    this.hideStats = this.hideStats.bind(this);
}

  componentDidMount()
  {

  }
  showStats()
  {
    this.setState({show:true});
  }
  hideStats()
  {
    this.setState({show:false});
  }

  render(){
    let {items,ready,title} = this.props;
    if (!ready)
      return <div className="uk-card uk-card-default comp_verticalbchart skeleton"> </div>
    if (items.length==0)
      return <></>

    let percent = [];
    let maxValue = 0;
    let barPercent = [];
    let totalNumber=0;
    for(let i=0; i< items.length; i++)
    {
      let item = items[i];
      totalNumber += item.value;
      if (item.value>maxValue)
        maxValue = item.value;
    }
    if (totalNumber===0)
      totalNumber=100;
    let maxPercent = maxValue/totalNumber;
    let scaleFactor = 1/maxPercent;

    for(let i=0;i < items.length; i++)
    {
      percent[i] = (items[i].value / totalNumber) * 100;
      barPercent[i] = (items[i].value / totalNumber)*scaleFactor * 90;
      if (barPercent[i]===0)
        barPercent[i]=2;
    }
    //console.log("totalNum: ",totalNumber, "maxValue: ",maxValue)
    let catWidth = 99 / items.length;
    let catMargin = catWidth*0.35;
    catWidth = catWidth - (catMargin*2);


    return (<div onMouseLeave ={()=>this.hideStats()} onMouseOver={()=>this.showStats()} className="uk-card uk-card-default comp_verticalbchart">
    {title===''?lng('Distribuição das Notas'):title}
  <div className="uk-flex uk-flex-row gradesflex">
  {items.map((element, index) => {
            return (
            <div key={index} className="barVerticalContainer" style={{width:catWidth+"%",marginLeft:catMargin+"%",marginRight:catMargin+"%"}}>
              <div style={{height:barPercent[index]+"%",backgroundImage: element.gradient}} data-uk-tooltip={element.value} className="barVertical">
                { this.state.show ? 
                (
                <><div className="uk-overlay uk-position-bottom overlayTextBottom">
                  {element.text}
                </div>
                <div className="uk-overlay uk-position-bottom overlayTextTop">
                  {percent[index].toFixed(0)+"%"}
                </div></>)
                : (<></>)
                }
              </div>
              
            </div>
          )
      })}
      </div>
  </div>);
}
}

ChartVerticalBar.propTypes = {
  ready: PropTypes.number.isRequired,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      gradient: PropTypes.string.isRequired,
    })
  ),
};
ChartVerticalBar.defaultProps = {
  ready:0,
  items:[],
  title:''
};


export default ChartVerticalBar;
