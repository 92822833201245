import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import "./NoRecordsFoundCard.css";

class NoRecordsFoundCard extends Component {
  componentDidMount()
  {

  }

  render(){
      let {text} = this.props;
    return (
      <div className='uk-card uk-card-default comp_norecordsfound' >
          <h2>{text}</h2>
      </div>
  );
}
}

NoRecordsFoundCard.propTypes = {
  text: PropTypes.string.isRequired
};
NoRecordsFoundCard.defaultProps = {
  text:''
};

export default NoRecordsFoundCard;



//