import React, { Component } from 'react';
import "./WYSIWYGButton.css";
import { removeTag, setCurrentCursorPosition, removeTagLoose, addTag, generateImageManagerButtons } from './WYSIWYGWriterUtils';
import Spinner from './Spinner';
import Emojis from './Emojis';
import {lng} from '../ulanguages/language';
import { genRandId } from './WYSIWYGWriterUtils';
import FileSelect from './FileSelect';
/*

*/

class WYSIWYGButton extends Component {

    constructor(props) {
        super(props);
        this.state = {linkToAdd: '', linkToAddText:'',
        imgUrlToAdd:'',uploadingFile:false, uploadError:''};
        this.additionalButtons = this.additionalButtons.bind(this);
        this.emojiClick = this.emojiClick.bind(this);
        this.activateEditor = this.activateEditor.bind(this);
        this.addLink = this.addLink.bind(this);
        this.addImg = this.addImg.bind(this);
        this.handleImgUpload = this.handleImgUpload.bind(this);
        this.addYoutubeVideo = this.addYoutubeVideo.bind(this);
    }


  emojiClick(emoji)
  {
    console.log("emoji clicked class", emoji);
    this.activateEditor();
    document.execCommand('insertHTML', false,emoji);
  }
  btnclick(action,active)
  {
    let {openTagList,currentSelection, myTag,htmlSelectionStart,htmlCaretPosition,editorId} = this.props;
    //let {linkToAdd,linkToAddText} = this.state;
    //console.log(action);
    let text = document.getElementById(editorId).innerHTML;
      switch (action)
      {
        case 'link':
            //this.activateEditor();
            this.setState({linkToAddText: currentSelection});
            if (active)
            {
                text = removeTagLoose(text,htmlCaretPosition,myTag,'</a>');
                document.getElementById(editorId).innerHTML = text;
                this.activateEditor();
            }
        break;
        case 'spoiler':
            console.log('start:',htmlSelectionStart,'end',htmlCaretPosition);
            if (active)
            {   
                console.log('in:',text);
                text = removeTag(text,htmlCaretPosition,myTag);
                document.getElementById(editorId).innerHTML = text;
                this.activateEditor();
                console.log('res:',text);
            }else
            {
                if (htmlSelectionStart!==htmlCaretPosition)
                {
                    console.log('in:',text);
                    text = addTag(text,htmlSelectionStart,htmlCaretPosition,'<span class="spoiler">');
                    document.getElementById(editorId).innerHTML = text;
                    this.activateEditor();
                    console.log('res:',text);
                }
            }
            console.log(openTagList);
            
            //document.execCommand('insertHTML', false, '<span class="spoiler">' + window.getSelection().toString() + '</span>');
        break;
        case 'table':
            document.execCommand('insertHTML', false,'<table><tr><td>Val1</td><td>Val2</td></tr></table>');
            break;
        default:
            document.execCommand(action, false,true);
      }
      //console.log(action);
  };
    activateEditor()
    {
        let {caretPosition,editorId} = this.props;
        setCurrentCursorPosition(caretPosition,editorId);
        if (document.activeElement!==document.getElementById(editorId))
                document.getElementById(editorId).focus();
    }
    addLink()
    {
        let {htmlSelectionStart,htmlCaretPosition,editorId} = this.props;
        let {linkToAdd,linkToAddText} = this.state;
        let text = document.getElementById(editorId).innerHTML;
        let cleanedLink = linkToAdd.replace('http://','https://');
        //if there is a selection, add href
        if (htmlSelectionStart!==htmlCaretPosition)
        {
            //console.log('in:',text);
            text = addTag(text,htmlSelectionStart,htmlCaretPosition,'<a href="'+cleanedLink+'">');
            document.getElementById(editorId).innerHTML = text;
            this.activateEditor();
            //console.log('out:',text);
        }
        else
        {
            this.activateEditor();
            document.execCommand('insertHTML', false, '<a href="'+cleanedLink+'">' + linkToAddText + '</a>');
        }
        this.setState({linkToAddText: ''});
        this.setState({linkToAdd: ''});
    }
    addImg()
    {
        this.activateEditor();
        let cleanedLink = this.state.imgUrlToAdd.replace('http://','https://');
        let imgId = genRandId();
        let upperDivId = 'wrimgup-'+imgId;
        let selfImageId= 'wrimg-'+imgId;
        let btns = generateImageManagerButtons(imgId);
        if (cleanedLink.length>0)
            document.execCommand('insertHTML', false, '<br><div name="'+upperDivId+'" contentEditable="true"><div class="wsyWriterimageWrapper" contentEditable="false">'+btns+'<img name="'+selfImageId+'" src="'+cleanedLink+'"/></div></div><br>');
    }
    addYoutubeVideo()
    {
        let url = this.state.linkToAdd;
        if (url.length===0)
            return;
        url = url.replace(/^\s+/,"").replace(/\s+$/,"");
        let a;
        if (url.indexOf("youtu.be")!==-1) {
            a = url.match(/^http[s]*:\/\/youtu\.be\/([a-z0-9_-]+)/i);
        }else{
            a = url.match(/^http[s]*:\/\/www\.youtube\.com\/watch\?.*?v=([a-z0-9_-]+)/i);
        }
        if (a && a.length===2) 
        {
            var code = a[1];
            this.activateEditor();
            document.execCommand('insertHTML', false, '<div class="youtubeEnclosure"><iframe src="https://www.youtube.com/embed/'+code+'"class="youtubevideo" frameborder="0" scrolling="no"></iframe> </div>');
        }
    }
    closeAllUkDrop()
    {
        let pop = document.getElementsByClassName("uk-drop");
        for(let i=0;i<pop.length;i++)
            pop[i].classList.remove("uk-open");
    }
    handleImgUpload(link)
    {
        this.setState(
            {uploadingFile: false,
            imgUrlToAdd: ''+link},this.addImg);
        this.closeAllUkDrop();
    }

    additionalButtons(active){
    let {htmlSelectionStart,htmlCaretPosition} = this.props;

        if (this.props.action==='youtube' && !active)
        {
        return (
        <div data-uk-drop="pos: top-center; mode:click" className="uk-card uk-card-default linkPop">
        <div  className="uk-align-center linkPanel">
            <input value={this.state.linkToAdd} onChange={(evt)=>this.setState({linkToAdd: evt.target.value})} className="uk-input" type="text" placeholder="Youtube Video URL"/>
            <button aria-label={lng('Adicionar') + " Youtube"} onClick={this.addYoutubeVideo} className="uk-button uk-button-default uk-drop-close" > {lng('Adicionar')}</button>
        </div>
        </div>);
        }

        if (this.props.action==='link' && !active)
        {
        return (
        <div data-uk-drop="pos: top-center; mode:click" className="uk-card uk-card-default linkPop">
        <div  className="uk-align-center linkPanel">
            {
            (htmlSelectionStart===htmlCaretPosition) ?
            <input value={this.state.linkToAddText} onChange={(evt)=>this.setState({linkToAddText: evt.target.value})} className="uk-input" type="text" placeholder="Texto"/> 
            : <></>
            }
            <input value={this.state.linkToAdd} onChange={(evt)=>this.setState({linkToAdd: evt.target.value})} className="uk-input" type="text" placeholder="Link URL"/>
            <button onClick={this.addLink} className="uk-button uk-button-default uk-drop-close" > {lng('Adicionar')}</button>
        </div>
        </div>);
        }

        if (this.props.action==='image')
        {
        return (
        <div data-uk-drop="pos: top-center; mode:click" className="uk-card uk-card-default imagePop">
        <div  className="uk-align-center imagePanel">
            <FileSelect purpose='thread' text={lng("Carregar do Dispositivo")} onUpload={this.handleImgUpload}/>
            <div>{this.state.uploadError}</div>
            <br/>{lng('ou')}
            <input value={this.state.imgUrlToAdd} onChange={(evt)=>this.setState({imgUrlToAdd: evt.target.value})} className="uk-input" type="text" placeholder="URL da Imagem"/>
            <button aria-label={lng('Adicionar') + " "+ lng("image")} onClick={this.addImg} className="uk-button uk-button-default uk-drop-close" >{lng('Adicionar')}</button>
        </div>
        </div>);
        }

        if (this.props.action==='emoji')
        {
        return (
        <div data-uk-drop="pos: top-center; mode:click" className="uk-card uk-card-default emojisPop">
        <div  className="uk-align-center emojisPanel">
            {Emojis.map( (value, index) => {
            return <span onClick={()=>this.emojiClick(value)} type="button" key={index} className= {"emoji clickable uk-drop-close"}>{value} </span>
            } )}
        </div>
        </div>);
        }
        return (<></>);
    }

    detectTag(openTags, myTag)
    {
        for(let i=0;i<openTags.length;i++)
        if (openTags[i].indexOf(myTag)!==-1)
            return true;
        return false;
    }

    render(){
    let {activeList,openTagList, action, iconClass,myTag} = this.props;
    let activeClass = "";
    let active = false;
    //console.log(activeList);
    if (activeList.indexOf(action)!==-1 || this.detectTag(openTagList,myTag))
        active=true;
    if (active)
        activeClass ='btnactive';

    let fullIconClass = iconClass;
    if (iconClass.indexOf('fa-')===-1)
        fullIconClass = 'fas fa-'+iconClass;
    return (
        <span data-uk-tooltip={lng(action)}>
            <button aria-label={lng(action)} onClick={()=>this.btnclick(action,active)} className={"wsyButton iconButton clickable "+activeClass} onMouseDown={(e)=>e.preventDefault()}>
            <span className={fullIconClass}/> 
            </button>
            {this.additionalButtons(active)}
        </span>

    );
    }
}

export default WYSIWYGButton;
