import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import "./ChartCategoryHorizontal.css";

class ChartCategoryHorizontal extends Component {
  componentDidMount()
  {

  }

  render(){
    let {items,ready,title} = this.props;
    if (!ready)
      return (<div className="uk-card uk-card-default comp_classhchart skeleton"></div>);
    if (items.length==0)
      return <></>;
    
    let percent = [];
    let percentBar = [];
    let totalNumber=0;
    for(let i=0; i< items.length; i++)
    {
      let item = items[i];
      totalNumber += item.value;
    }
    if (totalNumber>0)
    {
      for(let i=0;i < items.length; i++){
        percent[i] = (items[i].value / totalNumber) * 100;
        percentBar[i] = percent[i]+1;
      }
    }
    else
    {
      for(let i=0;i < items.length; i++)
      {
        percent[i] = 0;
        percentBar[i] = 100 / items.length ;
      }
    }

    let catWidth = 99 / items.length;
    let catMargin = catWidth*0.05;
    catWidth = catWidth - (catMargin*2);

    return (
    <div className="uk-card uk-card-default comp_classhchart">
    {title===''?lng('Usuários'):title}
    <div className="uk-flex uk-flex-row statsflex">
    {items.map((element, index) => {
            return (//"Quer" 
            <div key={index} style={{width:catWidth+"%", marginLeft:catMargin+"%", marginRight:catMargin+"%"}} className="statColumn"><div className="userStat" style={{backgroundImage:element.color}}> {element.text}</div>{element.value}</div>
            )
          })}
    </div>
    <div className="uk-flex uk-flex-row statsbar">
      {
        items.map((element, index) => {
          return (//"Quer"
            <div key = {index} style={{width:percentBar[index]+"%",backgroundImage:element.color}} data-uk-tooltip={element.text+ " "+percent[index].toFixed(1)+"%"} className="statbar"/>)
        })
      }
    </div>
    <div>
  </div>
</div>);
}
}

ChartCategoryHorizontal.propTypes = {
  ready: PropTypes.number.isRequired,
  title:PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      
    })
  ),
};
ChartCategoryHorizontal.defaultProps = {
  items: [],
  ready:0,
  title:'',
};

export default ChartCategoryHorizontal;
