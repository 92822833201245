
import { computed, toJS } from 'mobx';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { getCharacterAnimeRoute, getCharacterMangaRoute, getCharacterRoute, getObraRoute, getPersonRoute } from '../store/AppRoutes';
import { getFlag, getRelatedTypeIcon } from '../util/icon';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { getAnchorName, getSEOUriPath } from '../util/text';
import { contentTypeToFastType, isValidIndex, isSet, personFastResourceType } from '../util/typeu';
import { isServer } from '../util/windowUtil';




export default class CharacterController
{
    fetchCharacterById(index)
    {
        let loadId;
        if (!isSet(index) || !isValidIndex(index))
            return Promise.resolve(true);
        if (Number(index)===Number(MyAppPool.session.character.currentCharacter))
        {   
            loadId = MyAppPool.cGlobalLoadingBar.start();
            MyAppPool.cGlobalLoadingBar.end(loadId,true);
            return Promise.resolve(true);
        }
        
        MyAppPool.session.character.characters[index] = {ready:0};
        MyAppPool.session.character.currentCharacter= index;
        loadId = MyAppPool.cGlobalLoadingBar.start(1500);
        if (!isServer())
            brasRequest(routes.resourcePopularCount,false,{resourceType:contentTypeToFastType('character'),resourceId:index});
      
        return fetchAndProcess(this.setSessionCharacter,
            routes.getCharacterByIdLng,true,{cid:index,lng: getCurrentLanguage()})
            .then((data) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,true);
                let character = MyAppPool.session.character.characters[MyAppPool.session.character.currentCharacter];
                MyAppPool.cAffiliate.getCharacterAmazonAffiliate(character);
                return Promise.resolve(data);
            })
            .catch((e) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,false);
                MyAppPool.cAlert.notifyGenericError(JSON.stringify(e),"fetch character");
                //console.log("Error Fetching character",e)
            });
    }

    setSessionCharacter(data0,ready)
    {
        if (!data0.ok)
            return Promise.reject();
        
        let data = data0.data;
        if (!data.empty)
        {     
            data.fullName = data.name + ' ' + data.familyname;
            data.otherTitles = '';
            for (let i=0; i < data.altnames.length;i++)
            {
                data.otherTitles += data.altnames[i].name
                if (i<(data.altnames.length-1))
                    data.otherTitles+=", ";
            }
            if (data.namejp!=='' && isSet(data.namejp))
                data.otherTitles+=", "+data.namejp;

            data.columItems = [];
            data.spoilers = "";
            for(let i=0; i< data.extra.length; i++)
            {
                let item = data.extra[i];
                let content = {};
                if (item.type==='url')
                {
                    let displayVal = item.mvalue;
                    displayVal = displayVal.replace('/',' ');
                    content =  {name:item.mkey,info:[{type:item.type,value:item.url,display:displayVal}]};
                }
                else
                    content =  {name:item.mkey,info:[{type:item.type,value:item.mvalue,display:item.mvalue}]};
                if (item.mkey.indexOf('Spoiler')!==-1)
                {
                    data.spoilers+=item.mvalue + " \n ";
                }
                else
                    data.columItems[data.columItems.length]=content; 
            }
            let seoUriName = getSEOUriPath(data.name+ ' ' + data.familyname);

            let relVoiceActors = [];
            
            for(let k =0; k<data.voiceactors.length; k++)
            {
                let ele2 = data.voiceactors[k];
                relVoiceActors[k] = 
                {imgurl:ele2.mainimg, 
                url: getPersonRoute (ele2.personid,getSEOUriPath(ele2.name + ' ' + ele2.familyname),'#top'),
                title:ele2.name + ' ' + ele2.familyname,
                icon:getFlag(ele2.natcode),
                iconText:lng('Língua ') + ele2.nationality,
                resourceType:personFastResourceType,
                resourceId:ele2.personid
                }
            }
            
            data.relVoiceActors = relVoiceActors;
           

           
            
            let relatedAnimeTypes = {};
            for(let i =0; i<data.anime.length; i++)
            {
                let element = data.anime[i];
                let anchor = '#'+getAnchorName(element.title);
                element.url=getCharacterAnimeRoute(data.id,seoUriName,anchor);
                element.directUrl=getObraRoute(element.id,getSEOUriPath(element.title),element.pagetype,'#top');
                element.imgurl=element.mainimg;
                element.icon=getRelatedTypeIcon(element.type);
                element.iconText= lng(element.type);
                element.resourceType=contentTypeToFastType(element.pagetype);
                element.resourceId=element.id;

                relatedAnimeTypes[element.type]=1;
            }
            relatedAnimeTypes = Object.keys(relatedAnimeTypes);

            let relatedMangaTypes = {};
            for(let i =0; i<data.manga.length; i++)
            {
                let element = data.manga[i];
                let anchor = '#'+getAnchorName(element.title);
                element.url=getCharacterMangaRoute(data.id,seoUriName,anchor);
                element.directUrl=getObraRoute(element.id,getSEOUriPath(element.title),element.pagetype,'#top');
                element.imgurl=element.mainimg;
                element.icon=getRelatedTypeIcon(element.type);
                element.iconText= lng(element.type);
                element.resourceType=contentTypeToFastType(element.pagetype);
                element.resourceId=element.id;
                relatedMangaTypes[element.type]=1;
            }
            relatedMangaTypes = Object.keys(relatedMangaTypes);
            
            data.relatedAnimeTypes = relatedAnimeTypes;
            data.relatedMangaTypes = relatedMangaTypes;
            data.relVoiceActors = relVoiceActors;
            data.baseUrl = getCharacterRoute(data.id,seoUriName);
            data.resourceType = contentTypeToFastType('character');
            data.resourceId=data.id;
        }
        //console.log(data);
        MyAppPool.session.character.characters[data.id] = {...MyAppPool.session.character.characters[data.id],...data} ;
        MyAppPool.session.character.characters[data.id].ready = ready?1:0;
        //console.log(data);
        
        MyAppPool.session.character.currentCharacter= data.id;


        MyAppPool.cPageJsonSchema.makeCharacterJsonSchema();

        return Promise.resolve(true);
    }

    @computed get currentCharacter()
    {
        let data = toJS(MyAppPool.session.character.characters[MyAppPool.session.character.currentCharacter]);
        if (!isSet(data))
            return {ready:0};
        return data;//MyAppPool.session.character.characters[MyAppPool.session.character.currentCharacter];
    }
    
}