
import { getViewportWidthHeight, isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { isMobile } from '../util/mobile';

export default class PopMenu
{
    set(content,className='',cleanupFunction=()=>console.log('closing modal'), xArg=-1,yArg=-1)
    {
        let mousePosition = {x:0,y:0,viewX:0,viewY:0};
        let viewPort =  getViewportWidthHeight();
        let openinginLeftPos = ((viewPort.width/2)+(viewPort.width/6));
        if (isMobile.any)
            openinginLeftPos = ((viewPort.width/2)+(viewPort.width/10));
        if (!isServer())
            mousePosition = window.mousePosition;
        let useBottom=false;
        if (xArg!==-1 && yArg!==-1)
        {
            mousePosition.x =  xArg;
            mousePosition.y =  yArg;
        }else
        {
            if ((mousePosition.x-window.scrollX)>openinginLeftPos)
                mousePosition.x-=220;
            //if ((mousePosition.x-window.scrollY)>((viewPort.height/2)+(viewPort.height/6)))
            //    mousePosition.y-=220;
        }
       
        let key="";

        try{
            key = JSON.stringify(content.props);
        }catch(err)
        {
            let localProps = {...content.props.__self.props};
            localProps.__self=undefined;
            localProps.__source=undefined;
            try{
                key = JSON.stringify(localProps);
            }catch(err)
            {
                try{
                    key=JSON.stringify(content.props.children[0].props);
                }catch(err)
                {
                    try{
                        key=JSON.stringify(content.props.children.length);
                    }catch(err)
                    {
                        key=''+content.props.length;
                    }
                }
            }
        }
        //console.log(key);
        if (MyAppPool.jsObjectStore.popMenu.active===1)
            if (key===MyAppPool.jsObjectStore.popMenu.key)
                return this.deactivate();
        
        //console.log("1:   ",JSON.stringify(MyAppPool.jsObjectStore.popMenu.content.props),"2:   ", JSON.stringify(content.props));
        MyAppPool.jsObjectStore.popMenu = {
            content,
            x:mousePosition.x+10,
            y:mousePosition.y-10,
            useBottom:true,
            active:1,
            className,
            cleanupFunction,
            key
        }
    }
    deactivate()
    {
        MyAppPool.jsObjectStore.popMenu.active=0;
    }
}