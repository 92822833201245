import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { contentTypeToFastType } from '../util/typeu';
import MyAppPool from './../AppPool';
import "./MusicItem.css";
import ListWidget from './ListWidget';
import FormMusicUpdateVideo from './FormMusicUpdateVideo';
import LinkV2 from './LinkV2';
import { getMusicRoute } from '../store/AppRoutes';

function themeGetType(type)
{
  if (type===0)
    return 'op';
  if (type===1)
    return 'ed';
  return '';
}
class MusicItem extends Component {

    constructor(props) {
        super(props);
        this.play = this.play.bind(this);
        this.addToCurrentPlayList = this.addToCurrentPlayList.bind(this);
        this.state = {hover:false};
    }

  addToCurrentPlayList()
  {
    MyAppPool.cMusic.addToPlayQueue(this.props.music);
    MyAppPool.cPopMenu.deactivate();
  }

  play()
  {
      MyAppPool.cMusic.addToPlayQueue(this.props.music);
      MyAppPool.cMusic.playMusicObject(this.props.music);
  }

  render(){
    let {music} = this.props;
    let resourceType = contentTypeToFastType('music');

    let showPlayButtons = false;
    if (music.ytid!=='')
        showPlayButtons=true;
    let extraInfo = themeGetType(music.type) + ' ' +music.where;
    if (extraInfo!==' ')
      extraInfo = '('+extraInfo+')'

    let showChangeOrderButton = this.props.showBars;


    return (
        <li className={"comp_musicitem"}>
          <div onMouseLeave={()=>this.setState({hover:false})} onMouseOver={()=>this.setState({hover:true})} className="uk-flex uk-flex-row">
            {showChangeOrderButton?
                <div id="ytmusicchangeorderanchor"  className="fas fa-bars ytanchoricon"></div>
                :<></>
            }
            <div className="musicdata">
            <LinkV2 to={getMusicRoute(music.id,music.title+"-"+music.band,'#top')} className={"ytmusicname"}>{music.title + ' ' + extraInfo}</LinkV2>
              <p className={"ytsinger"}>{music.band}</p>
            </div>
            {showPlayButtons?
                <span onClick={this.play} data-uk-tooltip={lng('Tocar')} className="fas fa-play-circle ytplayicon"></span>
                :<></>
            }
            {(showPlayButtons || MyAppPool.cAuth.isDataMod())?
                <div id="ytmusicmenubutton" className="fas fa-ellipsis-v ytmenuicon" 
                onClick={(e)=>{
                  MyAppPool.cPopMenu.set(
                    <div>
                      <ul className="pop_ytmenulist">
                        {MyAppPool.cAuth.isDataMod()?[<li className="pop_ytmenuitem"onClick={()=>{
                        MyAppPool.cModal.set(lng("Atualizar Video"),
                        <FormMusicUpdateVideo musicId={music.id}/>
                        ,null,'',()=>{})
                        }}
                        >{lng("Atualizar Video")}</li>
                      ]:<></>}

                        <li className="pop_ytmenuitem" onClick={this.addToCurrentPlayList}>
                          <span className={"fas pop_ytmenuitemicon fa-music"} ></span><div className="ytmenuoptiontext">{lng("Adicionar à fila de reprodução")}</div>
                        </li>
                    </ul>
                    <ListWidget resourceType={resourceType} resourceId={music.id}/>
                    </div>
                  ,'')}}
                >
                </div>
                
              :<></> 
            }
          </div>
         </li>
    

    );

  }
}

MusicItem.propTypes = {
  music: PropTypes.shape({
    title: PropTypes.string.isRequired,
    band: PropTypes.string.isRequired,
    where: PropTypes.string.isRequired,
    ytid: PropTypes.string.isRequired,
    id:PropTypes.number.isRequired}),
  showBars: PropTypes.bool.isRequired
};
MusicItem.defaultProps = {
  music:{
    title: '',
    band: '',
    where: '',
    ytid: '',
    id:0
  },
  showBars: false

};

export default MusicItem;
