import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyAppPool from './../AppPool';
import { isMobile } from './../util/mobile';
import "./DonatorBar.css";
import { getRgbRanges } from '../util/gradient';
import { toJS } from 'mobx';
import HoverDiv from './HoverDiv';
import renderHTML from 'react-render-html';
import { lng } from '../ulanguages/language';
import LinkV2 from './LinkV2';
import { getSupportPage } from '../store/AppRoutes';
 
@observer
class DonatorBar extends Component {
    constructor(props)
    {
        super(props);
        this.state = {};
    }
  componentDidMount()
  {
    
  }
  render()
  {
    if (MyAppPool.session.campaign.loaded===false)
        return <></>;
    let amountOfGoals = MyAppPool.session.campaign.goals.length;
    let colors2 = [];
    let colors = getRgbRanges([[153,0,0],[153,0,0],[255, 153, 51],[153, 153, 0],[0, 255, 85]],amountOfGoals);
    let gradients = [];
    for(let i=0;i<colors.length;i++)
    {
      gradients[i] =`linear-gradient(to right, rgb(${colors[i].start[0]}, ${colors[i].start[1]}, ${colors[i].start[2]}),
      rgb(${colors[i].end[0]}, ${colors[i].end[1]}, ${colors[i].end[2]}) )`;
      colors2[i] = `rgb(${colors[i].end[0]}, ${colors[i].end[1]}, ${colors[i].end[2]})`;
    }
    let max=0;
    if (amountOfGoals>0)
    {
        max = MyAppPool.session.campaign.goals[amountOfGoals-1].value;
    }
    let minWidth = 400;
    if (isMobile.any)
        minWidth = 250;
    
    let progress = (MyAppPool.session.campaign.money/max)*100;
    let metaAtual = 0;
    
    return (
        <div className="comp_donatorbar">
            <div className="barWidth">
            <div className="barDimension">
            <div className="donatorBackground">
            {gradients.map((elem,index) =>{
                let previousVal = 0;
                if (index>0)
                    previousVal = MyAppPool.session.campaign.goals[index-1].value;

                if (MyAppPool.session.campaign.goals[index].value > MyAppPool.session.campaign.money && metaAtual===0)
                    metaAtual=MyAppPool.session.campaign.goals[index].value;

                let myval = MyAppPool.session.campaign.goals[index].value - previousVal;
                let width = 100*(myval)/max;
                return <HoverDiv minWidth={minWidth} key={index} className="donatorBar" style={{width:width+"%",backgroundImage:elem}}
                ><span >
                    {MyAppPool.session.campaign.goals[index].title}
                    <div>{lng("Meta Financeira")}:{MyAppPool.session.campaign.goals[index].value} {MyAppPool.session.campaign.currency}</div>
                    <div>{lng("Atingida")}:{MyAppPool.session.campaign.goals[index].value<MyAppPool.session.campaign.money? lng("SIM"):lng("NÃO")}</div>
                    <div>{renderHTML(MyAppPool.session.campaign.goals[index].desc)}</div>
                </span>{}</HoverDiv>;
            })}
            <div className="progressBar" style={{width:progress+"%"}}></div>
            </div>
            </div>
            <div style={{overflow:"hidden", height:"20px"}}>
            {isMobile.any?<></>:<span>
                {lng("Usuários Apoiadores")}: {MyAppPool.session.campaign.users} /
                {" "+ lng("Meta Atual")}: {metaAtual} {MyAppPool.session.campaign.currency}/{lng("Mês")}
            </span>}
            {" "+lng("Arrecadação")}: {MyAppPool.session.campaign.money} {MyAppPool.session.campaign.currency}/{lng("Mês")} /
            <LinkV2 to={getSupportPage()}>{lng("Saiba Mais")}</LinkV2>
            </div>
            
            </div>
            <div style ={{width:"20%"}}>
                <a target="_blank" href={MyAppPool.session.campaign.url} style ={{width:"100%"}} className="uk-button uk-button-green">{lng("Apoie")}!</a>
            </div>
            
        </div>);
  }
}

DonatorBar.propTypes = {
    title: PropTypes.string.isRequired,
    height: PropTypes.number,
    startOpen:PropTypes.number,
};
DonatorBar.defaultProps = {
    title:'',
    height:338,
    startOpen:1
};

export default DonatorBar;
