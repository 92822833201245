import loadable from "@loadable/component";
import { lng } from "../ulanguages/language";
import { getSEOUriPath } from "../util/text";

import { contentFastTypeToType, contentTypeToFastType } from "../util/typeu";
import '../view/allContentPage.css';
//Paginas com dados devem carregar imediatamente
import Home from "../view/home";
import Anime from "../view/anime";
import Manga from "../view/manga";
import Company from './../view/company';
import Publisher from './../view/publisher';
import Person from './../view/person';
import Character from './../view/character';
import User from "../view/user";
import ApoienosView from "../view/apoienos";
import RecentTrailerView from "../view/recentTrailers";
import TopsAnime from "../view/topAnime";
import SearchUser from "../view/searchUser";
import SearchMusic from "../view/searchMusic";
import BroadcastCalendar from "../view/broadcastcalendar";
import Simulcast from "../view/simulcast";
import Music from "../view/music";

//Paginas sem dados /suporte devem carregar lazy
const Login = loadable(() => import(/* webpackChunkName: "view-login" */ '../view/login'));
const RecoverAccount = loadable(() => import(/* webpackChunkName: "view-recoverAccount" */ '../view/recoverAccount'));
const ConfirmRegisterAccount = loadable(() => import(/* webpackChunkName: "view-confirmAccount" */ '../view/confirmRegister'));
const TermsOfUse = loadable(() => import(/* webpackChunkName: "view-termsOfUse" */ '../view/termsOfUse'));
const TermsOfPrivacy = loadable(() => import(/* webpackChunkName: "view-termsOfPrivacy" */ '../view/termsOfPrivacy'));
const TermsOfCookies = loadable(() => import(/* webpackChunkName: "view-termsOfCookies" */ '../view/termsOfCookies'));
const SearchAnime = loadable(() => import(/* webpackChunkName: "view-searchAnime" */ '../view/searchAnime'));
const SearchManga = loadable(() => import(/* webpackChunkName: "view-searchManga" */ '../view/searchManga'));
const SearchPerson = loadable(() => import(/* webpackChunkName: "view-searchPerson" */ '../view/searchPerson'));
const SearchCharacter = loadable(() => import(/* webpackChunkName: "view-searchCharacter" */ '../view/searchCharacter'));
const SearchCompany = loadable(() => import(/* webpackChunkName: "view-searchCompany" */ '../view/searchCompany'));
const SearchPublisher = loadable(() => import(/* webpackChunkName: "view-searchPublisher" */ '../view/searchPublisher'));

/*
function validPath(path)
{
    let ob = paths[path];
    if (typeof ob === 'undefined')
    {   
        console.error ("ONE OF YOUR VIEWS HAS AN INCORRECT PATH! Erroring Path: "+ path);
        return "";
    }
    return ob;
}*/


export function getLoginRoute(lang='')
{
    return '/'+lng('entrar',false,lang);
}
export function getRecoverAccountRoute(lang='')
{
    return '/'+lng('recuperar',false,lang);
}
export function getConfirmAccountRoute(lang='')
{
    return '/'+lng('confirmar',false,lang);
}

export function getCharacterRoute(id,name,anchor='',lang='')
{
    return '/'+lng('personagem',false,lang)+'/'+id+'/'+getSEOUriPath (name)+anchor;
}



export function getPersonRoute(id,name,anchor='',lang='')
{
    return '/'+lng('pessoa',false,lang)+'/'+id+'/'+getSEOUriPath (name)+anchor;
}
export function getTrailersRoute(anchor='',lang='')
{
    return '/trailers'+anchor;
}
export function getBroadCastRoute(anchor='',lang='')
{
    return '/'+lng('_programacao')+anchor;
}
export function getTopsRoute(type='anime',query='',anchor='')
{
    return '/top/'+type+query+anchor;
}
export function getSimulcastRoute(query='',anchor='')
{
    return '/simulcast/'+query+anchor;
}

export function getObraRoute(id,name,pagetype,anchor='',lang='')
{
    if (pagetype==='manga')
        return '/'+lng('manga',false,lang)+'/'+id+'/'+getSEOUriPath (name)+anchor;
    if (pagetype==='anime')
        return '/'+lng('anime',false,lang)+'/'+id+'/'+getSEOUriPath (name)+anchor;
}

export function getAnimePersonagensRoute(id,name,anchor='',lang='')
{
    return '/anime/'+id+'/'+getSEOUriPath(name)+'/'+lng('personagens',false,lang) + anchor;
}
export function getAnimePeopleRoute(id,name,anchor='',lang='')
{
    return '/anime/'+id+'/'+getSEOUriPath(name)+'/'+lng('autores',false,lang)+ anchor;
}
export function getAnimeRelatedRoute(id,name,anchor='',lang='')
{
    return '/anime/'+id+'/'+getSEOUriPath(name)+'/'+lng('obras-relacionadas',false,lang)+ anchor;
}

export function getUserRoute(id,name,anchor='',lang='')
{
    return '/'+lng('usuario',false,lang)+'/'+id+'/'+getSEOUriPath(name)+anchor;
}
export function getUserListRoute(id,name,anchor='',lang='')
{
    return '/'+lng('usuario',false,lang)+'/'+id+'/'+getSEOUriPath (name)+'/'+lng('listas',false,lang)+anchor;
}
export function getUserFavoriteRoute(id,name,anchor='',lang='')
{
    return '/'+lng('usuario',false,lang)+'/'+id+'/'+getSEOUriPath (name)+'/'+lng('favoritos',false,lang)+anchor;
}
export function getUserConfigRoute(id,name,anchor='',lang='')
{
    return '/'+lng('usuario',false,lang)+'/'+id+'/'+getSEOUriPath (name)+'/'+lng('configuracoes',false,lang)+anchor;
}

export function getAnimeSearchRoute(data)
{
    let args = '';
    let vals = Object.entries(data);
    for (const [key, value] of vals) {
    args += `${key}=${value}&`
    }
    args+='e=y'
    return `/anime?${args}`;
}
export function getCompanyRoute(id,name,anchor='',lang='')
{
    return '/'+lng('empresa',false,lang)+'/'+id+'/'+getSEOUriPath(name)+anchor;
}
export function getMagazineRoute(id,name,anchor='',lang='')
{
    return '/'+lng('revista',false,lang)+'/'+id+'/'+getSEOUriPath(name)+anchor;
}
export function getMangaPeopleRoute(id,name,anchor='',lang='')
{
    return '/manga/'+id+'/'+getSEOUriPath(name)+'/'+lng('autores',false,lang)+ anchor;
}
export function getMangaPersonagensRoute(id,name,anchor='',lang='')
{
    return '/manga/'+id+'/'+getSEOUriPath(name)+'/'+lng('personagens',false,lang) + anchor;
}
export function getMangaRelatedRoute(id,name,anchor='',lang='')
{
    return '/manga/'+id+'/'+getSEOUriPath(name)+'/'+lng('obras-relacionadas',false,lang)+ anchor;
}
export function getMangaSearchRoute(data)
{
    let args = '';
    let vals = Object.entries(data);
    for (const [key, value] of vals) {
    args += `${key}=${value}&`
    }
    args+='e=y'
    return `/manga?${args}`;
}
export function getPersonPersonagensRoute(id,name,anchor='',lang='')
{
    return '/'+lng('pessoa',false,lang)+'/'+id+'/'+getSEOUriPath(name)+'/'+lng('personagens',false,lang) + anchor;
}
export function getPersonAnimeRoute(id,name,anchor='',lang='')
{
    return '/'+lng('pessoa',false,lang)+'/'+id+'/'+getSEOUriPath(name)+'/'+lng('anime',false,lang)+ anchor;
}
export function getPersonMangaRoute(id,name,anchor='',lang='')
{
    return '/'+lng('pessoa',false,lang)+'/'+id+'/'+getSEOUriPath(name)+'/'+lng('manga',false,lang)+ anchor;
}

export function getCharacterAnimeRoute(id,name,anchor='',lang='')
{
    return '/'+lng('personagem',false,lang)+'/'+id+'/'+getSEOUriPath(name)+'/'+lng('anime',false,lang)+ anchor;
}
export function getMusicAnimeRoute(id,name,anchor='',lang='')
{
    return '/'+lng('music',false,lang)+'/'+id+'/'+getSEOUriPath(name)+'/'+lng('anime',false,lang)+ anchor;
}
export function getMusicRoute(id,name,anchor='',lang='')
{
    return '/'+lng('music',false,lang)+'/'+id+'/'+getSEOUriPath(name)+ anchor;
}
export function getCharacterMangaRoute(id,name,anchor='',lang='')
{
    return '/'+lng('personagem',false,lang)+'/'+id+'/'+getSEOUriPath(name)+'/'+lng('manga',false,lang)+ anchor;
}
export function getSearchRoute(resourceTypeName,params='',lang='')
{
    return '/'+lng('buscar',false,lang)+'/'+lng(resourceTypeName,false,lang)+params;
}

export function getSupportPage(anchor='#top',lang='')
{
    return '/'+lng('apoie-nos',false,lang)+anchor;
}

export function getResourceRoute(resourceType,resourceId,resourceTitle, anchor='')
{
    //console.log("RESOURCE TYPE URL:::",resourceType);
    //console.log("RESOURCE TYPE URLVS:::",contentTypeToFastType('person'));
    if (resourceType===0)//anime
        return getObraRoute(resourceId,resourceTitle,'anime', anchor);
    if (resourceType===1)//manga
        return getObraRoute(resourceId,resourceTitle,'manga', anchor);
    if (resourceType===contentTypeToFastType('character'))
        return getCharacterRoute(resourceId,resourceTitle, anchor);
    if (resourceType===contentTypeToFastType('person'))
        return getPersonRoute(resourceId,resourceTitle, anchor);
    if (resourceType===contentTypeToFastType('producer'))
        return getCompanyRoute(resourceId,resourceTitle, anchor);
    if (resourceType===contentTypeToFastType('publisher'))
        return getMagazineRoute(resourceId,resourceTitle, anchor);
    if (resourceType===contentTypeToFastType('user'))
        return getUserRoute(resourceId,resourceTitle, anchor);
    if (resourceType===contentTypeToFastType('music'))
        return getMusicRoute(resourceId,resourceTitle, anchor);
    return '';
}
/*
pLink(String path, Object params)
@path: a url with parameters to be replaced, parameters are in the form of 
:paramName, e.g contractor/:id/:country or contractor?id=:id&country=:country
@params: a js object with fields that match the path parameters,
e.g {id:554,country:'Brazil'}
usage: pLink('contractor/:id/:country',{id:554,country:'Brazil'})
returns contractor/554/Brazil
*/
export function pLink(path, params){
    let tpath = path;
    var paramCount = (tpath.match(/:/g) || []).length;

    if (paramCount<=0)
        return path;
    
    let ret = '';
    let argument = '';
    let pos = 0;
    pos = tpath.indexOf(':');
    ret = tpath;

    for (let i=0;i<paramCount;i++)
    {
        pos = tpath.indexOf(':');
        tpath = tpath.substr(pos);
        pos = tpath.indexOf('/');
        if (pos<=-1)
            pos = tpath.indexOf('&');
        if (pos<=-1)
            pos = undefined;
        argument =  tpath.substr(0,pos);
       // console.log(argument);
        ret = ret.replace(argument,encodeURIComponent(params[argument.substr(1)]));
        tpath = tpath.substr(pos+1);
    }
    return ret;
}

export function jumpTo(e,reactComponent,path)
{
    e.preventDefault();
    reactComponent.props.history.push(path)
    return false;
}

export var views = [
    {exact:true, realPath:"/",component:Home},
    {exact:false, realPath:getObraRoute(':id',':name','manga'),component:Manga},
    {exact:false, realPath:getObraRoute(':id',':name','anime'),component:Anime},
    {exact:false, realPath:getUserRoute(':id',':name',''),component:User},
    {exact:false, realPath:getCompanyRoute(':id',':name',''),component:Company},
    {exact:false, realPath:getMagazineRoute(':id',':name',''),component:Publisher},
    {exact:false, realPath:getPersonRoute(':id',':name',''),component:Person},
    {exact:false, realPath:getCharacterRoute(':id',':name',''),component:Character},
    {exact:true, realPath: getLoginRoute(),component: Login},
    {exact:true, realPath: getRecoverAccountRoute(),component: RecoverAccount},
    {exact:true, realPath: getConfirmAccountRoute(),component: ConfirmRegisterAccount},    
    {exact:false, realPath:getSearchRoute('anime'),component:SearchAnime, baseProps:{resourceType:'anime'}},
    {exact:false, realPath:getSearchRoute('manga'),component:SearchManga, baseProps:{resourceType:'manga'}},
    {exact:false, realPath:getSearchRoute('pessoa'),component:SearchPerson},
    {exact:false, realPath:getSearchRoute('music'),component:SearchMusic},
    {exact:false, realPath:getSearchRoute('personagem'),component:SearchCharacter},
    {exact:false, realPath:getSearchRoute('empresa'),component:SearchCompany},
    {exact:false, realPath:getSearchRoute('revista'),component:SearchPublisher},
    {exact:false, realPath:getSearchRoute('usuario'),component:SearchUser},
    {exact:true, realPath:'/termsofusepage',component:TermsOfUse},
    {exact:true, realPath:'/privacypolicypage',component:TermsOfPrivacy},
    {exact:true, realPath:'/cookiepolicypage',component:TermsOfCookies},
    {exact:true, realPath:getSupportPage(""),component:ApoienosView},
    {exact:true, realPath:getTrailersRoute(""),component:RecentTrailerView},
    {exact:true, realPath:getBroadCastRoute(""),component:BroadcastCalendar},
    {exact:true, realPath:getTopsRoute("anime"),component:TopsAnime,baseProps:{resourceType:'anime'}},
    {exact:true, realPath:getTopsRoute("manga"),component:TopsAnime,baseProps:{resourceType:'manga'}},
    {exact:true, realPath:getSimulcastRoute(),component:Simulcast},
    {exact:true, realPath:getMusicRoute(':id',':name',''),component:Music},
    
    
];


//console.log("VIEWS:::",views);

export default {
    jumpTo 
};