import PropTypes from 'prop-types';
import React, { Component } from 'react';
import "./BannerPicker.css";


import { observer } from 'mobx-react';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { isSet } from '../util/typeu';
import FileSelect from '../view_component/FileSelect';
import Spinner from '../view_component/Spinner';

@observer
class BannerPicker extends Component {
    constructor(props) {
        super(props);      
        this.state = {
          sendBanner:false,
          bannerUrl:""
        };
        this.resetAvatar = this.resetAvatar.bind(this);
        this.handleBannerUpload = this.handleBannerUpload.bind(this);
      }
      componentDidMount()
      {
        MyAppPool.cImage.fetchUserImages('avatar');
        MyAppPool.cImage.fetchUserImages('banner');
      }
      handleBannerUpload(link){
        this.setState({bannerUrl:link});
      }
      resetAvatar(){
        this.setState({bannerUrl:""});
      }

  render(){
    let userBanners = MyAppPool.session.user.images.banner;
    let currentUserPage = MyAppPool.session.userPage.profiles[MyAppPool.currentUser.userid];
    let currentBanner= '';
    let {onSave} = this.props;
    if (isSet(currentUserPage))
        currentBanner=currentUserPage.banner;
    let useNewBannerUrl= this.state.bannerUrl;
    if (useNewBannerUrl==='')
        useNewBannerUrl=currentBanner;
    return <div className="comp_banner_picker">
    <div id ={lng('Banner')} className="sectionCard">
    <h1 className="sectionName"><i className="fas fa-image"> </i> {lng('Banner')}</h1>
        <div className="">
            <div className="bannerImg" style={{backgroundImage:'url("'+currentBanner+'")'}}>
                <img className="avatarBig" src={MyAppPool.cUser.getCurrentUserImg} alt={lng("User")+" "+MyAppPool.currentUser.name+ " "+lng("avatar")}/>
            </div>
            
            <div className="fas fa-chevron-down bannerDownArrow"></div>
            <div className="fas fa-chevron-down bannerDownArrow uk-align-right"></div>
            <div className="bannerImg" style={{backgroundImage:'url("'+useNewBannerUrl+'")'}}>
                <img className="avatarBig" src={MyAppPool.cUser.getCurrentUserImg} alt={lng("User")+" "+MyAppPool.currentUser.name+ " "+lng("avatar")}/>
            </div>
            <div>
                <br/>
                <FileSelect purpose='banner' text={lng("Carregar do Dispositivo")} onUpload={this.handleBannerUpload}/>

                <div className="overflowrapper ">
                <div className="overflowinner appScroll">
                  {userBanners.map((item,index)=>{
                    let active = item===this.state.bannerUrl?'active':'';
                    let inUse = (item===currentBanner||active);
                    return  <div key={index} className="imgDiv">
                              <img alt={lng("Banner available")} onClick={()=>this.setState({bannerUrl:item})} className={"imgForSelection clickable "+active} src={item}/>
                              {!inUse?<i className="fas fa-trash-alt destroyIcon clickable"
                              onClick={()=>MyAppPool.cModal.set(lng('Deletar Imagem'),lng('Tem certeza?'),()=>MyAppPool.cImage.deleteUserImage(item))}></i>:<></>}
                            </div>
                  })}
                </div>
                </div>
                {this.state.sendBanner?
                  <div className="uk-button uk-button-primary">
                    <Spinner/>
                  </div>
                :
                <input onClick={()=>MyAppPool.cUser.updateAuthedUserProfile({banner:this.state.bannerUrl}).then(onSave)} className="uk-button uk-button-green" type="button" value={lng('Salvar')}/>
                }
                <div id="message"></div> 
               
            </div>
        </div>
  </div>
  </div>
  }
}


BannerPicker.propTypes = {
    ready:PropTypes.number.isRequired,
    changeTime:PropTypes.number.isRequired,
    onSave: PropTypes.func
};
BannerPicker.defaultProps = {
  ready:0,
  changeTime:8000,
  onSave: ()=>{return Promise.resolve()}
};

export default BannerPicker;
