import { observer } from 'mobx-react';
import React from 'react';
import { lng } from '../ulanguages/language';
import {  getImgAddress } from '../util/text';
import {  contentFastTypeToType, isSet } from '../util/typeu';
import MyAppPool from '../AppPool';

import './searchBase.css';
import { getObraRoute } from '../store/AppRoutes';
import SearchCard from '../view_component/SearchCard';
import { getRelatedTypeIcon } from '../util/icon';
import { PropTypes } from 'prop-types';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
  

@observer
class SearchPopular extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    }

  componentDidMount()
  {
    SearchPopular.getInitialValues();
  }
  static async getInitialValues()
  {
    return Promise.resolve();
  }
  componentWillReceiveProps()
  {
    SearchPopular.getInitialValues();
  }

  render(){
    let {resourceType} = this.props;
    let {popularThisSeason,comingNext,allTimePopular} = MyAppPool.session.search.contentEmpty[resourceType];

    if (!isSet(allTimePopular))
    {
      allTimePopular=[];comingNext=[];popularThisSeason=[];
    }

    return (<div>
      <ContentBannerSetter />
    {popularThisSeason.length>0?<div>
      <h2>{lng('Popular desta temporada')}</h2>
      <div className="searchContent">
      {popularThisSeason.map((item, index) => {
        let url  = getObraRoute(item.id,item.title,contentFastTypeToType(resourceType),'#top');
        let img = getImgAddress(item.mainimg,'/imgs/defaults/anime.webp','');
        let isHentai=false;
        if (item.genres.indexOf('Hentai')!==-1)
          isHentai=true;

        return <div key={index} className="searchItemResult">
        <SearchCard 
          isHentai={isHentai}
          title={item.title}
          url={url}
          resourceType={resourceType}
          resourceId={item.id}
          imgurl={img}
          genres={item.genreList}
          date={item.airfrom}
          icon={getRelatedTypeIcon(item.type)}
          gradeable={true}
          iconText={lng(item.type)}
        />
      </div>
      })}
      </div>
    </div> :<></>
    }
    {comingNext.length>0?<div>
      <h2>{lng('Próximos a lançar')}</h2>
      <div className="searchContent">
      {comingNext.map((item, index) => {
        let url  = getObraRoute(item.id,item.title,contentFastTypeToType(resourceType),'#top');
        let img = getImgAddress(item.mainimg,'/imgs/defaults/anime.webp','');
        let isHentai=false;
        if (item.genres.indexOf('Hentai')!==-1)
          isHentai=true;

        return <div key={index} className="searchItemResult" >
        <SearchCard 
          isHentai={isHentai}
          title={item.title}
          url={url}
          resourceType={resourceType}
          resourceId={item.id}
          imgurl={img}
          genres={item.genreList}
          date={item.airfrom}
          icon={getRelatedTypeIcon(item.type)}
          gradeable={true}
          iconText={lng(item.type)}
        />
      </div>
      })}
      </div>
    </div> :<></>
    }
    {allTimePopular.length>0?<div>
      <h2>{lng('Popular de todos os tempos')}</h2>
      <div className="searchContent">
      {allTimePopular.map((item, index) => {
        let url  = getObraRoute(item.id,item.title,contentFastTypeToType(resourceType),'#top');
        let img = getImgAddress(item.mainimg,'/imgs/defaults/anime.webp','');
        let isHentai=false;
        if (item.genres.indexOf('Hentai')!==-1)
          isHentai=true;

        return <div key={index} className="searchItemResult">
        <SearchCard 
          isHentai={isHentai}
          title={item.title}
          url={url}
          resourceType={resourceType}
          resourceId={item.id}
          imgurl={img}
          genres={item.genreList}
          date={item.airfrom}
          icon={getRelatedTypeIcon(item.type)}
          gradeable={true}
          iconText={lng(item.type)}
        />
      </div>
      })}
      </div>
    </div> :<></>
    }
  </div>)
}
}

SearchPopular.propType = {
    resourceType:PropTypes.number.isRequired,
};
SearchPopular.defaultProps = {
    resourceType:0
};

export default SearchPopular;
