
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../../AppPool';
import { lng } from '../../ulanguages/language';
import AnchorBox from '../../view_component/AnchorBox';
import ListBox from '../../view_component/ListBox';
import ScrollToTopButtom from '../../view_component/ScrollToTopButton';
import SkeletonBlock from '../../view_component/SkeletonBlock';
import { isMobile } from './../../util/mobile';
import { getSiteShortName } from './../../util/text';
import { contentFastTypeToType, isSet } from './../../util/typeu';
import './UserListView.css';


@observer
class UserListView extends Component {

  constructor(props) {
    super(props);
           

    this.passFilter = this.passFilter.bind(this);
    this.setCardPreference = this.setCardPreference.bind(this);

    let preferenceCardMode = MyAppPool.cPreference.getListBoxCardMode();
    this.state = { 
        nameFilter:'',
        relationFilter:'',
        typeFilter:-1,
        mode:preferenceCardMode
    };

    }
    setCardPreference(value)
    {
      MyAppPool.cPreference.setListBoxCardMode(value);
      this.setState({mode:value});
    }
    componentDidMount()
    {

    }
    numberFilter = (filterArg,valueArg) =>
    {
      let filter = Number(filterArg);
      let value = Number(valueArg);
      if (filter===-1)
        return true;
      if (filter==='null' && !isSet(value))
        return true;
      if (!isSet(value) || isNaN(value))
        return false;
      if (filter===value)
        return true;
      return false;
    }
    passFilter(item)
    {
      let {nameFilter,relationFilter,typeFilter} = this.state;
      return (this.numberFilter(typeFilter,item))
    }

    render()
    {
        //let baseUrl = ct.server.BASE_URL + ct.server.BASE_PORT;
        let {current} = this.props;
        let {mode,typeFilter} = this.state;

        let meta = {title:lng('Usuário')+' | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:''};
        let listAnchors = [];
        let listTypes = [];
        if (current.ready)
        {
          let desc = '';
          meta =  {
            title: ' | '+getSiteShortName()+'',
            desc:desc,
            keywords:desc
          };
          
          current.lists.map((elem)=>{
            if (this.passFilter(elem.resourcetype))
              listAnchors.push({name:elem.name, length:elem.itemIds.length})
            listTypes.push(elem.resourcetype);
          });
          listTypes = [...new Set([...listTypes])];
        }
        let mainWidth="60%";
        let mainMarginLeft="15px";
        if (isMobile.any)
        {
          mainWidth="95%";
          mainMarginLeft="2.5%";
        }

        return (<div className="userlist_view contentArea uk-flex uk-flex-row">
        
        <Helmet>
        <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
        </Helmet>
        
        {!isMobile.any?
        <div style={{width:"17%",marginLeft:"4%"}}>
          {//left collum
            
          }
          <AnchorBox items={listAnchors} />
        </div>
        :<></>}

        <div style={{width:mainWidth,marginLeft:mainMarginLeft}}>
          <div className="uk-card uk-card-default  filterBox">
          <select onChange={(e) => {this.setState({typeFilter: e.target.value});}}>
                <option value="-1">{lng('Tipo')}</option>
                {
                  listTypes.map((item, index) => {
                    return (<option value={item} key={index}>{lng(contentFastTypeToType(item))}</option>);
                  })
                }
              </select>
                <div className="uk-align-right">
                  <i onClick={()=>this.setCardPreference('list')} className="fas fa-th-list iconButton clickable"></i>
                  <i onClick={()=>this.setCardPreference('card')} className="fas fa-th-large iconButton clickable"></i>
                  <i onClick={()=>this.setCardPreference('scroll')} className="fas fa-grip-horizontal iconButton clickable"></i>
                </div>
            </div>
          {current.ready? 
          <div>
          {current.lists.map((item,index)=>{ 
            if (this.passFilter(item.resourcetype))
              return <ListBox mode={mode} list={item} listsOwner={current.id}/>
            })}
          </div>
          :
          <div>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
            <SkeletonBlock  style={{'width':'100%',height:'160px', 'marginTop':'8px'}}/>
          </div>}
        </div>

        {!isMobile.any?
        <div  style={{width:"15%",marginLeft:"15px"}}>
          <ScrollToTopButtom />
        </div>
        :<></>}

      </div>);


    }
}

export default UserListView;