
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { fetchAndProcess } from '../util/net';
import { contentTypeToFastType } from '../util/typeu';
import { setCurrentCursorPosition } from './../view_component/WYSIWYGWriterUtils';


export default class AlertController
{
    timeToClose=3000;
    notifyUser(text,details='',status=200)
    {
        if (status===200)
            console.log(text);
        if (status===500)
            console.error(text,details);
        if (status===400)
            console.warn(text,details);
        MyAppPool.session.alerts.time=new Date();
        let alerts = [...MyAppPool.session.alerts.data];
        console.log(alerts);
        alerts[alerts.length]={text:text,details:"",status:status,time:new Date()};
        if (alerts.length>3)
            alerts.splice(0,1);
        
        //setTimeout(()=>MyAppPool.session.alerts.time=new Date(),this.timeToClose);
        setTimeout(()=>MyAppPool.session.alerts.time=new Date(),this.timeToClose+500);
        MyAppPool.session.alerts.data=alerts;
    }
    notifyOk(text)
    {
        this.notifyUser(text,'',200);
    }
    notifyError(text,details='',where='')
    {
        console.error("AlertError:",details,"AT:",where);
        this.notifyUser(text,details,500);
    }
    notifyGenericError(details='',where='')
    {
        console.error("AlertError:",details,"AT:",where);
        this.notifyError(lng("Que vergonha! Parece que algo deu errado no nosso servidor, o desenvolvedor foi alertado, tente novamente mais tarde!"),details,500);
    }
    deactivate(index)
    {
        let alerts = [...MyAppPool.session.alerts.data];
        if (alerts.length>index)
            alerts[index].time.setSeconds(alerts[alerts.length-1].time.getSeconds() - 5);
        setTimeout(()=>MyAppPool.session.alerts.time=new Date(),1);
    }
    
}