import loadable from "@loadable/component";
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import MyAppPool from './../AppPool';
import CSSTransition2 from './CSSTransition2';
import ErrorJSXCatcher from './ErrorJSXCatcher';
import "./GlobalModal.css";
import "./GlobalLoginBox.css";
//import LoginBox from './LoginBox';

const LoginBox = loadable(() => import(/* webpackChunkName: "component-loginbox" */ './LoginBox'));


@observer
class GlobalLoginBox extends Component {
  constructor(props)
  {
    super(props);
    this.cleanup = this.cleanup.bind(this);
    this.close = this.close.bind(this);
  }
  close()
  {
    MyAppPool.cAuth.deactivateGlobalLoginBox();
  }
  cleanup()
  {

  }
  componentDidMount()
  {
    if (document)
    {
      let el = document.getElementById("appModal");
      if (el)
        el.addEventListener("hidden", ()=>
      {
        MyAppPool.cModal.deactivate();
        MyAppPool.jsObjectStore.modal.cleanupFunction();
      });
    }
  }
  componentDidUnmount()
  {
  }
  render(){
    
    let open =  toJS(MyAppPool.session.loginBoxActive);
    return (<div className='comp_app_modal'>
            <CSSTransition2
            in={open}
            timeout={150}
            classNames="global-modal"
            unmountOnExit
            >
              <div id="appModal" className='modal_back_click' onClick={this.close}>
              <CSSTransition2
                in={MyAppPool.session.loginBoxActive}
                key={2}
                timeout={300}
                classNames="modal-inside"  
                unmountOnExit
              >
                <div className={"globalLoginBox"} >
                <LoginBox />
                </div>
                </CSSTransition2>
                </div>
                </CSSTransition2>
        </div>);
}
}

export default GlobalLoginBox;
