import axios from 'axios';
import { serviceHosts } from './../store/ServiceHosts';
import { isSet, mobToObj } from './typeu';
import MyAppPool from './../AppPool';
import { merge } from 'lodash';
import { toJS } from 'mobx';
import { getCurrentUrl } from './windowUtil';

var TESTING=false;

let url = getCurrentUrl();
if (url.indexOf('localhost')!==-1)
  TESTING=true;

axios.defaults.withCredentials = false;

axios.interceptors.request.use(request => {
  if (TESTING===true)
    console.log('Starting Request', request)
  return request
})

axios.interceptors.response.use(response => {
  if (TESTING===true)
    console.log('Response:', response)
  return response
})

var defaulBrasOptions = {
  headers:{
  }
}

export async function brasRequest(route,useCacheArg=true, params={}, headers={}, extraOptions = {})
{
  if (!serviceHosts[route.service])
  {
    console.log("Trying to call an invalid service, returning");
    return Promise.reject("Trying to call an invalid service, returning");
  }
  let useCache = useCacheArg;
  if (route.cache===0)
    useCache=false;

  let options = extraOptions;
  if (isSet(route.options))
    options = {...options,...route.options};
  let host = serviceHosts[route.service];
  let addParams = "";
  options.baseURL = host;
  options.method = route.method.toLocaleLowerCase();
  await MyAppPool.cAuth.refreshLogin();
  let internalHeaders = {};
  if (route.service!=='imgur')
  {
    internalHeaders = {Authorization:'Bearer '+MyAppPool.currentUser.token};
    if (!useCache)
    {
        internalHeaders["Cache-Control"]='no-cache, must-revalidate, max-age=0';
        internalHeaders["Pragma"]='no-cache';
      
    }
  }
  options.headers = {...defaulBrasOptions.headers,...route.defaultHeaders,...internalHeaders,...headers};
  if (TESTING)
    console.log("Req:",route,"cache:",useCache,"headers:",options.headers);
  if (options.method==='get')
  {
    let entries = Object.entries(params);
    //console.log(entries);
    addParams = "?";
    for(let i=0; i < entries.length;i++)
    {
      //console.log(entries[i]);
      if (typeof entries[i][1] == 'object')
        entries[i][1]=JSON.stringify(entries[i][1]);
      if (Array.isArray(entries[i][1]))
        entries[i][1]=JSON.stringify(entries[i][1]);
      addParams += encodeURIComponent(entries[i][0]) +"="+ encodeURIComponent(entries[i][1]);
      if (i<entries.length-1)
        addParams += "&";
    }
  }else
  {
    options.data=params;
  }
  options.url = route.path + addParams;
  let res;
  if (useCache)
  {
    res = MyAppPool.requestCache[options.url];
    if (typeof res != 'undefined' && res!=null)
    {
      if ( Date.now() <= (res.stamp+(route.cache*1000) ) ) //math = cachedAge
        return Promise.resolve({data:res.data,future:null});
      else
      {
        //console.log("Cache is stale");
        //console.log(res);
        let resdata = res.data;
        MyAppPool.requestCache[options.url] = null;
        return Promise.resolve({data:resdata, future:brasRequest(route,useCache,params, headers)});
      }
    }
  }

  return axios(options).then( (response) => {
    if (typeof response.data === 'string' || response.data instanceof String)
    {
        console.error("Received string instead of JSON object: " +response);
    }
    MyAppPool.requestCache[options.url] = {data:response.data,stamp:Date.now()};
    return Promise.resolve({data:response.data,future:null});
  });
}


export async function brasRequestPure(route, params={}, headers={}, extraOptions = {})
{
  if (!serviceHosts[route.service])
  {
    console.log("Trying to call an invalid service, returning");
    return Promise.reject("Trying to call an invalid service, returning");
  }

  let options = extraOptions;
  if (isSet(route.options))
    options = {...options,...route.options};
  let host = serviceHosts[route.service];
  let addParams = "";
  options.baseURL = host;
  options.method = route.method.toLocaleLowerCase();
  await MyAppPool.cAuth.refreshLogin();
  let internalHeaders = {Authorization:'Bearer '+MyAppPool.currentUser.token};

  internalHeaders["Cache-Control"]='no-cache, must-revalidate, max-age=0';
  internalHeaders["Pragma"]='no-cache';

  options.headers = {...defaulBrasOptions.headers,...route.defaultHeaders,...internalHeaders,...headers};
  if (TESTING)
    console.log("Req:",route,"headers:",options.headers);
  if (options.method==='get')
  {
    let entries = Object.entries(params);
    //console.log(entries);
    addParams = "?";
    for(let i=0; i < entries.length;i++)
    {
      //console.log(entries[i]);
      if (typeof entries[i][1] == 'object')
        entries[i][1]=JSON.stringify(entries[i][1]);
      if (Array.isArray(entries[i][1]))
        entries[i][1]=JSON.stringify(entries[i][1]);
      addParams += encodeURIComponent(entries[i][0]) +"="+ encodeURIComponent(entries[i][1]);
      if (i<entries.length-1)
        addParams += "&";
    }
  }else
  {
    options.data=params;
  }
  options.url = route.path + addParams;
  let res;
  return axios(options).then( (response) => {
    return Promise.resolve({data:response.data,future:null});
  });
}
export async function externalRequest(route, params={}, headers={}, extraOptions = {})
{
  if (!serviceHosts[route.service])
  {
    console.log("Trying to call an invalid service, returning");
    return Promise.reject("Trying to call an invalid service, returning");
  }

  let options = extraOptions;
  if (isSet(route.options))
    options = {...options,...route.options};
  let host = serviceHosts[route.service];
  let addParams = "";
  options.baseURL = host;
  options.method = route.method.toLocaleLowerCase();

  options.headers = {...defaulBrasOptions.headers,...route.defaultHeaders,...headers};
  if (TESTING)
    console.log("Req:",route,"headers:",options.headers);
  if (options.method==='get')
  {
    let entries = Object.entries(params);
    //console.log(entries);
    addParams = "?";
    for(let i=0; i < entries.length;i++)
    {
      //console.log(entries[i]);
      if (typeof entries[i][1] == 'object')
        entries[i][1]=JSON.stringify(entries[i][1]);
      if (Array.isArray(entries[i][1]))
        entries[i][1]=JSON.stringify(entries[i][1]);
      addParams += encodeURIComponent(entries[i][0]) +"="+ encodeURIComponent(entries[i][1]);
      if (i<entries.length-1)
        addParams += "&";
    }
  }else
  {
    options.data=params;
  }
  options.url = route.path + addParams;
  return axios(options).then( (response) => {
    return Promise.resolve({data:response.data,future:null});
  });
}

export function fetchAndProcess(processFunc,route,useCache=true,params={},headers={})
{
    return brasRequest(route,useCache,params,headers).then(
        (response) => {
            let data = response.data;
            let futureRequest = response.future;
            
            let ready = !isSet(futureRequest);
            if (ready)
            {
                //console.log("FreshOrNewCached Data");
                //console.log("fresh:",data);
                ready = 2;
                data.params = params;
                return processFunc(data,ready);
            }else
            {
                //console.log("Stale Cached Data");
                //console.log(data);
                ready = 1;
                processFunc(data,ready);
                return futureRequest.then((response)=>{
                  response.data.params = params;
                  return processFunc(response.data,ready)
                });
            }
        }
    );
}

/*
@destinations= [{destination...},...];
destination={
  useCache:false/true,
  params:{},
  headers:{},
  route:'/api/blabla',
  name:'datawfetch'
};
*/
export function multipleFetchAndProcess(processFunc,destinations)
{
  let destinationsLength = destinations.length;
  let promises = [];
  let results = {};
  //make all requests
  for(let i=0;i<destinationsLength;i++)
  {
    let item = destinations[i];
    promises[promises.length] = brasRequest(item.route,item.useCache,item.params,item.headers);
  }
  //with all requests results
  return Promise.all(promises).then((values) => {
    let futureRequests = [];
    let secondTry=false;
    let ready=true;
    for(let i=0;i<destinationsLength;i++)
    {
      let data = values[i].data;
      let futureRequest = values[i].future;
      //check if some requests will ping again, with more recent data
      if (futureRequest!==null)
      {
        secondTry=true; //if yes we have to process the data twice
        futureRequests[i] = futureRequest;
      }
      else
        futureRequests[i] = Promise.resolve(values[i]);
      
      results[destinations[i].name] = data;
    }
    if (!secondTry)//all of our requests received fresh data
      return processFunc(results,ready);
    ready = false;

    //if we reached here, that means we have requests still incoming
    // we process the data we already have with some stale results and wait for the next batch of requests
    processFunc(results,ready);
    return Promise.all(futureRequests).then((values) => {
      ready = true;
      for(let i=0;i<destinationsLength;i++)
      {
        let data = values[i].data;
        results[destinations[i].name] = data;
      }
      return processFunc(results,ready);
    });

  });
}
