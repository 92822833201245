import HtmlToReact from 'html-to-react';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { getResourceRoute, getUserRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon, getUserAvatar } from '../util/icon';
import { lng } from '../ulanguages/language';
import { getImgAddress } from '../util/text';
import MyAppPool from './../AppPool';
import { contentFastTypeToType, contentTypeToFastType, isSet, mobToObj } from './../util/typeu';
import "./ReactionItem.css";
import { getYoutubeThumbnail } from '../util/youtube';

@observer
class ReactionItemHome extends Component {
  constructor(props) {
    super(props);
    this.state = {sending:false,content:''};
    this.deletePost = this.deletePost.bind(this);
}
  deletePost()
  {
    let {reactionItem} = this.props;
    MyAppPool.cReactionPosts.deleteReactionPost(reactionItem);
  }

  componentDidMount()
  {

  }

  render(){
    let {reactionItem,currentUserId,currentUserRoles,bigResourceImage} = this.props;
    let showEditIcons = false;
    let voteResourceType = contentTypeToFastType('reactionPost');
    let userVote = MyAppPool.session.user.votes[''+voteResourceType][''+reactionItem.reactid];
    if (typeof userVote ==='undefined')
      userVote=0;

    let icon = null;
    let iconText = '';
    let capaImg='';
    let title = 'Loading...';

    let contentTypeName =  contentFastTypeToType(reactionItem.resourcetype);
    let item = MyAppPool.session.samples[''+reactionItem.resourcetype][''+reactionItem.resourceid];
    if (isSet(item))
    {
      capaImg=item.mainimg;
      if (isSet(item.ytid))
        capaImg=getYoutubeThumbnail(item.ytid);
      title = item.title;
      icon = getRelatedTypeIcon(item.type);
      iconText = lng(item.type);
    }
    let authorId = reactionItem.author;
    let sampleAuthor =MyAppPool.cUser.getUserSampleObject(MyAppPool.session.samples[''+contentTypeToFastType('user')][''+authorId]);
    let resourceUrl = getResourceRoute(reactionItem.resourcetype,reactionItem.resourceid,title,'#top');
    let currentUserRolesUse = []
    if (Array.isArray(currentUserRoles))
      currentUserRolesUse = currentUserRoles;
    if (currentUserId===reactionItem.author || currentUserRolesUse.indexOf('mabmod')!==-1)
      showEditIcons=true;
    let imageClass='listImage';
    let imageGatherParam='x25';
    let imageWidth='40px';
    if (bigResourceImage)
    {
      imageClass='listImage bigListImage';
      imageGatherParam='x25';
      imageWidth='65px';
    }

    const displayContent = HtmlToReact.Parser().parse(reactionItem.displaytext);
    return (
    <div className="uk-flex uk-flex-row comp_reactionItem reactionHome uk-card uk-card-default">
        <div className="mbtngroup">
          {userVote===1? 
            <button aria-label={"+1"} className="uk-button uk-button-default mbtn upvote btnActivatedGood" onClick={()=>MyAppPool.cVotes.sendReactionPostVote(reactionItem.reactid,reactionItem.resourcetype,reactionItem.resourceid,1)}> <span className="fas fa-chevron-up textActivatedGood"/></button> 
          : <button aria-label={"+1"} className="uk-button uk-button-default mbtn upvote" onClick={()=>MyAppPool.cVotes.sendReactionPostVote(reactionItem.reactid,reactionItem.resourcetype,reactionItem.resourceid,1)}> <span className="fas fa-chevron-up"/></button>
          }
          {userVote===0? <p className="votesText">{reactionItem.votes}</p> 
          : userVote===1? <p className="votesText textActivatedGood">{reactionItem.votes}</p> 
          : <p className="votesText textActivatedBad">{reactionItem.votes}</p> 
         }
          
          {userVote===-1? 
            <button aria-label={"-1"} className="uk-button uk-button-default mbtn downvote btnActivatedBad" onClick={()=>MyAppPool.cVotes.sendReactionPostVote(reactionItem.reactid,reactionItem.resourcetype,reactionItem.resourceid,-1)}> <span className="fas fa-chevron-down textActivatedBad" /></button> 
          : <button aria-label={"-1"} className="uk-button uk-button-default mbtn downvote" onClick={()=>MyAppPool.cVotes.sendReactionPostVote(reactionItem.reactid,reactionItem.resourcetype,reactionItem.resourceid,-1)}> <span className="fas fa-chevron-down"/></button>
          }
        </div>
        <div className="uk-flex uk-flex-row reactionContent"> 
        <Link aria-label={sampleAuthor.name} className="userimghref" to={getUserRoute(reactionItem.author,sampleAuthor.name,"#top")}><LazyLoadImage alt={lng("User")+" "+sampleAuthor.name+ " "+lng("avatar")} className="userimg" src={getUserAvatar(sampleAuthor.img,sampleAuthor.hash,'x25')}/></Link>
          <div className="textContent">
            <Link aria-label={title}  style={{width:imageWidth,float:'right', marginRight:'10px',marginBottom:'3px'}} to={resourceUrl}>
                <LazyLoadImage alt={lng("Capa de")+" "+title} className={imageClass} src={getImgAddress(capaImg,'imgs/defaults/anime.webp',imageGatherParam)}/>
            </Link>
            <div className="reactionTitle">
                <Link to={getUserRoute(reactionItem.author,sampleAuthor.name,"#top")}><span className="author">{sampleAuthor.name}</span> </Link> 
                {lng("em")} 
                <Link to={resourceUrl}> <span>{title}</span></Link> 
                 {(icon)? <i className={icon + ' typeIcon'} data-uk-tooltip={iconText}></i> : <></>}
            </div>
            <div className="contentText">{displayContent} 
            {showEditIcons?
            <span onClick={()=>MyAppPool.cModal.set(lng('Deletar Reação'),lng('Tem certeza?'),this.deletePost)} className="fas fa-trash-alt iconButton clickable uk-align-right" />
            : <span></span>}
            </div>
          </div>
        </div>
    </div>);
}
}

ReactionItemHome.propTypes = {
  reactionItem: PropTypes.shape({
  displaytext: PropTypes.string.isRequired,
  publishdate:PropTypes.string.isRequired,
  votes:PropTypes.number.isRequired,
  authorname:PropTypes.string.isRequired,
  authorimg:PropTypes.string.isRequired,
  author:PropTypes.number.isRequired
  }),
  currentUserId: PropTypes.number.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  bigResourceImage:PropTypes.bool
};

ReactionItemHome.defaultProps = {
  reactionItem: {
    displaytext: '',
    publishdate:'',
    votes:0,
    authorname:'Defaulter',
    authorimg:'',
    author:0,
    resourcetype:0,
    resourceid:0
    },
    currentUserId: 0,
    currentUserRoles: ['guest'],
    bigResourceImage:false
};


export default ReactionItemHome;
