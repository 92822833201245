import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { lng } from '../ulanguages/language';
import { convertDataToShowable } from '../util/text';
import "./RelatedRowCard.css";
import { isSet } from './../util/typeu';
class RelatedRowCard extends Component {
  componentDidMount()
  {

  }

  render(){
    let {id, icon,name,description,relation,type,img,url,data,ready} = this.props;
    if (!ready)
    return <div className="uk-card uk-card-default skeleton">
      <div style={{height:'160px','width':'100%'}}></div>
    </div>;

    if (isSet(description))
    {
    description = description.substring(0,200);
    description += '...';
    }

    return (
        <div className="uk-card uk-card-default comp_relatedrowcard">
          <div className="uk-flex uk-flex-row"> 
            <div style={{width:'20%'}}>
            <Link aria-label={name} to={url}><img src={img} style={{height:'225px'}} alt={lng('Capa de')+' '+name}></img> </Link>
            </div>
            <div class="related_card_right" style={{width:'79%'}}>
              <h2>
                <Link to={url}> {name} </Link>
              </h2>
              <span> {convertDataToShowable(data)} </span> <br/>
              <span>{icon ?<i className={icon} data-uk-tooltip={type}></i> : <></>} 
               {' '+type} 
               / {relation}</span>
              <p> {description}  </p>
            </div>
          </div>
        
        
      

        
      </div>);
}
}

RelatedRowCard.propTypes = {
  ready: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  relation: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description:PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
  
  
};
RelatedRowCard.defaultProps = {
  id: 0,
  name: '',
  relation: '',
  type: '',
  data: '',
  img:'',
  url:'',
  description:'',
  icon:'',
  ready:0
}

export default RelatedRowCard;
