import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from '../AppPool';
import { getRgbRanges } from './../util/gradient';
import { lng } from '../ulanguages/language';
import { isSet } from './../util/typeu';
import "./Grader.css";


@observer
class Grader extends Component {

  constructor(props)
  {
    super(props);
    this.state = {grade:-1};
    this.onChangeGradeValue = this.onChangeGradeValue.bind(this);
    this.renderGrades = this.renderGrades.bind(this);
  }
  componentDidMount()
  {
  }

  onChangeGradeValue(value)
  {
    this.setState({grade:value});
  }
  
  renderGrades()
  {
    let {resourceId,resourceType}=this.props;
    let mySelectedGrade = MyAppPool.session.user.grades[resourceType][resourceId];
    let gradesToPrint = [];
    let {minVal,maxVal,increment,rgbStart,rgbEnd,rgbMiddle} = this.props.gradeOptions;
    
    let count = 0;
    let colors = getRgbRanges ([rgbStart,rgbMiddle,rgbEnd], (maxVal-minVal)/increment +1);
    let rgbStartActual = [0,0,0];
    let rgbEndActual = [0,0,0];
    let width=90/((maxVal-minVal)/increment);
    for(let i=minVal;i<=maxVal;i+=increment)
    {
      rgbStartActual = colors[count].start;
      rgbEndActual = colors[count].end;

      let gradStart = `rgb(${rgbStartActual[0]}, ${rgbStartActual[1]}, ${rgbStartActual[2]})`;
      let gradEnd = `rgb(${rgbEndActual[0]}, ${rgbEndActual[1]}, ${rgbEndActual[2]})`;

      let gradient = `linear-gradient(to right, ${gradStart}, ${gradEnd})`;
      
      let stars = [];

      for(let k=0;k<(maxVal-minVal);k+=1)
      {
        if (k+minVal >= i)
          stars[k] = <i className="far fa-star star"></i>
        if (k+minVal+0.5 < i)
          stars[k] = <i className="fas fa-star star"></i>
        if (k+minVal+0.5 === i)
          stars[k] = <i className="fas fa-star-half-alt star"></i>
      }
      let autoFocusSource = (i===mySelectedGrade) ? 'true' : undefined;
      gradesToPrint[count] = <button aria-label={lng('Nota')+" "+i} autofocus={autoFocusSource} autoFocus={autoFocusSource} onClick={()=>this.onChangeGradeValue(i)} className="clickable comp_Grader_gradientButtons" key={count} style={{backgroundImage:gradient, width:width+'%'}}>{i} <br/> {stars}</button>;
      count++;
    }
    return gradesToPrint;
  }

  render(){
        let {resourceType,resourceId,children} = this.props;
        let mainChild = {};

        let myOnClick = ()=>{
          if (MyAppPool.cAuth.isGuest())
          {
            MyAppPool.cAuth.activateGlobalLoginBox();
            return;
          }
            MyAppPool.cModal.set(
            lng("Adicionar Nota"),
            <div className="uk-flex uk-flex-row " onChange={this.onChangeGradeValue}>
                {this.renderGrades()}
            </div>,
            (ok)=>{
            if (this.state.grade===-1)
                return;
            MyAppPool.cGrade.sendWorkResourceGrade(resourceType,resourceId,this.state.grade)
            },'',(cleanup)=>{})};
        if (Array.isArray(children))
            mainChild = <div>{children}</div>;
        else
            mainChild=children;
            
        if (!isSet(mainChild))
            return <></>;
        let childElement = React.cloneElement(mainChild, { onClick:myOnClick });
        return (childElement);
    }
}

Grader.propTypes = {
  resourceId: PropTypes.number.isRequired,
  resourceType: PropTypes.number.isRequired,
  gradeOptions:PropTypes.shape({
    minVal: PropTypes.number.isRequired,
    maxVal: PropTypes.number.isRequired,
    increment: PropTypes.number.isRequired
  })
};
Grader.defaultProps = {
  resourceId:0,
  resourceType:0,
  gradeOptions:{
    minVal: 5,
    maxVal: 10,
    increment: 0.5,
    rgbStart : [153,0,0],
    rgbMiddle : [255, 255, 153],
    rgbEnd: [0, 255, 85]
  }
};

export default Grader;
