import loadable from "@loadable/component";
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import SkeletonBlock from '../view_component/SkeletonBlock';
import { isMobile } from './../util/mobile';
import { isServer } from './../util/windowUtil';
import './TrailerYoutubePlayBig.css';
import { isSet } from '../util/typeu';
import MyAppPool from '../AppPool';
//import YouTube from 'react-youtube';
const YouTube = loadable(() => import(/* webpackChunkName: "react-youtube" */ 'react-youtube'));


function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}
const nextTime=5000;
@observer
class TrailerYoutubePlayBig extends Component {
  constructor(props)
  {
    super(props);
    this.state = {};
  }

  componentDidMount()
  {
  }
  componentWillReceiveProps()
  {
  }

  render(){
    let {videourl,title,height} = this.props;
    let ready = (isSet(videourl) && videourl!=='');

    let myClassName = "trailerYoutubePlayBig ";
    let receivingClassName = this.props.className;
    if (isSet(receivingClassName))
      myClassName = myClassName+ receivingClassName;

    if (!ready)
      return <SkeletonBlock  {...this.props} className={myClassName}/>
    

    const trailerOpts = {
        height:vhToPixels(75),
        width: '100%',
        playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        enablejsapi:1,
        controls:1
        }
    };

    
    
    return (
        <div  style={{'height':height}}{...this.props} className={myClassName}>
            {videourl?
            <div className="trailerVideo clickable" style={{backgroundImage: "url('https://img.youtube.com/vi/"+videourl+"/hqdefault.jpg')"}}
                    onClick={()=>{
                      MyAppPool .cMusic.stopAndShouldResumeWhenPossible();
                      MyAppPool.cModal.set(title,
                    <YouTube
                      videoId={videourl}
                      id='trailerplayer'
                      containerClassName='trailerplayerYoutubeVideo'
                      opts={trailerOpts}
                    />,null,'trailermodal',()=>{MyAppPool.cMusic.startIfShouldResume()})
                    }
                  } >
                <div className="videoShow">
                <div className='trailerTitle'><h2 className='alwaysWhiteText'>{title}</h2></div>
                    <i className="far fa-play-circle playIcon iconButton clickable playBtn" />
                </div>
                </div>
        :<></>}
      </div>
    );
  }
}


TrailerYoutubePlayBig.propTypes = {
    videourl:PropTypes.string.isRequired,
    title:PropTypes.string.isRequired,
    height:PropTypes.string.isRequired,
};
TrailerYoutubePlayBig.defaultProps = {
    title:'',
    height:'272px'
};



export default TrailerYoutubePlayBig;
