
import { routes } from '../store/ApiRoutes';
import MyAppPool from '../AppPool';
import { brasRequest, fetchAndProcess } from '../util/net';
import { contentFastTypeToType, contentTypeToFastType, isSet } from '../util/typeu';
import { deleteIdFromArray } from '../util/array';
import { lng } from '../ulanguages/language';



export default class ReviewController
{
    fetchAndProcessCreateReview(resourceType,resourceId,content,animGrade,musicGrade,storyGrade,characterGrade,reviewId=-1)
    {
        if (!isSet(MyAppPool.session.review[resourceType]))
            MyAppPool.session.review[resourceType] = {};

        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let data = data0.data;
            if (data.length>0)
            {
                let myReview = data[0];
                
                let oldReviews = MyAppPool.session.review[''+resourceType][''+resourceId];
                if (!isSet(oldReviews))
                    oldReviews = [];
                if (reviewId===-1)//create
                {
                    MyAppPool.session.reviewData[''+myReview.reviewid] = myReview;
                    MyAppPool.session.review[''+resourceType][''+resourceId]=[myReview.reviewid, ...oldReviews];
                }
                else
                {//update
                    MyAppPool.session.reviewData[''+myReview.reviewid] = myReview;
                }
                MyAppPool.cAlert.notifyOk(lng("Resenha Enviada com Sucesso!"));
                return Promise.resolve();
            }
        },
        routes.createOrUpdateReview,false,
        {resourceType,resourceId,content,
        animGrade,musicGrade,storyGrade,characterGrade,reviewId})
        .catch((e)=>{
            MyAppPool.cAlert.notifyGenericError(e,"create review");
            console.error("Error creating review",e);
        });
    }

    createOrUpdateReview
    (resourceType,resourceId,content,animGrade,musicGrade,storyGrade,characterGrade,reviewId=-1)
    {
        if (content==='')
            return Promise.resolve();
        let sendResourceType;
        sendResourceType = resourceType;
        if (isNaN(resourceType))
            sendResourceType = contentTypeToFastType(resourceType);

        return this.fetchAndProcessCreateReview
        (sendResourceType,resourceId,content,animGrade,musicGrade,storyGrade,characterGrade,reviewId);
    }

    deleteReview(review)
    {
        console.log("DELETE REVIEEEW::::",review);
        return brasRequest(routes.deleteReview,false,{reviewId:review.reviewid},{})
        .then((response)=>{
            if (!response.data.ok)
                return Promise.reject(response.data);
            let temp = MyAppPool.session.review[''+review.resourcetype][''+review.resourceid];
            if (temp)
                MyAppPool.session.review[''+review.resourcetype][''+review.resourceid]= deleteIdFromArray(review.reviewid,temp);
            temp =  MyAppPool.session.latestContent.reviews.items;
            if (temp)
                MyAppPool.session.latestContent.reviews.items= deleteIdFromArray(review.reviewid,temp);
            
            MyAppPool.session.reviewData[''+review.reviewid] = undefined;
            
        }).catch((e)=>{
            MyAppPool.cAlert.notifyGenericError(e,"delete review");
            console.error("Error deleting review",e);
        });
    }

    fetchReviews(resourceType,resourceId,useCache=true)
    {
        //console.log('resType:',resourceType,'resId:',resourceId);
        if (!isSet(MyAppPool.session.review[resourceType]))
            MyAppPool.session.review[resourceType] = {};
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let data = data0.data;
            let dataIds = [];
            let authorIds = [];
            for(let i=0;i<data.length;i++)
            {
                let item = data[i];
                MyAppPool.session.reviewData[''+item.reviewid] = item;
                authorIds.push(item.author);
                dataIds[i] = item.reviewid;
            }
            if (data.length>0)
            {
                MyAppPool.session.review[''+resourceType][''+resourceId] = dataIds;
                MyAppPool.cSamples.getSamplesByArrayIdsList(contentTypeToFastType('user'),authorIds);
                return Promise.resolve(data);
            }
            MyAppPool.session.review[''+resourceType][''+resourceId] = [];
            return Promise.resolve([]);
        },
        routes.getReviewByResourceId,useCache,
        {resourceType,resourceId})
        .catch(e => console.log("Error Getting "+contentFastTypeToType(resourceType)+" Reviews",e));
    }
}