import React, { Component } from 'react';
import "./TextContentBox.css";
import { isSet } from '../util/typeu';

function splitStringAtFifthDot(inputString) {

  const dotsToSplitAt = 5;
  const dot = ".";
  const parts = [];
  let currentIndex = 0;
  let dotCount = 0;
  if (!isSet(inputString))
  return parts;

  while (currentIndex < inputString.length) {
      if (inputString[currentIndex] === dot) {
          dotCount++;
          if (dotCount === dotsToSplitAt) {
              const part = inputString.substring(0, currentIndex + 1); // Include the dot
              parts.push(part);
              inputString = inputString.substring(currentIndex + 1);
              currentIndex = 0;
              dotCount = 0;
              continue;
          }
      }
      currentIndex++;
  }

  if (inputString.length > 0) {
      parts.push(inputString);
  }

  return parts;
}

class TextContentBox extends Component {
  componentDidMount()
  {

  }

  render(){
      let {content} = this.props;
      let splitContent = splitStringAtFifthDot(content);
    return (
        <p className="comp_textcontentbox">
        {splitContent.map((elem)=><span>{elem} <br/><br/></span>)}
        <br/>
        
        </p>);
}
}

export default TextContentBox;
