import { observer } from 'mobx-react';
import React, { Component } from 'react';
import "./DropDown.css";
import MyAppPool from './../AppPool';
import { lng } from '../ulanguages/language';
import { PropTypes } from 'prop-types';

@observer
class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {shouldBeInTheLeft:false};
    this.elementRef = React.createRef();
    this.moveElementToLeft = this.moveElementToLeft.bind(this);
}

  componentDidMount() {
    this.moveElementToLeft();
  }
  moveElementToLeft() {
    const element = this.elementRef.current;
    const elementRect = element.getBoundingClientRect();
    const halfScreenWidth = window.innerWidth / 2;

    if (elementRect.left > halfScreenWidth) {
      this.setState({shouldBeInTheLeft:true});
    }

  }

  render(){
    let {shouldBeInTheLeft} = this.state;
    let leftClass = "";
    if (shouldBeInTheLeft)
      leftClass="dropDownToTheLeft";
    
    return <div {...this.props} className={"comp_dropdown "+this.props.className} ref={this.elementRef}> 
        <div className="dropDown">
        <button aria-label={this.props.btnContent} className={"dropBtn " + this.props.btnExtraClass}> {this.props.btnContent}</button>
        <div className={"dropDownContent "+leftClass}>
            {this.props.children}
        </div>    
    </div> 
</div>
  }
}

DropDown.propTypes = {
    btnContent:PropTypes.object.isRequired,
    btnExtraClass: PropTypes.string,
};

DropDown.defaultProps = {
    btnContent: <></>,
    btnExtraClass:''
};


export default DropDown;
