

function interpolate(y0,yMax,x0, xMax, x)
{
  let result =  ( y0 * (xMax - x) + yMax* (x - x0) ) / (xMax - x0);
  return result;
};

/*
@rgbColors: [[0,0,0],[123,123,123],[255,255,255]]
@numberOfItens: number of buttons/selections from one color to the other
*/
export function getRgbRanges(rgbColors, numberOfItens)
{
  let numberOfRanges = rgbColors.length -1;
  let breakOffValue = Math.floor(numberOfItens/numberOfRanges);
  let breakOffs = [];
  breakOffs[0] = 0;
  breakOffs[1] = breakOffValue;
  let rgbResults = [];
  let breakTop=1;
  let breakStart=0;
  for(let i=0;i<numberOfItens;i++)
  {
    if (i  % breakOffValue === 0 && i!==0 && i<numberOfItens-1)
    {
       breakStart++;
       breakTop++;
       breakOffs[breakTop] = breakOffs[breakStart] + breakOffValue;
    }
    let useRgbStart = rgbColors[breakStart];
    let useRgbEnd = rgbColors[breakTop];
    //console.log(useRgbStart,useRgbEnd);
    let rgbStartActual = [
      interpolate(useRgbStart[0],useRgbEnd[0],breakOffs[breakStart],breakOffs[breakTop],i),
      interpolate(useRgbStart[1],useRgbEnd[1],breakOffs[breakStart],breakOffs[breakTop],i),
      interpolate(useRgbStart[2],useRgbEnd[2],breakOffs[breakStart],breakOffs[breakTop],i)];
    let rgbEndActual = [
      interpolate(useRgbStart[0],useRgbEnd[0],breakOffs[breakStart],breakOffs[breakTop],i+1),
      interpolate(useRgbStart[1],useRgbEnd[1],breakOffs[breakStart],breakOffs[breakTop],i+1),
      interpolate(useRgbStart[2],useRgbEnd[2],breakOffs[breakStart],breakOffs[breakTop],i+1)];

    rgbResults[rgbResults.length] = {start:rgbStartActual,end:rgbEndActual};
  }
  return rgbResults;
}