
import { computed, toJS } from 'mobx';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { getMagazineRoute } from '../store/AppRoutes';
import { getCurrentLanguage } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { contentTypeToFastType, isValidIndex, isSet } from '../util/typeu';
import { isServer } from '../util/windowUtil';
import { convertDataToShowable } from '../util/text';


export default class PublisherController
{
    fetchPublisherById(index)
    {
        let loadId;
        if (!isSet(index) || !isValidIndex(index))
            return Promise.resolve(true);
        if (Number(index)===Number(MyAppPool.session.publisher.currentPublisher))
        {   
            loadId = MyAppPool.cGlobalLoadingBar.start();
            MyAppPool.cGlobalLoadingBar.end(loadId,true);
            return Promise.resolve(true);
        }
        MyAppPool.session.publisher.publishers[index] = {ready:0};
        MyAppPool.session.publisher.currentPublisher= index;
        loadId = MyAppPool.cGlobalLoadingBar.start(1500);

        if (!isServer())
            brasRequest(routes.resourcePopularCount,false,{resourceType:contentTypeToFastType('publisher'),resourceId:index});
      
        return fetchAndProcess(this.setSessionPublisher,
            routes.getPublisherByIdLng,true,{cid:index,lng: getCurrentLanguage()})
            .then((data) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,true);
                return Promise.resolve(data);
            })
            .catch((e) => {
                MyAppPool.cGlobalLoadingBar.end(loadId,false);
                console.log("Error Fetching publisher",e)
            });
    }

    setSessionPublisher(data0,ready)
    {
        if (!data0.ok)
            return Promise.reject();
        
        let data = data0.data;

        let mangasPublisherMangaTypes = {};
        if (!data.empty)
        {
            for(let i =0; i<data.mangas.length; i++)
            {
            mangasPublisherMangaTypes[data.mangas[i].type]=1;
            }
        }
        mangasPublisherMangaTypes = Object.keys(mangasPublisherMangaTypes);

        data.columItems = [];
        for(let i=0; i< data.extra.length; i++)
        {
            let item = data.extra[i];
            let content = {};
            if (item.type==='url')
            {
                let displayVal = item.mvalue;
                displayVal = displayVal.replace('/',' ');
                
                content =  {name:item.mkey,info:[{type:item.type,value:item.url,display:displayVal}]};
            }
            else
            {
                let displayVal = item.mvalue;
                if (item.type=='date')
                    displayVal = convertDataToShowable(item.mvalue);
                if (displayVal=='Invalid Date')
                    continue;
                content =  {name:item.mkey,info:[{type:item.type,value:item.mvalue,display:displayVal}]};
            }
            data.columItems[data.columItems.length] =content; 
        }
        
        //console.log(data);
        data.mangasPublisherMangaTypes = mangasPublisherMangaTypes;
        data.baseUrl = getMagazineRoute(data.id,data.name);
        data.resourceType = contentTypeToFastType('publisher');
        MyAppPool.session.grades[contentTypeToFastType('publisher')][data.id] = data.grades;
        MyAppPool.session.publisher.publishers[data.id] = {...MyAppPool.session.publisher.publishers[data.id],...data} ;
        MyAppPool.session.publisher.publishers[data.id].ready = ready?1:0;
       
        
        MyAppPool.session.publisher.currentPublisher=data.id;

        MyAppPool.cPageJsonSchema.makeMagazineJsonSchema();

        return Promise.resolve(true);
    }

    @computed get currentPublisher()
    {
        let data = toJS(MyAppPool.session.publisher.publishers[MyAppPool.session.publisher.currentPublisher]);
        if (!isSet(data))
            return {ready:0};
        data.grades = MyAppPool.session.grades[contentTypeToFastType('publisher')][MyAppPool.session.publisher.currentPublisher];
        return data;//MyAppPool.session.publisher.publishers[MyAppPool.session.publisher.currentPublisher];
    }
    
}