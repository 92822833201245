// import "./MusicFloatingMenu.css";
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { isSet } from '../util/typeu';
import MyAppPool from './../AppPool';
import { lng } from '../ulanguages/language';
import "./MusicBoxVertical.css";
import MusicFloatingMenu from './MusicFloatingMenu';
import MusicItem from './MusicItem';
import ListWidget from './ListWidget';
import MusicPlaylistCreator from './MusicPlaylistCreator';


class MusicBoxVertical extends Component {

  constructor(props) {
    super(props);
    this.addAllToQueue = this.addAllToQueue.bind(this);
    this.state = {};
  }

  componentDidMount()
  {

  }

  addAllToQueue(type=-1)
  {
    let {items} = this.props;
    for(let i=0; i < items.length; i++)
    {
      let music = items[i];
      if (type!==-1 && items[i].type!==type)
        continue;
      MyAppPool.cMusic.addToPlayQueue(music);
    }
    MyAppPool.cPopMenu.deactivate();
  }

  render(){

    
    let {items,ready} = this.props;
    if (!ready)
      return <div className="uk-card uk-card-default comp_musicbox skeleton"></div>;
    if (items.length===0) 
      return ( <div className="uk-card uk-card-default comp_musicbox">
        {lng('Trilha Sonora')}
        <div className='emptydiv'>
          <p>{lng('Nenhuma música encontrada')}</p>
          <button aria-label={ lng('Adicionar Música') } className="uk-button uk-button-default"> { lng('Adicionar Música') }</button>
        </div>
      </div>);

    return (
      <div ref={this.musicBoxRef} vid="musicbox" className="uk-card uk-card-default comp_musicbox">
        {lng('Trilha Sonora')}
        <div className="fas fa-ellipsis-v alignBtnRight clickable" 
                onClick={(e)=>{
                  MyAppPool.cPopMenu.set(
                    <div>
                      <ul className="pop_ytmenulist">
                        <li className="pop_ytmenuitem" onClick={()=>this.addAllToQueue(-1)}>
                          <span className={"fas pop_ytmenuitemicon fa-music"} ></span><div className="ytmenuoptiontext">{lng("Adicionar tudo à fila de reprodução")}</div>
                        </li>
                        <li className="pop_ytmenuitem" onClick={()=>this.addAllToQueue(0)}>
                          <span className={"fas pop_ytmenuitemicon fa-hourglass-start"} ></span><div className="ytmenuoptiontext">{lng("Adicionar OPs à fila de reprodução")}</div>
                        </li>
                        <li className="pop_ytmenuitem" onClick={()=>this.addAllToQueue(1)}>
                          <span className={"fas pop_ytmenuitemicon fa-hourglass-end"} ></span><div className="ytmenuoptiontext">{lng("Adicionar EDs à fila de reprodução")}</div>
                        </li>
                        <li className="pop_ytmenuitem" onClick={()=>MyAppPool.cModal.set(
                          lng("Adicionar Lista de Músicas"),
                          <MusicPlaylistCreator items={items}/>,
                          (ok)=>{},'',(cleanup)=>{})}
                        >
                          <span className={"fas pop_ytmenuitemicon fa-plus"} ></span><div className="ytmenuoptiontext">{lng("Criar Lista com essas músicas")}</div>
                        </li>
                    </ul>
                    
                    </div>
                  ,'')}}
                >
          </div>
        <div className="musiclistwrapper">
          <ul className="uk-list uk-list-striped musicItemList">
          {items.map((element, index) => {
              return <MusicItem key={index} music={element} showBars={false} openMenuFunction={(event) => this.openMenu(event,element,index)} />
            })
          }
          </ul>
        </div>
      </div>
    );
  }
}

MusicBoxVertical.propTypes = {
  ready: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      band: PropTypes.string.isRequired,
      where: PropTypes.string.isRequired,
      ytid: PropTypes.string.isRequired
    })
  ),
};
MusicBoxVertical.defaultProps = {
  items: [],
  ready:0
};

export default MusicBoxVertical;
