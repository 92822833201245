import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { contentTypeToFastType } from '../util/typeu';
import MyAppPool from './../AppPool';
import ReactionItemHome from './ReactionItemHome';
import "./ReviewGroup.css";
import ReviewItemHome from './ReviewItemHome';
import SkeletonBlock from './SkeletonBlock';

export let mangaCats= [
  {name:lng("Arte"),icons:["fas fa-paint-brush","fas fa-palette"]},
  {name:lng('Consistencia'),icons:["fab fa-confluence"]},
  {name:lng('Personagens'),icons:['fas fa-running','fas fa-hiking','fas fa-skating']},
  {name:lng('História'),icons:['fas fa-pen']}
];

export let animeCats = [
  {name:lng("Arte"),icons:["fas fa-paint-brush","fas fa-palette"]},
  {name:lng('Música/Sons'),icons:["fas fa-music"]},
  {name:lng('Personagens'),icons:['fas fa-running','fas fa-hiking','fas fa-skating']},
  {name:lng('História'),icons:['fas fa-pen']}
];

let animeContentType = contentTypeToFastType('anime');
let mangaContentType = contentTypeToFastType('manga');

@observer
class ReviewGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {currentPage:1,pageSize:props.pageSize};
    this.showMore = this.showMore.bind(this);
}
  showMore()
  {
    let {currentPage} = this.state;
    if (currentPage<(MyAppPool.session.latestContent.reviews.loadedPages))
        this.setState({currentPage:currentPage+1});
    if (currentPage===(MyAppPool.session.latestContent.reviews.loadedPages))
    {
        MyAppPool.cLatestContent.getMoreRecentReviews();
        this.setState({currentPage:currentPage+1});
    }
  }

  componentDidMount()
  {

  }

  render(){
    let {itens,currentUserId,currentUserRoles,pageSize} = this.props;
    let {currentPage} = this.state;

    let {ready} = this.props;
    if (!ready)
      return <SkeletonBlock  style={{'width':'100%',height:'412px'}}/>
    
    let maxItens = pageSize*currentPage;
    let filteredItens = itens.slice(0,maxItens);
    let lastLoadedBatchAmount=MyAppPool.session.latestContent.reviews.lastLoadedItensAmount;
    let loadedPages = MyAppPool.session.latestContent.reviews.loadedPages;
    return <div className="comp_reviewgroup">
        <div class="flexDrop" style={{}}>
                {filteredItens.map((item, index) => {
                  let mycats = undefined;
                  let itemData = MyAppPool.session.reviewData[item];
                if (itemData.resourcetype===animeContentType)
                  mycats=animeCats;
                if (itemData.resourcetype===mangaContentType)
                    mycats=mangaCats;

                  return <div className='doubleColumnItem' key={index}><ReviewItemHome
                  currentUserId={currentUserId}
                  currentUserRoles={currentUserRoles}
                  reviewItem={itemData}
                  cats={mycats}
                /></div>
                })}
              </div>
        {(lastLoadedBatchAmount===0 && (loadedPages===currentPage)) || filteredItens.length===0 ?
        <></>:
        <button aria-label={lng('Mais')} onClick={this.showMore} className="uk-button uk-button-default moreButton">{lng('Mais') }</button>
        
        }
    </div>;
}
}

ReviewGroup.propTypes = {
  itens: PropTypes.arrayOf(PropTypes.shape({
  displaytext: PropTypes.string.isRequired,
  publishdate:PropTypes.string.isRequired,
  votes:PropTypes.number.isRequired,
  authorname:PropTypes.string.isRequired,
  authorimg:PropTypes.string.isRequired,
  author:PropTypes.number.isRequired
  })),
  currentUserId: PropTypes.number.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  pageSize: PropTypes.number,
  ready:PropTypes.number
};

ReviewGroup.defaultProps = {
    itens: [{
    displaytext: '',
    publishdate:'',
    votes:0,
    authorname:'Defaulter',
    authorimg:'',
    author:0
    }],
    currentUserId: 0,
    currentUserRoles: ['guest'],
    pageSize:4,
    ready:0
};


export default ReviewGroup;
