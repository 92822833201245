import { observer } from 'mobx-react';
import React, { Component } from 'react';
import "./HoverDiv.css";
import MyAppPool from './../AppPool';
import { lng } from '../ulanguages/language';
import { PropTypes } from 'prop-types';

@observer
class HoverDiv extends Component {
  constructor(props) {
    super(props);
    this.state = {shouldBeInTheLeft:false};
    this.elementRef = React.createRef();
    this.moveElementToLeft = this.moveElementToLeft.bind(this);
}

  componentDidMount() {
    this.moveElementToLeft();
  }
  moveElementToLeft() {
    const element = this.elementRef.current;
    const elementRect = element.getBoundingClientRect();
    const halfScreenWidth = window.innerWidth / 2;

    if (elementRect.left > halfScreenWidth) {
      this.setState({shouldBeInTheLeft:true});
    }

  }

  render(){
    let {shouldBeInTheLeft} = this.state;
    let leftClass = "";
    if (shouldBeInTheLeft)
      leftClass="dropDownToTheLeft";
    
    return <div  ref={this.elementRef} {...this.props} className={"comp_hoverdiv "+this.props.className}> 
        <div className="dropDown">
        <button aria-label={this.props.btnContent} className={"dropBtn " + this.props.btnExtraClass}> {this.props.btnContent}</button>
        <div style={{minWidth:this.props.minWidth}} className={"dropDownContent "+leftClass}>
            {this.props.children}
        </div>    
    </div> 
</div>
  }
}

HoverDiv.propTypes = {
    btnContent:PropTypes.object.isRequired,
    btnExtraClass: PropTypes.string,
};

HoverDiv.defaultProps = {
    btnContent: <></>,
    btnExtraClass:'',
    minWidth:200
};


export default HoverDiv;
