
import MyAppPool from '../AppPool';

function moveBar(id){
    if (MyAppPool.session.globalPageLoadingBar.loadingId!==id)
        return;
    if(MyAppPool.session.globalPageLoadingBar.currentValue < 0.8){
        MyAppPool.session.globalPageLoadingBar.currentValue += (1/MyAppPool.session.globalPageLoadingBar.expectedTimeToFinish);
        setTimeout(moveBar, 1,id);
    }
}

function moveBarToEnd(id){ 
    if (MyAppPool.session.globalPageLoadingBar.loadingId!==id)
        return;

    if(MyAppPool.session.globalPageLoadingBar.currentValue < 1){
        MyAppPool.session.globalPageLoadingBar.currentValue += (1/MyAppPool.session.globalPageLoadingBar.expectedTimeToFinish);
        setTimeout(moveBarToEnd, 1,id);
    }
    else{
        setTimeout(() => {
            MyAppPool.session.globalPageLoadingBar.currentValue = 0;
        }, 500);
    }
}

export default class GlobalPageLoadingBarController
{
    start(expectedTimeToFinish = 100){
        MyAppPool.session.globalPageLoadingBar.expectedTimeToFinish = expectedTimeToFinish;
        MyAppPool.session.globalPageLoadingBar.currentValue=0;
        MyAppPool.session.globalPageLoadingBar.loadingId++;
        MyAppPool.session.globalPageLoadingBar.success = true;
        moveBar(MyAppPool.session.globalPageLoadingBar.loadingId);
        return MyAppPool.session.globalPageLoadingBar.loadingId;
    };
    end(id,success){
        MyAppPool.session.globalPageLoadingBar.success = success;
        MyAppPool.session.globalPageLoadingBar.expectedTimeToFinish = 100;
        if(id === MyAppPool.session.globalPageLoadingBar.loadingId){
            moveBarToEnd(id);
        }
    };
}