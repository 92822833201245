import loadable from "@loadable/component";
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
//import YouTube from 'react-youtube';
import MyAppPool from '../AppPool';
import { getObraRoute, getTrailersRoute } from '../store/AppRoutes';
import { lng } from '../ulanguages/language';
import { getRelatedTypeIcon } from '../util/icon';
import { convertDataToShowable, getImgAddress } from '../util/text';
import { isSet } from '../util/typeu';
import SkeletonBlock from '../view_component/SkeletonBlock';
import { isMobile } from './../util/mobile';
import { getViewportWidthHeight, isServer } from './../util/windowUtil';
import CSSTransition2 from './CSSTransition2';
import Spinner from './Spinner';
import './TrailerBox.css';
import LinkV2 from "./LinkV2";

const YouTube = loadable(() => import(/* webpackChunkName: "react-youtube" */ 'react-youtube'));


function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}
const nextTime=5000;
@observer
class TrailerBox extends Component {
  constructor(props)
  {
    super(props);
    this.state = {play:false,currentIndex:0,loading:false,disableAutomaticSwitch:false};
    this.onVideoEnd = this.onVideoEnd.bind(this);
    this.playCurrent = this.playCurrent.bind(this);
    this.playItem = this.playItem.bind(this);
    this.nextItem = this.nextItem.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.listRef = React.createRef();
  }

  componentDidMount()
  {
    this.nextItem(true);
  }
  componentWillReceiveProps()
  {
  }
  onVideoEnd()
  {
    MyAppPool.cMusic.startIfShouldResume();
    this.setState({play:false});
  }
  playCurrent()
  {
    MyAppPool.cMusic.stopAndShouldResumeWhenPossible();
    this.setState({play:true});
  }
  playItem(index)
  {
    MyAppPool.cMusic.stopAndShouldResumeWhenPossible();
    this.setState({currentIndex:index,play:true});
  }
  nextItem(start)
  {
    let {currentIndex,play,disableAutomaticSwitch} = this.state;
    let {items} = this.props;
    if (start)
    {
      setTimeout(()=>this.nextItem(false),nextTime);
      return;
    }
    if (play===false && disableAutomaticSwitch===false)
    {
      let nextIndex = ((currentIndex+1) % items.length);
      this.setState({currentIndex:nextIndex});
      const node = this.listRef.current;
      if (isSet(node))
      {
        let windowDimensions = getViewportWidthHeight();
        let height = 112;
        if (windowDimensions.width>800)
        {
          let totalSize = node.scrollHeight - node.clientHeight;
         node.scrollTop = ((totalSize/items.length)*nextIndex) +(nextIndex*2.5);//fix for accumulated height errors
        }else{
          let cardWidth=70;
          node.scrollLeft =nextIndex*cardWidth;
        }
        
      }
    }
    setTimeout(()=>this.nextItem(false),nextTime);
  }
  loadMore()
  {
    this.setState({loading:true});
    MyAppPool.cLatestContent.getMoreRecentTrailers()
    .finally(()=>this.setState({loading:false}));
  }
  tryChangeIndexTo(newIndex)
  {
    let {currentIndex,play,disableAutomaticSwitch} = this.state;
    let enableTime=15000;
    if (play===false)
    {
      if (disableAutomaticSwitch===false)
        setTimeout(()=>{this.setState({disableAutomaticSwitch:false})},enableTime)
      this.setState({currentIndex:newIndex,disableAutomaticSwitch:true});
    }
  }

  render(){

    let {currentIndex,play,loading} = this.state;
    let {items} = this.props;
    let {ready} = this.props;
    if (!ready)
      return <SkeletonBlock  style={{'width':'100%',height:'437px'}}/>
    if (items.length==0)
        return <></>;
    let current = items[currentIndex];
    if (typeof current ==='undefined')
      return <></>;
    
    let videoHeight = '440px';
    if (isMobile.any)
      videoHeight='340px';
      
    const trailerOpts = {
        width: '100%',
        height: videoHeight,
        playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
        enablejsapi:1,
        controls:1
        }
    };
    /*onReady={this.onYoutubeReady}
                      onPause={this.onPause}
                      onPlay={this.onPlay}
                      onEnd={this.onVideoEnd}
                      onError = {this.onError}
                      autoplay= {shouldPlay}*/
    
    return (
        <div  className="trailerBox trailerFlex">
            <div className="trailerVideo" style={{backgroundImage: "url('https://img.youtube.com/vi/"+current.ytid+"/hqdefault.jpg')"}}
        onClick={()=>{this.playCurrent()}}>
            <CSSTransition2
                in={(play)}
                timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                }}
                classNames="bare"
                unmountOnExit
            >
            <YouTube
                videoId={current.ytid}
                id='homeTrailerPlayer'
                containerClassName='homeTrailerPlayer'
                autoplay={false}
                opts={trailerOpts}
                onEnd={this.onVideoEnd}
            />
            </CSSTransition2>
            {!play?
            <div className="videoShow">
              <div className='trailerTitle'><h2 className='alwaysWhiteText'>{current.title}</h2></div>
                <i className="far fa-play-circle playIcon iconButton clickable playBtn" />
                <Link aria-label={current.title} to={getObraRoute(current.animeid,current.title,'anime','#top')}><LazyLoadImage alt={lng("Capa de")+" "+current.title} src={getImgAddress(current.mainimg,'imgs/defaults/anime.webp','x50')} key={current.id} className="videoCapa" data-uk-tooltip={current.title}/></Link>
            </div>
            :<></>
            }
            </div>
            <div  className="trailerList">
              <ul ref={this.listRef} className="listItens">
                {items.map((element, index) => {
                  let active='';
                  let icon = getRelatedTypeIcon(element.type);
                  let iconText = lng(element.type);
                  if (isMobile.any)
                  {
                    let obraRoute = getObraRoute(element.animeid,element.title,'anime','#top');
                  }

                  if (index===currentIndex)
                    active='active';
                return (
                <li className="upperListItem" key={index} onMouseOver={()=>this.tryChangeIndexTo(index)}>
                  <div className={'uk-flex uk-flex-row uk-card listItem '+active} onClick={()=>{this.playItem(index)}}>
                    <Link aria-label={element.title} className='listImageLink' onClick={(e)=>(isMobile.any)?e.preventDefault():null} to={getObraRoute(element.animeid,element.title,'anime','#top')}>
                      <LazyLoadImage alt={lng("Capa de")+" "+element.title} className='listImage'  src={getImgAddress(element.mainimg,'imgs/defaults/anime.webp','x50')}/>
                    </Link>
                    <div className="textContent">
                      <Link to={getObraRoute(element.animeid,element.title,'anime','#top')}><h5>{element.title+" "}
                      {icon? <i className={icon + ' searchIcon'} data-uk-tooltip={iconText}></i> : <></>}</h5> 
                      </Link>
                      {(isSet(element.airfrom) && element.airfrom!='')?
                        <div className="itemUnderText" onClick={()=>{}}>
                          {lng('Lançamento')}<br/>
                          {convertDataToShowable(element.airfrom)}
                        </div>
                      :<></>}
                      <span onClick={this.play}  className="fas fa-play-circle ytplayicon clickable"></span>
                    </div>
                    
                  </div>
                    <div className="uk-nav-divider"></div> 
                </li>)})
                }
                <li>
                <LinkV2 aria-label={lng("Tudo")} to={getTrailersRoute('#top')} className="link-button maisbtn" onClick={this.loadMore}>{lng('Tudo')+' '}</LinkV2>
                  {!loading?
                    <button aria-label={lng("Mais")} className="link-button uk-align-right maisbtn" onClick={this.loadMore}>{lng('Mais')}</button>
                    :<Spinner/>
                 }<br/><br/></li>
            </ul>
          </div>
        
      </div>
    );
  }
}


TrailerBox.propTypes = {
  ready:PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(
      {
        title: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        ytid: PropTypes.string.isRequired,
        mainimg: PropTypes.string,
        airfrom:PropTypes.string,
        type:PropTypes.string,
        animeid:PropTypes.number.isRequired,
      }
      ))
};
TrailerBox.defaultProps = {
    items:[],
    ready:0
};



export default TrailerBox;
