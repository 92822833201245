import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { lng } from '../ulanguages/language';
import { isServer } from '../util/windowUtil';
import MyAppPool from './../AppPool';
import { isMobile } from './../util/mobile';
import { isSet, userFastResourceType } from './../util/typeu';
import CSSTransition2 from './CSSTransition2';
import CapaWorkLikeButton from './CapaWorkLikeButton';
import ChangeableImg from './ChangeableImg';
import Grader from './Grader';
import LinkV2 from './LinkV2';
import ListWidget from './ListWidget';
import "./SearchCard.css";
import SkeletonBlock from './SkeletonBlock';
import StarsRating from './StarsRating';
import FormMusicUpdateVideo from './FormMusicUpdateVideo';


@observer
class SearchCard extends Component {

  constructor(props)
  {
    super(props);
    this.state = {
        hover:false,
        cooldown:false,
    };
    this.addToCurrentPlayList = this.addToCurrentPlayList.bind(this);
    this.play = this.play.bind(this);
    this.renderAsList = this.renderAsList.bind(this);
  }

  componentDidMount()
  {

  }

  addToCurrentPlayList()
  {
    let {playItem} =this.props;
    if (isSet(playItem))
      MyAppPool.cMusic.addToPlayQueue(this.props.playItem);
    MyAppPool.cPopMenu.deactivate();
  }
  play()
  {
    let {playItem} =this.props;
    if (isSet(playItem))
    {
      MyAppPool.cMusic.addToPlayQueue(playItem);
      MyAppPool.cMusic.playMusicObject(playItem);
    }
  }

  renderAsList()
  {
    let {position,grade, playItem,expand,gradeable,isHentai,title,url,resourceType,imgurl, key, genres, date, resourceId,icon,iconText,gradeOptions,alwaysShowName} = this.props;
    let mais18='';
    let mais18Text='';
    let useDate = date;
    if (!isSet(useDate))
      useDate='';
    if (isHentai)
    {
      mais18='mais18';
      mais18Text='18+';
    }
    let hovered='';
    if (this.state.hover)
      hovered = 'hovered';

    let showPlayButtons = false;
    if (isSet(playItem))
    {
      if (playItem.ytid!=='')
          showPlayButtons=true;
    }
    let transitionTime=50;
    
    let addBtnClasses = 'far fa-plus-square';
    let mySelectedGrade = MyAppPool.session.user.grades[resourceType][resourceId];
    let mySelectedGradeToPrint='';
    if (isSet(mySelectedGrade) && mySelectedGrade!==-1)
    {
      mySelectedGradeToPrint = '('+mySelectedGrade+')';
      addBtnClasses = 'fas fa-check-square comp_searchcard_graded';
    }
    let allowList=true;

    if (resourceType===userFastResourceType)
    {
      allowList=false;
    }

    let expandableClass = '';
    if (expand)
      expandableClass='comp_searchcard_expandable';

    return <LazyLoadComponent key={key}
      scrollPosition={MyAppPool.scrollPosition}
      
      visibleByDefault={isServer()}
      placeholder={<SkeletonBlock  style={{'width':'100%',height:'55px', 'marginTop':'8px'}}/>}
      >
      <div className={"comp_searchcard listMode "+hovered}
      onMouseEnter={()=>{this.setState({hover:true}) } } onMouseLeave={()=>{this.setState({hover:false}) } }
      >
        <div className="uk-flex uk-flex-row" style={{width:"100%", marginBottom:"5px"}}>
          <div style={{flexGrow:"1"}}>
            <LinkV2 aria-label={title} className={''} to={url!==''?url:undefined}>
            <ChangeableImg alt={lng("Capa de")+" "+title} className="listModeImg" src={imgurl} style={{'marginTop':'0px'}}/>
            <ChangeableImg alt={lng("Capa de")+" "+title} className="listModeImgBig" src={imgurl} style={{'marginTop':'0px'}}/>
            </LinkV2>
          </div>
          <div style={{flexGrow:"1", width:"40%", textAlign:"left",paddingLeft:"5px"}}>
            <LinkV2 className={''} to={url!==''?url:undefined}>
            {title}
            </LinkV2>
          </div>
          <div style={{flexGrow:"2"}}>
            {isSet(grade)?<StarsRating className="iconButton stars" data-uk-tooltip={lng("Usuário")+" "+grade.actualVal} actualVal={grade.actualVal} vertical={false}/>: <div style={{width:"138px"}}></div>}
          </div>
          {(!isMobile.any)?
          <div style={{flexGrow:"1"}}>
            {useDate!==''?<i className="fas fa-calendar-alt bottomCalendar searchIcon" data-uk-tooltip={useDate}></i>:<></>}<br/>
          </div>
          :<></>
          }
          <div style={{flexGrow:"1"}}>
            {(isSet(playItem) && showPlayButtons)?
              <span onClick={this.play} style={{fontSize:"1.5em"}} data-uk-tooltip={lng('Tocar')} className="fas fa-play-circle ytplayicon"></span>
              :<></>}
          </div>
          {(!isMobile.any)?
          <div style={{flexGrow:"1"}}>
            {icon? <i className={icon + ' searchIcon'} data-uk-tooltip={iconText}></i> : <></>} 
          </div>
          :<></>}
          <div style={{flexGrow:"1"}}>
          <CapaWorkLikeButton 
              resourceType={resourceType}
              resourceId={resourceId}
              className={"searchIcon"}
              />
          </div>
          <div style={{flexGrow:"1"}}>
              <div className="uk-align-right clickable" onClick={()=>{
                MyAppPool.cPopMenu.set(
                  <div>
                    <button aria-label={lng("Fechar")} className="uk-button uk-button-default searchCloseBtn" onClick={()=>MyAppPool.cPopMenu.deactivate()}>{lng("Fechar")}</button>
                    {isSet(playItem)?
                    <div myid={playItem.id}>
                    <ul className="pop_ytmenulist">
                    {MyAppPool.cAuth.isDataMod()?[<li className="pop_ytmenuitem"onClick={()=>{
                        MyAppPool.cModal.set(lng("Atualizar Video"),
                        <FormMusicUpdateVideo musicId={playItem.id}/>
                        ,null,'',()=>{})
                        }}
                        >{lng("Atualizar Video")}</li>
                      ]:<></>}
                      <li className="pop_ytmenuitem" onClick={this.addToCurrentPlayList}>
                        <span className={"fas pop_ytmenuitemicon fa-music"} ></span><div className="ytmenuoptiontext">{lng("Adicionar à fila de reprodução")}</div>
                      </li>
                  </ul>
                  </div>:
                  <></>}
                    {gradeable?
                    <Grader resourceId={resourceId} resourceType={resourceType} gradeOptions={gradeOptions}>
                      <button aria-label={lng("Avaliar")}  className="uk-button uk-button-default searchCloseBtn">{lng('Avaliar')} <i className={addBtnClasses}></i> {mySelectedGradeToPrint}</button>
                    </Grader>
                    :<></>}
                  {allowList?<ListWidget resourceType={resourceType} resourceId={resourceId} />:<></>}
                  </div>
                );
              }}>
                <i className="fas fa-ellipsis-v threeDotsVertical"></i>
              </div>
              
          </div>
        </div>
      </div>

              

      </LazyLoadComponent>


  }

  render(){
    let {position,grade,mode, playItem,expand,gradeable,isHentai,title,url,resourceType,imgurl, key, genres, date, resourceId,icon,iconText,gradeOptions,alwaysShowName} = this.props;
    let mais18='';
    let mais18Text='';
    let useDate = date;
    if (!isSet(useDate))
      useDate='';
    if (isHentai)
    {
      mais18='mais18';
      mais18Text='18+';
    }
    let hovered='';
    if (this.state.hover)
      hovered = 'hovered';

    let showPlayButtons = false;
    if (isSet(playItem))
    {
      if (playItem.ytid!=='')
          showPlayButtons=true;
    }
    let colors = ['#ff7f0e', '#2ca02c', '#1f77b4', '#d62728', '#9467bd'];
    let colorIndex = 0; 
    if (isSet(position))
      colorIndex = (position) % colors.length;
    const color = colors[colorIndex];
    let allowList = true;
    
    let addBtnClasses = 'far fa-plus-square';
    let mySelectedGrade = MyAppPool.session.user.grades[resourceType][resourceId];
    let mySelectedGradeToPrint='';
    if (isSet(mySelectedGrade) && mySelectedGrade!==-1)
    {
      mySelectedGradeToPrint = '('+mySelectedGrade+')';
      addBtnClasses = 'fas fa-check-square comp_searchcard_graded';
    }
    let imageHeight = '270px';
    let placeHolderHeight = '270px';
    /*
    if (resourceType===userFastResourceType)
    {
      placeHolderHeight='140px';
      imageHeight='150px';
      allowList=false;
    }
    */
    let transitionTime=50;
    let expandableClass = '';
    if (expand)
      expandableClass='comp_searchcard_expandable';
    if (mode==='list')
      return this.renderAsList();

    if (isMobile.any)
    {
      return <LazyLoadComponent key={key}
      scrollPosition={MyAppPool.scrollPosition}
      visibleByDefault={isServer()}
      placeholder={<SkeletonBlock  style={{'width':'150px',height:placeHolderHeight, 'marginTop':'8px'}}/>}
      >
      <div key={key}
      className={"comp_searchcard " + expandableClass}>
      <div className="outerTitle">{title}</div>
        <div className='mais18Text'>{mais18Text}</div>
        <div className={mais18 +' ' + hovered}>
          <LinkV2 className={''} to={url} onClick={()=>{
            if (isSet(playItem))
              this.play()
              return;
          }}>
          <div style={{height:imageHeight, overflow:"hidden"}}>
          <ChangeableImg usePadding={true} paddingAmount={80} alt={lng("Capa de")+" "+title} className="" src={imgurl} style={{width:"100%", 'marginTop':'8px'}}/>
          </div>
          
          {(isSet(playItem))?
              <span onClick={this.play} data-uk-tooltip={lng('Tocar')} className="fas fa-play-circle ytplayiconMobile"></span>
              :<></>
            }
          </LinkV2>
        </div>
        {isSet(position)?<div style={{backgroundColor:color}} className="positionBall borderedText">{position}</div>: <></>}
      </div>
      </LazyLoadComponent>
    }
    
    //DESKTOP
    return <LazyLoadComponent key={key}
      scrollPosition={MyAppPool.scrollPosition}
      visibleByDefault={isServer()}
      placeholder={<SkeletonBlock  style={{'width':'150px',height:placeHolderHeight, 'marginTop':'8px'}}/>}
      >
      <div key={key}
      onMouseEnter={()=>{this.setState({hover:true}) } } onMouseLeave={()=>{this.setState({hover:false}) } }
      className={"comp_searchcard " + expandableClass}>
        
        {alwaysShowName? <div className="uk-overlay uk-overlay-primary uk-position-top nameBox">{title}</div> : <></> }
          <CSSTransition2
          in={(this.state.hover && !alwaysShowName)?1:0}
          timeout={transitionTime}
          classNames="search-node"
          unmountOnExit>
            <div className="uk-overlay uk-overlay-primary uk-position-top nameBox">
                {title}
            </div>
        </CSSTransition2>
        <div className='mais18Text'>{mais18Text}</div>
        <div className={mais18 +' ' + hovered}>
          <LinkV2 aria-label={title} className={''} to={url!==''?url:undefined}>
            <div style={{height:imageHeight,"width":"100%", overflow:"hidden"}}>
            <ChangeableImg usePadding={true} paddingAmount={80} alt={lng("Capa de")+" "+title} className="" src={imgurl} style={{width:"100%", 'marginTop':'8px'}}/>
            </div>
            </LinkV2>
        </div>
        <CSSTransition2
          in={this.state.hover?1:0}
          timeout={transitionTime}
          classNames="search-node"
          unmountOnExit>
            <div className="bottomMenu">
            <div className="bottomDotMenu clickable" onClick={()=>{
                MyAppPool.cPopMenu.set(
                  <div>
                    <button aria-label={lng("Fechar")} className="uk-button uk-button-default searchCloseBtn" onClick={()=>MyAppPool.cPopMenu.deactivate()}>{lng("Fechar")}</button>
                    {isSet(playItem)?
                    <div myid={playItem.id}>
                    <ul className="pop_ytmenulist ">
                    {MyAppPool.cAuth.isDataMod()?[<li className="pop_ytmenuitem"onClick={()=>{
                        MyAppPool.cModal.set(lng("Atualizar Video"),
                        <FormMusicUpdateVideo musicId={playItem.id}/>
                        ,null,'',()=>{})
                        }}
                        >{lng("Atualizar Video")}</li>
                      ]:<></>}
                      <li className="pop_ytmenuitem" onClick={this.addToCurrentPlayList}>
                        <span className={"fas pop_ytmenuitemicon fa-music"} ></span><div className="ytmenuoptiontext">{lng("Adicionar à fila de reprodução")}</div>
                      </li>
                  </ul>
                  </div>:
                  <></>}
                    
                    {gradeable?
                    <Grader resourceId={resourceId} resourceType={resourceType} gradeOptions={gradeOptions}>
                      <button aria-label={lng("Avaliar")} className="uk-button uk-button-default searchCloseBtn">{lng('Avaliar')} <i className={addBtnClasses}></i> {mySelectedGradeToPrint}</button>
                    </Grader>
                    :<></>}
                    {allowList?<ListWidget resourceType={resourceType} resourceId={resourceId} />:<></>}
                  </div>
                );
              }}>
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <CapaWorkLikeButton 
              resourceType={resourceType}
              resourceId={resourceId}
              />
              
              {useDate!==''?<i className="fas fa-calendar-alt bottomCalendar searchIcon" data-uk-tooltip={useDate}></i>:<></>}<br/>
              {icon? <i className={icon + ' searchIcon'} data-uk-tooltip={iconText}></i> : <></>} 
              {isSet(grade)?<StarsRating className="iconButton verticalStars" data-uk-tooltip={grade.actualVal} actualVal={grade.actualVal} vertical={true}/>: <></>}
              
   
            </div>
        </CSSTransition2>
        <CSSTransition2
          in={this.state.hover?1:0}
          timeout={transitionTime}
          classNames="search-node"
          unmountOnExit>
            <div className="bottomGenres">
            {(isSet(playItem) && showPlayButtons)?
              <span onClick={this.play} data-uk-tooltip={lng('Tocar')} className="fas fa-play-circle ytplayicon"></span>
              :<></>
            }
            <Link aria-label={title} className={''} to={url}>
            {genres.map((item, index) => {
              if (index>=7)
                return <></>;
              return <span  key={index} className="uk-badge genretag">{item}</span> 
            })
            }
            </Link>
            </div>
        </CSSTransition2>
        {isSet(position)?<div style={{backgroundColor:color}} className="positionBall borderedText">{position}</div>: <></>}
      </div>
      </LazyLoadComponent>
}
}


SearchCard.propTypes = {
    alwaysShowName:PropTypes.bool,
    isHentai: PropTypes.bool.isRequired,
    mode:PropTypes.string,
    title:PropTypes.string.isRequired,
    genres:PropTypes.arrayOf(PropTypes.string),
    url:PropTypes.string.isRequired,
    resourceType:PropTypes.string.isRequired,
    imgurl:PropTypes.string.isRequired,
    date:PropTypes.string,
    icon:PropTypes.string,
    iconText:PropTypes.string,
    resourceId:PropTypes.number.isRequired,
    position:PropTypes.number,
    gradeable:PropTypes.bool.isRequired,
    expand:PropTypes.bool,
    grade: PropTypes.shape({
      minVal:PropTypes.number,
      maxVal:PropTypes.number,
      actualVal:PropTypes.number.isRequired
    }),
    playItem: PropTypes.shape({
      title: PropTypes.string.isRequired,
      band: PropTypes.string.isRequired,
      where: PropTypes.string.isRequired,
      ytid: PropTypes.string.isRequired,
      id:PropTypes.number.isRequired}),
  };
  SearchCard.defaultProps = {
    alwaysShowName:false,
    isHentai:false,
    expand:true,
    playMusicUrl:'',
    title:'',
    url:'',
    resourceType:0,
    imgurl:'',
    key:0,
    gradeable:false,
    genres:[],
    date:'',
    resourceId:0,
    iconText:'',
    gradeOptions:{
      minVal: 5,
      maxVal: 10,
      increment: 0.5,
      rgbStart : [153,0,0],
      rgbMiddle : [255, 255, 153],
      rgbEnd: [0, 255, 85]
    },
    position:undefined,
    playItem:undefined,
    grade:undefined,
    mode:'card'
  };
  
  /*modes: card, list*/


export default SearchCard;
