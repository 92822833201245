import React, { Component } from 'react';
import './CycleBanner.css'; // Ensure you create this CSS file
import { PropTypes } from 'prop-types';
import LinkV2 from './LinkV2';

class CycleBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0
    };
    this.intervalId = null;
  }

  componentDidMount() {
    this.startAutoCycle();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  startAutoCycle = () => {
    const { interval = 5 } = this.props;
    this.intervalId = setInterval(this.nextImage, interval * 1000);
  }

  nextImage = () => {
    this.setState(prevState => ({
      currentIndex: (prevState.currentIndex + 1) % this.props.images.length
    }));
  }

  prevImage = () => {
    this.setState(prevState => ({
      currentIndex: (prevState.currentIndex - 1 + this.props.images.length) % this.props.images.length
    }));
  }

  goToImage = (index) => {
    clearInterval(this.intervalId);
    this.setState({ currentIndex: index }, this.startAutoCycle);
  }

  renderDots() {
    const { images, maxDots = 10 } = this.props;
    const { currentIndex } = this.state;
    const totalImages = images.length;

    let start = Math.max(0, currentIndex - Math.floor(maxDots / 2));
    let end = Math.min(totalImages, start + maxDots);

    if (end - start < maxDots) {
      start = Math.max(0, end - maxDots);
    }

    const dots = [];
    for (let i = start; i < end; i++) {
      dots.push(
        <span
          key={i}
          className={`banner-dot ${currentIndex === i ? 'active' : ''}`}
          onClick={() => this.goToImage(i)}
        ></span>
      );
    }
    return dots;
  }

  render() {
    const { images } = this.props;
    const { currentIndex } = this.state;
    if (images.length === 0) {
      return <></>;
    }

    return (
      <div className="banner-container">
        <div
          className="banner-image"
          style={{ backgroundImage: `url(${images[currentIndex].image})` }}
        >
          <LinkV2 to={images[currentIndex].url} className="banner-link"></LinkV2>
        </div>
        {images.length>1?
        <div className="banner-navigation">
          <button className="banner-arrow left" onClick={this.prevImage}>&#9664;</button>
          <div className="banner-dots">
          {this.renderDots()}
          </div>
          <button className="banner-arrow right" onClick={this.nextImage}>&#9654;</button>
        </div>
        :<></>}
      </div>
    );
  }
}

CycleBanner.propTypes = {
    interval:PropTypes.number,
    images:PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.string.isRequired,
        url: PropTypes.string
    }))
  };

  CycleBanner.defaultProps = {
    images:[],
    interval:7
  };


export default CycleBanner;