import PropTypes from 'prop-types';
import React, { Component } from 'react';
import "./ButtonsPanel.css";
import { isSet } from '../util/typeu';

class ButtonsPanel extends Component {
  componentDidMount()
  {

  }

  render(){
    let {buttons} = this.props;
    let btnWidth = 100/buttons.length;
    btnWidth = ''+btnWidth+'%';
    return (
        <div  className="uk-align-center comp_buttonsPanel">
            {buttons.map((element, index) => {
              
                let text = element.text;
                let faicon = element.faicon;
                if (!faicon)
                    faicon='';
                if (!text)
                    text = '';
                let icon = element.ukicon?<span data-uk-icon={"icon:  "+element.ukicon}></span> : <span className={faicon}></span>;
                if (isSet(element.url))
                return (
              <a aria-label={element.tooltip} data-uk-tooltip={element.tooltip} style={{width:btnWidth}}  key={index} onClick={element.onClick} target='_blank' rel="noopener noreferrer" href={element.url} className="uk-button uk-button-default sharebutton"> {icon} {text} </a>)
            else
            return (
              <button aria-label={element.tooltip} data-uk-tooltip={element.tooltip} style={{width:btnWidth}}  key={index} onClick={element.onClick} target='_blank' rel="noopener noreferrer" href={element.url} className="uk-button uk-button-default sharebutton"> {icon} {text} </button>)
              
            })}
        </div>
        );
}
}

ButtonsPanel.propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
        onClick: PropTypes.func,
        ukicon: PropTypes.string,
        faicon: PropTypes.string,
        tooltip: PropTypes.string
      })
    ),
  };

  ButtonsPanel.defaultProps = {
    items: []
  };

export default ButtonsPanel;
