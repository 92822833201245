
import { getImgAddress } from './text';
import { isSet } from './typeu';

var flagIcons = {
    'un':'far fa-flag',
    'jp':'jp',
    'us':'us',
    'fr':'fr',
    'es':'es',
    'kr':'kr',
    'de':'de',
    'it':'it',
    'hu':'hu',
    'br':'br',
    'il':'il',
    'tw':'tw',
    'sg':'sg',
    'pt':'br',
    'en':'us'
};

export function getSwitchFlags(){
    let flags = {
    //english speaking countries
    "en":{
        'us':'us',
        'uk':'england',
        'ca':'ca',
        'au':'au',
        'nz':'nz',
        'mt':'mt',
        'ie':'ie'
        },
        //spanish speaking countries
        es:{
        'es':'es',
        'mx':'mx',
        'co':'co',
        'ar':'ar',
        'cl':'cl',
        'bo':'bo',
        'py':'py',
        'uy':'uy',
        'pe':'pe'
        },
        //portuguese speaking countries
        pt:{
        'br':'br',
        'pt':'pt',
        'ao':'ao',
        'cv':'cv',
        'gw':'gw',
        'mz':'mz',
        'st':'st',
        },
        //deutsch speaking countries
        de:{
        'de':'de',
        'ch':'ch',
        'at':'at',
        'lu':'lu',
        'li':'li'
        }
    }
    flags.de = Object.values(flags.de);
    flags.es = Object.values(flags.es);
    flags.pt = Object.values(flags.pt);
    flags.en = Object.values(flags.en);

    return flags;
}

var animeTypeIcons = {
    '':'',
    'ONA':'fas fa-wifi',
    'OVA':'fas fa-bullseye',
    'TV':'fas fa-tv',
    'Music':'fas fa-music',
    'Special':'fas fa-gift',
    'Movie':'fas fa-film',
    'Manga':'far fa-file-image',
    'Doujinshi':'fas fa-images',
    'Manhwa':'fas fa-file-image',
    'Manhua':'fas fa-file-image',
    'One-Shot':'fas fa-book-open',
    'Novel':'fas fa-book',
    'Empresa':'fas fa-building',
    'Pessoa':'fas fa-people-carry',
    'Personagem':'fas fa-skating',
    'Revista':'fas fa-book-open'
};

export function getFlag(natcode)
{
    if (!isSet(natcode))
        return 'far fa-flag';
    let f = flagIcons[natcode];
    if (isSet(f))
        return f;
    return 'far fa-flag';
}

export function getRelatedTypeIcon(type)
{
    if (!isSet(type))
        return '';
    let f = animeTypeIcons[type];
    if (isSet(f))
        return f;
    return '';
}
export function getUserAvatar(imgAddress,imgHash,size='')
{
    let userImg=imgAddress;
    if (!isSet(imgAddress) || imgAddress===''){
        userImg = "https://www.gravatar.com/avatar/" + imgHash +"?d=retro";
    }
    return getImgAddress(userImg,size);
}

