import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { getRgbRanges } from './../util/gradient';
import "./GradeBox.css";
import Grader from './Grader';
import { lng } from '../ulanguages/language';


@observer
class GradeBox extends Component {

  constructor(props)
  {
    super(props);
    this.state = {grade:-1};
    this.onChangeGradeValue = this.onChangeGradeValue.bind(this);
    this.renderGrades = this.renderGrades.bind(this);
  }
  componentDidMount()
  {
  }

  onChangeGradeValue(value)
  {
    this.setState({grade:value});
  }
  
  renderGrades(stateGrade)
  {
    let gradesToPrint = [];
    let {mySelectedGrade} = this.props;
    let {minVal,maxVal,increment,rgbStart,rgbEnd,rgbMiddle} = this.props.gradeOptions;
    
    let count = 0;
    let colors = getRgbRanges ([rgbStart,rgbMiddle,rgbEnd], (maxVal-minVal)/increment +1);
    let rgbStartActual = [0,0,0];
    let rgbEndActual = [0,0,0];
    let width=90/((maxVal-minVal)/increment);
    for(let i=minVal;i<=maxVal;i+=increment)
    {
      rgbStartActual = colors[count].start;
      rgbEndActual = colors[count].end;

      let gradStart = `rgb(${rgbStartActual[0]}, ${rgbStartActual[1]}, ${rgbStartActual[2]})`;
      let gradEnd = `rgb(${rgbEndActual[0]}, ${rgbEndActual[1]}, ${rgbEndActual[2]})`;

      let gradient = `linear-gradient(to right, ${gradStart}, ${gradEnd})`;
      
      let stars = [];
      for(let k=0;k<(maxVal-minVal);k+=1)
      {
        if (k+minVal >= i)
          stars[k] = <i className="far fa-star star"></i>
        if (k+minVal+0.5 < i)
          stars[k] = <i className="fas fa-star star"></i>
        if (k+minVal+0.5 === i)
          stars[k] = <i className="fas fa-star-half-alt star"></i>
      }
      let autoFocusSource = (i===mySelectedGrade) ? 'true' : undefined;
      gradesToPrint[count] = <button aria-label={lng('Nota')+" "+i} autofocus={autoFocusSource} autoFocus={autoFocusSource} onClick={()=>this.onChangeGradeValue(i)} className="clickable comp_gradeBox_gradientButtons" key={count} style={{backgroundImage:gradient, width:width+'%'}}>{i} <br/> {stars}</button>;
      count++;
    }
    return gradesToPrint;
  }

  render(){

    let {value,amount,title,bywho,ready, mySelectedGrade, resourceId,resourceType,gradeOptions} = this.props;
    let useMySelectedGrade=mySelectedGrade;
    if (typeof useMySelectedGrade !== 'number')
    {
      useMySelectedGrade=-1;
    }
    let {grade} = this.state;
    if (!ready)
    {
      return (
        <div className="uk-card uk-card-default comp_gradeBox skeleton">
        </div>);
    }
    let addBtnClasses = 'far fa-plus-square';
    if (useMySelectedGrade!==-1)
    {
      addBtnClasses = 'fas fa-check-square graded';
    }
    return (
    <div className="uk-card uk-card-default comp_gradeBox">
      <div className="nota">{title}</div>
      <div className="uk-text-lead uk-text-bold notatext"> {amount>0?value.toFixed(1) : '--'}</div>
      <div className="uk-text-small notausers"> {amount.toFixed(0)} <br/> {bywho}</div>
        <Grader resourceId={resourceId} resourceType={resourceType} gradeOptions={gradeOptions} mySelectedGrade={useMySelectedGrade}>
          <div className="addbtn clickable">
            <i className={addBtnClasses}></i>
          </div>
        </Grader>
  </div>);
}
}

GradeBox.propTypes = {
  value: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  bywho: PropTypes.string.isRequired,
  ready: PropTypes.number.isRequired,
  resourceId:PropTypes.number.isRequired,
  resourceType:PropTypes.number.isRequired,
  gradeOptions:PropTypes.shape({
    minVal: PropTypes.number.isRequired,
    maxVal: PropTypes.number.isRequired,
    increment: PropTypes.number.isRequired
  })
};
GradeBox.defaultProps = {
  value: 0,
  amount: 0,
  resourceId:0,
  resourceType:0,
  title: '',
  bywho: '',
  ready:0,
  mySelectedGrade:-1,
  gradeOptions:{
    minVal: 5,
    maxVal: 10,
    increment: 0.5,
    rgbStart : [153,0,0],
    rgbMiddle : [255, 255, 153],
    rgbEnd: [0, 255, 85]
  }
};

export default GradeBox;
