import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import "./ReviewCard.css";
import ReviewItem from './ReviewItem';
import ReviewWriter from './ReviewWriter';

@observer
class ReviewCard extends Component {

  constructor(props) {
    super(props);
    this.state = {max:2};
    this.expand = this.expand.bind(this);
  }

  expand()
  {
    let {max,expand,items} = this.props;
    let actualMax = this.state.max;

    if (actualMax<items.length)
    {
      this.setState({max: actualMax + expand});
    }
  }

  componentDidMount()
  {
    this.setState({max:this.props.max});
  }

  render(){
    let {items,currentUserId,currentUserRoles,ready,goToUrl} = this.props;
    let useItems = items;
    let {max} = this.state;
    if (!ready)
    return <div className="uk-card uk-card-default comp_reviews skeleton">
      <div style={{height:'300px','width':'100%'}}></div>
    </div>
    let showEmptyBox = false;
    if (!Array.isArray(useItems))
      useItems=[];
    if (useItems.length===0)
      showEmptyBox = true;
    let filteredItems = useItems.slice(0,max);

    return (
        <div className="uk-card uk-card-default comp_reviews">
        {lng('Análises')} <br/>
        <ReviewWriter cats={this.props.cats} contentName={this.props.contentName} resourceType={this.props.resourceType} resourceId={this.props.resourceId}/>
        {
        filteredItems.map((element, index) => {
          return (
          <div key={index} className="reviewItem">
            <ReviewItem 
            currentUserId={currentUserId}
            currentUserRoles={currentUserRoles}
            reviewItem={MyAppPool.session.reviewData[element]}
            cats={this.props.cats}/>
        </div>
        )
        })
      }
      {showEmptyBox? 
      <div className="emptyReviewContent">
         {lng('Ninguém escreveu uma análise ainda! Seja o primeiro(a)!!')}
      </div>
      :<></>}
        <p className="moreReviews">
        {useItems.length>0?
        <span><Link to={goToUrl} className="smalllink"> <i className="fas fa-external-link-alt"></i> {lng('Todas')}</Link><span> </span> </span>
        : <span></span>
        }
        {max<useItems.length?
          <button aria-label={lng('Mais')} className="link-button smalllink" onClick={()=>this.expand()}>{lng('Mais')}</button>
         :<span></span>
        }
          </p>
      </div>);
}
}

ReviewCard.propTypes = {
  ready: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  contentName:PropTypes.string.isRequired,
  resourceType:PropTypes.string.isRequired,
  resourceId:PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  expand: PropTypes.number.isRequired,
  goToUrl:PropTypes.string.isRequired,
  cats:PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icons:PropTypes.arrayOf(PropTypes.string.isRequired),
    })),
};

ReviewCard.defaultProps = {
  items: [],
  currentUserId: 0,
  currentUserRoles: [],
  ready:0,
  contentName:'content name',
  resourceType:'notype',
  resourceId:0,
  max:2,
  expand:2,
  goToUrl:'',
  cats:[
    {name:lng("Arte"),icons:["fas fa-paint-brush","fas fa-palette"]},
    {name:lng('Música/Sons'),icons:["fas fa-music"]},
    {name:lng('Personagens'),icons:['fas fa-running','fas fa-hiking','fas fa-skating']},
    {name:lng('História'),icons:['fas fa-pen']}
]
}

export default ReviewCard;
