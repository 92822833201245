
import { computed, toJS } from 'mobx';
import { routes } from '../store/ApiRoutes';
import { getDefaultSession, getDefaultUser } from '../store/defaultSessionVals';
import { baseHash, MD5 } from '../util/secUtil';
import { isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { animeFastResourceType, contentFastTypeToType, contentTypeToFastType, isSet, mangaFastResourceType, mobToObj } from '../util/typeu';
import { getUserRoute } from '../store/AppRoutes';
import { getUserAvatar } from '../util/icon';
import { capitalizeFirstLetterString } from '../util/text';



export default class TasksController
{
    createMalAnimeSyncTask(animeId,full=false){
        MyAppPool.cAlert.notifyOk(lng("Task Requerida"));
        MyAppPool.cUser.createUserTask("datamod mal update anime",{animeid:animeId,full});
    }
    createMalMangaSyncTask(mangaId,full=false){
        MyAppPool.cAlert.notifyOk(lng("Task Requerida"));
        MyAppPool.cUser.createUserTask("datamod mal update manga",{mangaid:mangaId,full});
    }
    
}