import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { scrollToAnchor } from '../util/windowUtil';
import "./ScrollToTopButtom.css";

class ScrollToTopButtom extends Component {
  componentDidMount()
  {

  }

  render(){
    return (
      <button aria-label={lng("Ir para o topo")} className="uk-button uk-button-default comp_scrolltotopbuttom" data-uk-sticky="offset: 50" onClick={()=>scrollToAnchor('#top',0)}> {lng("Ir para o topo")}</button>
  );
}
}

ScrollToTopButtom.propTypes = {
};
ScrollToTopButtom.defaultProps = {
};

export default ScrollToTopButtom;



//