import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import "./ProgressBar.css";

class ProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {hovered:false};
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }
  componentDidMount()
  {

  }

  onMouseEnter(){this.setState({hovered:true});}
  onMouseLeave(){this.setState({hovered:false});}

  render(){

    let {currentValue,maxValue,height} = this.props;
    if (maxValue===0)
        maxValue=1;
    let fillColorChange = '';
    if (this.state.hovered)
        fillColorChange = 'hoverColorChange';
    let percent = (currentValue/maxValue)*100;
    return (
        <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} className="comp_progressbar" style={{height:height+1}}>
            <div className={"innerbar "+fillColorChange} style={{width:percent+'%',height:height-1}}></div>
        </div>
        );
}
}

ProgressBar.propTypes = {
    currentValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};
ProgressBar.defaultProps = {
  currentValue: 0,
  maxValue:1,
  height: 0
};

export default ProgressBar;
