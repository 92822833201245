
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { brasRequestPure } from '../util/net';

export default class TermsController
{
    async getTermsOfUse()
    {
        try{
        let p1 = brasRequestPure (routes.termsOfUseEn);
        let p2 = brasRequestPure (routes.termsOfUsePt);
        let p3 = brasRequestPure (routes.termsOfUseEs);
        let p4 = brasRequestPure (routes.termsOfUseDe);

        let res = await Promise.all([p1,p2,p3,p4]);

        // ?? operator coalesces to use the rightmost value in case the previous value is null or undefined
        let termsOfUseHtml = {
            en:res[0].data ?? '', 
            pt:res[1].data ?? '',
            es:res[2].data ?? '',
            de:res[3].data ?? ''
        }
        MyAppPool.session.terms.ofUse = termsOfUseHtml;
        return Promise.resolve(res);
    }catch(error)
    {
        console.error(error);
        MyAppPool.session.terms.ofUse="";
        return Promise.resolve({data:""});
    }
        
    }

    async getTermsOfPrivacy()
    {
        try{
        let p1 = brasRequestPure (routes.termsOfPrivacyEn);
        let p2 = brasRequestPure (routes.termsOfPrivacyPt);
        let p3 = brasRequestPure (routes.termsOfPrivacyEs);
        let p4 = brasRequestPure (routes.termsOfPrivacyDe);

        let res = await Promise.all([p1,p2,p3,p4]);

        // ?? operator coalesces to use the rightmost value in case the previous value is null or undefined
        let termsOfPrivacyHtml = {
            en:res[0].data ?? '', 
            pt:res[1].data ?? '',
            es:res[2].data ?? '',
            de:res[3].data ?? ''
        }
        MyAppPool.session.terms.ofPrivacy = termsOfPrivacyHtml;
        return Promise.resolve(res);
        }catch(error)
        {
            console.error(error);
            MyAppPool.session.terms.ofPrivacy="";
            return Promise.resolve({data:""});
        }
    
        
    }

    async getTermsOfCookies()
    {
        try{
        let p1 = brasRequestPure (routes.termsOfCookiesEn);
        let p2 = brasRequestPure (routes.termsOfCookiesPt);
        let p3 = brasRequestPure (routes.termsOfCookiesEs);
        let p4 = brasRequestPure (routes.termsOfCookiesDe);

        let res = await Promise.all([p1,p2,p3,p4]);

        // ?? operator coalesces to use the rightmost value in case the previous value is null or undefined
        let termsOfCookiesHtml = {
            en:res[0].data ?? '', 
            pt:res[1].data ?? '',
            es:res[2].data ?? '',
            de:res[3].data ?? ''
        }
        MyAppPool.session.terms.ofCookies = termsOfCookiesHtml;
        return Promise.resolve(res);
        }catch(error)
        {
            console.error(error);
            MyAppPool.session.terms.ofCookies="";
            return Promise.resolve({data:""});
        }
    
        
    }
}