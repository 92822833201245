import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import MyAppPool from './../AppPool';
import { textAreaAdjust } from './../util/text';
import "./ReactionWriter.css";
import SpinnerPulse from './SpinnerPulse';

class ReactionWriter extends Component {

  constructor(props) {
    super(props);
    this.state = {sending:false,content:''};
    this.sendReaction = this.sendReaction.bind(this);
  }

  sendReaction()
  {
    if (MyAppPool.cAuth.isGuest())
    {
        MyAppPool.cAuth.activateGlobalLoginBox();
        return;
    }
    let {resourceType,resourceId} = this.props;
    let {content} = this.state;
    this.setState({sending: true});
    
    MyAppPool.cReactionPosts.createOrUpdateReactionPost(
    resourceType,resourceId,content
    ).then(()=>{
        this.setState({sending: false,content: ''});
    });
  }

  componentDidMount()
  {

  }

  render(){
    let {resourceName} = this.props;
    let {content} = this.state;

    return (
    <div className="uk-flex uk-flex-row  comp_reactionWriter">
      <textarea value={content} onChange={(e) => {this.setState({content: e.target.value}); textAreaAdjust(e);}} className="uk-textarea textInput" maxLength={140} rows={1} placeholder={lng("Em poucas palavras, o que você diria de ") + resourceName + " ?" }  >{content}</textarea>
      {this.state.sending? <span className="uk-button uk-button-default clickable mbtn sendButton btnspinner"><SpinnerPulse/></span>:<button aria-label={lng('Enviar')} className="uk-button uk-button-green clickable mbtn sendButton" onClick={()=>this.sendReaction()}> <span  className="fas fa-chevron-right"/></button>}
  </div>);
}
}

ReactionWriter.propTypes = {
  resourceName: PropTypes.string.isRequired,
  resourceType: PropTypes.number.isRequired,
  resourceId: PropTypes.number.isRequired
};

ReactionWriter.defaultProps = {
  resourceName:'',
  resourceType:0,
  resourceId:0
};

export default ReactionWriter;
