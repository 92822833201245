
import { getViewportWidthHeight, isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { isMobile } from '../util/mobile';
import { isSet } from '../util/typeu';

export default class Preference
{
    set(name,value)
    {
        MyAppPool.saveItemToLocalStorage(name,value);
    }
    get(name)
    {
        return MyAppPool.recoverItemFromLocalStorage(name);
    }
    setTheme(value)
    {
        this.set("theme",value);
    }
    getTheme() 
    {
        let ret = this.get("theme");
        if (!isSet(ret))
            ret = 'darkTheme';
        return ret;
    }
    setListBoxCardMode(value)
    {
        this.set("listBoxCardMode",value);
    }
    getListBoxCardMode()
    {
        let ret =  this.get("listBoxCardMode");
        if (!isSet(ret))
            ret = 'card';
        return ret;
    }
}