import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import { isMobile } from '../util/mobile';
import { getImgAddress, getSiteShortName } from '../util/text';
import { contentTypeToFastType, isSet } from '../util/typeu';
import { isServer, scrollToAnchor } from '../util/windowUtil';
import BreadCumbs from '../view_component/BreadCumbs';
import ScrollToTopButtom from '../view_component/ScrollToTopButton';
import SearchCard from '../view_component/SearchCard';
import SkeletonBlock from '../view_component/SkeletonBlock';
import SwitcherHeading from '../view_component/SwitcherHeading';
import UrlPageSelector from '../view_component/UrlParamPageSelector';
import UrlParamRemoverTags from '../view_component/UrlParamRemoverTags';
import UrlParamTextInput from '../view_component/UrlParamTextInput';
import { getUserRoute } from './../store/AppRoutes';

import SearchPopular from './searchPopular';
import ContentBannerSetter from '../view_component/ContentBannerSetter';
import { serverRegisterFetch } from '../util/updateManager';
import InfiniteScrollBlock from '../view_component/InfiniteScrollBlock';
import UrlParamPageSelector from '../view_component/UrlParamPageSelector';
import { getUserAvatar } from '../util/icon';

function vhToPixels (vh) {
  if (!isServer())
    return Math.round(window.innerHeight / (100 / vh)) + 'px';
  return '1080px';
}

@observer
class SearchUser extends Component {

  constructor(props) {
    super(props);
    this.state = {advancedSearch:false, adultContent:false, finishedLoading:true};
    this.flipAdvancedSearch = this.flipAdvancedSearch.bind(this);
    this.flipAdultContent = this.flipAdultContent.bind(this);
    this.fetchUserLocal = this.fetchUserLocal.bind(this);
    serverRegisterFetch(SearchUser.getInitialValues);
    
    }

  componentDidMount()
  {
    SearchUser.getInitialValues();
  }
  static async getInitialValues()
  {
    let p1 = SearchUser.fetchUser();
    await Promise.all([p1]);

    return Promise.resolve();
  }
  componentWillReceiveProps()
  {
    SearchUser.getInitialValues();
  }
  static fetchUser()
  {
    let filterKeys =  SearchUser.getFilterKeys();
    let parametersBase = {};
    parametersBase[filterKeys.page]='0';
    parametersBase[filterKeys.name]='';

    let parametersUrl = MyAppPool.cLocation.formatedUrlParameters;
    let parameters = {...parametersBase,...parametersUrl};
    
    return MyAppPool.cSearchOthers.getUserSearch(
      parameters[filterKeys.name],
      parameters[filterKeys.page]
      );
  }

  async fetchUserLocal()
  {
    this.setState({finishedLoading:false});
    let p2 = SearchUser.fetchUser();
    await Promise.all([p2]);
    this.setState({finishedLoading:true});
    return Promise.resolve();
  }

  flipAdvancedSearch()
  {
    let {advancedSearch} = this.state;
    let newAdvancedSearch =  !advancedSearch;
    this.setState({advancedSearch:newAdvancedSearch});
  }
  flipAdultContent()
  {
    let {adultContent} = this.state;
    let newAdultContent =  !adultContent;
    this.setState({adultContent:newAdultContent});
  }

  static getFilterKeys()
  {
    return {
      name:lng('nome'),
      page:lng('pagina')
    };
  }

  loadNextPage = ()=>{
    let resourceType = contentTypeToFastType('user');
    let {pages} = MyAppPool.session.search.contentInfo[resourceType];
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let filterKeys = SearchUser.getFilterKeys();
    if (!isSet(pages))
    {
      pages = [];
    }
    let maxPage = pages.length-1;
    let currentPage=parameters[filterKeys.page];
    if (!isSet(currentPage))
      currentPage = 0;
    let nextPage = Number(currentPage)+1;
    if (nextPage>maxPage)
      return Promise.resolve();
    MyAppPool.cLocation.setSingularParamVal(nextPage,filterKeys.page);
    return SearchUser.fetchUser();
  }

  goToPage = () =>{
    let resourceType = contentTypeToFastType('user');
    let {pageSize} = MyAppPool.session.search.contentInfo[resourceType];
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let filterKeys = SearchUser.getFilterKeys();
    let currentPage=parameters[filterKeys.page];
    let scrollToItemId=((Number(currentPage)*pageSize));
    scrollToAnchor('#item_'+scrollToItemId,1000);
  }

  render(){
    let resourceType = contentTypeToFastType('user');
    let filterKeys = SearchUser.getFilterKeys();
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let {pages,pageSize,contentLength,sortType} = MyAppPool.session.search.contentInfo[resourceType];
    let {finishedLoading} = this.state;
    let emptySearchItems =Array.from(Array(20).keys());
    let fetching=false;
    if (!isSet(pages))
    {
      pages = [0];
      fetching=true;
    }
    let currentPage=parameters[filterKeys.page];
    if (!isSet(currentPage)||currentPage==='')
      currentPage=0;
    
    let people = MyAppPool.cSearchOthers.getGeneralSearchAllPage(MyAppPool.session.search.content,resourceType,pageSize,currentPage);
    let pageContent =[];
    if (Array.isArray(people))
      pageContent = people;

    let meta = {title:lng('Buscar Usuários')+' | '+getSiteShortName()+'',desc:'',img:'',imgwidth:'',imgheight:'',imgtype:'',keywords:''};
    if (isMobile.any)
    {
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div className="contentpage searchBase">
          <ContentBannerSetter />
            <div className='contentArea'>
                <BreadCumbs
                />
                <SwitcherHeading heading={lng('Descubra Usuários')} />
                <div style={{'width':'95%','marginLeft':'2.5%'}}>
                <UrlParamTextInput 
                      filterKey={filterKeys.name}
                      onChangeFunction={this.fetchUserLocal}
                      placeHolderText={lng("Filtrar por Nome")}
                      parameters={parameters}
                />
                </div>
                <UrlParamRemoverTags 
                    onChangeFunction={this.fetchUserLocal}
                    paramToNiceText={[]}
                />
                
                <div className="searchContentSection">
                {!fetching && finishedLoading?
                <div>
                    
                    <InfiniteScrollBlock fetchFunction={this.loadNextPage}>
                    <div className="searchContent">
                      {pageContent.map((item, index) => {
                        let url=getUserRoute(item.id,item.name,'#top');
                        let img = getUserAvatar(item.mainimg,item.mailhash,'');
                          
                        return <div id={"item_"+index} key={index} className="searchItemResult">
                            <SearchCard 
                              alwaysShowName={true}
                              key={index}
                              isHentai={false}
                              title={item.name}
                              url={url}
                              resourceType={resourceType}
                              resourceId={item.id}
                              imgurl={img}
                              date={''}
                              icon={''}
                              gradeable={false}
                              iconText={''}
                            />
                          </div>
                        })
                      }
                    </div>
                    </InfiniteScrollBlock>
                    
                    </div> 
                    :
                    <div className="searchContent">
                      <SkeletonBlock style={{"height":"30px", "marginTop":"5px","marginBottom":"15px"}}/>
                          {emptySearchItems.map((item, index) => {
                            return <div className="searchItemResult"><div className={" skeleton ratioDiv"}></div></div>
                          })
                        }
                     </div>
                    }
                </div>

            </div>
          </div>
        </div>       
      )
    }

    //DESKTOP
      return (
        <div>
          <Helmet>
            <title>{meta.title}</title>
            <meta name="title" content={meta.title} />
            <meta name="description" content={meta.desc} />
            <meta name="keywords" content={meta.keywords}/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta name="twitter:title" content={meta.title}/>
            <meta name="twitter:description" content={meta.desc}/>
            <meta name="twitter:image:src" content={getImgAddress(meta.img)}/>
            <meta property="og:image" content={getImgAddress(meta.img)} />
            <meta property="og:image:type" content={meta.imgtype}/>
            <meta property="og:image:width" content={meta.imgwidth}/>
            <meta property="og:image:height" content={meta.imgheight}/> 
          </Helmet>
          <div className="contentpage searchBase">
          <ContentBannerSetter />
            <div className='uk-flex uk-flex-row contentArea'>
              
              <div className="leftContent">
              </div>

                <div className="middleContent">
                  <BreadCumbs
                  />
                  <SwitcherHeading heading={lng('Descubra Usuários')} />
                  <div className="filterBox">
                    
                    <div className="uk-flex uk-flex-row">
                      <UrlParamTextInput 
                      filterKey={filterKeys.name}
                      onChangeFunction={this.fetchUserLocal}
                      placeHolderText={lng("Filtrar por Nome")}
                      parameters={parameters}
                      />
                    </div>
                    <UrlParamRemoverTags 
                    onChangeFunction={this.fetchUserLocal}
                    />
                  </div>
                  
                  <div className="searchContentSection">
                  {!fetching && finishedLoading?
                  <div>
                    
                    <InfiniteScrollBlock fetchFunction={this.loadNextPage}>
                    <div className="searchContent">
                    {pageContent.map((item, index) => {
                        let url= getUserRoute(item.id,item.name,'#top');
                        let img = getUserAvatar(item.mainimg,item.mailhash,'');
                          
                        return <div id={"item_"+index} key={index} className="searchItemResult">
                            <SearchCard 
                              alwaysShowName={true}
                              key={index}
                              isHentai={false}
                              title={item.name}
                              url={url}
                              resourceType={resourceType}
                              resourceId={item.id}
                              imgurl={img}
                              date={''}
                              icon={''}
                              gradeable={false}
                              iconText={''}
                            />
                          </div>
                        })
                      }
                    </div>
                    </InfiniteScrollBlock>
                    
                  </div>
                  :
                  <div className="searchContent">
                    <SkeletonBlock style={{"height":"30px", "marginTop":"5px","marginBottom":"15px"}}/>
                          {emptySearchItems.map((item, index) => {
                            return <div className="searchItemResult"><div className={" skeleton ratioDiv"}></div></div>
                          })
                        }
                     </div>
                  }
                  </div>
                  
              </div>

              <div className="rightContent">

              {!isMobile.any?
                <div  style={{width:"100%",marginLeft:"15px"}}>
                    <ScrollToTopButtom />
                    <div data-uk-sticky="offset: 190px">
                    <UrlParamPageSelector 
                        items={pages}
                        filterKey={filterKeys.page}
                        onChangeFunction={this.goToPage}
                    />
                  </div>
                </div>
                :<></>}
              </div>
            </div>
          </div>
        </div>
      );

}
}

export default SearchUser;
