import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { contentTypeToFastType } from '../util/typeu';
import MyAppPool from './../AppPool';
import "./GlobalMusicQueueMusicItem.css";
import ListWidget from './ListWidget';

function themeGetType(type)
{
  if (type===0)
    return 'op';
  if (type===1)
    return 'ed';
  return '';
}
class GlobalMusicCurrentMusicItem extends Component {

    constructor(props) {
        super(props);
        this.play = this.play.bind(this);
        this.removeFromPlaylist = this.removeFromPlaylist.bind(this);
        this.togglePlay = this.togglePlay.bind(this);
        this.playNext = this.playNext.bind(this);
        this.state = {hover:false};
    }

  removeFromPlaylist()
  {
    MyAppPool.cMusic.removeFromPlayQueue(this.props.music);
    MyAppPool.cPopMenu.deactivate();
  }


  play()
  {
      MyAppPool.cMusic.playMusicObject(this.props.music);
  }

    togglePlay()
    {
        let playing = MyAppPool.cMusic.isPlaying;
        if (playing)
        {
            MyAppPool.cMusic.setStopPlease(true);
        }else
        {
            MyAppPool.cMusic.playMusicObject(this.props.music);
            
        }
    }
    playNext()
    {
        MyAppPool.cMusic.goNext();
        MyAppPool.cPopMenu.deactivate();
    }

  render(){
    let {music} = this.props;
    let resourceType = contentTypeToFastType('music');

    let showPlayButtons = false;
    if (music.ytid!=='')
        showPlayButtons=true;
    let extraInfo = themeGetType(music.type) + ' ' +music.where;
    if (extraInfo!==' ')
      extraInfo = '('+extraInfo+')'

    let showChangeOrderButton = this.props.showBars;
    if (music.id===0 || typeof music.title === 'undefined')
      return <>{lng("Nada")}</>;

    return (
        <li className={"comp_queuemusicitem"}>
          <div onMouseLeave={()=>this.setState({hover:false})} onMouseOver={()=>this.setState({hover:true})} className="uk-flex uk-flex-row">
            {showChangeOrderButton?
                <div id="ytmusicchangeorderanchor"  className="fas fa-bars ytanchoricon"></div>
                :<></>
            }
            <div className="musicdata">
              <p className={"ytmusicname"}>{music.title + ' ' + extraInfo}</p>
              <p className={"ytsinger"}>{music.band}</p>
            </div>
            {  MyAppPool.cMusic.isPlaying? 
                <i onClick={this.togglePlay} className="far fa-pause-circle playIcon iconButton clickable ytplayicon" />
                :
                <i onClick={this.togglePlay} className="far fa-play-circle playIcon iconButton clickable ytplayicon" />
            }
            {showPlayButtons?
                <div id="ytmusicmenubutton" className="fas fa-ellipsis-v ytmenuicon" 
                onClick={(e)=>{
                  MyAppPool.cPopMenu.set(
                    <div>
                      <ul className="pop_ytmenulist">
                        <li className="pop_ytmenuitem" onClick={this.playNext}>
                          <span className={"fas pop_ytmenuitemicon fa-step-forward"} ></span><div className="ytpop_queuetextoption">{lng("Próxima")}</div>
                        </li>
                    </ul>
                    <ListWidget resourceType={resourceType} resourceId={music.id}/>
                    </div>
                  ,'')}}
                >
                </div>
                
              :<></> 
            }
          </div>
         </li>
    

    );

  }
}

GlobalMusicCurrentMusicItem.propTypes = {
  music: PropTypes.shape({
    title: PropTypes.string.isRequired,
    band: PropTypes.string.isRequired,
    where: PropTypes.string.isRequired,
    ytid: PropTypes.string.isRequired,
    id:PropTypes.number.isRequired}),
  showBars: PropTypes.bool.isRequired
};
GlobalMusicCurrentMusicItem.defaultProps = {
  music:{
    title: '',
    band: '',
    where: '',
    ytid: '',
    id:0
  },
  showBars: false
};

export default GlobalMusicCurrentMusicItem;
