import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import ListCreateWidget from './ListCreateWidget';
import "./ListWidget.css";
import { contentTypeToFastType } from './../util/typeu';

@observer
class GlobalMusicListAddWidget extends Component {

  constructor(props)
  {
    super(props);
    this.state = {};
  }
  componentDidMount()
  {
  }

  render(){
    let resourceType = contentTypeToFastType('music');
    let lists = [];
    lists = MyAppPool.session.user.lists[resourceType];
    if (!Array.isArray(lists))
      lists=[];

    return (
      <div className="comp_listwidget uk-nav uk-dropdown-nav">
        {lng('Tocar Lista de Musica')}
        <ul className="listItens">
            {lists.map((element, index) => {
            return (
            <li key={index}>
                <span onClick={()=>{
                    MyAppPool.cMusic.loadMusicListToQueue(element.itens,element.name);
                    MyAppPool.cPopMenu.deactivate();}}
                  className="listcheckbuttons clickable">{element.name} 
                </span> 
                <div className="uk-nav-divider"></div> 
            </li>)})
            }
        </ul>
    
    </div>);
}
}

GlobalMusicListAddWidget.propTypes = {
};
GlobalMusicListAddWidget.defaultProps = {
};

export default GlobalMusicListAddWidget;
