import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import MyAppPool from '../AppPool';
import { lng } from '../ulanguages/language';
import ListCreateWidget from './ListCreateWidget';
import "./ListWidget.css";

@observer
class ListWidget extends Component {

  constructor(props)
  {
    super(props);
    this.state = {clicks:{}};
    this.sendCheckList = this.sendCheckList.bind(this);
  }
  componentDidMount()
  {
  }

  sendCheckList(listPos)
  {
      let {resourceId,resourceType} = this.props;
      let {clicks} = this.state;
      clicks[''+listPos] = true;
      this.setState({clicks});
    //  console.log("Requesting Checklist:::");
      MyAppPool.cLists.sendCheckList(resourceType,resourceId,listPos).then( 
          () =>{
              clicks[''+listPos] = false;
              this.setState({clicks});
        }
      );
  }

  render(){
    let {resourceType,resourceId} = this.props;
    let {clicks} = this.state;
    let listsPresence = [];
    let lists = [];
    if (resourceType < 0 || resourceType < 0)
        return <></>;
    lists = MyAppPool.session.user.lists[resourceType];
    listsPresence = MyAppPool.cLists.getListPresence(resourceType,resourceId);
    if (!Array.isArray(lists))
      lists=[];
    //console.log(toJS(this.props));
    return (
      <div className="comp_listwidget uk-nav uk-dropdown-nav">
        <ul className="listItens">
            {lists.map((element, index) => {
            return (
            <li key={index}>
                <span onClick={()=>this.sendCheckList(index)} className="listcheckbuttons clickable">{element.name} 
                {(element.essentialid!==null)?
                    (clicks[index]===true? <i className="uk-align-right fas fa-check-circle"></i>
                    :
                        (listsPresence[index]===true?
                        <i className="checkOk uk-align-right fas fa-check-circle"></i> 
                        :<i className="uk-align-right far fa-circle"></i>) )
                :
                (clicks[index]===true? <i className="uk-align-right fas fa-check-square"></i>
                    :
                    (listsPresence[index]===true?
                        <i className="checkOk uk-align-right fas fa-check-square"></i> 
                        :<i className="uk-align-right far fa-square"></i>))
                }
                </span> 
                <div className="uk-nav-divider"></div> 
            </li>)})
            }
        </ul>
        <ul className="bottomList">
            <li className="listcheckbuttons clickable" onClick={()=>{
                if (MyAppPool.cAuth.isGuest())
                {
                  MyAppPool.cAuth.activateGlobalLoginBox();
                  return;
                }
                  MyAppPool.cModal.set(
                    lng("Criar Lista"),
                    <ListCreateWidget resourceTypeHint={resourceType} />,
                    (ok)=>{MyAppPool.cLists.createList()},'',(cleanup)=>{MyAppPool.cLists.cleanListWidgetVals()});
                }}>
              {lng("Criar Lista")}  <i className="uk-align-right far fa-plus-square"></i> 
            </li>
        </ul>
    
    </div>);
}
}

ListWidget.propTypes = {
  resourceType:PropTypes.number.isRequired,
  resourceId:PropTypes.number.isRequired
};
ListWidget.defaultProps = {
  resourceType:0,
  resourceId:-1
};

export default ListWidget;
