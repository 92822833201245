import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getPrettyUrlName } from '../util/text';
import MyAppPool from './../AppPool';
import { isSet, mobToObj } from './../util/typeu';
import "./ColumnCard.css";

class ColumnCard extends Component {
  constructor(props) {
    super(props);
    this.showMapWidget = this.showMapWidget.bind(this);
    this.renderElemInfo = this.renderElemInfo.bind(this);
  }

  showMapWidget(item)
  {
    //console.log(item)
    let searchString = encodeURIComponent(item);
    let apiKey = MyAppPool.session.gMapsApiKey;
    
    let iframeUrl = "https://www.google.com/maps/embed/v1/place?q="+searchString+"&key="+apiKey;
    let mapElement = <iframe width="100%" height="450" style={{"border":"0"}} loading="lazy" allowfullscreen
    src={iframeUrl}></iframe>
    MyAppPool.cModal.set(
      item,
      <div>
        {mapElement}
      </div>,
      null,'mediumModal',(cleanup)=>{});
  }
  componentDidMount()
  {

  }

  renderElemInfo(info)
  {
    if (!Array.isArray(info))
      return <span></span>;
    let lastElemIndex = info.length-1;

    return  ( <span>
      {info.map((element, index) => {
        if (element.type=='url')
        {
          if (element.value.indexOf('http')!==-1)
            return <span  key={index}>
              <a href={element.value} target="_blank" >{getPrettyUrlName(element.display)}</a>
              {index<lastElemIndex? ', ':''} 
            </span>;
          return <span  key={index}>
            <Link to={element.value}>{getPrettyUrlName(element.display)}</Link>
            {index<lastElemIndex? ', ':''} 
          </span>;
        }
        if (element.type=='location')
         return <span  key={index}>
          <button aria-label={element.display} onClick={()=>this.showMapWidget(element.value)} className="link-button smalllink">{element.display}</button>
          {index<lastElemIndex? ', ':''} 
         </span>;
        if (element.type=='date')
        {
          
        }
        return <span  key={index}> 
          <span>{element.display}</span>
          {index<lastElemIndex? ', ':''} 
        </span>;
      })
    }
    </span>
    );
  }

  render(){
    let {items,ready} = this.props;
    if (!ready)
    return (
        <div className="uk-card uk-card-default columnCard skeleton">
          <div style={{'width':'100%','height':'600px'}} />
      </div>);
    let elements = [];
    if (items.length==0)
      return <></>;

    let finalItens={};
    for(let i=0;i<items.length;i++)///agregate values of the same key
    {
      let currItem=items[i];
      if (!isSet(finalItens[currItem.name]))
        finalItens[currItem.name]=mobToObj(currItem);
      else
        finalItens[currItem.name].info[finalItens[currItem.name].info.length]=mobToObj(currItem.info[0]);
    }
    finalItens = Object.values(finalItens);
    return (
        <div className="uk-card uk-card-default columnCard">
         <ul className="uk-list">
         {finalItens.map((element, index) => {
              return <li key={index}><b>{element.name}</b> <br/> {this.renderElemInfo(element.info)}</li>
            })
          }
            
         </ul>
      </div>);
}
}

ColumnCard.propTypes = {
  ready: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      info: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
          display: PropTypes.string.isRequired
        }))
    })
  ),
};
ColumnCard.defaultProps = {
  items: [],
  ready:0
};

export default ColumnCard;
