import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import MyAppPool from './../AppPool';
import CSSTransition2 from './CSSTransition2';
import ErrorJSXCatcher from './ErrorJSXCatcher';
import "./GlobalModal.css";

@observer
class GlobalModal extends Component {
  constructor(props)
  {
    super(props);
    this.cleanup = this.cleanup.bind(this);
    this.close = this.close.bind(this);
  }
  close()
  {
    MyAppPool.cModal.deactivate(); 
    MyAppPool.jsObjectStore.modal.cleanupFunction();
  }
  cleanup()
  {

  }
  componentDidMount()
  {
    if (document)
    {
      let el = document.getElementById("appModal");
      if (el)
        el.addEventListener("hidden", ()=>
      {
        MyAppPool.cModal.deactivate();
        MyAppPool.jsObjectStore.modal.cleanupFunction();
      });
    }
  }
  componentDidUnmount()
  {
  }
  render(){
    
    let open = toJS(MyAppPool.jsObjectStore.modal.active);
    return (<div className='comp_app_modal'>
            <CSSTransition2
            in={open}
            timeout={150}
            classNames="global-modal"
            >
              <div id="appModal" className='modal_back_click' onClick={this.close}>
              <CSSTransition2
                in={MyAppPool.jsObjectStore.modal.active}
                key={2}
                timeout={300}
                classNames="modal-inside"  
              >
                <div className={"modalbody " + MyAppPool.jsObjectStore.modal.className} onClick={(event)=>event.stopPropagation()}>
                <h2 className="uk-modal-title">{MyAppPool.jsObjectStore.modal.title} {MyAppPool.jsObjectStore.modal.okFunction? <span></span> : <i class="far fa-window-close uk-align-right clickable" onClick={this.close}></i>}</h2>
                {MyAppPool.jsObjectStore.modal.active===1?
                <div>
                  <ErrorJSXCatcher>
                    {toJS(MyAppPool.jsObjectStore.modal.content)}
                    <p className="uk-text-right">
                        {MyAppPool.jsObjectStore.modal.okFunction?
                        <button aria-label={lng('Cancelar')} className="uk-button uk-button-default" type="button"
                        onClick={this.close}>{lng('Cancelar')}</button>
                        : <span></span>}
                        {MyAppPool.jsObjectStore.modal.okFunction?
                        <button aria-label={lng('Confirmar')} onClick={()=>{MyAppPool.jsObjectStore.modal.okFunction(); this.close();}} className="uk-button uk-button-primary" type="button">{lng('Confirmar')}</button>
                        : <span></span>}
                        </p>
                   </ErrorJSXCatcher>
                  </div>
                    : <span></span>
                  }
                </div>
                </CSSTransition2>
                </div>
                </CSSTransition2>
        </div>);
}
}

export default GlobalModal;
