import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MergeForm.css'; // Import the CSS file
import MyAppPool from '../AppPool';
import { getObraRoute, getSearchRoute } from '../store/AppRoutes';
import { getImgAddress } from '../util/text';
import { getRelatedTypeIcon } from '../util/icon';
import { lng } from '../ulanguages/language';
import { mangaFastResourceType } from '../util/typeu';
import Spinner from './Spinner';
import { getCssVar } from '../util/windowUtil';


function sortByDateDistance(items, inputDateStr) {
    const inputDate = new Date(inputDateStr);

    return items.sort((a, b) => {
        const dateA = new Date(a.info.airfrom);
        const dateB = new Date(b.info.airfrom);

        const distanceA = Math.abs(dateA - inputDate);
        const distanceB = Math.abs(dateB - inputDate);

        return distanceA - distanceB;
    });
}

class MergeFormManga extends Component {

constructor(props) {
    super(props);
    this.state={selectedGiver:null, query:'',preview:false,fetching:false,previewData:[],previewReady:false};
}
fetchPreviewAnime(){
    let {query} = this.state;
    this.setState({fetching:true,preview:true,previewReady:false});
    MyAppPool.cSearchAnimeManga.getMangaSearch(query,'','','','','','','','')
    .then(()=>{
        let currentPage=0;
        let currentPageRangeBottom=0;
        let currentPageRangeTop=currentPageRangeBottom + 15;
        let searchContent = MyAppPool.session.search.content[mangaFastResourceType];
        let pageContent =[];
        if (Array.isArray(searchContent))
        {
            pageContent = searchContent.slice(currentPageRangeBottom,currentPageRangeTop);
            sortByDateDistance(pageContent,this.props.date);
            pageContent = pageContent.filter((item)=>{return item.info.id!==this.props.mangaid});

            for(let i=0;i<pageContent.length;i++)
            {
                let item = pageContent[i];
                item.url = getObraRoute(item.info.id,item.titleList[0],'manga','#top');
                item.img = getImgAddress(item.info.mainimg,'/imgs/defaults/manga.webp','');
                item.title=item.titleList[0];
                //item.info.type,item.info.airfrom,item.titleList, item.img, item.url
            }
        this.setState({previewData:pageContent,previewReady:true,previewType:mangaFastResourceType,fetching:false,});
        }else{
            this.setState({fetching:false,preview:false});
            MyAppPool.cAlert.notifyGenericError('',"fetch preview manga (empty)");
        }
    })
    .catch((e)=>{
        this.setState({fetching:false,preview:false});
        MyAppPool.cAlert.notifyGenericError(e,"fetch preview manga ");
    });
}
    delayedSetNameAndFetch = (val) =>
    {
      let delayTime=500;
      
      let updateFunc = ()=>{
        let {query,fetching} = this.state;
        if (query==='')
            this.setState({preview:false});
        if (fetching)
            return;
        if (query===val)
                this.fetchPreviewAnime();
        else
            setTimeout(updateFunc,delayTime);
      }
      setTimeout(updateFunc,delayTime);
      this.setState({query:val});
    }

   handleRadioChange = (item) => {
     this.setState({selectedGiver:item});
    };
    handleMerge =()=>{
        if (this.state.selectedGiver===null)
            return MyAppPool.cAlert.notifyError("You Must Select a giver",'merge manga', 'merge manga form');
        
        let receiverId = this.props.mangaid;
        let giverId = this.state.selectedGiver.info.id;
        MyAppPool.cManga.mergeManga(receiverId,giverId);
        MyAppPool.cModal.deactivate();
    };

  render() {
    const { coverImage, date, title,otherTitles, type } = this.props;
    let {fetching,previewData,previewReady,query,selectedGiver} = this.state;

    return (
      <div className="mergeform">
        <div  className="mergeformtop">
            <div className="left-section">
            <h3 className="title">{"Receiver"}</h3>
            <img src={coverImage} alt={title} className="cover-image" />
            <h3 className="title">{title}</h3>
            <span>{otherTitles}</span>
            <p className="date">{type}</p>
            <p className="date">{date}</p>
            </div>
            <div className="right-section">
            <h3 className="title">{"Giver"}</h3>
            <input value={this.state.query} onChange={(e) => {this.delayedSetNameAndFetch(e.target.value);}} className="uk-search-input searchInput" type="search" placeholder={lng("Buscar...")} autoFocus/>
            {fetching?<Spinner color={getCssVar('--loading-spinner-text-color')}/>:<></>}
                    {previewReady?<div className="results">
                            {previewData.map((item, index) => {
                              return <div key={index} className="searchItemResult">
                                <div className="uk-flex uk-flex-row">
                                  <img src={item.img} alt={item.title} className="cover-image" />
                                  <div style={{"paddingLeft":"10px","paddingBottom":"15px"}}>
                                    <h3>{item.title} <input
                                    type="radio"
                                    name="giverCandidate"
                                    value={item.info.id}
                                    onClick={() => this.handleRadioChange(item)}
                                    checked={this.state.selectedGiver?.info.id === item.info.id}
                                    className="big-radio"
                                    />
                                    </h3>
                                        <span>{item.titleList}</span>
                                        <p className="date">{item.info.id}</p>
                                        <p className="date">{item.info.type}</p>
                                        <p className="date">{item.info.airfrom}</p>

                                        <div className="radio-container">
                                    
                                </div>
                                    </div>
                                </div>
                              </div>
                            })}
                            {previewData.length===0?<div>{lng("Nenhum resultado encontrado para ")}"{query}"</div>:<></>}
                            </div>:<></>
                          }
                          {selectedGiver!==null?
                          <div className="searchItemResult">
                            <h3>SELECTED GIVER (note this is a destructive action, giver will be merged and erased)
                              </h3>
                          <div className="uk-flex uk-flex-row">
                            <img src={selectedGiver.img} alt={selectedGiver.title} className="cover-image" />
                            <div style={{"paddingLeft":"10px","paddingBottom":"15px"}}>
                              <h3>{selectedGiver.title}
                              </h3>
                                  <span>{selectedGiver.titleList}</span>
                                  <p className="date">{selectedGiver.info.id}</p>
                                  <p className="date">{selectedGiver.info.type}</p>
                                  <p className="date">{selectedGiver.info.airfrom}</p>

                                  <div className="radio-container">
                              
                          </div>
                              </div>
                          </div>
                        </div> :<></>
                          }
            </div>
        </div>
        <div className="button-container">
          <button className="uk-button uk-button-default" onClick={()=>MyAppPool.cModal.deactivate()}>Cancel</button>
          <button className="uk-button uk-button-default" onClick={()=>this.handleMerge()}>Confirm</button>
        </div>
      </div>
    );
  }
}

// Define PropTypes for the component
MergeFormManga.propTypes = {
  coverImage: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  otherTitles: PropTypes.string,
  type: PropTypes.string,
  mangaid: PropTypes.number.isRequired
};

export default MergeFormManga;