import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { lng } from '../ulanguages/language';
import { isSet } from './../util/typeu';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class PersonRowCard extends Component {
  componentDidMount()
  {

  }

  render(){
    let {description,roles,id,name,img,url,natIcon,natName,ready} = this.props;
    if (!ready)
    return <div className="uk-card uk-card-default skeleton">
      <div style={{height:'160px','width':'100%'}}></div>
    </div>;
    if (isSet(description))
    {
    description = description.substring(0,200);
    description += '...';
    }

    return (
        <div className="uk-card uk-card-default">
          <div className="uk-flex uk-flex-row"> 
            <div style={{width:'20%'}}>
            <Link aria-label={name} to={url}><LazyLoadImage src={img} style={{height:'225px'} } alt={lng("Foto")+": "+lng('Autor')+" "+name}></LazyLoadImage> </Link>
            </div>
            <div style={{width:'79%'}}>
              <h2>
                <Link to={url}> {name} </Link>
                {natIcon? <i className={natIcon} data-uk-tooltip={natName}></i> : <></>}
              </h2>
              <ul>
              {
                roles.map((item, index) => {
                  return (
                    <li key={index} data-uk-tooltip={item.description}>
                    {item.role}
                    </li>
                  )})
              }
              </ul>
              <p> {description}  </p>
            </div>
          </div>
        
        
      

        
      </div>);
}
}

PersonRowCard.propTypes = {
  ready: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  natIcon: PropTypes.string.isRequired,
  natName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description:PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ) 
  
};
PersonRowCard.defaultProps = {
  voiceActorRelated: [],
  id: 0,
  name: '',
  natIcon: '',
  natName: '',
  img:'',
  url:'',
  description:'',
  roles: [],
  ready:0
}

export default PersonRowCard;
