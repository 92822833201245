import { toJS } from "mobx";
import MyAppPool from "../AppPool";
import { isSet } from "./typeu";

export function shuffleArray(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;
      
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
      
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      
        return array;
      }

      
export function deleteIdFromArray(id,myarray)
{
    let splitIndex = -1;
    for(let i=0; i<myarray.length;i++)
    {
        if (myarray[i]===id)
        {
            splitIndex=i;
            break;
        }
    }
    if (splitIndex===-1)
        return myarray;
    let left = myarray.slice(0,splitIndex);
    let right = myarray.slice(splitIndex+1);
    let newReviews = [...left,...right];
    return newReviews;
}

