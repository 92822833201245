import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Redirect } from 'react-router-dom';
import MyAppPool from '../AppPool';
import '../css/flags.css';
import { lng } from '../ulanguages/language';
import { capitalizeFirstLetterAllWords, getAnchorName, getImgAddress } from './../util/text';
import { animeFastResourceType, companyFastResourceType, isSet, magazineFastResourceType, mangaFastResourceType, mobToObj } from './../util/typeu';
import CSSTransition2 from './CSSTransition2';
import CapaWorkLikeButton from './CapaWorkLikeButton';
import ChangeableImg from './ChangeableImg';
import Grader from './Grader';
import LinkV2 from './LinkV2';
import ListWidget from './ListWidget';
import "./AmazonCarrousel.css";
import StarsRating from './StarsRating';

function getMouseOrTouchX(e)
{
  let x = 0;
  if (isSet(e.touches) && isSet(e.touches[0]))
    x = e.touches[0].clientX;
  else
    x = e.clientX;
  return x;
}
function getMovenessRatio(e)
{
  let r = 0;
  if (isSet(e.touches) && isSet(e.touches[0]))
    r = 52;
  else
    r = 80;
  return r;
}

@observer
class AmazonCarrousel extends Component {

  constructor(props) {
    super(props);
    this.state = {hover:{}, itemIndex:-1,dragging:false,dragStartTime:0,dragX:0, startDragX:0,itemUrl:''};
    this.moveRight = this.moveRight.bind(this);
    this.moveLeft = this.moveLeft.bind(this);
    this.startDrag = this.startDrag.bind(this);
    this.duringDrag = this.duringDrag.bind(this);
    this.endDrag = this.endDrag.bind(this);
  }

  startDrag(e,element
    ) {
    let x = getMouseOrTouchX(e);
    this.setState({dragging:true,dragStartTime:Date.now(),dragX: x,startDragX:x,itemUrl:element.url});
    if (!isSet(e.touches))
    {
      e.target.onpointermove = this.duringDrag;
      e.target.setPointerCapture(e.pointerId);
    }
  }
  
  duringDrag(e)
  {
    let {dragging,dragX,itemIndex} = this.state;
    if (!dragging)
      return; 
    let x = getMouseOrTouchX(e);

    let moveDiff = dragX - x;
    moveDiff = moveDiff/getMovenessRatio(e);
    this.setState({dragX:x, itemIndex:itemIndex+moveDiff});
  }
  
  endDrag(e) {
    let {dragStartTime,startDragX} = this.state;
    let timeDiff = Date.now()- dragStartTime;
    let x = getMouseOrTouchX(e);

    let movementDiff = startDragX-x;
    if (Math.abs(movementDiff) > 10)
      setTimeout(()=>this.setState({dragging:false}),100);
    else
      this.setState({dragging:false});
    if (!isSet(e.touches))
      e.target.releasePointerCapture(e.pointerId);
  }

  moveRight()
  {
    let {showItemNumber} = this.props;
    let {itemIndex} = this.state;
    itemIndex = Math.round(itemIndex);
    let nextState = itemIndex +showItemNumber;
    let remaining =  nextState - itemIndex;
    let iterations = 40;
    let step = remaining/iterations;
    for(let i=0;i<=iterations;i++)
      setTimeout(()=>this.setState({itemIndex:itemIndex+(step*i)}),i*5)
  }
  moveLeft()
  {
    let {showItemNumber} = this.props;
    let {itemIndex} = this.state;
    itemIndex = Math.round(itemIndex);
    let nextState = itemIndex -showItemNumber;
    let remaining =  nextState - itemIndex;
    let iterations = 40;
    let step = remaining/iterations;
    for(let i=0;i<=iterations;i++)
      setTimeout(()=>this.setState({itemIndex:itemIndex+(step*i)}),i*5)
  }

  componentDidMount()
  {

  }

  getCurrentItens(itens, simpleIndex, shownItensNumber)
  {
    let index =simpleIndex;
    index = index % itens.length;
    if (index<0)
      index = itens.length + index;
    
    let maxItemIndex = itens.length;
    let endOfRange = index + shownItensNumber;
    let currentItens = [];

    
    //console.log(index,shownItensNumber,endOfRange,maxItemIndex);
    if (endOfRange>maxItemIndex)
    {
      let newRange = endOfRange -maxItemIndex;
      let endItens = itens.slice(index, maxItemIndex);
      let newItens = itens.slice(0,newRange);
      currentItens = [...currentItens,...endItens,...newItens];
      if (currentItens.length>itens.length)
        currentItens = currentItens.slice(0,itens.length);
      return currentItens;
    }
    return itens.slice(index, index+shownItensNumber);
  }

  render(){
    //console.log(this.props);
    let {gradeOptions,showItemNumber,title,height,query,imgSize} = this.props;
    let {itemIndex,dragging,itemUrl} = this.state;

    let myheight = height;
    let amazonSearch = MyAppPool.session.affiliate.amazon[query];
    if (!isSet(amazonSearch))
        return <></>;
    if (amazonSearch.links.length==0)
        return <a style={{width:"100%"}} href={amazonSearch.searchLink} target="_blank" className="uk-button uk-button-yellow"><i className="fas fa-gifts"></i> {title} <i className="fab fa-amazon"></i></a>
    

    let reducedItens = this.getCurrentItens(amazonSearch.links, itemIndex, showItemNumber+2);
    let localItemNumber = showItemNumber;
    if (reducedItens.length === showItemNumber+1)
      localItemNumber-=1;
    
    let itemWidth = 100/localItemNumber;
    let titleHeight=24;
    if (title==='0')
      titleHeight=0;
    //console.log('DRAG::',dragging);

    
    let textSizePercent = 1 *(myheight/352);//352 is a big card height
    let lineSizePercent = 1.4 *(myheight/352);

    let movement = itemIndex -Math.floor(itemIndex);
    let interactable = reducedItens.length>showItemNumber;
    let showArrows = interactable;
    let startingIndex = interactable?1:0;

    //everything ok
    return (<div ref={this.myRef} className="uk-card uk-card-default comp_amazoncarrousel">
      {title}
      {reducedItens.length>0? 
      <div style={{height:myheight-titleHeight}} className="carouselfather">
          {reducedItens.map((element, index) => {

            let {imgurl,producturl,price,score,name} = element;
            let useName = name;
            if (typeof useName==='string')
              useName = capitalizeFirstLetterAllWords((useName.substring(0,40)+"...").toLowerCase());
            return (
            <div key={index} 
            style={{'width':itemWidth+'%','left':(itemWidth*(index-startingIndex) - movement*itemWidth) +'%'}}
            className="carouselItem"
            onMouseEnter={()=>{let n = this.state.hover; n[index]=true; this.setState({hover:n}) } } onMouseLeave={()=>{let n = this.state.hover; n[index]=false; this.setState({hover:n}) } }
            draggable={false}
            onPointerDown={(e)=> {if (interactable) this.startDrag(e,element)}}
            onPointerUp={(e)=> {if (interactable) this.endDrag(e)}}
            onTouchStart={(e)=> {if (interactable) this.startDrag(e,element)}}
            onTouchMove={(e)=> {if (interactable) this.duringDrag(e)}}
            onTouchEnd={(e)=> {if (interactable) this.endDrag(e)}}
            >
              <a aria-label={title} onClick={(e)=> {
                //await delay(5);
                if (dragging)
                {
                 /// console.log("IM DRAGGGGIIIIN...")
                  e.preventDefault();
                  e.stopPropagation();
                }
              }} 
              href={producturl} target="_blank" draggable={false} >
              <ChangeableImg
              alt={title}
              id={getAnchorName(title)+'i'+(index+itemIndex)}
              draggable={false} 
              style={{'height':myheight-titleHeight,'borderRadius':'5px'}}
              effect="blur" className="relatedImg" src={getImgAddress(imgurl,'',imgSize)}/>
              </a>
              <div className="uk-overlay uk-overlay-primary uk-position-top starsWrapperFather">
              
                <div className="starsWrapper">
                <div className="relatedStars" onClick={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();}}>
                  {score!=-1?<StarsRating minVal={1} maxVal={5} className="iconButton" data-uk-tooltip={score} actualVal={score} vertical={true}/>: <></>}
                </div>
                </div>
              </div>

              <div className="uk-overlay uk-overlay-primary uk-position-bottom relatedNameBox" style={{lineHeight:lineSizePercent + 'em'}}>
              <div style={{lineHeight:lineSizePercent + 'em',fontSize: textSizePercent + 'em'}} className='priceText borderedText'>{price}</div>
                  <span style={{lineHeight:lineSizePercent + 'em',fontSize: textSizePercent + 'em'}} className='borderedText'>{useName}</span>
                  
              </div> 
            </div>
          )
          })}
          {showArrows?
          <button aria-label={lng('Mover para Esquerda')} onClick={this.moveLeft} className="link-button moveBtn moveLeftBtn"><i className="fas fa-chevron-left"></i></button>:<></>
          }
          {showArrows?
          <button aria-label={lng('Mover para Direita')} onClick={this.moveRight} className="link-button moveBtn moveRightBtn"><i className="fas fa-chevron-right"></i></button>:<></>
          }
            </div>
      : <></>}
    </div>);
}
}

AmazonCarrousel.propTypes = {
  height: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  showItemNumber:PropTypes.number.isRequired,
  imgSize:PropTypes.string.isRequired,
};
AmazonCarrousel.defaultProps = {
  query: '',
  title:'',
  height: 272,
  imgSize:'',
  showItemNumber:4,
  gradeOptions:{
    minVal: 0,
    maxVal: 5,
    increment: 0.5,
    rgbStart : [153,0,0],
    rgbMiddle : [255, 255, 153],
    rgbEnd: [0, 255, 85]
  }
}

export default AmazonCarrousel;


//onMouseEnter={()=>{let n = this.state.hover; n[index]=true; this.setState({hover:n}) } } onMouseLeave={()=>{let n = this.state.hover; n[index]=false; this.setState({hover:n}) } }