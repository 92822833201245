import React, { Component } from 'react';
import "./UrlParamRemoverTags.css";
import MyAppPool from '../AppPool';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { mobToObj, isSet } from './../util/typeu';
import { lng } from '../ulanguages/language';

@observer
class UrlParamRemoverTags extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickSingle = this.onClickSingle.bind(this);
    this.onClickMultiple = this.onClickMultiple.bind(this);
    this.clearAllUrlParams = this.clearAllUrlParams.bind(this);
}
  onClickSingle(val,key)
  {
    MyAppPool.cLocation.setSingularParamVal('',key);
    this.props.onChangeFunction(val,key);
  }
  onClickMultiple(val,key)
  {
    MyAppPool.cLocation.setMultipleParamVal(val,key);
    this.props.onChangeFunction(val,key);
  }
  clearAllUrlParams()
  {
    MyAppPool.cLocation.clearAllUrlParams();
  }

  
  componentDidMount()
  {

  }

  render(){
    let {paramToNiceText} = this.props;
    let parameters = MyAppPool.cLocation.formatedUrlParameters;
    let paramEntries = Object.entries(parameters);

    for(let i=0;i<paramEntries.length;i++)
    {
      let val=paramEntries[i][1];
      
      if (val.indexOf('_')!==-1)
        paramEntries[i][1]=val.split('_')
    }

    return <div className="comp_urlParamRemoverTags">
    {paramEntries.map((item, index) => {
        let filterKey=item[0];
        let value=item[1];
        if (value==='')
          return <></>
        let text=value;
        if (isSet(paramToNiceText[filterKey]))
          if (isSet(paramToNiceText[filterKey][''+value]))
            text=paramToNiceText[filterKey][value];
        //multiple url param
        if (Array.isArray(value))
            return value.map((item, index) => {
              return <div key={index} className="uprItemSpace" data-uk-tooltip={filterKey} onClick={()=>this.onClickMultiple(item,filterKey)}><button aria-label={item} className={'uprItemButton '}  value={item}>{item}</button> <i class="fas fa-times"></i></div>
          });
        //single url param
        return (<div key={index} className="uprItemSpace" data-uk-tooltip={filterKey}  onClick={()=>this.onClickSingle(value,filterKey)}><button aria-label={text} className={'uprItemButton '} value={value}>{text}</button> <i className="fas fa-times"></i></div>)
      })
    }
    {
      paramEntries.length>0?
      <div className="uprItemSpace" data-uk-tooltip={lng('Remove todos os filtros da pesquisa')}  onClick={()=>this.clearAllUrlParams()}><button aria-label={lng ('Limpar Filtros')} className={'uprItemButton '} >{lng ('Limpar Filtros')}</button> <i className="fas fa-times"></i></div>
      :
        <></>
    }
    </div>
  }
}

UrlParamRemoverTags.propTypes = {
  onChangeFunction:PropTypes.func.isRequired,
  paramToNiceText: PropTypes.object
};

UrlParamRemoverTags.defaultProps = {
  onChangeFunction:()=>{},
  paramToNiceText:{
    'tipo':'tipo',
    'exibicao':'exibicao'
  }
};


export default UrlParamRemoverTags;
