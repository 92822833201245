
import { computed, toJS } from 'mobx';
import MyAppPool from '../AppPool';
import { routes } from '../store/ApiRoutes';
import { getCharacterRoute, getMagazineRoute, getMangaPeopleRoute, getMangaPersonagensRoute, getMangaRelatedRoute, getObraRoute, getPersonRoute } from '../store/AppRoutes';
import { getRelatedTypeIcon } from '../util/icon';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { convertDataToShowable, getAnchorName, getSEOUriPath } from '../util/text';
import { contentFastTypeToType, contentTypeToFastType, isSet, mobToObj } from '../util/typeu';

let validTypes = {};
validTypes[contentTypeToFastType("music")]=true;
validTypes[contentTypeToFastType("anime")]=true;
validTypes[contentTypeToFastType("manga")]=true;
validTypes[contentTypeToFastType("producer")]=true;
validTypes[contentTypeToFastType("publisher")]=true;
validTypes[contentTypeToFastType("person")]=true;
validTypes[contentTypeToFastType("character")]=true;
validTypes[contentTypeToFastType("user")]=true;

export default class ResourceMediaController
{
    getResourceMedia(resourceType,resourceId)
    {
        if(validTypes[resourceType]!==true)
            return;
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let resData = data0.data;
            MyAppPool.session.media[''+resourceType][''+resourceId] = resData;
            return Promise.resolve(resData);
        },
        routes.getResourceMedia,true,
        {resourceId:resourceId,resourceType:resourceType})
        .catch(e => console.log(("Error Getting Resource Media"),e));
    }


    uploadResourceMediaImage(resourceType,resourceId,purpose,imageBase64,provider)
    {
        if(validTypes[resourceType]!==true)
            return;
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            MyAppPool.cAlert.notifyOk(lng("Sucesso!"));
            this.getResourceMedia(resourceType,resourceId);
            return Promise.resolve();
        },
        routes.saveResourceMedia,true,
        {resourceId:resourceId,resourceType:resourceType,purpose:purpose,
        provider:provider,type:'image',imageBase64:imageBase64})
        .catch(e => console.log(("Error Getting Resource Media"),e));
    }

    saveResourceMediaVideo(resourceType,resourceId,purpose,link,provider)
    {
        if(validTypes[resourceType]!==true)
            return;
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            MyAppPool.cAlert.notifyOk(lng("Sucesso!"));
            this.getResourceMedia(resourceType,resourceId);
            return Promise.resolve();
        },
        routes.saveResourceMedia,true,
        {resourceId:resourceId,resourceType:resourceType,type:'video',
        provider:provider,purpose:purpose,link:link})
        .catch(e => 
            {
                MyAppPool.cAlert.notifyGenericError('Error Saving Resource Media','Resource Media Controller');
            });
    }

    saveResourceMediaDefault(resourceType,resourceId,purpose,link)
    {
        if(validTypes[resourceType]!==true)
            return;
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            MyAppPool.cAlert.notifyOk(lng("Sucesso!"));
            return Promise.resolve();
        },
        routes.setResourceMediaDefault,true,
        {resourceId:resourceId,resourceType:resourceType,field:purpose,value:link})
        .catch(e => 
            {
                MyAppPool.cAlert.notifyGenericError(e,'Resource Media Controller Set Default');
            });
    }

    deleteResourceMedia(resourceType,resourceId,link)
    {
        if(validTypes[resourceType]!==true)
            return;
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            MyAppPool.cAlert.notifyOk(lng("Sucesso!"));
            this.getResourceMedia(resourceType,resourceId); 
            return Promise.resolve();
        },
        routes.deleteResourceMedia,true,
        {resourceId:resourceId,resourceType:resourceType,link:link})
        .catch(e => 
            {
                MyAppPool.cAlert.notifyError(lng('Erro ao deletar imagem!'),'','Resource Media Controller Delete Resource Media');
                console.log(("Error Deleting Resource Media"),e);
            });
    }
    
}