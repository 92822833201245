import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { lng } from '../ulanguages/language';
import { onlyLettersAndNumbers } from '../util/text';
import { scrollToAnchor } from '../util/windowUtil';
import "./TagsColumn.css";
import { isSet, mobToObj } from '../util/typeu';
import { getFlag } from '../util/icon';

@observer
class TagsColumn extends Component {
    constructor(props) {
      super(props);
      this.state = {allowSpoiler:false};
    }

  componentDidMount()
  {
  }

  render()
  {
    let {items,title} = this.props;
    let {allowSpoiler} = this.state;

    if (!isSet(items) || items.length==0)
        return <></>;
    let spoilerCount=0;

    return (
        <div className="comp_extlinksBox"> 
            <div className="uk-card uk-card-default mainCard">
            <span>{title} <br/></span>
              <div style={{marginTop:"15px"}}>
                  {items.map((item,index)=>{ 
                    let isSpoiler = (item.isgeneralspoiler==1 || item.ismediaspoiler==1);
                    let color={};
                    if (isSpoiler)
                    {   spoilerCount++;
                        color = {"color":"#800000",fontWeight: "bold"};
                    }

                    if (!allowSpoiler && isSpoiler)
                        return <></>;
                    
                    
                    return <div className="link-button tagdiv" uk-tooltip={item.description}>
                        <span style={color}>{item.name}</span>
                            <br />
                        </div>
                  })}
                  <br/>
                  {spoilerCount>0?
                  <span className="link-button" onClick={()=>this.setState({allowSpoiler:!allowSpoiler})}>
                        {allowSpoiler?lng("Hide Spoilers"):lng("Show Spoilers")+"("+spoilerCount+")"}
                            <br />
                  </span>
                :<></>}
               </div>
            </div>
        </div>);
  }
}

TagsColumn.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          color: PropTypes.string,
          description: PropTypes.string,
          isgeneralspoiler: PropTypes.number,
          ismediaspoiler: PropTypes.number,
          downvote: PropTypes.number,
          upvote: PropTypes.number,
          rtid: PropTypes.number,
        })
      ),
      title:PropTypes.string
};
TagsColumn.defaultProps = {
    items:[],
    title:lng("Tags")
};

export default TagsColumn;
