
import { computed } from 'mobx';
import MyAppPool from '../AppPool';
import { isSet, mobToObj } from '../util/typeu';
import { getCurrentPathInAnotherLang, isServer } from '../util/windowUtil';
import { getAlternateLocales, getUrlAlternatesWithLocale, lng } from '../ulanguages/language';
import { useHistory } from 'react-router'

let currIndex = 0;
if (!isServer())
{
  var _wr = function(type) 
  {
    let name = type.replace('history_','');
    var orig = window.history[name];
    return function() {
        var rv = orig.apply(this, arguments);
        var e = new Event(type);
        e.arguments = arguments;
        window.dispatchEvent(e);
        return rv;
        };
  };

  window.history.pushState = _wr('history_pushState'); 
  window.history.replaceState = _wr('history_replaceState');

  function updateAlternateUrlsLocales(){
    if (isSet(MyAppPool.cLocation))
    {
      let alternateLocales = getAlternateLocales();
      let alternateUrls = getUrlAlternatesWithLocale();
      MyAppPool.cLocation.setCurrentLocales(alternateLocales);
      MyAppPool.cLocation.setCurrentAlternateUrls(alternateUrls);
      MyAppPool.cLocation.setAlternativeUrls();
    }
  }

  window.addEventListener('popstate', 
  function(e){
    //console.log('back changed',window.location.pathname );
    MyAppPool.setCurrentLocation(mobToObj(window.location));
    MyAppPool.session.breadcrumbs.currentPath=window.location.pathname;
    let histLength = MyAppPool.session.breadcrumbs.history.length;
    updateAlternateUrlsLocales();
    if (histLength>0)
    {
        if (currIndex>0)
            currIndex-=1;
        //MyAppPool.session.breadcrumbs.history = MyAppPool.session.breadcrumbs.history.slice(0,histLength-1);
    }
  });
  window.addEventListener('history_pushState', 
  function(e){
    //console.log('push changed',window.location.pathname);
    MyAppPool.setCurrentLocation(mobToObj(window.location));
    MyAppPool.session.breadcrumbs.currentPath=window.location.pathname;
    let histLength = MyAppPool.session.breadcrumbs.history.length;
    updateAlternateUrlsLocales();
    for(let i=0; i<histLength;i++)
    {
        if (MyAppPool.session.breadcrumbs.history[i].path===window.location.pathname)
        {
            if (currIndex!==i || currIndex===histLength-1)
            {
                currIndex=i;
                return;
            }
        } 
    }
    if (currIndex != histLength-1)
    {
        MyAppPool.session.breadcrumbs.history = MyAppPool.session.breadcrumbs.history.slice(0,currIndex+1);
        histLength = MyAppPool.session.breadcrumbs.history.length;
    }
    currIndex = histLength;
    MyAppPool.session.breadcrumbs.history[histLength] = {path:window.location.pathname,params:window.location.search};
  });

  window.addEventListener('history_replaceState', 
  function(e){
    //console.log('pop changed',window.location.pathname);
    MyAppPool.setCurrentLocation(mobToObj(window.location));
    //console.log(mobToObj(MyAppPool.session.location));
    MyAppPool.session.breadcrumbs.currentPath=window.location.pathname;
    updateAlternateUrlsLocales();
    let histLength = MyAppPool.session.breadcrumbs.history.length;
    if (histLength>0)
        MyAppPool.session.breadcrumbs.history[histLength-1] = {path:window.location.pathname,params:window.location.search};
    else
        MyAppPool.session.breadcrumbs.history[0] = {path:window.location.pathname,params:window.location.search};
  });
}
function getPrettyName(url)
{
    if (!isSet(url))
      return '';
    let terms = url.split('/');
    let name = '';
    if (terms.length>3)
    {
      for(let i=3;i<terms.length;i++)
      {
          name += terms[i]+'-';
      }
    }else
    {
      for(let i=0;i<terms.length;i++)
      {
          name += terms[i]+'-';
      }
    }
    name = name.replaceAll('-',' ');
    name = name.split(" ");
    for (let i = 0; i < name.length; i++) {
        if (name[i].length>0)
            name[i] = name[i][0].toUpperCase() + name[i].substr(1);
        else
            name[i] = '';
    }
    name = name.join(" ");
    if (name.trim()==='')
      name=lng('Home');
    return name;
}

export default class LocationController
{
    @computed get getCurrentCrumbs()
    {
        let histLength = MyAppPool.session.breadcrumbs.history.length;
        let currentPath = MyAppPool.session.breadcrumbs.currentPath;
        let crumbsElements = [];
        let showIndex = histLength-1;
        for(let i=(histLength-1);i>=0;i--)
        {
            if (MyAppPool.session.breadcrumbs.history[i].path===currentPath)
            {
                showIndex = i;
                break;
            }
        }
        let minStart = showIndex-4;
        let maxStart = showIndex+4;
        if (minStart<0)
            minStart=0;
        if (maxStart>histLength)
            maxStart = histLength;
        for(let i=minStart;i<maxStart;i++)
        {
            let element = MyAppPool.session.breadcrumbs.history[i];
            let retEl = {url:element.path, name: getPrettyName(element.path), params:element.params}
            crumbsElements[crumbsElements.length] = retEl; 
        }
        return crumbsElements;
    }

  myReactRouterHistory=null;
  setReactRouterHistory(reactRouterHistory){
    this.myReactRouterHistory=reactRouterHistory;
  }
  setCurrentLocales(locales){
    MyAppPool.session.locationExtra.locales = locales;
  }
  
  setAlternativeUrls(){
    MyAppPool.session.locationExtra.ptPath = getCurrentPathInAnotherLang("pt");
    MyAppPool.session.locationExtra.enPath = getCurrentPathInAnotherLang("en");
    MyAppPool.session.locationExtra.dePath = getCurrentPathInAnotherLang("de");
    MyAppPool.session.locationExtra.esPath = getCurrentPathInAnotherLang("es");
  }

  setCurrentAlternateUrls(alternates){
    MyAppPool.session.locationExtra.alternateUrls = alternates;
  }
  
  updateUrl(path,urlParams='')
  {
    if (urlParams!=='')
      urlParams = '?'+urlParams;
      
    if (!isServer())
    {
      if (this.myReactRouterHistory!==null)
        this.myReactRouterHistory.push(path + urlParams);
        else
        window.history.pushState('page', '', path + urlParams);
    }
  }
  setSingularParamVal(val,key)
  {
    if (!isServer())
    {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get(key)===''+val)
        urlParams.set(key, '');
      else
        urlParams.set(key, val);
      this.updateUrl(MyAppPool.session.location.pathname,urlParams);
    }
  }
  setDefinitiveParamVal(val,key)
  {
    if (!isServer())
    {
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.set(key, val);
      this.updateUrl(MyAppPool.session.location.pathname,urlParams);
    }
  }
  clearAllUrlParams()
  {
    if (!isServer())
    {
      let urlParams = new URLSearchParams('');
      this.updateUrl(MyAppPool.session.location.pathname,urlParams);
    }
  }
  setMultipleParamVal(val,key)
  {
    if (!isServer())
    {
      let urlParams = new URLSearchParams(window.location.search);
      let paramList = urlParams.get(key);
      
      if (!isSet(paramList) || paramList==='')
        paramList=''+val;
      else
      {
        let paramListArray= paramList.split('_');
        let itemPos = paramListArray.indexOf(''+val);
        if (itemPos!==-1)
        {
          paramListArray = [...paramListArray.slice(0,itemPos),...paramListArray.slice(itemPos+1,paramListArray.length)];
        }else
        {
          paramListArray[paramListArray.length] = ''+val;
        }
        if (paramListArray.length==1 && paramListArray[0]=='')
          paramList='';
        paramList=paramListArray.join('_');
      } 
      urlParams.set(key, paramList);
      this.updateUrl(MyAppPool.session.location.pathname,urlParams);
    }
  }


  @computed get formatedUrlParameters(){
    let filterParameters = decodeURIComponent(MyAppPool.session.location.search).replace('?','').replaceAll('+',' ').split("&");
    let parameters = {};
    if (filterParameters.length==1 && filterParameters[0]==='')
      return {};
    for(let i=0;i<filterParameters.length;i++)
    {
      let itemVar = filterParameters[i].split("=");
      parameters[itemVar[0]]=itemVar[1];
    }
    return parameters;
  }

}