import { observer } from 'mobx-react';
import React, { Component } from 'react';
import "./EditableDescription.css";
import MyAppPool from './../AppPool';
import { lng } from '../ulanguages/language';
import { PropTypes } from 'prop-types';
import { genRandId } from './WYSIWYGWriterUtils';
import WYSIWYGWriter from './WYSIWYGWriter';

@observer
class EditableDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
        editing:false, 
        hover:false,
        editorId:genRandId()};
}

  componentDidMount()
  {

  }

  render(){
    let {onSend,startContent,extraActions,canEdit} = this.props;
    let {editing,hover} = this.state;
    let actions = [...[
    'superscript',
    'link',
    'spoiler',
    'insertOrderedList',
    'insertUnorderedList'], ...extraActions];

    return <div className="comp_editable_description" >
        {(!editing)?
                <div className="" onMouseLeave={()=>this.setState({hover:false})} onMouseOver={()=>this.setState({hover:true})} >
                {(hover && canEdit)?<i class="fas fa-edit editIcon clickable" onClick={()=>this.setState({editing:true})}></i>:<></>}

                <div
                  dangerouslySetInnerHTML={{__html: startContent}}
                />
                </div>
                :
                <WYSIWYGWriter id={this.state.editorId} hasSendButton={1} hasCloseButton={1}
                  placeHolder={lng("Escreva sua folha de rosto!")}
                  actions={actions} 
                  actionClasses={{
                      insertOrderedList:'list-ol',
                      insertUnorderedList:'list-ul',
                      table:'border-all',
                      spoiler: 'eye-slash',
                      emoji:'grin-squint-tears',
                      youtube:'fab fa-youtube'
                      }}
                  actionTags={{
                      link:'<a ',
                      spoiler:'<span class="spoiler">'
                  }}
                  onCloseClick = {()=>this.setState({editing:false})}
                  onSendClick = {(content)=>{
                    return onSend(content).then(()=>this.setState({editing:false}))
                    .catch((error)=>{MyAppPool.cAlert.notifyUser("error when sending message",error,500); console.log(error)});
                  }
                }
                  startContent = {startContent}
                  />
                }
</div>
  }
}

EditableDescription.propTypes = {
    extraActions: PropTypes.arrayOf(
        PropTypes.string
    ),
    onSend:PropTypes.func.isRequired,
    startContent:PropTypes.string,
    canEdit:PropTypes.bool.isRequired,
};

EditableDescription.defaultProps = {
    extraActions:[],
    onSend: (content) => {},
    startContent:'',
    canEdit:false
};


export default EditableDescription;
