
import { computed, toJS } from 'mobx';
import { routes } from '../store/ApiRoutes';
import { getDefaultSession, getDefaultUser } from '../store/defaultSessionVals';
import { baseHash, MD5 } from '../util/secUtil';
import { isServer } from '../util/windowUtil';
import MyAppPool from '../AppPool';
import { getCurrentLanguage, lng } from '../ulanguages/language';
import { brasRequest, fetchAndProcess } from '../util/net';
import { animeFastResourceType, contentFastTypeToType, contentTypeToFastType, isSet, mangaFastResourceType, mobToObj } from '../util/typeu';
import { getUserRoute } from '../store/AppRoutes';
import { getUserAvatar } from '../util/icon';
import { capitalizeFirstLetterString } from '../util/text';

let refreshCallStack=0;

function consolidateGrades(gradesArr)
{
    gradesArr[9]+= gradesArr[10];
    gradesArr[5]+= gradesArr[0]+gradesArr[1] + gradesArr[2] + gradesArr[3] + gradesArr[4];
}



export default class UserController
{
    @computed get getCurrentUserImg()
    {
        let userImg=getUserAvatar(MyAppPool.currentUser.img,MyAppPool.currentUser.mailHash);
        return userImg;
    }

    getUserSampleObject(sessionSampleUser)
    {
        let author = {
            name:'...',
            img:'',
            hash:'',
            id:0
        };
        if (isSet(sessionSampleUser))
        {
            author.name=sessionSampleUser.title;
            author.img=sessionSampleUser.mainimg;
            author.hash=sessionSampleUser.mhash;
            author.id=sessionSampleUser.author;
        }
        return author;
    }

    fetchUserById(index)
    {
        let loadId;
        if (Number(index)===Number(MyAppPool.session.userPage.currentUserPage))
        {   
            loadId = MyAppPool.cGlobalLoadingBar.start();
            MyAppPool.cGlobalLoadingBar.end(loadId,true);
            return Promise.resolve(true);
        }
        let resourceType = contentTypeToFastType('user');
        MyAppPool.session.userPage.profiles[index] = {ready:0};
        MyAppPool.session.userPage.currentUserPage=index;
        loadId = MyAppPool.cGlobalLoadingBar.start(1500);

        if (!isServer())
            brasRequest(routes.resourcePopularCount,false,{resourceType:resourceType,resourceId:index});
        MyAppPool.cSamples.getSamplesByArrayIdsList(resourceType,[index]);

        return fetchAndProcess(this.setSessionUser,
        routes.getUserByIdLng,true,{uid:index,lang: getCurrentLanguage()})
        .then((data) => {
            MyAppPool.cGlobalLoadingBar.end(loadId,true);
            return Promise.resolve(data);
        })
        .catch((e) => {
            MyAppPool.cGlobalLoadingBar.end(loadId,false);
            console.log("Error Fetching User Page",e)
        });

    }

    setSessionUser(data0,ready)
    {
        if (!data0.ok)
        return Promise.reject();
    
        let data = data0;
        let userPage = {};
        let sampleRequests = {};
        for(let resourceType=0;resourceType<=15;resourceType++)
        {
            sampleRequests[resourceType] = {resourceType:resourceType, ids:[]}; 
        }
        if (!data.empty)
        {
           // console.log(data);
            userPage = data.profile.data[0];
            userPage.titles = [];
            userPage.tasks = [];
            userPage.votes = {};
            userPage.evals = {};
            userPage.lists = [];
            userPage.countwantAnime = 0;
            userPage.countcurrentAnime = 0;
            userPage.countfinishedAnime = 0;
            userPage.countdroppedAnime = 0;
            userPage.countwantManga = 0;
            userPage.countcurrentManga = 0;
            userPage.countfinishedManga = 0;
            userPage.countdroppedManga = 0;
            userPage.animeGrades = new Array(11).fill(0);
            userPage.mangaGrades = new Array(11).fill(0);
            for(let i =0;i <  data.titles.data.length; i++)
            {
                let item = data.titles.data[i];
                userPage.titles.push(item);
            }
            for(let i =0;i <  data.votes.data.length; i++)
            {
                let item = data.votes.data[i];
                if (!isSet(userPage.votes[item.resourcetype]))
                    userPage.votes[item.resourcetype] = {};
                userPage.votes[item.resourcetype][item.resourceid]=item.votevalue;
                sampleRequests[item.resourcetype].ids.push(item.resourceid);
            }
            let favorites = [];
            let tempFavorites = Object.entries(userPage.votes);
            tempFavorites.map((elem,index)=>{
                let item = {
                    resourcetype:parseInt(elem[0]),
                    itemIds:Object.keys(elem[1]).map((elem)=>parseInt(elem)),
                    name:lng(capitalizeFirstLetterString(contentFastTypeToType(parseInt(elem[0]))))
                };
              //  console.log("FAVORITE LIST::",item);
                
                favorites.push(item);
            });

            let lists = [];
            for(let i =0;i <  data.lists.data.length; i++)
            {
                let item = data.lists.data[i];
                console.log("LOOKHER:",item);
                let cleanArray = [];
                for(let k=0;k<item.itens.data.length;k++)
                {
                    let listItem = item.itens.data[k];
                    cleanArray.push(listItem.resourceid);
                    sampleRequests[item.resourcetype].ids.push(listItem.resourceid);
                }
                item.itemIds = cleanArray;
                lists.push(item);
                if (item.resourcetype===animeFastResourceType)
                {
                    if(item.essentialid===1)//quero
                        userPage.countwantAnime=item.itemIds.length;
                    if(item.essentialid===2)//watching
                        userPage.countcurrentAnime=item.itemIds.length;
                    if(item.essentialid===3)//finished
                        userPage.countfinishedAnime=item.itemIds.length;
                    if(item.essentialid===4)//dropped
                        userPage.countdroppedAnime=item.itemIds.length;
                }
                if (item.resourcetype===mangaFastResourceType)
                {
                    if(item.essentialid===1)//quero
                        userPage.countwantManga=item.itemIds.length;
                    if(item.essentialid===2)//watching
                        userPage.countcurrentManga=item.itemIds.length;
                    if(item.essentialid===3)//finished
                        userPage.countfinishedManga=item.itemIds.length;
                    if(item.essentialid===4)//dropped
                        userPage.countdroppedManga=item.itemIds.length;
                }
            }
            userPage.lists = lists;
            for(let i =0;i < data.evals.data.length; i++)
            {
                let item = data.evals.data[i];
                if (!isSet(userPage.evals[item.resourcetype]))
                    userPage.evals[item.resourcetype] = {};
                userPage.evals[item.resourcetype][item.resourceid]=item.grade;
                if(item.resourcetype===0)
                    userPage.animeGrades[Math.floor(item.grade)]+=1;
                if(item.resourcetype===1)
                    userPage.mangaGrades[Math.floor(item.grade)]+=1;
            }
           // console.log("grade count:",userPage.grades);
            consolidateGrades(userPage.animeGrades);
            consolidateGrades(userPage.mangaGrades);
            let sampleRequestArray = Object.values(sampleRequests);
            for(let i=0;i<sampleRequestArray.length;i++)
            {
                let item = sampleRequestArray[i];
                if (item.ids.length>0)
                    MyAppPool.cSamples.getSamplesByArrayIdsList(item.resourceType,item.ids);
            }
            userPage.favorites = favorites;
            userPage.displayTitles = userPage.titles.slice(0,2);
            userPage.resourceType = contentTypeToFastType("user");
            userPage.ready = true;
            userPage.baseUrl = getUserRoute(userPage.id,userPage.name);
            MyAppPool.session.userPage.profiles[userPage.id] = userPage;
            

           //// console.log("userPAGE:::",userPage);
          //  console.log("SamplesRequest:::",sampleRequests);
        }
    }

    updateResourceGradePage(resourceId,resourceType,grade)
    {
        let userPage = MyAppPool.session.userPage.profiles[MyAppPool.currentUser.userid];
        if (isSet(userPage))
        {
            userPage.evals[resourceId][resourceType]=grade;
        }
    }

    removeListEntryPage(listId,resourceId)
    {
        let userPage = MyAppPool.session.userPage.profiles[MyAppPool.currentUser.userid];
       // console.log("REMOVING::",listId,resourceId);
        if (isSet(userPage))
        {//remove this entry from the user page list, if it is preloaded already
            for(let i=0; i < userPage.lists.length;i++)
            {
                let pageList = userPage.lists[i];
                if (listId===pageList.listid)
                {
                    pageList.itemIds = pageList.itemIds.filter((item)=>item!==resourceId);
                }
            }
        }
    }

    addListEntryPage(listId,resourceId)
    {
        let userPage = MyAppPool.session.userPage.profiles[MyAppPool.currentUser.userid];
        if (isSet(userPage))
        {//remove this entry from the user page list, if it is preloaded already
        for(let i=0; i < userPage.lists.length;i++)
        {
            let pageList = userPage.lists[i];
            if (listId===pageList.listid)
            {
                pageList.itemIds.push(resourceId);
            }
        }
        }
    }

    //list ob: {essentialid,itens,listid,name,ordering,resourcetype,visibility}
    createListPage(newList)
    {
        let userPage = MyAppPool.session.userPage.profiles[MyAppPool.currentUser.userid];
        newList.itemIds =[];
        if (isSet(userPage))
        {
            userPage.lists = [...userPage.lists,newList];
        }
    }
    deleteListPage(listId)
    {
        let userPage = MyAppPool.session.userPage.profiles[MyAppPool.currentUser.userid];
        if (isSet(userPage))
        {
            userPage.lists = userPage.lists.filter((item)=>item.listid!==listId);
          //  console.log("ULISTs::",mobToObj(userPage.lists));
        }
    }
    updateListPage(listId,name,ordering,orderChange)
    {
        let userPage = MyAppPool.session.userPage.profiles[MyAppPool.currentUser.userid];
        let newOrder = ordering+orderChange;
        if (newOrder<0)
            newOrder=0;
        for(let i=0; i < userPage.lists.length;i++)
        {
            let pageList = userPage.lists[i];
            if (listId===pageList.listid)
            {
                pageList.name = name;
                pageList.ordering = newOrder;
            }else{//adjust everyone new positions
                if (orderChange!==0)
                {
                    if(orderChange>0)
                    {
                    if (pageList.ordering<=newOrder
                    && pageList.ordering>ordering)
                        pageList.ordering+=-1;
                    }
                    if(orderChange<0)
                    {
                        if (pageList.ordering>=newOrder
                        && pageList.ordering<ordering)
                            pageList.ordering+=1;    
                    }
                }
            }
        }
        userPage.lists = userPage.lists.sort((a,b)=> {
            if (a.ordering<b.ordering) return -1;
            if (a.ordering>b.ordering) return 1;
            return 0;
        });
       // console.log("PAGELISTS::",mobToObj(userPage.lists));
        return Promise.resolve();
    }

    @computed get currentUserPage()
    {
        let data = toJS(MyAppPool.session.userPage.profiles[MyAppPool.session.userPage.currentUserPage]);
        if (!isSet(data))
            return {ready:0};
        return data;
    }

    /*
    @profile: {bio:"bioText",avatar:"imgUrl",banner:"bannerUrl"}
    */
    updateAuthedUserProfile(profile){
        let {bio,avatar,banner} = profile;
       // console.log("SENT:",profile);
       MyAppPool.cAlert.notifyOk(lng("Salvando..."));
        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
                return Promise.reject(data0.msg);
            let data = data0.data;
            let {bio,avatar,banner} = data[0];
          //  console.log("GOT:",data[0]);
            let userPage = MyAppPool.session.userPage.profiles[MyAppPool.currentUser.userid];
            MyAppPool.currentUser.img = avatar;
            if (isSet(userPage))
            {
                userPage.mainimg=avatar;
                userPage.description = bio;
                userPage.banner = banner;
            }
            MyAppPool.session.userPage.profiles[MyAppPool.currentUser.userid]=userPage;
            MyAppPool.cAlert.notifyOk(lng("Sucesso!"));
            return Promise.resolve();
        },
        routes.userUpdateProfile,
        false,
        {bio,avatar,banner}
        )
        .catch(e => {
       //     console.log("Error updating user profile:",e); 
            MyAppPool.cAlert.notifyGenericError(e,"user, update user profile");
        return Promise.reject(e);});
        
    }

    createUserTask(taskname,details){
        let userId = MyAppPool.currentUser.userid;
        if (userId<=0)
            return;

        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
            {
                
                return Promise.reject(data0.msg);
            }
            let data = data0.data;
            //console.log("GOT:",data[0]);

            let userPage = MyAppPool.session.userPage.profiles[userId];
            if (isSet(userPage))
            {
                userPage.tasks=[data[0], ...userPage.tasks];
            }
            MyAppPool.session.userPage.profiles[userId]=userPage;
            return Promise.resolve();
        },
        routes.createUserTask,
        false,
        {taskname,details}
        )
        .catch(e => {
        let err = String(e);
        if (err.indexOf(412)!==-1){
            MyAppPool.cAlert.notifyError(lng("Você pode apenas requisitar uma task similar por vez. Espere a execução da ultima tarefa completar antes requisitar outra."),e,"create user task");
        }else{
            MyAppPool.cAlert.notifyGenericError(e,"user, create user task");
        }
        
        return Promise.reject(e);});
    }
    getUserRecentTasks(){
        let userId = MyAppPool.currentUser.userid;
        if (userId<=0)
            return;

        return fetchAndProcess ((data0,ready)=>{
            if (!data0.ok)
            {
                return Promise.reject(data0.msg);
            }
            let data = data0.data;
            let userPage = MyAppPool.session.userPage.profiles[userId];
            if (isSet(userPage))
            {
                userPage.tasks=data;
            }
            MyAppPool.session.userPage.profiles[userId]=userPage;
            //console.log("USERPAGE::",toJS(userPage))
            return Promise.resolve();
        },
        routes.getRecentUserTasks,
        false,
        {}
        )
        .catch(e => {
        MyAppPool.cAlert.notifyGenericError(e,"user, get user recent tasks");
        return Promise.reject(e);});
    }
}