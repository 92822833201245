import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { isSet } from '../util/typeu';
import "./MusicFloatingMenu.css";


class MusicFloatingMenu extends Component {

    constructor(props) {
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.wrapperRef = React.createRef();
        this.menuButtonRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.state = {
          menuRight:this.props.menuRight,
          menuBottom:this.props.menuBottom,
          menuOpacity: 0,
          menuIndex: -1,
          menuPreviousIndex: -2,
          scrollPosition: 0,
          menuVisibility: "hidden",
          myOpenIndex: 0
        };
        this.clickOutsideCheck=false;
    }

    //I'm currently trying to create a new component for Floating Music Menu
    //Still trying to figure out how to make functions work
    //have to find a way to pass music index to parent component
    handleMenuClick(e) {

        if(isSet(this.wrapperRef.current)){
          if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            this.clickOutsideCheck=true;
            this.closeMenu();
            console.log("remove listener");
          }
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    componentWillReceiveProps(newProps){
        
        let {menuRight,menuBottom,options,openIndex,menuIndex} = newProps;
        this.setState({menuRight:menuRight,menuBottom:menuBottom,options:options});
       // console.log("Scroll position => ", this.state.scrollPosition)

        let clickedOpen = false;
        let same = false;
        if(this.state.myOpenIndex !== openIndex){
          clickedOpen = true;
          this.setState({myOpenIndex : openIndex});
        }
        if(menuIndex === this.state.menuPreviousIndex){
          same = true;
        }
        this.setState({menuPreviousIndex : menuIndex});

       // console.log("Same=>",same);
      //  console.log("ClickedOpen ==> ", clickedOpen);

        if(newProps.scrollPosition != this.state.scrollPosition && typeof newProps.scrollPosition !== "undefined"){
          this.setState({scrollPosition:newProps.scrollPosition})
          this.closeMenu();
          return;
        }

        if(clickedOpen){
          if (same && this.state.menuVisibility === "visible")
          {
            this.closeMenu();
            return;
          }
          setTimeout(
            ()=>{
              this.setState({menuVisibility : "visible"});
              this.setState({menuOpacity : 1});
              document.addEventListener('click', this.handleMenuClick, false);
            //  console.log("timeout - open")
            }
          ,20);
        }
    }

    
    closeMenu(){
      this.setState({menuVisibility : "hidden"});
      this.setState({menuOpacity : 0});
      document.removeEventListener('click', this.handleMenuClick, false);
    }


    render(){

      let {options} = this.props;
        
       // console.log("Props: " + this.props)

        return (
          <div className="ytfloatingmenuWrapper" style={{visibility: this.state.menuVisibility, opacity: this.state.menuOpacity, 'right' : this.state.menuRight, 'bottom' : this.state.menuBottom}}>
            <div ref={this.wrapperRef} id="ytfloatingmenu" className="ytfloatingmenu">
                <ul className="ytmenulist">

                  {options.map((element, index) => {
                    return(
                      <li key={index} className="ytmenuitem" onClick={element.function}>
                        <span className={"fas ytmenuitemicon " + element.icon } ></span><p className="ytmenuoptiontext">{element.text}</p>
                      </li>
                      )
                  })}
                  
                </ul>
            </div>
        </div>
        );
    }
}

MusicFloatingMenu.propTypes = {
  menuBottom: PropTypes.number.isRequired,
  menuRight: PropTypes.number.isRequired,
  menuIndex: PropTypes.number.isRequired,
  scrollPosition: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      function: PropTypes.func.isRequired
    })
  ),
  openIndex: PropTypes.number.isRequired
}
MusicFloatingMenu.defaultProps = {
  menuBottom: 0,
  menuRight: 0,
  menuIndex: -1,
  scrollPosition: 0,
  options:[],
  openIndex: 0
};

export default MusicFloatingMenu;
