import { observer } from 'mobx-react';
import React, { Component } from 'react';
import "./UserTopBarBox.css";
import MyAppPool from './../AppPool';
import { lng } from '../ulanguages/language';
import DropDown from './DropDown';
import { isSet, mobToObj } from './../util/typeu';
import { getUserConfigRoute, getUserFavoriteRoute, getUserListRoute, getUserRoute } from '../store/AppRoutes';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import LinkV2 from './LinkV2';
import { isMobile } from '../util/mobile';

@observer
class UserTopBarBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setDesignTheme = this.setDesignTheme.bind(this);
}
setDesignTheme(val){
  MyAppPool.session.theme=val;
  MyAppPool.cPreference.setTheme(val);
}

  componentDidMount()
  {

  }

  render(){
    //<span>{lng('Olá Visitante,')}</span>
    let userId = MyAppPool.currentUser.userid;
    let userUrl="";
    if (!isMobile.any)
      userUrl=getUserRoute(userId,MyAppPool.currentUser.name,'#top');

    return <div className="comp_usertopbarbox">
        {(userId===0 || !isSet(userId))?
        <div>
            
            <button aria-label={lng('Entrar')} onClick={()=>{
                MyAppPool.cAuth.activateGlobalLoginBox();
            }} className="uk-button uk-button-primary sendButton">{lng('Entrar') }</button>
        </div>
        ://not logged in
        <div>
        <DropDown
        btnContent={
          <LinkV2 aria-label={MyAppPool.currentUser.name} to={userUrl}>
        <div className="uk-flex uk-flex-row">
        <img className="userImg"  src={MyAppPool.cUser.getCurrentUserImg} alt={lng("User")+" "+ MyAppPool.currentUser.name+ " "+lng("avatar")}/> 
        <p className="userNameText">{MyAppPool.currentUser.name}</p>
        </div>
        </LinkV2>
        }
        >
          <ul className="uk-nav uk-dropdown-nav uk-margin">
            <li><Link to={getUserListRoute(userId,MyAppPool.currentUser.name,'#naveg')}>{lng('Listas')}</Link></li>
            <li class="uk-nav-divider"></li>
            <li><Link to={getUserFavoriteRoute(userId,MyAppPool.currentUser.name,'#naveg')}>{lng('Favoritos')}</Link></li>
            <li class="uk-nav-divider"></li>
            <li><Link to={getUserConfigRoute(userId,MyAppPool.currentUser.name,'#naveg')}>{lng('Configurações/Preferências')}</Link></li>
            <li class="uk-nav-divider"></li>
            <li className="themeSelectionMenu">
                {lng("Tema")}<span>&nbsp;&nbsp;</span>
                <button aria-label={lng('Tema')+" "+lng("Claro")} style={{"background-color":"white"}} className="uk-button uk-button-default themeSelector"
                onClick={()=>{this.setDesignTheme("lightTheme")}}></button>
                <button aria-label={lng('Tema')+" "+lng("Escuro")} style={{"background-color":"black"}} className="uk-button uk-button-default themeSelector"
                onClick={()=>{this.setDesignTheme("darkTheme")}}></button>
            </li>
          </ul>
            <button aria-label={lng('Sair') } onClick={()=>{
                MyAppPool.cAuth.activateGlobalLoginBox();
            }} className="uk-button uk-button-primary sendButton">{lng('Sair') }</button>
        </DropDown>
        </div>
        }

    </div>
  }
}

UserTopBarBox.propTypes = {
};

UserTopBarBox.defaultProps = {
  
};


export default UserTopBarBox;
