import { getUserAgent, isServer } from "./windowUtil";
import MyAppPool from './../AppPool';
import { computed } from 'mobx';

class MobileChecker
{
	Android() {
		return getUserAgent().match(/Android/i);
	}
	BlackBerry() {
		return getUserAgent().match(/BlackBerry/i);
	}
	iOS() {
		return getUserAgent().match(/iPhone|iPad|iPod/i);
	}
	Opera() {
		return getUserAgent().match(/Opera Mini/i);
	}
	Windows() {
		return getUserAgent().match(/IEMobile/i);
	}
	anyByAgent() {
		return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
	}

	@computed get any()
	{
		if (!isServer())
		{
			return MyAppPool.session.isMobile;
		}
		return this.anyByAgent();
	}
}
export var isMobile = new MobileChecker();